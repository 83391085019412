import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const templateService = {
  getTemplates,
  getCaseTemplate,
  addTemplate,
  updateTemplate,
  deleteTemplate,
  getPlaceholders,
};

function getTemplates(flow_id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/email_flows/${flow_id}/templates`,
    requestOptions
  ).then(handleResponse);
}

function getCaseTemplate(case_id, template_id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${case_id}/email_flows/templates/${template_id}`,
    requestOptions
  ).then(handleResponse);
}

function addTemplate(flow_id, updateScheduledEmails, data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  let query = "";
  if (typeof updateScheduledEmails === "boolean") {
    query = `?add_scheduled_emails=${updateScheduledEmails}`;
  }

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/email_flows/${flow_id}/templates` +
      query,
    requestOptions
  ).then(handleResponse);
}

function updateTemplate(flow_id, template_id, updateScheduledEmails, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let query = "";

  if (typeof updateScheduledEmails === "boolean") {
    query = `?update_scheduled_emails=${updateScheduledEmails}`;
  }

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/email_flows/${flow_id}/template/${template_id}` +
      query,
    requestOptions
  ).then(handleResponse);
}

function deleteTemplate(flow_id, template_id, updateScheduledEmails) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  let query = "";

  if (typeof updateScheduledEmails === "boolean") {
    query = `?delete_scheduled_emails=${updateScheduledEmails}`;
  }

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/email_flows/${flow_id}/template/${template_id}` +
      query,
    requestOptions
  ).then(handleResponse);
}

function getPlaceholders() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/customer/config/placeholders`,
    requestOptions
  ).then(handleResponse);
}
