import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "@_actions/user.actions";
import { makeStyles } from "@material-ui/core";
import { Button, Paper } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PasswordField from "@_components/PasswordField/PasswordField";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { catchError } from "@_utils/helpers";

import styles from "./ChangePassword.module.css";

function ChangePassword({ setDisplayChangePassword }) {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userReducer);
  const useStyles = makeStyles({
    helperText: { fontSize: "12px !important" },

    confirmButton: {
      fontWeight: "bold !important",
      color: "var(--color-theme-blue) !important",
      border: "1px solid var(--color-theme-blue) !important",

      "&:hover": {
        color: "var(--color-theme-white) !important",
        backgroundColor: "var(--color-theme-blue) !important",
        boxShadow: "2px 6px 6px  #0000002",
      },
    },
    cancelButton: {
      fontWeight: "bold !important",
      color: "var(--color-theme-silver) !important",
      border: "1px solid var(--color-theme-silver) !important",
      "&:hover": {
        color: "var(--color-theme-white) !important",
        backgroundColor: "var(--color-theme-silver) !important",
        boxShadow: "2px 6px 6px  #0000002",
      },
    },
  });
  const classes = useStyles();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [NewPasswordError, setNewPasswordError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setCurrentPasswordError(false);
    setNewPasswordError(false);
    if (currentPassword === "") {
      setCurrentPasswordError(true);
    }
    if (newPassword === "") {
      setNewPasswordError(true);
    }
    if (currentPassword && newPassword) {
      let data = {
        current_password: currentPassword,
        new_password: newPassword,
      };
      dispatch(userActions.resetPassword(data));
    }
  };

  useEffect(() => {
    if (userState.status.USER_RESET_PASSWORD === "success") {
      setSuccessMessage(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_RESET_PASSWORD"));
        setDisplayChangePassword(false);
      }, 2000);
    } else if (catchError(userState.status.USER_RESET_PASSWORD)) {
      if (userState.status.USER_RESET_PASSWORD === "Invalid password.") {
        setCurrentPasswordError(true);
        setErrorMessage(userState.status.USER_RESET_PASSWORD);
      }
      if (userState.status.USER_RESET_PASSWORD === "Authentication failed") {
        setCurrentPasswordError(true);
        setErrorMessage(userState.status.USER_RESET_PASSWORD);
      }

      if (
        userState.status.USER_RESET_PASSWORD ===
        "Password must be at least 8 characters long and includes 1 number and 1 special character."
      ) {
        setNewPasswordError(true);
      }
    }
  }, [userState.status, dispatch, setDisplayChangePassword]);

  const handleClickAway = () => {
    setDisplayChangePassword(false);
    dispatch(userActions.clearStatusMessage("USER_RESET_PASSWORD"));
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper className={styles.container}>
        <p className={styles.changePasswordHeader}>Change Password</p>
        <PasswordField
          label="Current Password"
          placeholder="Current Password"
          name="Current Password"
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          error={currentPasswordError}
          helperText={currentPasswordError && errorMessage}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          required
        />

        <PasswordField
          label="New Password"
          placeholder="New Password"
          name="New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          error={NewPasswordError}
          required
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          helperText="Enter at least 8 characters (containing 1 number, 1 special character)."
        />
        <div className={styles.button_container}>
          <Button
            className={classes.cancelButton}
            onClick={() => {
              setDisplayChangePassword(false);
              dispatch(userActions.clearStatusMessage("USER_RESET_PASSWORD"));
            }}
          >
            Cancel
          </Button>
          <Button className={classes.confirmButton} onClick={handleSubmit}>
            Save
          </Button>
        </div>
        {successMessage && (
          <div className={styles.succsse_message}>
            <CheckCircleIcon />
            You have successfully changed your password.
          </div>
        )}
      </Paper>
    </ClickAwayListener>
  );
}

export default ChangePassword;
