export const addressConstants = {
    ADDRESS_GET_REQUEST: "ADDRESS_GET_REQUEST",
    ADDRESS_GET_SUCCESS: "ADDRESS_GET_SUCCESS",
    ADDRESS_GET_FAILURE: "ADDRESS_GET_FAILURE",

    ADDRESS_UPDATE_REQUEST: "ADDRESS_UPDATE_REQUEST",
    ADDRESS_UPDATE_SUCCESS: "ADDRESS_UPDATE_SUCCESS",
    ADDRESS_UPDATE_FAILURE: "ADDRESS_UPDATE_FAILURE",
    };
  
  