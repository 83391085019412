const Config = {
  // Settlement Portal
  BASE_SETTINGS: {
    key: 'BASE_SETTINGS',
    name: 'Base Settings',
  },
  TRANSLATIONS: {
    key: 'TRANSLATIONS',
    name: 'Translations',
  },
};

export default Config;
