import { templateConstants } from "@_constants/template.constants";
import { templateService } from "@_services/template.service";

export const templateActions = {
  getTemplates,
  addTemplate,
  updateTemplate,
  deleteTemplate,
  addTemplateState,
  selectTemplate,
  getPlaceholders,
  clearStatusMessage,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return { type: templateConstants.TEMPLATE_CLEAR_STATUS_MESSAGE, stateName };
  }
}

function getTemplates(flow_id) {
  return (dispatch) => {
    dispatch(request());
    templateService.getTemplates(flow_id).then(
      (data) => {
        dispatch(success(data, flow_id));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: templateConstants.TEMPLATE_GET_REQUEST };
  }
  function success(data) {
    return { type: templateConstants.TEMPLATE_GET_SUCCESS, data, flow_id };
  }
  function failure(error) {
    return { type: templateConstants.TEMPLATE_GET_FAILURE, error };
  }
}

function addTemplate(flow_id, updateScheduledEmails, data) {
  return (dispatch) => {
    dispatch(request());
    templateService.addTemplate(flow_id, updateScheduledEmails, data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: templateConstants.TEMPLATE_POST_REQUEST };
  }
  function success(data) {
    return { type: templateConstants.TEMPLATE_POST_SUCCESS, data };
  }
  function failure(error) {
    return { type: templateConstants.TEMPLATE_POST_FAILURE, error };
  }
}

function updateTemplate(flow_id, template_id, updateScheduledEmails, data) {
  return (dispatch) => {
    dispatch(request());
    templateService
      .updateTemplate(flow_id, template_id, updateScheduledEmails, data)
      .then(
        (data) => {
          dispatch(success(data));
        },
        (error) => {
          console.log(error);
          dispatch(failure(error.toString()));
          return error;
        }
      );
  };

  function request() {
    return { type: templateConstants.TEMPLATE_UPDATE_REQUEST };
  }
  function success(data) {
    return { type: templateConstants.TEMPLATE_UPDATE_SUCCESS, data };
  }
  function failure(error) {
    return { type: templateConstants.TEMPLATE_UPDATE_FAILURE, error };
  }
}

function deleteTemplate(flow_id, template_id, updateScheduledEmails) {
  return (dispatch) => {
    dispatch(request());
    templateService
      .deleteTemplate(flow_id, template_id, updateScheduledEmails)
      .then(
        (template_id) => {
          dispatch(success(flow_id, template_id));
        },
        (error) => {
          console.log(error);
          dispatch(failure(error.toString()));
        }
      );
  };

  function request() {
    return { type: templateConstants.TEMPLATE_DELETE_REQUEST };
  }
  function success(flow_id, template_id, data) {
    return {
      type: templateConstants.TEMPLATE_DELETE_SUCCESS,
      flow_id,
      template_id,
      data,
    };
  }
  function failure(error) {
    return { type: templateConstants.TEMPLATE_DELETE_FAILURE, error };
  }
}

function addTemplateState(addTemplate, templateType, trigger, emailFlowId) {
  return (dispatch) => {
    dispatch(request(addTemplate, templateType, trigger, emailFlowId));
  };

  function request(addTemplate, templateType, trigger, emailFlowId) {
    return {
      type: templateConstants.ADD_TEMPLATE,
      addTemplate,
      templateType,
      trigger,
      emailFlowId,
    };
  }
}

/**name: (selectedTemplate && selectedTemplate.name) || "",
    subject: (selectedTemplate && selectedTemplate.subject) || "",
    content: 
 * 
 * @param {{name:string, subject:string, content:string, id:string|undefined, emailFlowId:string}}} data 
 * @returns 
 */
function selectTemplate(data) {
  return (dispatch) => {
    dispatch(request(data));
  };

  function request() {
    return { type: templateConstants.SELECT_TEMPLATE, data };
  }
}

function getPlaceholders() {
  return (dispatch) => {
    dispatch(request());
    templateService.getPlaceholders().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: templateConstants.PLACEHOLDER_GET_REQUEST };
  }
  function success(data) {
    return { type: templateConstants.PLACEHOLDER_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: templateConstants.PLACEHOLDER_GET_FAILURE, error };
  }
}
