import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
// import styles from "./TextfieldInput.module.css";

const CustomTextField = withStyles({
  root: {
    '& .MuiInputBase-root':{
      height: "35px"
    },
    '& .MuiInputLabel-asterisk': {
      color: 'red',
    },
    '& label.Mui-focused': {
      color: 'var(--color-theme-blue)',
    },
    "& label.Mui-disabled": {
      color: "#888",
    },
    "& .Mui-disabled:hover": {
    },
    "& label": {
      color: "var(--color-theme-darkgray)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--color-theme-gray-white)",
    },
    "& .MuiOutlinedInput-multiline": {
      backgroundColor: "var(--color-theme-gray)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--color-theme-gray-white)",
      },
      '&:hover fieldset': {
        borderColor: 'var(--color-theme-gray-white)',
      },
      '&.Mui-focused fieldset': {
        border: '2px solid var(--color-theme-blue)',
      },
      "& .MuiInputBase-input": {
        color: "var(--color-theme-darkgray)",
      },
      "& .MuiInputBase-input::placeholder": {
        color: "var(--color-theme-silver)",
      },
      "& .MuiInputBase-input:disabled": {
        color: "var(--color-theme-disabled)",
      },
      '& .MuiSelect-icon': {
        color: 'var(--color-theme-silver)',
      },
      '&:hover': {
        backgroundColor: 'var(--color-theme-lightblue)',
        borderColor: 'var(--color-theme-gray-white)',
      },
    },
  },
})(TextField);

function TextfieldInput(props) {
  return (
    <CustomTextField
      {...props}
      fullWidth={true}
      variant="outlined"
      margin="normal"
    >
      {props.children}
    </CustomTextField>
  );
}

export default TextfieldInput;
