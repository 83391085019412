import React, { useState, useEffect, useRef } from "react";
import styles from "./Dashboard.module.css";
import SettingsIcon from "@material-ui/icons/Settings";
import DashboardSettingsPopup from "@_components/popups/DashboardSettingsPopup/DashboardSettingsPopup";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import {
  CircularProgress,
  Divider,
  MenuItem,
  Snackbar,
} from "@material-ui/core";
import { dateRange, dateGrouping } from "@_configs/dashboard.config";

import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "@_actions/dashboard.actions";
import {
  catchError,
  getUserDataFromJwt,
  translateDateRange,
} from "@_utils/helpers";
import { userActions } from "@_actions/user.actions";
import BarChartBlock from "@_components/BarChart/BarChartBlock";
import { userConstants } from "@_constants/user.constants";
import { Alert } from "@material-ui/lab";
import { sidebarActions } from "@_actions/sidebar.actions";
import { caseConstants } from "@_constants/case.constants";

function Dashboard({ setFilter }) {
  const dispatch = useDispatch();
  const chartsViewRef = useRef(null);
  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const userReducer = useSelector((state) => state.userReducer);
  const jwtData = getUserDataFromJwt();
  const dashboardSettings = dashboardReducer.settings;
  const userKpis = dashboardReducer.kpis;
  const ChartsData = dashboardReducer.chartData;
  const [generalChartSettings, setGeneralChartSettings] = useState(null);
  const chartDateRange = dashboardSettings.filter.date_range;
  const [dashboardSettingsPopup, setDashboardSettingsPopup] = useState(false);
  const [settingsLoaded, setSettingsLoaded] = useState(false);
  const [chartsViewWidth, setChartsViewWidth] = useState(0);

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // TODO snackbar and loading UI/UX
  // const [loading, setLoading] = useState(false);
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const maxNumberOfKpis = 8;

  useEffect(() => {
    dispatch(dashboardActions.getUserSettings());
    dispatch(dashboardActions.getCustomerKpis());
    dispatch(userActions.getUsers());
  }, [dispatch]);

  const chartOption3 = (chart) => {
    if (chart.class === "Payments") {
      if (chart.currency) {
        return chart.currency;
      } else if (dashboardSettings?.currencies?.length > 1) {
        return dashboardSettings?.currencies[0];
      } else return "";
    } else if (chart.class === "Emails" && chart.date_group_by === "received") {
      return chart.subgroup;
    } else {
      return "all";
    }
  };

  useEffect(() => {
    if (
      dashboardReducer.status.SETTINGS_GET === "success" ||
      dashboardReducer.status.SETTINGS_SET === "success"
    ) {
      const dateRange = translateDateRange(chartDateRange);
      const SettingsObject = {
        start_date: dateRange.startDate.toISOString().split("T")[0],
        end_date: dateRange.endDate.toISOString().split("T")[0],
        date_range: chartDateRange,
        date_grouping: dashboardSettings.filter.date_group_by,
        filter: {
          user: dashboardSettings.filter.user || " ",
        },
        currencies: dashboardSettings.currencies,
      };
      if (SettingsObject) {
        dashboardSettings.selected_charts.forEach((chart, index) => {
          let chartOptions = {
            ...SettingsObject,
            option1: chart.date_group_by,
            option2:
              chart.class === "Emails" && chart.date_group_by === "received"
                ? ""
                : chart.subgroup,
            option3: chartOption3(chart),
          };
          dispatch(
            dashboardActions.getTimeBasedChart(chart.class, chartOptions, index)
          );
        });
      }
      setGeneralChartSettings(SettingsObject);
      dispatch(
        dashboardActions.clearStatusMessage(
          dashboardReducer.status.SETTINGS_GET === "success"
            ? "SETTINGS_GET"
            : "SETTINGS_SET"
        )
      );
    }
    // eslint-disable-next-line
  }, [
    dispatch,
    dashboardReducer.status.SETTINGS_GET,
    dashboardReducer.status.SETTINGS_SET,
    chartDateRange,
    dashboardSettings.filter.date_group_by,
    dashboardSettings.filter.user,
    dashboardSettings.selected_charts,
    dashboardSettings.currencies,
  ]);

  useEffect(() => {
    if (dashboardReducer.status.KPIS_GET === "success") {
      setSettingsLoaded(true);
    } else if (catchError(dashboardReducer.status.KPIS_GET) === true) {
      setSnackBarMessage("Fetching KPI's failed");
      setSnackbarOpen(true);
      setSettingsLoaded(true);
    }
  }, [dashboardReducer.status.KPIS_GET, settingsLoaded]);

  useEffect(() => {
    if (chartsViewRef.current) {
      setChartsViewWidth(chartsViewRef.current.offsetWidth);
    }

    const handleResize = () => {
      if (chartsViewRef.current) {
        setChartsViewWidth(chartsViewRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleCloseDashboardSettingsPopup = () => {
    setDashboardSettingsPopup(false);
  };

  const editGeneralChartSettings = (event) => {
    if (event.target.name === "date_range") {
      const dateRange = translateDateRange(event.target.value);
      setGeneralChartSettings((prev) => {
        return {
          ...prev,
          date_range: event.target.value,
          start_date:
            event.target.value === "all"
              ? ""
              : dateRange.startDate.toISOString().split("T")[0],
          end_date:
            event.target.value === "all"
              ? ""
              : dateRange.endDate.toISOString().split("T")[0],
        };
      });
    } else if (event.target) {
      setGeneralChartSettings((prev) => {
        return {
          ...prev,
          date_range:
            event.target?.name === "start_date" ||
            event.target?.name === "end_date"
              ? "custom"
              : prev.date_range,
          [event.target?.name]: event.target?.value,
        };
      });
    }
  };

  const editChartFilter = (event) => {
    event.persist();
    setGeneralChartSettings((prev) => {
      return {
        ...prev,
        filter: {
          ...prev.filter,
          [event.target?.name]: event.target?.value,
        },
      };
    });
  };

  const redirectFromKpi = (kpi) => {
    switch (kpi) {
      case "Standby cases":
        dispatch(sidebarActions.updateStatus(caseConstants.CASE_STATE_STANDBY));
        break;
      case "Unread emails":
        dispatch(
          sidebarActions.updateStatus("CASES", caseConstants.CASE_FILTER_MAIL)
        );
        break;
      case " Licenses to check":
        dispatch(
          sidebarActions.updateStatus(
            "CASES",
            caseConstants.CASE_FILTER_UPLOADED_LICENSE
          )
        );
        break;
      case "Overdue reminders":
        dispatch(
          sidebarActions.updateStatus(
            "CASES",
            caseConstants.CASE_FILTER_REMINDER
          )
        );
        break;
      case "Unmapped emails":
        dispatch(sidebarActions.updateStatus("INBOUNDEMAILS"));
        break;
      case "Failed payments":
        dispatch(
          sidebarActions.updateStatus(
            "CASES",
            caseConstants.CASE_FILTER_FAILED_PAYMENT
          )
        );
        break;
      case "Paused automation":
        dispatch(
          sidebarActions.updateStatus("CASES", caseConstants.CASE_FILTER_PAUSED)
        );
        break;
      case "Failed emails":
        dispatch(
          sidebarActions.updateStatus(
            "CASES",
            caseConstants.CASE_FILTER_FAILED_EMAIL
          )
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className={styles.root}>
      <DashboardSettingsPopup
        open={dashboardSettingsPopup}
        close={handleCloseDashboardSettingsPopup}
        dashboardSettings={dashboardSettings}
        maxNumberOfKpis={maxNumberOfKpis}
      />
      <SettingsIcon
        className={styles.settings_icon}
        onClick={() => setDashboardSettingsPopup(true)}
      />
      <div className={styles.kpi_container}>
        {dashboardSettings?.available_kpis?.map((e, i) =>
          userKpis[i]?.name ? (
            <div
              className={styles.kpi_item}
              onClick={() => redirectFromKpi(userKpis[i].name)}
            >
              <p
                className={styles.kpi_value}
                style={{
                  color: `${
                    userKpis[i].threshold &&
                    userKpis[i].threshold < userKpis[i].value
                      ? "red"
                      : ""
                  }`,
                }}
              >
                {userKpis[i]?.value || 0}
              </p>
              <h3 className={styles.kpi_name}>{userKpis[i].name}</h3>
            </div>
          ) : (
            <span className={styles.kpi_item} style={{ border: 0 }}></span>
          )
        )}
        {(!userKpis || userKpis.length === 0) && !settingsLoaded && (
          <>
            <CircularProgress
              className={styles.loading_title}
              color="primary"
            />
            <div className={styles.loading_label}>Loading KPI's ...</div>
          </>
        )}
      </div>

      <Divider />
      <div className={styles.lower}>
        <div className={styles.chart_filters}>
          <div className={styles.date_chart_filters}>
            <div className={styles.date_chart_filter}>
              <TextfieldInput
                label="Date range"
                select
                name="date_range"
                size="small"
                className={styles.select}
                value={generalChartSettings?.date_range || ""}
                onChange={editGeneralChartSettings}
              >
                {dateRange.map((range) => (
                  <MenuItem
                    style={{
                      display: `${range.value === "custom" ? "none" : ""}`,
                    }}
                    key={range.value}
                    value={range.value}
                  >
                    {`${range.label} `}
                  </MenuItem>
                ))}
              </TextfieldInput>
            </div>
            <div className={styles.date_chart_filter}>
              <TextfieldInput
                label="From"
                type="date"
                name="start_date"
                size="small"
                value={generalChartSettings?.start_date || ""}
                onChange={editGeneralChartSettings}
                onKeyDown={(e) => e.preventDefault()}
                className={styles.date_picker}
              />
            </div>
            <div className={styles.date_chart_filter}>
              <TextfieldInput
                label="To"
                type="date"
                name="end_date"
                size="small"
                value={generalChartSettings?.end_date || ""}
                onChange={editGeneralChartSettings}
                onKeyDown={(e) => e.preventDefault()}
                className={styles.date_picker}
              />
            </div>
          </div>
          <div className={styles.chart_filter}>
            <TextfieldInput
              label="Grouping"
              select
              name="date_grouping"
              size="small"
              className={styles.select}
              value={generalChartSettings?.date_grouping || " "}
              onChange={editGeneralChartSettings}
            >
              {dateGrouping.map((group) => (
                <MenuItem key={group.value} value={group.value}>
                  {`${group.label} `}
                </MenuItem>
              ))}
            </TextfieldInput>
          </div>

          <div className={styles.chart_filter}>
            <TextfieldInput
              label="Users"
              select
              name="user"
              size="small"
              className={styles.select}
              value={generalChartSettings?.filter?.user || " "}
              onChange={editChartFilter}
            >
              <MenuItem value={" "}>All</MenuItem>
              {jwtData.user_role !== userConstants.USER_ROLE_AGENT ? (
                userReducer.users.list &&
                userReducer.users.list
                  .filter((user) => user.state !== "DEACTIVATED")
                  .map((filteredUser) => (
                    <MenuItem
                      key={filteredUser.user_id}
                      value={filteredUser.user_id}
                    >
                      {filteredUser.name}
                    </MenuItem>
                  ))
              ) : (
                <MenuItem value={jwtData.user_id}>{jwtData.username}</MenuItem>
              )}
            </TextfieldInput>
          </div>
        </div>
        <div ref={chartsViewRef} className={styles.charts_view}>
          {dashboardSettings?.selected_charts?.map((chart, index) => (
            <BarChartBlock
              selectedChart={chart}
              ChartData={ChartsData[index]}
              chartIndex={index}
              generalChartSettings={generalChartSettings}
              chartsViewWidth={chartsViewWidth}
            />
          ))}
        </div>
      </div>
      <p className={styles.bottom_disclaimer}>
        This dashboard is currently in beta, and the information presented may
        not be fully reliable. Please note that all times and dates are
        displayed in GMT.
      </p>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Dashboard;
