import React, {useState} from "react";
import {formatDatetime} from "@_utils/helpers";
import ItemTypes from "@_configs/contactLog.config";
import ChatIcon from "@material-ui/icons/Chat";
import CallbackPopup from "@_components/popups/CallbackPopup/CallbackPopup";
import styles from "./CallbackLogItem.module.css";


function CallbackLogItem(props) {
  const [openCallbackPopup, setOpenCallbackPopup] = useState(false);

  const {data, caseId} = props;

  return (
    <div>
      <div className={styles.root} onClick={()=>setOpenCallbackPopup(true)}>
        <div className={styles.icon_container}>
          <div className={styles.title_icon}>
            {ItemTypes["CALLBACK"].icon}
          </div>
        </div>
        <div className={styles.left_container}>
          <div className={styles.title_container}>
            <div className={styles.title_text}>
              {ItemTypes["CALLBACK"].title}
            </div>
          </div>
          <div className={styles.date}>
            {formatDatetime(data.date_requested, "medium")}
          </div>
        </div>
        <div className={styles.center_container}>
          <div className={styles.center_secondary}>
            Phone Number
          </div>
          <div className={styles.center_primary}>
            {data.phone_number}
          </div>
        </div>
        <div className={styles.center_container}>
          <div className={styles.center_secondary}>
            Contact Person
          </div>
          <div className={styles.center_primary}>
            {data.name}
          </div>
        </div>
        <div className={!data.note?styles.right_container_disabled:styles.right_container}>
          <ChatIcon className={styles.chat_icon}/>
        </div>
      </div>
      <CallbackPopup open={openCallbackPopup} close={()=>setOpenCallbackPopup(false)} data={data} caseId={caseId}/>
    </div>
  );
}

export default CallbackLogItem;