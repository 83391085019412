import React from 'react';
import DialogPopup from '@_components/DialogPopup/DialogPopup';
import Cancel from '@material-ui/icons/Cancel';
import styles from './ErrorMessagePopup.module.css';
import ErrorIcon from '@material-ui/icons/Error';

let closeImg = {
  cursor: 'pointer',
  float: 'right',
  marginTop: '5px',
  width: '20px',
};
const ErrorMessagePopup = (props) => {
  const { errorMessage, popupTitle } = props;
  return (
    <DialogPopup
      title={
        <span>
          {popupTitle}
          <Cancel style={closeImg} onClick={() => props.close()} />
        </span>
      }
      open={props.open}
      onClose={() => props.close()}
    >
      <div className={styles.container}>
        <ErrorIcon />
        <p className={styles.message}>{errorMessage}</p>
      </div>
    </DialogPopup>
  );
};

export default ErrorMessagePopup;
