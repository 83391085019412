export const invoiceTemplateConstants = {
  INVOICE_TEMPLATE_GET_REQUEST: "INVOICE_TEMPLATE_GET_REQUEST",
  INVOICE_TEMPLATE_GET_SUCCESS: "INVOICE_TEMPLATE_GET_SUCCESS",
  INVOICE_TEMPLATE_GET_FAILURE: "INVOICE_TEMPLATE_GET_FAILURE",

  INVOICE_TEMPLATES_GET_REQUEST: "INVOICE_TEMPLATES_GET_REQUEST",
  INVOICE_TEMPLATES_GET_SUCCESS: "INVOICE_TEMPLATES_GET_SUCCESS",
  INVOICE_TEMPLATES_GET_FAILURE: "INVOICE_TEMPLATES_GET_FAILURE",

  INVOICE_TEMPLATE_CREATE_REQUEST: "INVOICE_TEMPLATE_CREATE_REQUEST",
  INVOICE_TEMPLATE_CREATE_SUCCESS: "INVOICE_TEMPLATE_CREATE_SUCCESS",
  INVOICE_TEMPLATE_CREATE_FAILURE: "INVOICE_TEMPLATE_CREATE_FAILURE",

  INVOICE_TEMPLATE_UPDATE_REQUEST: "INVOICE_TEMPLATE_UPDATE_REQUEST",
  INVOICE_TEMPLATE_UPDATE_SUCCESS: "INVOICE_TEMPLATE_UPDATE_SUCCESS",
  INVOICE_TEMPLATE_UPDATE_FAILURE: "INVOICE_TEMPLATE_UPDATE_FAILURE",

  INVOICE_TEMPLATE_DELETE_REQUEST: "INVOICE_TEMPLATE_DELETE_REQUEST",
  INVOICE_TEMPLATE_DELETE_SUCCESS: "INVOICE_TEMPLATE_DELETE_SUCCESS",
  INVOICE_TEMPLATE_DELETE_FAILURE: "INVOICE_TEMPLATE_DELETE_FAILURE",

  INVOICE_TEMPLATE_PREVIEW_REQUEST: "INVOICE_TEMPLATE_PREVIEW_REQUEST",
  INVOICE_TEMPLATE_PREVIEW_SUCCESS: "INVOICE_TEMPLATE_PREVIEW_SUCCESS",
  INVOICE_TEMPLATE_PREVIEW_FAILURE: "INVOICE_TEMPLATE_PREVIEW_FAILURE",

  INVOICES_LIST_GET_REQUEST: "INVOICES_LIST_GET_REQUEST",
  INVOICES_LIST_GET_SUCCESS: "INVOICES_LIST_GET_SUCCESS",
  INVOICES_LIST_GET_FAILURE: "INVOICES_LIST_GET_FAILURE",

  INVOICES_LIST_DOWNLOAD_REQUEST: "INVOICES_LIST_DOWNLOAD_REQUEST",
  INVOICES_LIST_DOWNLOAD_SUCCESS: "INVOICES_LIST_DOWNLOAD_SUCCESS",
  INVOICES_LIST_DOWNLOAD_FAILURE: "INVOICES_LIST_DOWNLOAD_FAILURE",

  INVOICE_DOWNLOAD_REQUEST: "INVOICE_DOWMLOAD_REQUEST",
  INVOICE_DOWNLOAD_SUCCESS: "INVOICE_DOWMLOAD_SUCCESS",
  INVOICE_DOWNLOAD_FAILURE: "INVOICE_DOWMLOAD_FAILURE",

  INVOICE_CANCEL_REQUEST: "INVOICE_CANCEL_REQUEST",
  INVOICE_CANCEL_SUCCESS: "INVOICE_CANCEL_SUCCESS",
  INVOICE_CANCEL_FAILURE: "INVOICE_CANCEL_FAILURE",

  ADD_INVOICE_TEMPLATE: "ADD_INVOICE_TEMPLATE",

  SELECT_INVOICE_TEMPLATE: "SELECT_INVOICE_TEMPLATE",

  INVOICE_TYPE_CANCELLED: "CANCELLED",
  INVOICE_TYPE_CANCELLATION: "CANCELLATION",
  INVOICE_TYPE_ACTIVE: "ACTIVE",

  // template placeholders
  INVOICE_PLACEHOLDER_GET_REQUEST: "INVOICE_PLACEHOLDER_GET_REQUEST",
  INVOICE_PLACEHOLDER_GET_SUCCESS: "INVOICE_PLACEHOLDER_GET_SUCCESS",
  INVOICE_PLACEHOLDER_GET_FAILURE: "INVOICE_PLACEHOLDER_GET_FAILURE",

  // clear status
  INVOICE_TEMPLATE_CLEAR_STATUS_MESSAGE: "TEMPLATE_CLEAR_STATUS_MESSAGE",
};
