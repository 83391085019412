import React from 'react';

import CloudUploadIcon from '@material-ui/icons/CloudUpload';

function UploadImage({ styles, id, handleFileInputChange, accept }) {
  return (
    <div>
      <label htmlFor={id}>
        <CloudUploadIcon
          fontSize="large"
          className={styles.cloud_upload_icon}
        />
      </label>
      <input
        className={styles.upload_input}
        id={id}
        multiple
        type="file"
        name="file"
        accept={accept}
        onChange={handleFileInputChange}
      />
    </div>
  );
}
export default UploadImage;
