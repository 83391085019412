import React from 'react';
import styles from './WelcomeToFairLicensing.module.css';
import email_verification from '@_assets/images/email_verification.png';

const WelcomeToFairLicensing = ({ email }) => {
  return (
    <div className={styles.welcomeToFairLicensingContainer}>
      <img
        className={styles.welcomeToFairLicensingImage}
        src={email_verification}
        alt="Fair Licensing Logo"
      />
      <div className={styles.welcomeToFairLicensingTextContainer}>
        <p className={styles.welcomeToFairLicensingTitle}>
          Welcome to Fair Licensing!
        </p>
        <p className={styles.welcomeToFairLicensingSubtitle}>
          We have sent a verification email to
          <br />
          <span className={styles.welcomeToFairLicensingEmail}>{email}</span>
        </p>
        <p>
          After verification, you are able to login to your Fair Licensing
          account. Please check your email account and click on the verification
          link to complete the signup.
        </p>
      </div>
    </div>
  );
};

export default WelcomeToFairLicensing;
