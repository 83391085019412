import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import styles from "./InboundEmailTestPopup.module.css";
import { Close, Done } from "@material-ui/icons";

const InboundEmailTestPopup = (props) => {
  return (
    <DialogPopup title="Sending Test Email ..." open={props.open}>
      <div className={styles.content_container}>
        {props.status === "request" ? (
          <>
            <CircularProgress color="primary" />
          </>
        ) : (
          <>
            {props.status === true ? (
              <div>
                <h2>Test Email Received</h2>
                <Done fontSize="large" htmlColor="green" />
              </div>
            ) : (
              <div>
                {props.status === false ? (
                  <h2>Test Failed</h2>
                ) : (
                  <h3>Something went wrong: {props.status}</h3>
                )}
                <Close fontSize="large" color="error" />
              </div>
            )}{" "}
            <div className="action_container">
              <Button className="btn_cancel" onClick={props.onClose}>
                Close
              </Button>
            </div>
          </>
        )}
      </div>
    </DialogPopup>
  );
};

export default InboundEmailTestPopup;
