import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import styles from './DialogPopup.module.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DialogPopup(props) {
  const { children, title } = props;

  return (
    <Dialog
      TransitionComponent={Transition}
      className={styles.popup_root}
      classes={{
        paper:
          props.size && props.size === 'large'
            ? styles.paper_big
            : styles.paper,
      }}
      keepMounted={false}
      disablePortal={true}
      {...props}
      title={null}
    >
      <div className={styles.popup_header}>{title}</div>
      <div className={styles.popup_content}>{children}</div>
    </Dialog>
  );
}

export default DialogPopup;
