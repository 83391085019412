import React, { useState } from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextfieldInput from '@_components/TextfieldInput/TextfieldInput';
import { makeStyles } from '@material-ui/core';

function PasswordField(props) {
  const [showPassword, togglePassword] = useState(false);
  const handleClickShowPassword = () => {
    togglePassword(!showPassword);
  };

  const useStyles = makeStyles({
    IconButton: { width: '50px' },
  });

  const classes = useStyles();

  return (
    <TextfieldInput
      {...props}
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              className={classes.IconButton}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default PasswordField;
