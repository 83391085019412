import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "@_actions/auth.actions";
import { Redirect } from "react-router-dom";
import config from "../../config.json";
import styles from "./Login.module.css";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import PasswordField from "@_components/PasswordField/PasswordField";
import { userActions } from "@_actions/user.actions";
import EnterEmail from "@_components/ForgotPassword/EnterEmail/EnterEmail";
import LinkSent from "@_components/ForgotPassword/LinkSent/LinkSent";
import ChangePassword from "@_components/ForgotPassword/ChangePassword/ChangePassword";
import PasswordChanged from "@_components/ForgotPassword/PasswordChanged/PasswordChanged";
import InfoIcon from "@material-ui/icons/Info";
import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { customerActions } from "@_actions/customer.actions";

function LoginPage(props) {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authReducer);
  const userReducer = useSelector((state) => state.userReducer);
  const customerReducer = useSelector((state) => state.customerReducer);

  const [inputs, setInputs] = useState({
    email: config.debug_mode ? "example@pixray.com" : "",
    password: config.debug_mode ? "password" : "",
  });
  const [forgotPassword, setForgotPassword] = useState(false);
  const [linkSent, setLinkSent] = useState(false);
  const [validLink, setValidLink] = useState(false);
  const [changedPassword, setChangedPassword] = useState(false);
  const [forgotPasswordId, setForgotPasswordId] = useState();
  const [loading, setLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isVerified, setIsVerified] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value.trim() }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    if (inputs.email && inputs.password)
      dispatch(authActions.login(inputs.email, inputs.password));
  }

  function getRedirectPath() {
    const locationState = props.location.state;
    if (locationState && locationState.from.pathname) {
      return locationState.from.pathname; // redirects to referring url
    } else {
      return "/";
    }
  }

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const handleCancel = () => {
    setForgotPassword(false);
    setLinkSent(false);
    setChangedPassword(false);
  };

  const handleResendEmail = () => {
    let data = {
      email: inputs.email,
    };
    dispatch(customerActions.sendVerficationEmail(data));
  };

  // prefill with email if verification is successful
  useEffect(() => {
    if (
      customerReducer.status.CUSTOMER_VERIFICATION &&
      customerReducer.status.CUSTOMER_VERIFICATION === "success"
    ) {
      setInputs((inputs) => ({
        ...inputs,
        email: customerReducer.verification_data.email,
      }));
    }
  }, [
    customerReducer.status.CUSTOMER_VERIFICATION,
    customerReducer.verification_data.email,
  ]);

  // successful redirects:

  // customer email invitation verfied
  useEffect(() => {
    let url = window.location.href;
    if (url.includes("verification/")) {
      let verificationId = url.split("verification/").pop();
      verificationId &&
        dispatch(customerActions.verifiedCustomer(verificationId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      customerReducer.status.CUSTOMER_VERIFICATION &&
      customerReducer.status.CUSTOMER_VERIFICATION === "success"
    ) {
      setVerificationSuccess(true);
    } else return;
  }, [customerReducer.status.CUSTOMER_VERIFICATION]);

  // email entered and valid
  useEffect(() => {
    if (
      userReducer.status.USER_PUT_PASSWORD_LINK &&
      userReducer.status.USER_PUT_PASSWORD_LINK === "success"
    ) {
      setLinkSent(true);
      setForgotPassword(false);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_PUT_PASSWORD_LINK"));
      }, 500);
    }
  }, [
    userReducer.status.USER_PUT_PASSWORD_LINK,
    linkSent,
    forgotPassword,
    dispatch,
  ]);

  // if successful PUT email call get the password reset id from URL
  useEffect(() => {
    let url = window.location.href;
    if (url.includes("password_reset")) {
      let forgotPasswordId = url.split("password_reset/").pop();
      setForgotPasswordId(forgotPasswordId);
      if (forgotPasswordId && !changedPassword) {
        dispatch(userActions.getPasswordLink(forgotPasswordId));
      } else return;
    }
  }, [
    forgotPassword,
    forgotPasswordId,
    linkSent,
    userReducer.status.USER_PUT_PASSWORD_LINK,
    dispatch,
    changedPassword,
  ]);

  // if successful get call for the password reset id redirect to page with new password input
  useEffect(() => {
    if (
      userReducer.status.USER_GET_PASSWORD_LINK &&
      userReducer.status.USER_GET_PASSWORD_LINK === "success"
    ) {
      setForgotPassword(false);
      setLinkSent(false);
      setValidLink(true);
    }
  }, [
    userReducer.status.USER_GET_PASSWORD_LINK,
    linkSent,
    forgotPassword,
    validLink,
  ]);

  // if entered password is valid redirect to the success page
  useEffect(() => {
    if (
      userReducer.status.USER_PUT_NEW_PASSWORD &&
      userReducer.status.USER_PUT_NEW_PASSWORD === "success"
    ) {
      setForgotPassword(false);
      setLinkSent(false);
      setValidLink(false);
      setChangedPassword(true);
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage("USER_PUT_NEW_PASSWORD"));
      }, 500);
    }
  }, [
    userReducer.status.USER_PUT_NEW_PASSWORD,
    linkSent,
    forgotPassword,
    validLink,
    changedPassword,
    dispatch,
  ]);

  useEffect(() => {
    if (auth.message && auth.message === "unverified_account") {
      setIsVerified(false);
    } else return;
  }, [auth.message]);

  useEffect(() => {
    if (customerReducer.status.VERIFICATION_EMAIL === "success") {
      setIsSuccessful(true);
    }
  }, [customerReducer.status.VERIFICATION_EMAIL]);

  useEffect(() => {
    if (customerReducer.status.VERIFICATION_EMAIL === "request") {
      setLoading(true);
    } else setLoading(false);
  }, [customerReducer.status.VERIFICATION_EMAIL]);

  return auth.user && localStorage.getItem("user") != null ? (
    <Redirect
      to={{ pathname: getRedirectPath(), state: { from: props.location } }}
    />
  ) : (
    <div className={styles.login}>
      <div className={styles.loginFormContainer}>
        {/* forgot password first page */}
        {forgotPassword && !linkSent && (
          <EnterEmail handleCancel={handleCancel} />
        )}

        {/* link sent page */}
        {!forgotPassword && linkSent && (
          <LinkSent handleCancel={handleCancel} />
        )}

        {/* change password page */}
        {validLink && !linkSent && !forgotPassword && (
          <ChangePassword forgotPasswordId={forgotPasswordId} />
        )}

        {/* password successfully changed page */}
        {!forgotPassword && !linkSent && !validLink && changedPassword && (
          <PasswordChanged handleCancel={handleCancel} />
        )}

        {/* login page */}
        {!forgotPassword && !linkSent && !validLink && !changedPassword && (
          <form name="form" onSubmit={handleSubmit}>
            <div className={styles.loginForm}>
              <div className={styles.loginFormHeader}>LOGIN</div>

              <div className={styles.input_container}>
                <TextfieldInput
                  placeholder="Email"
                  type="text"
                  name="email"
                  value={inputs.email}
                  onChange={handleChange}
                  disabled={!isVerified}
                />
              </div>
              <div className={styles.input_container}>
                <PasswordField
                  placeholder="Password"
                  name="password"
                  value={inputs.password}
                  onChange={handleChange}
                  required
                  disabled={!isVerified}
                />
              </div>
              {auth.message !== "unverified_account" && (
                <div className={styles.loginError}>{auth.message}</div>
              )}
              <div
                className={
                  !isVerified
                    ? styles.forgot_password_gray
                    : styles.forgot_password_container
                }
                onClick={() => handleForgotPassword()}
              >
                Forgot Password?
              </div>
            </div>
            {isVerified ? (
              <div className={styles.btnContainer}>
                <button className={styles.button} disabled={auth.loading}>
                  LOGIN
                </button>
              </div>
            ) : (
              <div className={styles.email_unverified}>
                <div className={styles.email_unverified_content}>
                  <div className={styles.unverified_info_text}>
                    <InfoIcon className={styles.info_icon} />{" "}
                    <p>
                      Please verify your email address by clicking the link in
                      the verification email we have sent you. Don't forget to
                      check your spam folder!
                    </p>
                  </div>
                  <div className={styles.resend_btn_container}>
                    {loading && (
                      <CircularProgress
                        className={styles.resend_email_loading}
                      />
                    )}
                    {isSuccessful && (
                      <div className={styles.resend_email_success}>
                        <CheckCircleIcon />
                        <p>Email Sent!</p>
                      </div>
                    )}

                    {!isSuccessful && !loading && (
                      <Button
                        className={styles.resend_btn}
                        onClick={() => handleResendEmail()}
                      >
                        Resend Email
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {verificationSuccess && (
              <div className={styles.verification_success_container}>
                {" "}
                <CheckCircleIcon className={styles.check_icon} />
                <p>Email verification successful!</p>{" "}
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
