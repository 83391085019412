import React, { useState, useEffect } from "react";
import classnames from "classnames";
import userStates from "@_configs/userStates.config";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "@_actions/user.actions";
import styles from "./UserRow.module.css";

function UserRow(props) {
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);
  const { user } = props;
  const [isSelected, setIsSelected] = useState(false);

  function itemClicked() {
    dispatch(userActions.selectUser(user));
    dispatch(userActions.addUserState(false));
  }

  useEffect(() => {
    if (
      userReducer.selected_user &&
      userReducer.selected_user.user_id === user.user_id
    ) {
      setIsSelected(true);
    } else if (
      userReducer.selected_user &&
      userReducer.selected_user.user_id !== user.user_id
    ) {
      setIsSelected(false);
    }
  }, [userReducer.selected_user, user]);

  return (
    <div>
      <div
        className={
          (user.state === "DEACTIVATED" &&
            classnames(
              styles.root,
              styles.deactivated,
              isSelected && styles.selected
            )) ||
          (user.state === "INVITATION_PENDING" &&
            classnames(
              styles.root,
              styles.pending,
              isSelected && styles.selected
            )) ||
          (user.state === "ACTIVE" &&
            classnames(
              styles.root,
              styles.active,
              isSelected && styles.selected
            ))
        }
        onClick={() => itemClicked()}
      >
        <div className={styles.left_container}>
          <div className={styles.status_icon}>
            {userStates[user.state].icon}
          </div>
          <div className={styles.user_role}>{user.role.toLowerCase()}</div>
          <div className={styles.user_role}>
            {(user.state === "INVITATION_PENDING" && <b>pending</b>) ||
              user.state.toLowerCase()}
          </div>
        </div>
        <div className={styles.middle_container}>
          <div className={styles.host_value}>
            <div>{user.email}</div>
          </div>
          <div className={styles.user_name_container}>
            <div className={styles.user_name_value}>{user.name}</div>
            <div className={styles.total_value}>
              <div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right_container}></div>
      </div>
    </div>
  );
}

export default UserRow;
