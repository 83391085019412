import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Signup.module.css';
import TextfieldInput from '@_components/TextfieldInput/TextfieldInput';
import { userActions } from '@_actions/user.actions';
import { catchError } from '@_utils/helpers';
import PasswordField from '@_components/PasswordField/PasswordField';
import ExpiredSignup from '@_components/ExpiredSignup/ExpiredSignup';
import { authActions } from '@_actions/auth.actions';

// import Checkbox from "@material-ui/core/Checkbox";

function SignupPage() {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.authReducer);
  const userReducer = useSelector((state) => state.userReducer);
  const [invitationId, setInvitationId] = useState('');
  const [expired, setExpired] = useState(false);

  const [inputs, setInputs] = useState({
    name: userReducer.signup_user.name || '',
    password: '',
  });
  // const [checkbox, setCheckbox] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (auth.user) {
      dispatch(authActions.logout());
    }
  }, [auth.user, dispatch]);

  useEffect(() => {
    setInputs((inputs) => ({
      ...inputs,
      name: userReducer.signup_user.name || '',
    }));
  }, [userReducer.signup_user]);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e) {
    setIsClicked(true);
    e.preventDefault();
    let data = {
      ...inputs,
      user_id: userReducer.signup_user.user_id,
    };
    dispatch(userActions.updateSignup(invitationId, data));
  }

  useEffect(() => {
    let url = window.location.href;
    if (url.includes('signup/')) {
      let invitationId = url.split('signup/').pop();
      invitationId && dispatch(userActions.getSignup(invitationId));
      setInvitationId(invitationId);
    }
  }, [dispatch]);

  useEffect(() => {
    if (catchError(userReducer.status.USER_PUT_SIGNUP)) {
      setErrorMessage(userReducer.status.USER_PUT_SIGNUP);
    }
  }, [userReducer.status.USER_PUT_SIGNUP]);

  useEffect(() => {
    if (catchError(userReducer.status.USER_GET_SIGNUP)) {
      setExpired(true);
      setErrorMessage('get failed');
    }
  }, [userReducer.status.USER_GET_SIGNUP]);

  return (
    <div className={styles.signup}>
      <div className={styles.signupFormContainer}>
        {!expired && invitationId ? (
          <form
            className={styles.signupForm}
            name="form"
            onSubmit={handleSubmit}
          >
            <div className={styles.signupFormHeader}>Create Your Account</div>
            <div className={styles.email_container}>
              {userReducer.signup_user && userReducer.signup_user.email}
            </div>
            <div className={styles.input_container}>
              <TextfieldInput
                placeholder="Name"
                type="text"
                name="name"
                value={inputs.name}
                onChange={handleChange}
                error={isClicked && !inputs.name}
              />
            </div>
            <div className={styles.input_container}>
              <PasswordField
                placeholder="Password"
                name="password"
                value={inputs.password}
                onChange={handleChange}
                required
              />
              <div
                className={
                  errorMessage
                    ? styles.password_requirements_error
                    : styles.password_requirements
                }
              >
                {' '}
                Enter at least 8 characters (containing 1 number, 1 special
                character, 1 capital letter)
              </div>
            </div>
            {/* <div className={styles.accept_container}>
            <Checkbox
              color="default"
              value={checkbox}
              onChange={(e) => setCheckbox(!checkbox)}
            />
            I agree to the Data Policy.
          </div> */}
            <div className={styles.btnContainer}>
              <button className={styles.signupBtn} disabled={auth.loading}>
                SIGN UP
              </button>
            </div>
          </form>
        ) : (
          <ExpiredSignup />
        )}
      </div>
    </div>
  );
}

export default SignupPage;
