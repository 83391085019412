import React from 'react';
import styles from './Footer.module.css';
import { Button, makeStyles } from '@material-ui/core';

const Footer = ({ handleSubmit, currentStep, responseError, resendEmail }) => {
  const useStyles = makeStyles({
    confirmButton: {
      fontWeight: 'bold !important',
      color: 'var(--color-theme-blue) !important',
      border: '1px solid var(--color-theme-blue) !important',

      '&:hover': {
        color: 'var(--color-theme-white) !important',
        backgroundColor: 'var(--color-theme-blue) !important',
        boxShadow: '2px 6px 6px  #0000002',
      },
    },
  });
  const classes = useStyles();

  return (
    <div className={styles.footerContainer}>
      {currentStep === 3 ? null : currentStep === 2 ? (
        <Button className={classes.confirmButton} onClick={handleSubmit}>
          Create Account
        </Button>
      ) : (
        <Button className={classes.confirmButton} onClick={handleSubmit}>
          Next
        </Button>
      )}
    </div>
  );
};

export default Footer;
