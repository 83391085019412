import React, { useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { formatDatetime } from "@_utils/helpers";
import { caseActions } from "@_actions/case.actions";
import styles from "./CallbackPopup.module.css";

function CallbackPopup(props) {
  const { data, caseId } = props;
  const dispatch = useDispatch();

  const [inputs, setInputs] = useState({
    id: Date.now(),
    type: "NOTE",
    note: "",
    contact_person_name: "",
    contact_person_role: "",
    contact_person_phone_number: "",
    contact_person_phone_email: "",
    date_created: Date.now(),
  });

  function handleInputChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function actionClicked() {
    dispatch(caseActions.addCaseLog(caseId, inputs));
    setTimeout(() => {
      dispatch(caseActions.deleteCaseCallbackRequest(caseId));
      // TODO: snackbar and error handling before closing the popup
      props.close();
    }, 100);
  }

  return (
    <DialogPopup
      title="Callback Request"
      open={props.open}
      onClose={() => props.close()}
    >
      <div className={styles.content_container}>
        <div className={styles.date}>
          {formatDatetime(data.date_requested, "long")}
        </div>
        <div className={styles.name}>{data.name}</div>
        <div className={styles.role}>{data.role}</div>
        <div className={styles.email}>{data.email}</div>
        <div className={styles.phone}>{data.phone_number}</div>
        <div className={styles.note}>{data.note}</div>

        <TextfieldInput
          label="Note"
          id={"note"}
          name={"note"}
          multiline
          rowsMax={5}
          rows={5}
          value={inputs.note}
          onChange={handleInputChange}
        />
      </div>
      <div className="action_container">
        <Button className="btn_cancel" onClick={() => props.close()}>
          Cancel
        </Button>
        <Button className="btn_confirm" onClick={actionClicked}>
          Close & Log
        </Button>
      </div>
    </DialogPopup>
  );
}

export default CallbackPopup;
