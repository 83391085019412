export const customerConstants = {
  CUSTOMER_VERIFICATION_EMAIL_REQUEST: 'CUSTOMER_VERIFICATION_EMAIL_REQUEST',
  CUSTOMER_VERIFICATION_EMAIL_SUCCESS: 'CUSTOMER_VERIFICATION_EMAIL_SUCCESS',
  CUSTOMER_VERIFICATION_EMAIL_FAILURE: 'CUSTOMER_VERIFICATION_EMAIL_FAILURE',

  CUSTOMER_VERIFICATION_PUT_REQUEST: 'CUSTOMER_VERIFICATION_PUT_REQUEST',
  CUSTOMER_VERIFICATION_PUT_SUCCESS: 'CUSTOMER_VERIFICATION_PUT_SUCCESS',
  CUSTOMER_VERIFICATION_PUT_FAILURE: 'CUSTOMER_VERIFICATION_PUT_FAILURE',

  CUSTOMER_GET_PAYMENT_REPORT_REQUEST: 'CUSTOMER_GET_PAYMENT_REPORT_REQUEST',
  CUSTOMER_GET_PAYMENT_REPORT_SUCCESS: 'CUSTOMER_GET_PAYMENT_REPORT_SUCCESS',
  CUSTOMER_GET_PAYMENT_REPORT_FAILURE: 'CUSTOMER_GET_PAYMENT_REPORT_FAILURE',

  CUSTOMER_GET_PAYMENT_REPORT_DETAILS_REQUEST:
    'CUSTOMER_GET_PAYMENT_REPORT_DETAILS_REQUEST',
  CUSTOMER_GET_PAYMENT_REPORT_DETAILS_SUCCESS:
    'CUSTOMER_GET_PAYMENT_REPORT_DETAILS_SUCCESS',
  CUSTOMER_GET_PAYMENT_REPORT_DETAILS_FAILURE:
    'CUSTOMER_GET_PAYMENT_REPORT_DETAILS_FAILURE',

  // clear status
  CUSTOMER_CLEAR_STATUS_MESSAGE: 'CUSTOMER_CLEAR_STATUS_MESSAGE',
};
