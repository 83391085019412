import { sidebarConstants } from "@_constants/sidebar.constants";

export const sidebarActions = {
    updateStatus,
    initNumbers,
    updateSelections,
    resetFilter,
};

function updateStatus(status,optionalFilter) {
    return { type: sidebarConstants.UPDATE_STATUS, status,optionalFilter };
}

function initNumbers(numbers) {
    return { type: sidebarConstants.INIT_NUMBERS, numbers };
}

function updateSelections(id) {
    return { type: sidebarConstants.UPDATE_SELECTIONS, id };
}

function resetFilter(){
    return { type: sidebarConstants.RESET_FILTER};
}

  
  