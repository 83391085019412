import React, { useState, useEffect } from "react";
import styles from "./BaseSettings.module.css";
import { withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { settlementActions } from "@_actions/settlement_portal.actions";
import { fileActions } from "@_actions/files_settlement_portal.actions";
import { catchError, getUserDataFromJwt } from "@_utils/helpers";
import UpgradeDomainImg from "@_assets/images/your_domain.png";
import UpgradeLogoImg from "@_assets/images/your_logo.png";
import { sidebarActions } from "@_actions/sidebar.actions";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DeleteIcon from "@material-ui/icons/Delete";
import ImportantDevicesIcon from "@material-ui/icons/ImportantDevices";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import Button from "@material-ui/core/Button";
import UploadImage from "@_components/UploadImage/UploadImage";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import DialogPopup from "@_components/DialogPopup/DialogPopup";

const CustomTabs = withStyles({
  root: {
    fontSize: 24,
    height: 60,
    backgroundColor: "var(--color-theme-white)",
  },
  indicator: {
    backgroundColor: "var(--color-theme-blue)",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    height: 60,
    fontSize: 18,
    color: "var(--color-theme-10)",
    "&:hover": {
      backgroundColor: "var(--color-theme-lightblue)",
      opacity: 1,
    },
    "&$selected": {
      color: "var(--color-theme-blue)",
    },
    "&:focus": {
      color: "var(--color-theme-blue)",
    },
  },
  selected: {},
}))(Tab);

function BaseSettings() {
  const jwtData = getUserDataFromJwt();

  const dispatch = useDispatch();
  const settlement = useSelector((state) => state.settlementReducer);
  const settlementFiles = useSelector((state) => state.fileReducer);
  const [selectedTab, setSelectedTab] = useState(0);

  // test uploaded file type:
  const [fileType, setFileType] = useState("");
  const [localLogo, setLocalLogo] = useState(false);
  const [localFavicon, setLocalFavicon] = useState(false);
  const [localAboutUsImage, setLocalAboutUsImage] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState({
    open: false,
    type: "",
  });

  const [disableSelectBtn, setDisableSelectBtn] = useState(false);
  const [disableUploadBtn, setDisableUploadBtn] = useState(false);

  const [settlementDetails, setSettlementDetails] = useState({
    name: settlement.settlement_details.name || "",
    homepage: settlement.settlement_details.homepage || "",
    settlement_portal_host_internal:
      settlement.settlement_details.settlement_portal_host_internal || "",
    settlement_portal_host_external:
      settlement.settlement_details.settlement_portal_host_external || "",
    imprint_link: settlement.settlement_details.imprint_link || "",
    privacy_statement_link:
      settlement.settlement_details.privacy_statement_link || "",
    about_us_text: settlement.settlement_details.about_us_text || "",
    login_text: settlement.settlement_details.login_text || "",
    case_overview_text: settlement.settlement_details.case_overview_text || "",
    logo_url: settlement.settlement_details.logo_url || "",
    favicon_url: settlement.settlement_details.favicon_url || "",
    about_us_image_url: settlement.settlement_details.about_us_image_url || "",
  });

  const [selectedFile, setSelectedFile] = useState({
    file: null,
    image_data: "",
    image_name: "",
  });

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    setSettlementDetails({
      name: settlement.settlement_details.name || "",
      homepage: settlement.settlement_details.homepage || "",
      settlement_portal_host_internal:
        settlement.settlement_details.settlement_portal_host_internal || "",
      settlement_portal_host_external:
        settlement.settlement_details.settlement_portal_host_external || "",
      imprint_link: settlement.settlement_details.imprint_link || "",
      privacy_statement_link:
        settlement.settlement_details.privacy_statement_link || "",
      about_us_text: settlement.settlement_details.about_us_text || "",
      login_text: settlement.settlement_details.login_text || "",
      case_overview_text:
        settlement.settlement_details.case_overview_text || "",
      logo_url: settlement.settlement_details.logo_url || "",
      favicon_url: settlement.settlement_details.favicon_url || "",
      about_us_image_url:
        settlement.settlement_details.about_us_image_url || "",
    });
  }, [settlement.settlement_details]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      // let fileInfo;
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  useEffect(() => {
    if (selectedFile) {
      setDisableSelectBtn(true);
    }
  }, [disableSelectBtn, selectedFile]);

  const handleFileInputChange = (e) => {
    let { file } = selectedFile;
    let { image_name } = selectedFile;
    image_name = e.target.files[0].name;

    file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;

        setSelectedFile({
          image_data: result,
          file,
          image_name,
        });
      })
      .catch((err) => {
        console.log(err);
      });

    setSelectedFile({
      selectedFile: e.target.files[0],
    });
  };

  useEffect(() => {
    if (
      selectedFile &&
      selectedFile.image_data &&
      selectedFile.image_name &&
      fileType
    ) {
      let { image_data, image_name } = selectedFile;
      image_data = image_data.split(";base64,")[1];

      let data = { image_data, image_name };
      dispatch(fileActions.updateFiles(fileType, data));
      dispatch(settlementActions.getSettlement());
    } else return;
  }, [dispatch, selectedFile, fileType]);

  useEffect(() => {
    dispatch(settlementActions.getSettlement());
  }, [dispatch]);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setSettlementDetails((settlementDetails) => ({
      ...settlementDetails,
      [name]: value,
    }));
  }

  const handleSubmitPortalAddress = () => {
    const { settlement_portal_host_internal, settlement_portal_host_external } =
      settlementDetails;
    const portalAddressData = {
      settlement_portal_host_internal,
      settlement_portal_host_external,
    };
    dispatch(settlementActions.updatePortalAddress(portalAddressData));
  };

  const handleSubmitLegalContact = () => {
    const { email, privacy_statement_link, imprint_link, homepage } =
      settlementDetails;
    var legalContactData = {
      email,
    };

    if (homepage && homepage.length > 0) {
      legalContactData["homepage"] = homepage;
    }

    if (privacy_statement_link && privacy_statement_link.length > 0) {
      legalContactData["privacy_statement_link"] = privacy_statement_link;
    }

    if (imprint_link && imprint_link.length > 0) {
      legalContactData["imprint_link"] = imprint_link;
    }
    dispatch(settlementActions.updateLegalContact(legalContactData));
  };

  const openDeleteDialog = (type) => {
    setDeleteDialogOpen({ open: true, type });
  };

  const handleDeleteFile = (type) => {
    if (type === "logo") {
      const logoUrl = settlement.settlement_details.logo_url || localLogo;
      const index = logoUrl.lastIndexOf("/") + 1;
      const image_name = logoUrl.substr(index);
      dispatch(fileActions.deleteFiles(type, image_name));
      dispatch(settlementActions.getSettlement());

      if (settlementFiles.success && !settlement.settlement_details.logo_url) {
        setSettlementDetails({ ...settlementDetails, logo_url: "" });
      }
    } else if (type === "favicon") {
      const faviconUrl =
        settlement.settlement_details.favicon_url || localFavicon;
      const index = faviconUrl.lastIndexOf("/") + 1;
      const image_name = faviconUrl.substr(index);
      dispatch(fileActions.deleteFiles(type, image_name));
      dispatch(settlementActions.getSettlement());
    } else if (type === "about_us_image") {
      const aboutUsUrl =
        (settlement.settlement_details.about_us_image_url &&
          settlement.settlement_details.about_us_image_url) ||
        localAboutUsImage;
      const index = aboutUsUrl.lastIndexOf("/") + 1;
      const image_name = aboutUsUrl.substr(index);
      dispatch(fileActions.deleteFiles(type, image_name));
      dispatch(settlementActions.getSettlement());
    } else {
      return;
    }
    dispatch(settlementActions.getSettlement());
    setDeleteDialogOpen({ open: false, type: "" });
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
  };

  useEffect(() => {
    let string = settlementFiles.image_url;
    if (settlementFiles.image_url && string.image_url.includes("logo")) {
      setLocalLogo(string.image_url);
    } else if (
      settlementFiles.image_url &&
      string.image_url.includes("favicon")
    ) {
      setLocalFavicon(string.image_url);
    } else if (
      settlementFiles.image_url &&
      string.image_url.includes("about_us_image")
    ) {
      setLocalAboutUsImage(settlementFiles.image_url);
    } else {
      return;
    }
  }, [settlementFiles.image_url, localLogo, localFavicon, localAboutUsImage]);

  useEffect(() => {
    if (!selectedFile) {
      setDisableUploadBtn(true);
    }
  }, [selectedFile, disableUploadBtn]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // ERROR AND SUCCESS MESSAGES

  // display settlement (portal address + legal & contact) PUT success & error
  useEffect(() => {
    if (settlement.status.SETTLEMENT_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(settlementActions.clearStatusMessage("SETTLEMENT_PUT"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(settlement.status.SETTLEMENT_PUT)) {
      setErrorMessage(settlement.status.SETTLEMENT_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(settlementActions.clearStatusMessage("SETTLEMENT_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, settlement.status]);

  // display file upload (logo, favicon, about us image) PUT success & error
  useEffect(() => {
    if (settlementFiles.status.FILE_PUT === "success") {
      setSelectedFile("");
      setFileType("");
      setLocalLogo("");
      setLocalFavicon("");
      setLocalAboutUsImage("");
      setSuccessMessage("Success!");
      setOpenSnackbar(true);

      setTimeout(() => {
        dispatch(fileActions.clearStatusMessage("FILE_PUT"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(settlementFiles.status.FILE_PUT)) {
      setErrorMessage("Wrong dimensions of image");
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(fileActions.clearStatusMessage("FILE_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, settlementFiles.status]);

  // display file upload (logo, favicon, about us image) DELETE success & error
  useEffect(() => {
    if (settlementFiles.status.FILE_DELETE === "success") {
      // setSuccessMessage("Your changes has been saved!");
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      dispatch(settlementActions.getSettlement());
      setLocalLogo(false);
      if (!settlement.settlement_details.logo_url && localLogo) {
        setLocalLogo(false);
      } else if (!settlement.settlement_details.favicon_url && localFavicon) {
        setLocalFavicon(false);
      } else if (
        !settlement.settlement_details.about_us_url &&
        localAboutUsImage
      ) {
        setLocalAboutUsImage(false);
      }
      setTimeout(() => {
        dispatch(fileActions.clearStatusMessage("FILE_DELETE"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(settlementFiles.status.FILE_DELETE)) {
      setErrorMessage(settlementFiles.status.FILE_DELETE);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(fileActions.clearStatusMessage("FILE_DELETE"));
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [
    dispatch,
    settlementFiles.status,
    localAboutUsImage,
    localFavicon,
    localLogo,
    settlement.settlement_details.about_us_url,
    settlement.settlement_details.favicon_url,
    settlement.settlement_details.logo_url,
  ]);

  if (errorMessage && errorMessage.includes("String does not match pattern")) {
    setErrorMessage("Sorry, this file format is not supported");
  }

  const handleUpgrade = () => {
    dispatch(sidebarActions.updateStatus("SUBSCRIPTION"));
  };

  const subscription = jwtData.subscription;

  useEffect(() => {
    if (settlementFiles.status.FILE_PUT === "success") {
      dispatch(settlementActions.getSettlement());
    }
  }, [dispatch, settlementFiles.status.FILE_PUT]);

  return (
    <div className={styles.root}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorSnackbar}
        autoHideDuration={7000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>

      <div className={styles.settlement_portal_title}>
        <ImportantDevicesIcon className={styles.device_icon} />
        <h2>Settlement Portal Settings </h2>
        <ArrowForwardIosIcon fontSize="small" className={styles.arrow_icon} />
        <h2>Base Settings</h2>
      </div>
      <p>
        Base settings that apply universally to all translations of the
        settlement portal.
      </p>
      <div className={styles.tabs_root}>
        <div className={styles.tabs_container}>
          <CustomTabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            variant="fullWidth"
          >
            <CustomTab label="Portal Address *" />
            <CustomTab label="Legal & Contact" />
            <CustomTab label="Logo & Images" />
          </CustomTabs>
        </div>
        <div
          className={
            selectedTab !== 2
              ? styles.tabs_content_container
              : styles.tabs_content_container_images
          }
        >
          <div
            hidden={selectedTab !== 0}
            className={styles.tab_content_portal_address}
          >
            <div className={styles.subdomain_container}>
              <p className={styles.label}>Subdomain in fairlicensing.com</p>
              <p className={styles.description}>
                Set the subdomain that your settlement portal can be accessed on
                in the fairlicensing.com domain (i.e.
                subdomain.fairlicensing.com)
              </p>
              <div className={styles.example_input_container}>
                <div className={styles.input_container}>
                  <TextfieldInput
                    label="Subdomain in fairlicensing.com"
                    id={"settlement_portal_host_internal"}
                    name={"settlement_portal_host_internal"}
                    value={settlementDetails.settlement_portal_host_internal}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <p>.fairlicensing.com</p>
              </div>
              <div className={styles.border}></div>
              {subscription === "LITE" && (
                <div className={styles.confirm_btn_container}>
                  <Button
                    className={styles.confirm_btn}
                    onClick={(e) => handleSubmitPortalAddress()}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>

            <div>
              {subscription === "LITE" ? (
                <div className={styles.upgrade_container}>
                  <div>
                    <img
                      src={UpgradeDomainImg}
                      alt="own domain illustration"
                      className={styles.upgrade_domain_image}
                    />
                  </div>
                  <div className={styles.upgrade_content}>
                    <p className={styles.upgrade_text_large}>
                      Gain trust! Intergrate the settlement portal in your own
                      domain.
                    </p>
                    <div className={styles.upgrade_btn_container}>
                      <Button
                        className={styles.confirm_btn}
                        onClick={() => handleUpgrade()}
                      >
                        Upgrade To Pro
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p className={styles.label}>
                    Settlement Portal in your own Domain
                  </p>
                  <p className={styles.description}>
                    Optionally choose to publish the settlement portal in your
                    own domain (i.e. fairlicensing.yourdomain.com).<br></br>
                    <br></br>
                    In order for your settlement portal to be accessible in your
                    own domain you have to set a CNAME record in your domain
                    configuration pointing from fairlicensing.yourdomain.com to
                    settlement.fairlicensing.com.
                  </p>
                  <div className={styles.example_input_container}>
                    <p>fairlicensing.</p>

                    <div className={styles.input_container}>
                      <TextfieldInput
                        label="Your Domain"
                        id={"settlement_portal_host_external"}
                        name={"settlement_portal_host_external"}
                        value={
                          settlementDetails.settlement_portal_host_external
                        }
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {subscription !== "LITE" && (
              <div className={styles.confirm_btn_container}>
                <Button
                  className={styles.confirm_btn}
                  onClick={(e) => handleSubmitPortalAddress()}
                >
                  Save
                </Button>
              </div>
            )}
          </div>
          <div
            hidden={selectedTab !== 1}
            className={styles.tab_content_legal_contact}
          >
            <p className={styles.description}>
              Set mandatory links and contact info that will be displayed in the
              settlement portal.
            </p>
            <div className={styles.legal_contact_container}>
              <div className={styles.input_row}>
                <div className={styles.input_container}>
                  <div className={styles.input_container}>
                    <TextfieldInput
                      label="Company Homepage"
                      id={"homepage"}
                      name={"homepage"}
                      value={settlementDetails.homepage}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div>
                  <div className={styles.input_container}>
                    <TextfieldInput
                      label="Privacy Statement Link"
                      id={"privacy_statement_link"}
                      name={"privacy_statement_link"}
                      value={settlementDetails.privacy_statement_link}
                      onChange={handleInputChange}
                      className={styles.link_input}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.input_row}>
                <div>
                  <div className={styles.input_container}>
                    <TextfieldInput
                      label="Contact Page Link"
                      id={"imprint_link"}
                      name={"imprint_link"}
                      value={settlementDetails.imprint_link}
                      onChange={handleInputChange}
                      className={styles.link_input}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.confirm_btn_container}>
              <Button
                className={styles.confirm_btn}
                onClick={() => handleSubmitLegalContact()}
              >
                Save
              </Button>
            </div>
          </div>
          <div hidden={selectedTab !== 2} className={styles.tab_content}>
            <div className={styles.logo_favicon_container}>
              {subscription === "LITE" ? (
                <div className={styles.upgrade_container}>
                  <div>
                    <img
                      src={UpgradeLogoImg}
                      alt="upload logo illustration"
                      className={styles.upgrade_logo_image}
                    />
                  </div>
                  <div className={styles.upgrade_content}>
                    <p className={styles.upgrade_text_large}>
                      Customize your settlement portal by using your own logo
                      <br />
                      and your own image on the 'about us' page.
                    </p>
                    <div className={styles.upgrade_btn_container}>
                      <Button
                        className={styles.confirm_btn}
                        onClick={() => handleUpgrade()}
                      >
                        Upgrade To Pro
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p className={styles.description}>
                    Upload images and logos that will be displayed in the
                    settlement portal.
                  </p>
                  <div className={styles.general_col_images}>
                    <div>
                      <div className={styles.upload_title_container}>
                        <p>Logo</p>
                        <p className={styles.upload_size}>
                          35px - 1600px width, 35px - 400px height
                        </p>
                      </div>
                      <div className={styles.general_image_container}>
                        <div
                          className={styles.logo_box}
                          onClick={() => setFileType("logo")}
                        >
                          {settlement.settlement_details.logo_url ||
                          localLogo ? (
                            <div>
                              <div
                                className={styles.delete_icon_container_logo}
                              >
                                <DeleteIcon
                                  className={styles.delete_icon}
                                  onClick={(e) => openDeleteDialog("logo")}
                                  fontSize="small"
                                />
                              </div>
                              <img
                                src={
                                  (settlement.settlement_details.logo_url &&
                                    settlement.settlement_details.logo_url) ||
                                  localLogo
                                }
                                alt="logo"
                                className={styles.logo}
                              />
                            </div>
                          ) : (
                            <UploadImage
                              styles={styles}
                              id="logo"
                              accept=".jpg, .gif, .png, .jpeg, .ico"
                              handleFileInputChange={handleFileInputChange}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={styles.favicon_container}>
                      <div className={styles.upload_title_container}>
                        <p>Favicon</p>
                        <p className={styles.upload_size}>
                          16/32/48/64/128px square size
                        </p>
                      </div>
                      <div className={styles.general_image_container}>
                        <div
                          className={styles.favicon_box}
                          onClick={() => setFileType("favicon")}
                        >
                          {settlement.settlement_details.favicon_url ||
                          localFavicon ? (
                            <div>
                              <div
                                className={styles.delete_icon_container_favicon}
                              >
                                <DeleteIcon
                                  className={styles.delete_icon}
                                  onClick={(e) => openDeleteDialog("favicon")}
                                  fontSize="small"
                                />
                              </div>
                              <img
                                src={
                                  settlement.settlement_details.favicon_url +
                                    "?" +
                                    new Date() || localFavicon
                                }
                                className={styles.favicon}
                                alt="favicon"
                              />
                            </div>
                          ) : (
                            <UploadImage
                              styles={styles}
                              id="favicon"
                              handleFileInputChange={handleFileInputChange}
                              accept=".gif, .png, .ico"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {subscription !== "LITE" && (
              <div className={styles.about_us_container}>
                <div className={styles.upload_title_container}>
                  <p className={styles.description}>About Us Page Image{""}</p>
                  <p className={styles.upload_size}>
                    100px -2000px width, 120px - 2400px height
                  </p>
                </div>
                <p className={styles.about_us_image_text}>
                  An optional image to be displayed on the “about us” page. If
                  no image is uploaded, the image will be dynamically selected
                  by the software and can potentially vary between different
                  visits to the settlement portal based on algorithmic decisions
                  made by the software.
                </p>
                <div className={styles.about_us_content}>
                  <div
                    className={styles.about_us_image_box}
                    onClick={() => setFileType("about_us_image")}
                  >
                    {settlement.settlement_details.about_us_image_url ||
                    localAboutUsImage ? (
                      <div>
                        <div className={styles.delete_icon_container_about_us}>
                          <DeleteIcon
                            className={styles.delete_icon}
                            onClick={(e) => openDeleteDialog("about_us_image")}
                            fontSize="small"
                          />
                        </div>
                        <img
                          src={
                            (settlement.settlement_details.about_us_image_url &&
                              settlement.settlement_details
                                .about_us_image_url) ||
                            localAboutUsImage
                          }
                          alt="custom about us"
                          className={styles.about_us_image}
                        />
                      </div>
                    ) : (
                      <UploadImage
                        styles={styles}
                        id="about_us_image"
                        accept=".jpg, .jpeg, .png"
                        handleFileInputChange={handleFileInputChange}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {deleteDialogOpen.open && (
        <DialogPopup
          title="Confirmation required"
          open={deleteDialogOpen.open}
          onClose={() => setDeleteDialogOpen({ open: false, type: "" })}
        >
          <div className={styles.deletionPopupText}>
            Do you really want to delete the{" "}
            {deleteDialogOpen.type === "about_us_image"
              ? "about us image"
              : deleteDialogOpen.type}
            ?
          </div>

          <div className={styles.deletionPopupButtonContainer}>
            <Button
              className={styles.button_confirm}
              onClick={() => handleDeleteFile(deleteDialogOpen.type)}
            >
              Yes
            </Button>
            <Button
              className={styles.button_cancel}
              onClick={() => setDeleteDialogOpen({ open: false, type: "" })}
            >
              Cancel
            </Button>
          </div>
        </DialogPopup>
      )}
    </div>
  );
}

export default BaseSettings;
