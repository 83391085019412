import React, { useState, useEffect } from 'react';
import styles from './ChangePassword.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { isNull, validatePasswordRules, catchError } from '@_utils/helpers';
import { userActions } from '@_actions/user.actions';
import PasswordField from '@_components/PasswordField/PasswordField';

import { makeStyles } from '@material-ui/core';

function ChangePassword(props) {
  const useStyles = makeStyles({
    helperText: { fontSize: '12px !important' },
  });
  const classes = useStyles();

  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);
  const [forgotPasswordInputs, setForgotPasswordInputs] = useState({
    email: userReducer.forgot_password_details.email || '',
    password: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  function handleForgotPasswordInputsChange(e) {
    const { name, value } = e.target;
    setError(false);
    setForgotPasswordInputs((forgotPasswordInputs) => ({
      ...forgotPasswordInputs,
      [name]: value,
    }));
  }

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (isNull(forgotPasswordInputs.password)) {
      setError(true);
      setErrorMessage('Please enter a password.');
      return;
    }
    if (!validatePasswordRules(forgotPasswordInputs.password)) {
      setError(true);
      setErrorMessage('Please enter a valid password.');
      return;
    }
    let data = {
      new_password: forgotPasswordInputs.password,
      email: userReducer.forgot_password_details.email,
    };
    if (props.forgotPasswordId) {
      dispatch(userActions.newPassword(props.forgotPasswordId, data));
    }
  };

  useEffect(() => {
    if (userReducer.status.USER_PUT_NEW_PASSWORD === 'success') {
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage('USER_PUT_NEW_PASSWORD'));
      }, 2000);
    } else if (catchError(userReducer.status.USER_PUT_NEW_PASSWORD)) {
      setErrorMessage(userReducer.status.USER_PUT_NEW_PASSWORD);
      setError(true);
    }
  }, [userReducer.status, dispatch]);

  return (
    <div className={styles.loginForm}>
      <div className={styles.loginFormHeader}>CHANGE PASSWORD</div>
      <p>
        {userReducer.forgot_password_details &&
          userReducer.forgot_password_details.email}
      </p>
      <div className={styles.input_container}>
        <PasswordField
          placeholder="New Password"
          name="password"
          value={forgotPasswordInputs.password}
          onChange={handleForgotPasswordInputsChange}
          error={error}
          helperText={error && errorMessage}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          required
        />
      </div>
      <div
        className={
          errorMessage
            ? styles.password_requirements_error
            : styles.password_requirements
        }
      >
        {' '}
        Enter at least 8 characters (containing 1 number, 1 special character, 1
        capital letter)
      </div>
      <div className={styles.btnContainer}>
        <button
          className={styles.button}
          onClick={(e) => handleChangePassword(e)}
        >
          CONFIRM
        </button>
      </div>
    </div>
  );
}

export default ChangePassword;
