import React, { useEffect, useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { caseActions } from "@_actions/case.actions";
import { casesActions } from "@_actions/cases.actions";

function CancelCasePopup(props) {
  const { overviewData, caseId } = props;
  const dispatch = useDispatch();
  const [cancelInProgress, setCancelInProgress] = useState(false);
  const caseReducer = useSelector((state) => state.caseReducer);

  function actionClicked() {
    setCancelInProgress(true);
    dispatch(caseActions.updateCaseStateCancel(caseId));
    setTimeout(() => {}, 100);
  }

  useEffect(() => {
    if (
      cancelInProgress &&
      caseReducer.status.CASE_UPDATE_STATE_CANCEL === "success"
    ) {
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_STATE_CANCEL"));
      dispatch(casesActions.getCases());
      dispatch(casesActions.getCasesCount());
      dispatch(caseActions.getCaseWithChargeDetails(caseId, overviewData));

      props.close();
    } else if (
      cancelInProgress &&
      caseReducer.status.CASE_UPDATE_STATE_CANCEL !== "success" &&
      caseReducer.status.CASE_UPDATE_STATE_CANCEL !== "request" &&
      caseReducer.status.CASE_UPDATE_STATE_CANCEL !== ""
    ) {
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_STATE_CANCEL"));
      //error occured
      window.alert("Cancel case failed. Try again later.");
    }
  }, [
    cancelInProgress,
    caseId,
    caseReducer.status.CASE_UPDATE_STATE_CANCEL,
    dispatch,
    overviewData,
    props,
  ]);

  return (
    <DialogPopup
      title="Cancel Case"
      open={props.open}
      onClose={() => props.close()}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          actionClicked();
        }
      }}
    >
      <div>Are you sure you want to cancel this case?</div>
      <div className="action_container">
        <Button className="btn_cancel" onClick={() => props.close()}>
          No
        </Button>
        <Button className="btn_confirm" onClick={actionClicked}>
          Yes
        </Button>
      </div>
    </DialogPopup>
  );
}

export default CancelCasePopup;
