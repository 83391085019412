import { Box, Button, Snackbar, TextField } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./InboundEmailsDetail.module.css";
import { shortnString } from "@_utils/helpers";
import { caseService } from "@_services/case.service";
import _ from "lodash";
import { customerService } from "@_services/customer.service";
import { emailsActions } from "@_actions/emails.actions";
import { casesService } from "@_services/cases.service";
import { caseConstants } from "@_constants/case.constants";
import Scrollbar from "react-perfect-scrollbar-z";
import "scrollbar.css";

function InboundEmailsDetail(props) {
  const dispatch = useDispatch();
  /**
   * Email from the list selection. It does not contain
   * the email content at the beginning
   */
  const { email, onCloseDetails } = props;

  const [noOptionsText, setNoOptionsText] = useState(
    "Start typing to get cases list..."
  );

  /**
   * Email Content when loaded
   */
  const [emailContent, setEmailContent] = useState("");

  /**
   * This is needed to reset the autocomplete
   */
  const [autoCompleteKey, setAutoCompleteKey] = useState("k1");

  /**
   * Holds cases as the result of search (no standby)
   *
   * {label: case_id + " " + host, id: case.id}
   */
  const [currentFilteredCases, setCurrentFilteredCases] = useState([]);

  /**
   * Holds the select case to map the email to
   */
  const [mapInput, setMapInput] = useState(null);

  /**
   * Disables the "Map to Case" button when no case is selected
   */
  const [mapButtonDisabled, setMapButtonDisabled] = useState(true);

  const [snackBarText, setSnackBarText] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);

  /**
   * Called when text input changes to fire a new search reqeust
   * @param {event} e onChange Event containing the new text
   */
  const onTextInputChange = (e) => {
    if (e && e.target && e.target.value) {
      setNoOptionsText("No case found");
      var searchOptions = {
        sort: {
          key: "date_state_updated",
          ascending: false,
        },
        filter: {
          search: e.target.value,
          unread_emails: false,
          unchecked_licenses: false,
          overdue_reminders: false,
          failed_emails: false,
          paused_scheduled_emails: false,
          action_required: false,
          idle: false,
        },
      };
      casesService
        .getCases(searchOptions)
        .then((data) => {
          if (!data.cases) return;
          setCurrentFilteredCases(
            data.cases
              .filter((x) => x.state !== caseConstants.CASE_STATE_STANDBY)
              .map((x) => {
                return { label: x.case_id + " " + x.host, id: x.id };
              })
          );
        })
        .catch((e) => {
          console.log("Error while getting cases for search.", e);
          setNoOptionsText("Something went wrong filtering the cases.");
        });
    } else {
      setNoOptionsText("Start typing to get cases list...");
    }
  };

  /**
   * Called when user selects a case from drop down
   *
   * @param {Event} e Selection change event
   * @param {any} values Value which was selected
   */
  const onMapinputChange = (e, values) => {
    if (values) setMapInput(values.id);
    else {
      setMapInput(null);
    }
  };

  /**
   * Change the disabled state of map to case button when user
   * selects a case or unselect
   */
  useEffect(() => {
    if (mapInput) setMapButtonDisabled(false);
    else setMapButtonDisabled(true);
  }, [mapInput]);

  /**
   * Only called once at the beginnning.
   * Gets the email content
   */
  useEffect(() => {
    if (email) {
      caseService
        .getEmailContent(email.id)
        .then((data) => {
          setEmailContent(
            typeof data.data.html === "boolean"
              ? data.data.textAsHtml
              : data.data.html
          );
          setAutoCompleteKey(_.uniqueId());
          setMapInput(null);
          setCurrentFilteredCases([]);
          window.scrollTo({ top: 0 });
          // update email state with email id to unread:0
          dispatch(emailsActions.updateEmailProperty(email.id, "unread", 0));
        })
        .catch((e) => {
          console.log("Error while getting email content.", e);
          setEmailContent(<>Loading Email failed...</>);
        });
    }
  }, [email, dispatch]);

  /**
   * Map the Mail to a case. Afterwards call reload list and unselect the case.
   */
  const onMapToCase = () => {
    customerService
      .mapMailToCase(email.id, mapInput)
      .then((response) => {
        dispatch(emailsActions.loadEmails());
        onCloseDetails();
      })
      .catch((e) => {
        console.error(e);
        setSnackBarText("Map to case was not successfull");
        setOpenSnackbar(true);
      });
  };

  const onDeleteMail = () => {
    customerService
      .deleteMail(email.id, mapInput)
      .then((response) => {
        dispatch(emailsActions.deleteEmail(email.id));
        onCloseDetails();
      })
      .catch((e) => {
        console.error(e);

        setSnackBarText("Mail was not deleted");
        setOpenSnackbar(true);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackBarText("");
    setOpenSnackbar(false);
  };

  const renderContent = () => {
    if (!email) return <div>NO EMAIL SELECTED</div>;

    return (
      <>
        <div className={styles.detail_container}>
          <div className={styles.top_row}>
            <div style={{}}>
              <Autocomplete
                key={autoCompleteKey}
                disablePortal={true}
                noOptionsText={noOptionsText}
                id="combo-box-demo"
                options={currentFilteredCases}
                getOptionLabel={(o) => o.label}
                style={{
                  minWidth: "280px",
                  maxWidth: "280px",
                  marginRight: 20,
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Case or Domain"
                    onChange={onTextInputChange}
                  />
                )}
                onChange={onMapinputChange}
                groupBy={() => 1}
                renderGroup={(params) => (
                  <li key={params.key}>
                    <div className={styles.autocomplete_group_header}>
                      <table
                        style={{
                          width: "100%",
                          backgroundColor: "var(--color-bg-caselist)",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td style={{ paddingLeft: 5, width: 100 }}>ID</td>
                            <td>Domain</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className={styles.autocomplete_group_item}>
                      {params.children}
                    </div>
                  </li>
                )}
                renderOption={(props, option) => (
                  <Box>
                    {" "}
                    <table
                      style={{
                        width: "100%",
                      }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ paddingLeft: 5, minWidth: 80 }}>
                            {props.label.split(" ")[0]}
                          </td>
                          <td
                            style={{ whiteSpace: "nowrap", overflow: "hidden" }}
                          >
                            {shortnString(props.label.split(" ")[1], 21)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>
                )}
              ></Autocomplete>
            </div>
            <Button
              className={styles.btn_default}
              style={{ marginRight: "370px" }}
              disabled={mapButtonDisabled}
              onClick={onMapToCase}
            >
              Map to case
            </Button>
            <Button className={styles.btn_default} onClick={onDeleteMail}>
              DELETE
            </Button>
          </div>
          <Scrollbar className={styles.detail_scrollbar} options={{suppressScrollX:true}} maxWidth="840px" maxHeight="calc(100vh - 141px)">
            <div className={styles.detail_row}>
              {emailContent === "" ? (
                <>...loading</>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: emailContent,
                  }}
                ></div>
              )}
            </div>
          </Scrollbar>
        </div>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={handleCloseSnackbar}
          sev
        >
          <Alert onClose={handleCloseSnackbar} severity="error">
            {snackBarText}
          </Alert>
        </Snackbar>
      </>
    );
  };

  return (
    <>
      <div className={styles.root}>{renderContent()}</div>
    </>
  );
}

export default InboundEmailsDetail;
