import React, { useState, useEffect } from 'react';
import styles from './EnterEmail.module.css';
import TextfieldInput from '@_components/TextfieldInput/TextfieldInput';
import { validateEmail, isNull, catchError } from '@_utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '@_actions/user.actions';

import { makeStyles } from '@material-ui/core';

function EnterEmail(props) {
  const useStyles = makeStyles({
    helperText: { fontSize: '12px !important' },
  });

  const classes = useStyles();
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);

  const [forgotPasswordInputs, setForgotPasswordInputs] = useState({
    email: userReducer.forgot_password_details.email || '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [error, setError] = useState(false);

  function handleForgotPasswordInputsChange(e) {
    const { name, value } = e.target;
    setError(false);
    setForgotPasswordInputs((forgotPasswordInputs) => ({
      ...forgotPasswordInputs,
      [name]: value,
    }));
  }

  const handleSendLink = (e) => {
    e.preventDefault();
    if (isNull(forgotPasswordInputs.email)) {
      setError(true);
      setErrorMessage('Please enter a email address.');
      return;
    }
    if (!validateEmail(forgotPasswordInputs.email)) {
      setError(true);
      setErrorMessage('Please enter a valid email address.');
      return;
    }

    let data = { ...forgotPasswordInputs };
    dispatch(userActions.sendPasswordLink(data));
  };

  useEffect(() => {
    if (userReducer.status.USER_PUT_PASSWORD_LINK === 'success') {
      setTimeout(() => {
        dispatch(userActions.clearStatusMessage('USER_PUT_PASSWORD_LINK'));
      }, 2000);
    } else if (catchError(userReducer.status.USER_PUT_PASSWORD_LINK)) {
      setErrorMessage(userReducer.status.USER_PUT_PASSWORD_LINK);
      setError(true);
    }
  }, [userReducer.status, dispatch]);

  return (
    <div className={styles.loginForm}>
      <div className={styles.loginFormHeader}>FORGOT PASSWORD?</div>
      <p>Enter your email to get a reset link.</p>
      <div className={styles.input_container}>
        <TextfieldInput
          placeholder="Email"
          type="text"
          name="email"
          value={forgotPasswordInputs.email}
          onChange={handleForgotPasswordInputsChange}
          error={error}
          helperText={error && errorMessage}
          FormHelperTextProps={{
            className: classes.helperText,
          }}
          required
        />
      </div>
      <div className={styles.two_buttons_container}>
        <button
          className={styles.button_cancel}
          onClick={() => props.handleCancel()}
        >
          CANCEL{' '}
        </button>
        <button className={styles.button} onClick={(e) => handleSendLink(e)}>
          SEND LINK
        </button>
      </div>
    </div>
  );
}

export default EnterEmail;
