import React, { useState, useEffect } from "react";
import styles from "./ValidateEmailSetup.module.css";
import { useSelector, useDispatch } from "react-redux";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { emailSetupActions } from "@_actions/email_setup.actions";
import { catchError } from "@_utils/helpers";

import Button from "@material-ui/core/Button";

function ValidateEmailSetupPopup(props) {
  const dispatch = useDispatch();
  const emailSetupReducer = useSelector((state) => state.emailSetupReducer);

  const [successfulValidation, setSuccessfulValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    if (emailSetupReducer.status.EMAIL_SETUP_VALIDATION_PUT === "success") {
      setSuccessfulValidation(true);
      setSuccessMessage(
        "Success! Your setup is valid. You can activate your setup and save the details below."
      );
      setErrorMessage(false);
    }
  }, [
    successfulValidation,
    emailSetupReducer.status.EMAIL_SETUP_VALIDATION_PUT,
    errorMessage,
    successMessage,
  ]);

  const handleUpdateEmailSetup = (e) => {
    if (!props.smtpData.smtp_username && !props.checkbox) {
      let data = {
        ...props.smtpData,
        smtp_port: parseInt(props.smtpData.smtp_port),
        smtp_username: props.smtpData.smtp_email,
      };
      dispatch(emailSetupActions.updateOutboundEmailSetup(data));
    } else {
      let data = {
        ...props.smtpData,
        smtp_port: parseInt(props.smtpData.smtp_port),
      };
      dispatch(emailSetupActions.updateOutboundEmailSetup(data));
    }

    let activationData = {
      active_email_setup: "SMTP",
    };

    if (emailSetupReducer.email_setup.active_email_setup !== "SMTP") {
      dispatch(emailSetupActions.activateOutboundEmailSetup(activationData));
    }
    setTimeout(() => {
      dispatch(emailSetupActions.getEmailSetup());
    }, 400);
    props.close();
  };

  useEffect(() => {
    if (catchError(emailSetupReducer.status.EMAIL_SETUP_VALIDATION_PUT)) {
      setErrorMessage(emailSetupReducer.status.EMAIL_SETUP_VALIDATION_PUT);
      setSuccessMessage(false);
    }
  }, [errorMessage, emailSetupReducer.status.EMAIL_SETUP_VALIDATION_PUT]);

  if (
    errorMessage ===
    "Request timeout. Please check your configuration and try again."
  ) {
    setErrorMessage(
      "Something went wrong, please check your configuration and try again!"
    );
  } else if (
    errorMessage ===
    "512 DNS error: the host server for the recipient’s domain name cannot be found."
  ) {
    setErrorMessage(
      "Your SMTP host could not be found, please check your configuration and try again!"
    );
  } else if (
    errorMessage ===
    "535 Authentication failed: Email or password not accepted."
  ) {
    setErrorMessage(
      "Your email or password is incorrect, please check your configuration and try again!"
    );
  } else if (errorMessage === "Expected type integer but found type null") {
    setErrorMessage("Please enter a valid host.");
  } else if (
    errorMessage ===
    "451 Request timeout - closing connection. Please check your configuration and try again."
  ) {
    setErrorMessage(
      "Something went wrong, please check your configuration and try again!"
    );
  }

  return (
    <DialogPopup
      title="Email Setup Validation"
      open={props.open}
      onClose={() => props.close()}
    >
      <div>
        {emailSetupReducer.status.EMAIL_SETUP_VALIDATION_PUT === "success" &&
          emailSetupReducer.email_setup.active_email_setup !== "SMTP" && (
            <p className={styles.success_message}>
              Your setup is valid! You can activate your setup and save the
              details below.
            </p>
          )}
        {emailSetupReducer.status.EMAIL_SETUP_VALIDATION_PUT === "success" &&
          emailSetupReducer.email_setup.active_email_setup === "SMTP" && (
            <p className={styles.success_message}>
              Your setup is valid! You can save the details below.
            </p>
          )}
        {errorMessage &&
          emailSetupReducer.status.EMAIL_SETUP_VALIDATION_PUT !== "success" && (
            <p className={styles.error_message}>{errorMessage}</p>
          )}
      </div>

      <div className="action_container">
        <Button className="btn_cancel" onClick={() => props.close()}>
          ok
        </Button>
        {successfulValidation &&
          emailSetupReducer.email_setup.active_email_setup !== "SMTP" && (
            <Button
              className="btn_confirm"
              onClick={() => handleUpdateEmailSetup()}
            >
              save & activate
            </Button>
          )}
        {successfulValidation &&
          emailSetupReducer.email_setup.active_email_setup === "SMTP" && (
            <Button
              className="btn_confirm"
              onClick={() => handleUpdateEmailSetup()}
            >
              save
            </Button>
          )}
      </div>
    </DialogPopup>
  );
}

export default ValidateEmailSetupPopup;
