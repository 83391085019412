import React from 'react';
import styles from './PersonalInformation.module.css';
import PersonalInfo from '@_assets/images/personal_info.png';
import TextfieldInput from '@_components/TextfieldInput/TextfieldInput';
import PasswordField from '@_components/PasswordField/PasswordField';
import { makeStyles } from '@material-ui/core';

const PersonalInformation = ({
  name,
  email,
  password,
  formDataValidation,
  handleChange,
  formRef,
}) => {
  const useStyles = makeStyles({
    helperText: { fontSize: '12px !important' },
  });
  const classes = useStyles();

  return (
    <div className={styles.personalInformationContainer}>
      <img
        src={PersonalInfo}
        alt="Fair Licensing Logo"
        className={styles.personalImg}
      />
      <div className={styles.personalInformationFormContainer}>
        <div>
          Nice to see you!
          <br /> Please tell us about yourself.
        </div>

        <form className={styles.formContainer} ref={formRef}>
          <p className={styles.requiredTag}>
            <span className={styles.requiredRed}>*</span> required fields
          </p>
          <TextfieldInput
            placeholder="Name"
            label="Name"
            type="text"
            name="name"
            value={name}
            onChange={handleChange}
            className={styles.personalInformationInput}
            required
          />
          <TextfieldInput
            label="Email"
            placeholder="Email"
            type="email"
            name="email"
            value={email}
            onChange={handleChange}
            error={Boolean(formDataValidation.email)}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            helperText={
              formDataValidation.email && 'Enter a valid email address'
            }
            className={styles.personalInformationInput}
            required
          />
          <PasswordField
            className={styles.personalInformationInputPasswordField}
            label="Password"
            placeholder="Password"
            name="password"
            value={password}
            onChange={handleChange}
            error={Boolean(formDataValidation.password)}
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            helperText="Enter at least 8 characters (containing 1 number, 1 special character)."
            required
          />
        </form>
      </div>
    </div>
  );
};

export default PersonalInformation;
