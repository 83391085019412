import Config from "@_configs/api.config";
import { authHeader, getUserDataFromJwt } from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const PaymentService = {
  downloadPaymentReport,
};

function downloadPaymentReport(
  startDate,
  endDate,
  output_filename,
  format,
  details
) {
  let report_type = details ? "payment_report_details" : "payment_report";

  let start_date_param = startDate ? "start_date=" + startDate + "&" : "";
  let end_date_param = endDate ? "end_date=" + endDate + "&" : "";
  const customer_id = getUserDataFromJwt().customer_id;
  let url =
    Config.API_ROOT +
    `/customer/${customer_id}/${report_type}?${start_date_param}${end_date_param}format=${format}`;

  fetch(url, {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", output_filename);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
}
