import React, { useEffect, useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import DateInput from "@_components/DateInput/DateInput";
import styles from "./ReminderPopup.module.css";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { caseActions } from "@_actions/case.actions";
import { casesActions } from "@_actions/cases.actions";
import { caseConstants } from "@_constants/case.constants";

function ReminderPopup(props) {
  const dispatch = useDispatch();
  const [nameInput, setNameInput] = useState(
    props.data.reminder_name ? props.data.reminder_name : "Reminder"
  );
  const [dateInput, setDateInput] = useState(
    props.data.reminder_date ? props.data.reminder_date : ""
  );
  const requestState = useSelector(
    (state) => state.caseReducer.status.CASE_UPDATE_DEADLINE
  );
  const { data, caseId, doClose } = props;

  function handleDelete() {
    //send null,null to service
    dispatch(caseActions.updateReminder(caseId, null));
    setTimeout(() => dispatch(casesActions.getCases(), 500));
  }

  function handleConfirm() {
    //send data to service
    dispatch(
      caseActions.updateReminder(caseId, { name: nameInput, date: dateInput })
    );
    setTimeout(() => dispatch(casesActions.getCases(), 500));
  }

  useEffect(() => {
    if (requestState && requestState === "success") {
      doClose();
      //reset requestState so that we ca open again
      dispatch({ type: caseConstants.CASE_UPDATE_DEADLINE_FAILURE_RESET });
    }
  }, [requestState, doClose, dispatch]);

  return (
    <DialogPopup
      title="Reminder Settings"
      open={props.open}
      onClose={() => props.close()}
      //size={"large"}
    >
      <div className={styles.dialog_container}>
        <div className={styles.input_name}>
          <TextfieldInput
            className={styles.input_small}
            label="Reminder Title"
            id="reminder_title"
            name="reminder_title"
            type="string"
            value={nameInput}
            onChange={(event) => setNameInput(event.target.value)}
          />
        </div>
        <div className={styles.input_date}>
          <DateInput
            label="Reminder date"
            id="reminder_date"
            name="reminder_date"
            value={dateInput}
            onChange={(date) => setDateInput(date)}
            minDate={new Date().setDate(new Date().getDate() + 1)}
          />
        </div>
        <div className="action_container">
          <Button className="btn_cancel" onClick={() => props.close()}>
            Cancel
          </Button>
          <Button
            className={styles.btn_delete}
            onClick={handleDelete}
            disabled={!data.reminder_date}
          >
            Delete
          </Button>
          <Button
            className="btn_confirm"
            onClick={handleConfirm}
            disabled={!dateInput || (dateInput && dateInput.length < 1)}
          >
            Confirm
          </Button>
        </div>
      </div>
    </DialogPopup>
  );
}

export default ReminderPopup;
