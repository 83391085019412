import React, { useState, useEffect } from "react";
import styles from "./EmailSetup.module.css";
import { useDispatch, useSelector } from "react-redux";
import { emailSetupActions } from "@_actions/email_setup.actions";
import { catchError } from "@_utils/helpers";
import CopyPasteEmailSetup from "@_components/CopyPasteEmailSetup/CopyPasteEmailSetup";

import EmailIcon from "@material-ui/icons/Email";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SmtpSetup from "@_components/SmtpSetup/SmtpSetup";

import SpfSetup from "@_components/SpfSetup/SpfSetup";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CheckIcon from "@material-ui/icons/Check";
import Scrollbar from "react-perfect-scrollbar-z";
import "scrollbar.css";

function EmailSetup() {
  const dispatch = useDispatch();

  const emailSetupReducer = useSelector((state) => state.emailSetupReducer);

  const [openMenu, setOpenMenu] = useState(null);
  const [emailSetup, setEmailSetup] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [activeEmailSetup, setActiveEmailSetup] = useState("");

  useEffect(() => {
    dispatch(emailSetupActions.getEmailSetup());
  }, [dispatch]);

  const handleOpenMenu = (event) => {
    setOpenMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const handleSmtpOption = () => {
    setEmailSetup("Own Mail Server");
    setOpenMenu(null);
  };

  // const handleSpfOption = () => {
  //   setEmailSetup("Fair Licensing Mail Server");
  // setOpenMenu(null);
  // };

  const handleCopyPasteOption = () => {
    setEmailSetup("copy & paste");
    setOpenMenu(null);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
  };

  useEffect(() => {
    if (
      emailSetupReducer.email_setup &&
      emailSetupReducer.email_setup.active_email_setup &&
      !emailSetup
    ) {
      if (emailSetupReducer.email_setup.active_email_setup === "SMTP") {
        setEmailSetup("Own Mail Server");
      } else if (emailSetupReducer.email_setup.active_email_setup === "SLL") {
        setEmailSetup("Fair Licensing Mail Server");
      } else {
        setEmailSetup("copy & paste");
      }
    } else return;
  }, [
    emailSetupReducer.email_setup,
    emailSetup,
    emailSetupReducer.email_setup.active_email_setup,
  ]);

  useEffect(() => {
    if (
      !emailSetup &&
      emailSetupReducer.email_setup &&
      emailSetupReducer.email_setup.active_email_setup !== undefined &&
      !emailSetupReducer.email_setup.active_email_setup
    ) {
      setEmailSetup("copy & paste");
    }
  }, [emailSetup, emailSetupReducer.email_setup]);

  useEffect(() => {
    if (
      emailSetupReducer.email_setup &&
      emailSetupReducer.email_setup.active_email_setup &&
      emailSetupReducer.email_setup.active_email_setup === "SMTP"
    ) {
      setActiveEmailSetup("Own Mail Server");
    } else if (
      emailSetupReducer.email_setup &&
      emailSetupReducer.email_setup.active_email_setup &&
      emailSetupReducer.email_setup.active_email_setup === "SLL"
    ) {
      setActiveEmailSetup("Fair Licensing Mail Server");
    } else {
      setActiveEmailSetup("copy & paste");
    }
  }, [dispatch, emailSetupReducer.email_setup]);

  // display email setup PUT success & error
  useEffect(() => {
    if (emailSetupReducer.status.EMAIL_SETUP_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);

      setTimeout(() => {
        dispatch(emailSetupActions.clearStatusMessage("EMAIL_SETUP_PUT"));
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (catchError(emailSetupReducer.status.EMAIL_SETUP_PUT)) {
      if (
        emailSetupReducer.status.EMAIL_SETUP_PUT ===
        "Expected type integer but found type null"
      ) {
        setErrorMessage("Please enter a valid port.");
      } else {
        setErrorMessage(emailSetupReducer.status.EMAIL_SETUP_PUT);
      }
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(emailSetupActions.clearStatusMessage("EMAIL_SETUP_PUT"));
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, emailSetupReducer.status]);

  // display email setup activation PUT success & error
  useEffect(() => {
    if (emailSetupReducer.status.EMAIL_SETUP_ACTIVATION_PUT === "success") {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      setTimeout(() => {
        dispatch(
          emailSetupActions.clearStatusMessage("EMAIL_SETUP_ACTIVATION_PUT")
        );
        setErrorMessage("");
        setOpenSnackbar(false);
      }, 4000);
    } else if (
      catchError(emailSetupReducer.status.EMAIL_SETUP_ACTIVATION_PUT)
    ) {
      setErrorMessage(emailSetupReducer.status.EMAIL_SETUP_ACTIVATION_PUT);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        dispatch(
          emailSetupActions.clearStatusMessage("EMAIL_SETUP_ACTIVATION_PUT")
        );
        setOpenErrorSnackbar(false);
        setErrorMessage("");
      }, 7000);
    } else return;
  }, [dispatch, emailSetupReducer.status]);

  return (
    <div className={styles.root}>
      <Scrollbar maxHeight="calc(100vh - 141px)">
        <div style={{marginRight:20}}>
          {!openErrorSnackbar && (
            <Snackbar
              open={openSnackbar}
              autoHideDuration={4000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="success">
                {successMessage}
              </Alert>
            </Snackbar>
          )}

          <Snackbar
            open={openErrorSnackbar}
            autoHideDuration={7000}
            onClose={handleClose}
          >
            <Alert onClose={handleClose} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>

          <div className={styles.email_setup_header}>
            <div className={styles.settlement_portal_title}>
              <EmailIcon className={styles.email_icon} />
              <h2>Email</h2>
              <ArrowForwardIosIcon
                fontSize="small"
                className={styles.arrow_icon}
              />
              <h2>Setup</h2>
            </div>
            <p className={styles.email_setup_description}>
              Configure how you want to send emails with Fair Licensing. Emails
              in Fair Licensing are always sent from your own email address.
            </p>
            <p className={styles.select_setup_label}>I want to use...</p>
            <Button
              variant="outlined"
              color="default"
              className={styles.btn_select_email_setup}
              onClick={handleOpenMenu}
              endIcon={<ArrowDropDownIcon />}
            >
              {emailSetup === activeEmailSetup ? (
                <div className={styles.menu_item}>
                  <p>{emailSetup}</p>{" "}
                  <CheckIcon className={styles.check_icon} />
                </div>
              ) : (
                <div className={styles.menu_item}>
                  {" "}
                  <p>{emailSetup}</p>
                </div>
              )}
            </Button>
          </div>
          <Menu
            id="simple-menu"
            anchorEl={openMenu}
            keepMounted={false}
            open={Boolean(openMenu)}
            onClose={handleCloseMenu}
          >
            <MenuItem
              className={styles.email_setup_dropdown}
              onClick={() => handleCopyPasteOption()}
            >
              <div className={styles.menu_item}>
                <p>Copy & Paste</p>{" "}
                {activeEmailSetup === "copy & paste" && (
                  <CheckIcon className={styles.check_icon} />
                )}
              </div>
            </MenuItem>
            <MenuItem
              className={styles.email_setup_dropdown}
              onClick={() => handleSmtpOption()}
            >
              <div className={styles.menu_item}>
                <p>Own Mail Server </p>{" "}
                {activeEmailSetup === "Own Mail Server" && (
                  <CheckIcon className={styles.check_icon} />
                )}
              </div>
            </MenuItem>
            {/* <MenuItem
          className={styles.email_setup_dropdown}
          onClick={() => handleSpfOption()}
        >
          <div className={styles.menu_item}>
            <p>Fair Licensing Mail Server </p>{" "}
            {activeEmailSetup === "Fair Licensing Mail Server" && (
              <CheckIcon className={styles.check_icon} />
            )}
          </div>
        </MenuItem> */}
          </Menu>
          {emailSetup === "Own Mail Server" && <SmtpSetup />}
          {emailSetup === "Fair Licensing Mail Server" && <SpfSetup />}
          {emailSetup === "copy & paste" && <CopyPasteEmailSetup />}
        </div>
      </Scrollbar>
    </div>
  );
}

export default EmailSetup;
