import { caseTemplatesConstants } from "@_constants/caseTemplates.constants";
import { caseTemplatesService } from "@_services/caseTemplates.service";
import { removeNullsFromModel } from "@_utils/helpers";

export const caseTemplateActions = {
  getCaseTemplates,
  addCaseTemplate,
  updateCaseTemplate,
  deleteDeleteCaseTemplate,
  selectCaseTemplate,
  clearStatusMessage,
};

function getCaseTemplates() {
  return (dispatch) => {
    dispatch(request());
    caseTemplatesService.getCaseTemplates().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
    function request() {
      return { type: caseTemplatesConstants.CASETEMPLATE_GET_REQUEST };
    }
    function success(data) {
      return { type: caseTemplatesConstants.CASETEMPLATE_GET_SUCCESS, data };
    }
    function failure(error) {
      return { type: caseTemplatesConstants.CASETEMPLATE_GET_FAILURE, error };
    }
  };
}
function addCaseTemplate(template) {
  var t = removeNullsFromModel(template);
  return (dispatch) => {
    dispatch(request());
    caseTemplatesService.addCaseTemplate(t).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
    function request() {
      return { type: caseTemplatesConstants.CASETEMPLATE_ADD_REQUEST };
    }
    function success(data) {
      return { type: caseTemplatesConstants.CASETEMPLATE_ADD_SUCCESS, data };
    }
    function failure(error) {
      return { type: caseTemplatesConstants.CASETEMPLATE_ADD_FAILURE, error };
    }
  };
}

function updateCaseTemplate(template) {
  var t = removeNullsFromModel(template);
  return (dispatch) => {
    dispatch(request());
    caseTemplatesService.updateCaseTemplate(t.id, t).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
    function request() {
      return { type: caseTemplatesConstants.CASETEMPLATE_UPDATE_REQUEST };
    }
    function success(data) {
      return { type: caseTemplatesConstants.CASETEMPLATE_UPDATE_SUCCESS, data };
    }
    function failure(error) {
      return {
        type: caseTemplatesConstants.CASETEMPLATE_UPDATE_FAILURE,
        error,
      };
    }
  };
}

function deleteDeleteCaseTemplate(id) {
  return (dispatch) => {
    dispatch(request());
    caseTemplatesService.deleteCaseTemplate(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
    function request() {
      return { type: caseTemplatesConstants.CASETEMPLATE_DELETE_REQUEST };
    }
    function success(data) {
      return { type: caseTemplatesConstants.CASETEMPLATE_DELETE_SUCCESS, data };
    }
    function failure(error) {
      return {
        type: caseTemplatesConstants.CASETEMPLATE_DELETE_FAILURE,
        error,
      };
    }
  };
}

function selectCaseTemplate(id) {
  return (dispatch) => {
    dispatch(request(id));
  };

  function request() {
    return { type: caseTemplatesConstants.SELECT_CASETEMPLATE, id };
  }
}

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return {
      type: caseTemplatesConstants.CASETEMPLATE_CLEAR_STATUS_MESSAGE,
      stateName,
    };
  }
}
