import React from 'react';
import config from '../../../../config.json';
import Checkbox from '@material-ui/core/Checkbox';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './TermsOfService.module.css';

const TermsOfService = ({
  checkboxstate,
  setCheckbox,
  handleChangeRecaptcha,
  recaptchaRef,
  termsOfService,
}) => {
  const sitekey = config.REACT_APP_RE_CAPTCHA;
  return (
    <div className={styles.termsOfServiceContainer}>
      {termsOfService && (
        <iframe
          className={styles.iframe}
          srcDoc={termsOfService}
          title="TermsOfService"
        ></iframe>
      )}
      <div className={styles.accept_container}>
        <Checkbox
          color="default"
          value={checkboxstate}
          onChange={(e) => setCheckbox(!checkboxstate)}
          required
        />
        I have read and agree with Terms and Conditions.
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={sitekey}
        onChange={handleChangeRecaptcha}
        size="invisible"
      />
    </div>
  );
};

export default TermsOfService;
