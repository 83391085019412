import React, { useEffect, useRef, useState } from "react";
import styles from "./CaseActivityCard.module.css";
import DeleteIcon from "@material-ui/icons/Delete";
import caseStates from "@_configs/caseStates.config";
import {
  formatDatetime,
  unEscapeHTML,
  shortnString,
  getUserDataFromJwt,
  createNameFromMimeAndChecksum,
} from "@_utils/helpers";
import {
  DescriptionOutlined,
  EmailOutlined,
  EuroOutlined,
  ExitToAppOutlined,
  ExpandMore,
  MoreHoriz,
  TimerOffTwoTone,
  Warning,
} from "@material-ui/icons";
import {
  Button,
  Chip,
  DialogActions,
  ListItem,
  Snackbar,
  SvgIcon,
  Tooltip,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { useDispatch, useSelector } from "react-redux";
import { caseActivityActions } from "@_actions/caseActivity.actions";
import { customerService } from "@_services/customer.service";
import { Alert } from "@material-ui/lab";
import AreYouSurePopup from "@_components/popups/AreYouSurePopup/AreYouSurePopup";
import RenderPopup from "@_components/popups/RenderPopup/RenderPopup";
import { caseConstants } from "@_constants/case.constants";
import { caseActions } from "@_actions/case.actions";
import { emailConstants } from "@_constants/email_flows.constants";
import { invoiceTemplateActions } from "@_actions/invoiceTemplate.actions";
import { invoiceTemplateConstants } from "@_constants/invoiceTemplate.constants";
import Scrollbar from "react-perfect-scrollbar-z";
import "scrollbar.css";

function CaseActivityCard(props) {
  const { activity, deleteNote, caseData, resumeAutomation } = props;

  const dispatch = useDispatch();
  const caseActivityReducer = useSelector((state) => {
    return state.caseActivityReducer;
  });

  const jwtData = getUserDataFromJwt();
  const anchorRef = React.useRef(null);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [hightClass, setHightClass] = useState(false);
  // const [resumeAutomationPopup, setResumeAutomationPopup] = useState(false);
  const [isReplyPopupOpen, setIsReplyPopupOpen] = useState(false);
  const [showAttachements, setShowAttachements] = useState(false);
  const [mailLoaded, setMailLoaded] = useState(false);

  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openRenderPopup, setOpenRenderPopup] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [openInvoiceMenu, setOpenInvoiceMenu] = useState(false);
  const [openAreyouSurePopup, setOpenAreYouSurePopup] = useState(false);
  const [areYouSurePopupText, setAreYouSurePopupText] = useState("");
  const [areYouSurePopupAction, setAreYouSurePopupAction] = useState({});

  const emailSetupReducer = useSelector((state) => state.emailSetupReducer);
  const emailSetup = emailSetupReducer.email_setup.active_email_setup;

  /**
   * To avoid loading the activity again by opening, this
   * overwrites the unread property of activity when
   * user opens to read.
   */
  const [unreadUpdated, setUnreadUpdated] = useState(false);

  var replyToAll = useRef(false);

  const getEmailStringFromEmailObject = (input) => {
    var retval = "";
    if (Array.isArray(input)) {
      retval = input.map((x) => x.address).join(", ");
      return retval;
    }

    return input;
  };

  /**
   * Sets the icon in the upper left corner of the row
   * @returns
   */
  const renderIcon = () => {
    let icon = null;
    switch (activity.type) {
      case caseConstants.CASEACTIVITY_TYPE_NOTE:
        icon = (
          <>
            <SvgIcon
              children={
                <>
                  <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1s-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7-.25c.41 0 .75.34.75.75s-.34.75-.75.75-.75-.34-.75-.75.34-.75.75-.75zM19 19H5V5h14v14z"></path>
                  <path d="m15.08 11.03-2.12-2.12L7 14.86V17h2.1zm1.77-1.76c.2-.2.2-.51 0-.71l-1.41-1.41c-.2-.2-.51-.2-.71 0l-1.06 1.06 2.12 2.12 1.06-1.06z"></path>
                </>
              }
            ></SvgIcon>
          </>
        );
        break;
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT:
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_IN:
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED:
        icon = (
          <>
            <EmailOutlined />
            {activity.content.status ===
              caseConstants.CASE_ACTIVITY_STATE_FAILED && (
              <Tooltip title="Failed to send Email">
                <Warning className={styles.warning_icon} />
              </Tooltip>
            )}
            {activity.content.status ===
              caseConstants.CASE_ACTIVITY_STATE_PAUSED && (
              <Tooltip title="Automation paused">
                <TimerOffTwoTone className={styles.icon} />
              </Tooltip>
            )}
            {activity.content &&
              activity.content.unread === 1 &&
              !unreadUpdated && <div className={styles.unread_marker}></div>}
          </>
        );
        break;
      case caseConstants.CASEACTIVITY_TYPE_LINK_CLICKED:
      case caseConstants.CASEACTIVITY_TYPE_BILLING_ADDR_SET:
      case caseConstants.CASEACTIVITY_TYPE_LOGIN:
        icon = (
          <>
            <ExitToAppOutlined></ExitToAppOutlined>
          </>
        );
        break;
      case caseConstants.CASEACTIVITY_TYPE_PAYMENT_SUCCEEDED:
      case caseConstants.CASEACTIVITY_TYPE_PAYMENT_INITIATED:
      case caseConstants.CASEACTIVITY_TYPE_PAYMENT_FAILED:
        icon = (
          <>
            <EuroOutlined></EuroOutlined>
          </>
        );
        break;
      case caseConstants.CASEACTIVITY_TYPE_INVOICE_GENERATED:
        icon = (
          <>
            <DescriptionOutlined></DescriptionOutlined>
          </>
        );
        break;
      case caseConstants.CASEACTIVITY_TYPE_STATE_CHANGE:
        icon = (
          <>
            <SvgIcon
              children={
                <path d="M3 11c0 2.45 1.76 4.47 4.08 4.91l-1.49-1.49L7 13l4 4.01L7 21l-1.41-1.41 1.58-1.58v-.06C3.7 17.54 1 14.58 1 11c0-3.87 3.13-7 7-7h3v2H8c-2.76 0-5 2.24-5 5zm19 0V4h-9v7h9zm-2-2h-5V6h5v3zm-7 4h9v7h-9z"></path>
              }
            ></SvgIcon>
          </>
        );
        break;
      case caseConstants.CASEACTIVITY_TYPE_CASE_CREATED:
        icon = (
          <>
            {" "}
            <SvgIcon
              children={
                <path d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"></path>
              }
            />
          </>
        );
        break;
      default:
        break;
    }

    return icon;
  };

  /**
   * Sets the Text next to the icon in the upper part of the row
   * @returns
   */
  const renderTypeText = () => {
    let text = null;
    switch (activity.type) {
      case caseConstants.CASEACTIVITY_TYPE_NOTE:
        text = <>Note</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT:
        text = <>Email sent</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_IN:
        text = <>Email received</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_LINK_CLICKED:
        text = <>Portal Visit</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_LOGIN:
        text = <>Portal Login</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_BILLING_ADDR_SET:
        text = <>Checkout initiated</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_PAYMENT_SUCCEEDED:
        text = <>Payment succeeded</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_STATE_CHANGE:
        text = <>State changed</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_PAYMENT_FAILED:
        text = <>Payment failed</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_INVOICE_GENERATED:
        text =
          activity.content.type ===
          invoiceTemplateConstants.INVOICE_TYPE_CANCELLATION ? (
            <>Cancellation Invoice</>
          ) : (
            <>Invoice</>
          );
        break;
      case caseConstants.CASEACTIVITY_TYPE_PAYMENT_INITIATED:
        text = <>Payment initiated</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_CASE_CREATED:
        text = <>Case created</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED:
        text =
          activity.content.type &&
          activity.content.type ===
            caseConstants.CASEACTIVITY_TYPE_TRIGGERED ? (
            <>Triggered Email </>
          ) : (
            <>Scheduled Email </>
          );
        break;
      default:
        text = <></>;
        break;
    }

    return text;
  };

  const renderTypeSubText = () => {
    var retval = <></>;
    switch (activity.type) {
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED:
        retval = (
          <div
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "180px",
              whiteSpace: "nowrap",
            }}
          >
            {activity.content.template_name}
          </div>
        );
        break;
      default:
        retval = <>{activity.user_name}</>;
    }
    return retval;
  };

  const toStateName = (state) => {
    return caseStates[state].name;
  };

  const renderContent = () => {
    let content = null;
    switch (activity.type) {
      case caseConstants.CASEACTIVITY_TYPE_NOTE:
        if (detailsModalOpen) {
          content = (
            <>
              <div>
                {unEscapeHTML(activity.content.text)
                  .split("\n")
                  .map((str, index) => {
                    return (
                      <>
                        <p
                          style={{
                            overflow: "hidden",
                            width: "400px",
                          }}
                        >
                          {str}
                        </p>
                      </>
                    );
                  })}
              </div>
            </>
          );
        } else {
          content = (
            <>
              <div // do not allow to get heigher
                style={{
                  height: "39px",
                }}
              >
                {renderNoteShortened(activity)}
              </div>
            </>
          );
        }
        break;
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT:
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_IN:
        if (detailsModalOpen) {
          content = (
            <>
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "400px",
                  whiteSpace: "nowrap",
                  color: "black",
                  fontSize: "12px",
                  marginTop: "2px",
                }}
              >
                {" "}
                {activity.content.to
                  ? Array.isArray(activity.content.to)
                    ? activity.content.to[0].address
                    : activity.content.to
                  : Array.isArray(activity.content.from)
                  ? activity.content.from[0].address
                  : activity.content.from}
              </div>
              <div
                style={{
                  width: "400px",
                }}
                title={activity.content.subject}
              >
                {activity.content.subject}
              </div>
            </>
          );
        } else {
          content = (
            <>
              {" "}
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "400px",
                  whiteSpace: "nowrap",
                  color: "black",
                  fontSize: "12px",
                  marginTop: "2px",
                }}
              >
                {" "}
                {activity.content.to
                  ? Array.isArray(activity.content.to)
                    ? activity.content.to[0].address
                    : activity.content.to
                  : Array.isArray(activity.content.from)
                  ? activity.content.from[0].address
                  : activity.content.from}
              </div>
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "400px",
                  whiteSpace: "nowrap",
                }}
                title={activity.content.subject}
              >
                {activity.content.subject}
              </div>
            </>
          );
        }
        break;
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED:
        content = (
          <>
            {" "}
            <div
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "400px",
                whiteSpace: "nowrap",
                color: "black",
                fontSize: "12px",
                marginTop: "2px",
              }}
            >
              {" "}
              {activity.content.recipient_email
                ? activity.content.recipient_email
                : ""}
            </div>
            <div
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "400px",
                whiteSpace: "nowrap",
              }}
              title={activity.content.template_subject}
            >
              {activity.content.mail_content
                ? activity.content.mail_content.subject
                : activity.content.template_subject}
            </div>
          </>
        );

        break;
      case caseConstants.CASEACTIVITY_TYPE_LINK_CLICKED:
        content = <>The website owner visited the settlement portal</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_LOGIN:
        content = <>The website owner logged into the settlement portal</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_BILLING_ADDR_SET:
        content = <>The website owner has started the checkout process</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_STATE_CHANGE:
        if (activity.content.new_state === "PAID") {
          if (!activity.content.additional_info) {
            content = <>Old API version 🤷 ...</>;
          } else
            content = (
              <>
                <p>
                  From {toStateName(activity.content.old_state)} to{" "}
                  <b>{toStateName(activity.content.new_state)}</b>
                </p>
                <p>
                  {activity.content.additional_info.amount}{" "}
                  {activity.content.additional_info.currency} paid via{" "}
                  {activity.content.additional_info.payment_method}
                </p>
              </>
            );
        } else
          content = (
            <>
              <p>
                From {toStateName(activity.content.old_state)} to{" "}
                <b>{toStateName(activity.content.new_state)}</b>
              </p>
            </>
          );
        break;
      case caseConstants.CASEACTIVITY_TYPE_PAYMENT_FAILED:
        content = <>{activity.content.message}</>;
        break;
      case caseConstants.CASEACTIVITY_TYPE_INVOICE_GENERATED:
        content = (
          <>
            <div>
              {activity.content.invoice_number}{" "}
              {` ${
                activity.content.type ===
                invoiceTemplateConstants.INVOICE_TYPE_CANCELLED
                  ? "(Cancelled)"
                  : ""
              }`}
            </div>
            {activity.content.type ===
              invoiceTemplateConstants.INVOICE_TYPE_CANCELLATION && (
              <div>
                Cancelled invoice: {activity.content.cancelled_invoice_number}
              </div>
            )}
            {activity.content.type ===
              invoiceTemplateConstants.INVOICE_TYPE_CANCELLED && (
              <div>
                Cancellation invoice:{" "}
                {activity.content.cancellation_invoice_number}
              </div>
            )}
          </>
        );
        break;
      case caseConstants.CASEACTIVITY_TYPE_CASE_CREATED:
      default:
        content = <></>;
        break;
    }

    return content;
  };

  const noteDeleteIcon = (
    <div
      title={
        jwtData.username !== activity.user_name
          ? "You can only delete notes created by yourself"
          : "Delete note"
      }
    >
      {" "}
      <DeleteIcon
        onClick={(e) => {
          if (jwtData.username === activity.user_name) handleDeleteNote();
        }}
        className={
          jwtData.username !== activity.user_name
            ? styles.delete_icon_disabled
            : styles.delete_icon
        }
        style={{
          height: "20px",
          marginBottom: "-7px",
        }}
      />
    </div>
  );

  const renderOpener = () => {
    let opener = null;
    switch (activity.type) {
      case caseConstants.CASEACTIVITY_TYPE_NOTE:
        opener = <></>;
        if (needOpener()) {
          //the text has more than one line or a line longer than row. We have to sho the opener
          opener = (
            <>
              {noteDeleteIcon}
              <div
                style={{
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (detailsModalOpen) {
                    setHightClass(false);
                    setTimeout(() => {
                      setDetailsModalOpen(false);
                    }, 650);
                  } else {
                    setHightClass(true);
                    setDetailsModalOpen(true);
                  }
                }}
              >
                <hr
                  style={{
                    backgroundColor: "var(--color-theme-cloud)",
                    margin: "5px 5px -4px",
                  }}
                ></hr>
                <ExpandMore
                  style={{ height: "20px", marginBottom: "-6px" }}
                  className={detailsModalOpen ? styles.gray : {}}
                ></ExpandMore>
              </div>
            </>
          );
        } else {
          opener = noteDeleteIcon;
        }

        break;
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT:
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_IN:
      case caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED:
        opener = (
          <>
            <div
              style={{
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => {
                toggleDetails();
              }}
            >
              <ExpandMore
                style={{ height: "20px", marginBottom: "-5px" }}
                className={detailsModalOpen ? styles.gray : {}}
              ></ExpandMore>
            </div>
          </>
        );
        break;
      default:
        opener = <></>;
        break;
    }

    return opener;
  };

  const onReplyClicked = () => {
    setIsReplyPopupOpen(true);
  };

  const onMarkUnreadClicked = () => {
    dispatch(
      caseActivityActions.markUnread(
        activity.content.email_id,
        activity.case_id
      )
    );
    setUnreadUpdated(false);
    setDetailsModalOpen(false);
  };

  const onShowAttachementsClicked = () => {
    setShowAttachements(true);
  };

  useEffect(() => {
    if (caseActivityReducer.status.EMAIL_UNREAD === "success") {
      dispatch(caseActivityActions.clearState("EMAIL_UNREAD"));
      dispatch(caseActivityActions.clearState("EMAIL_CONTENT"));
      setMailLoaded(false);
      //dispatch(caseActivityActions.getCaseActivities(activity.case_id));
    }
  }, [activity.case_id, caseActivityReducer.status.EMAIL_UNREAD, dispatch]);

  const renderMailFromContent = () => {
    // if we do not have content yes. we request it
    if (!activity.content.mail_content && mailLoaded === false) {
      dispatch(
        caseActivityActions.getEmailContent(
          activity.content.email_id,
          activity.case_id,
          activity.content.unread > 0
        )
      );
      setMailLoaded(true);
    }

    return (
      <>
        {activity.content.mail_content && (
          <div className={styles.notePopupTextContainer}>
            <Scrollbar maxHeight="550px" options={{ suppressScrollX: true }}>
              {(activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_IN ||
                activity.type ===
                  caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT) && (
                <div className={styles.email_tools}>
                  <Button
                    onClick={() => {
                      replyToAll.current = false;
                      onReplyClicked();
                    }}
                    className={
                      activity.type ===
                        caseConstants.CASEACTIVITY_TYPE_EMAIL_IN ||
                      activity.type ===
                        caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT
                        ? styles.btn_default
                        : styles.btn_default_invisible
                    }
                  >
                    Reply
                  </Button>
                  {!(
                    activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT
                  ) &&
                    ((activity.content.mail_content.to &&
                      activity.content.mail_content.to.length > 1) ||
                      ((typeof activity.content.mail_content.data.cc ===
                        "string" ||
                        (activity.content.mail_content.data.cc &&
                          activity.content.mail_content.data.cc.value.length >
                            0)) && (
                        <Button
                          onClick={() => {
                            replyToAll.current = true;
                            onReplyClicked();
                          }}
                          className={styles.btn_default}
                        >
                          Reply all
                        </Button>
                      )))}

                  <Button
                    onClick={() => {
                      onMarkUnreadClicked();
                    }}
                    className={
                      activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_IN
                        ? styles.btn_default
                        : styles.btn_default_invisible
                    }
                  >
                    Mark unread
                  </Button>

                  {getNumAttachements() > 0 && (
                    <div className={styles.attachment_button_container}>
                      <Button
                        onClick={() => {
                          onShowAttachementsClicked();
                        }}
                        className={styles.btn_default}
                      >
                        <Chip
                          style={{ height: "24px", marginRight: "4px" }}
                          label={getNumAttachements()}
                        ></Chip>
                        Attachments
                      </Button>
                    </div>
                  )}
                </div>
              )}
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    typeof activity.content.mail_content.data.html === "boolean"
                      ? activity.content.mail_content.data.textAsHtml
                      : activity.content.mail_content.data.html,
                }}
              ></div>
            </Scrollbar>
          </div>
        )}
        {!activity.content.mail_content && (
          <>
            {caseActivityReducer.status.EMAIL_CONTENT === "failure" ? (
              <div style={{ textAlign: "center" }}>Failed to load mail...</div>
            ) : (
              <div style={{ textAlign: "center" }}>loading...</div>
            )}{" "}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    // we request the scheduled mail content from the begining to get the case id in the subject
    setMailLoaded(false);
    if (
      activity.content &&
      activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED &&
      !activity.content.mail_content
    ) {
      dispatch(
        caseActivityActions.getEmailTemplateContentByTemplate(
          activity.case_id,
          activity.content.source_email_template_id,
          {
            subject: activity.content.template_subject,
            content: activity.content.template_content,
            config: activity.content.template_config,
          }
        )
      );
      setMailLoaded(true);
    }
  }, [activity.content, activity.case_id, activity.type, dispatch]);

  const handleOpenTemplatePopup = (event) => {
    event.stopPropagation();
    setEmailData({
      content: activity.content.mail_content.content,
      name: activity.content.template_name,
      subject: activity.content.mail_content.subject,
      opponentEmail: caseData.opponent_contact_details.email,
    });
    setOpenRenderPopup(true);
  };

  // const activateAutomation = () => {
  //   dispatch(
  //     caseActions.toggleAutomation(
  //       activity.case_id,
  //       caseConstants.CASE_AUTOMATION_STATE_SCHEDULED
  //     )
  //   );
  //   setResumeAutomationPopup(false);
  // };

  // const handleResumeAutomationPopup = () => {
  //   if (
  //     caseData.overview.num_scheduled_emails_failed === 1 ||
  //     caseData.overview.num_triggered_emails_failed === 1
  //   ) {
  //     setResumeAutomationPopup(true);
  //   }
  // };

  const renderMailFromTemplate = () => {
    return (
      <>
        {activity.content.template_content && (
          <div className={styles.notePopupTextContainer}>
            {(activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_IN ||
              getNumAttachements() > 0) && (
              <div className={styles.email_tools}>
                <Button
                  onClick={() => {
                    replyToAll.current = false;
                    onReplyClicked();
                  }}
                  className={
                    activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_IN
                      ? styles.btn_default
                      : styles.btn_default_invisible
                  }
                >
                  Reply
                </Button>
                {((activity.content.mail_content.to &&
                  activity.content.mail_content.to.length > 1) ||
                  (activity.content.mail_content.data.cc &&
                    activity.content.mail_content.data.cc.value.length)) && (
                  <Button
                    onClick={() => {
                      replyToAll.current = true;
                      onReplyClicked();
                    }}
                    className={styles.btn_default}
                  >
                    Reply all
                  </Button>
                )}

                <Button
                  onClick={() => {
                    onMarkUnreadClicked();
                  }}
                  className={
                    activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_IN
                      ? styles.btn_default
                      : styles.btn_default_invisible
                  }
                >
                  Mark unread
                </Button>

                {getNumAttachements() > 0 && (
                  <div className={styles.attachment_button_container}>
                    <Button
                      onClick={() => {
                        onShowAttachementsClicked();
                      }}
                      className={styles.btn_default}
                    >
                      <Chip
                        style={{ height: "24px", marginRight: "4px" }}
                        label={getNumAttachements()}
                      ></Chip>
                      Attachments
                    </Button>
                  </div>
                )}
              </div>
            )}
            {activity.content.status ===
              caseConstants.CASE_ACTIVITY_STATE_FAILED && (
              <div className={styles.email_resend_container}>
                <p className={styles.resend_text}>
                  Sending email failed :{" "}
                  {activity.content.history.find(
                    (a) => a.event === caseConstants.CASE_ACTIVITY_STATE_FAILED
                  )?.error_msg || ""}
                </p>

                <Button
                  className={styles.btn_default}
                  onClick={handleOpenTemplatePopup}
                >
                  Resend
                </Button>
              </div>
            )}
            {activity.content.mail_content && (
              <div
                className={styles.scheduled_text_container}
                dangerouslySetInnerHTML={{
                  __html: activity.content.mail_content.content,
                }}
              ></div>
            )}
            <RenderPopup
              title={`Email preview: ${
                emailData.name === emailConstants.NEW_MAIL
                  ? "New email"
                  : emailData.name
              }`}
              open={openRenderPopup}
              close={() => setOpenRenderPopup(false)}
              data={emailData}
              caseId={activity.case_id}
              emailSetup={emailSetup}
              activity={activity}
              isReplyMode={false}
              resumeAutomation={resumeAutomation}
              JwtData={jwtData}
            />
          </div>
        )}
        {!activity.content.mail_content && (
          <>
            {caseActivityReducer.status.EMAIL_CONTENT === "failure" ? (
              <div style={{ textAlign: "center" }}>Failed to load mail...</div>
            ) : (
              <div style={{ textAlign: "center" }}>loading...</div>
            )}{" "}
          </>
        )}
      </>
    );
  };

  const renderEmail = () => {
    if (activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED)
      return renderMailFromTemplate();
    else return renderMailFromContent();
  };

  const handleDeleteNote = () => {
    setOpenAreYouSurePopup(true);
    setAreYouSurePopupText("Are you sure you want to delete this?");
    setAreYouSurePopupAction(() => deleteActivity);
  };

  const handleCancelPopup = () => {
    setOpenAreYouSurePopup(true);
    setAreYouSurePopupText("Are you sure you want to cancel this invoice ?");
    setAreYouSurePopupAction(() => handleCancelInvoice);
  };

  const deleteActivity = () => {
    setOpenAreYouSurePopup(false);
    setAreYouSurePopupText("");
    setAreYouSurePopupAction({});
    if (activity.type === caseConstants.CASEACTIVITY_TYPE_NOTE) {
      deleteNote(activity);
    } else {
      dispatch(
        caseActivityActions.deleteScheduledMails(activity.case_id, activity.id)
      );
      if (
        activity.content.status === caseConstants.CASE_ACTIVITY_STATE_FAILED
      ) {
        dispatch(
          caseActions.decreaseFailed(
            activity.case_id,
            activity.content.type === caseConstants.CASEACTIVITY_TYPE_TRIGGERED
              ? "num_triggered_emails_failed"
              : "num_scheduled_emails_failed"
          )
        );
      }
    }
  };

  function needOpener() {
    return (
      (activity.type === caseConstants.CASEACTIVITY_TYPE_NOTE &&
        activity.content &&
        activity.content.text &&
        activity.content.text.split("\n").length > 1) ||
      (activity.content &&
        activity.content.text &&
        activity.content.text.split("\n").find((x) => x.length > 51))
    );
  }

  function toggleDetails() {
    if (
      needOpener() ||
      activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT ||
      activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_IN ||
      activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED
    ) {
      if (detailsModalOpen) {
        setHightClass(false);
        setTimeout(() => {
          setDetailsModalOpen(false);
        }, 650);
      } else {
        setHightClass(true);
        setDetailsModalOpen(true);
      }

      if (activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_IN) {
        setUnreadUpdated(true);
      }
    }
  }

  const downloadAttachement = (attachement) => {
    customerService
      .downloadAttachement(activity.content.email_id, attachement.checksum)
      .catch((e) => {
        setSnackBarMessage("Can not download. Unauthorized");
        setSnackbarOpen(true);
      })
      .then((response) => {
        if (response.status !== 200) {
          setSnackBarMessage("Can not download. Unauthorized");
          setSnackbarOpen(true);
          return;
        }
        return response.blob();
      })
      .then((blob) => {
        if (!blob) return;
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", attachement.filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const handleOpenInvoiceMenu = (event) => {
    event.stopPropagation();
    setOpenInvoiceMenu(true);
  };

  const handleDownloadInvoice = (action) => {
    // invoiceTemplateService.downloadInvoice(
    //   activity.case_id,
    //   activity.content.invoice_id,
    //   activity.content.invoice_number,
    //   action
    // );
    dispatch(
      invoiceTemplateActions.downloadInvoice(
        activity.case_id,
        activity.content.invoice_id,
        activity.content.invoice_number,
        action
      )
    );
  };

  const handleCancelInvoice = () => {
    dispatch(
      invoiceTemplateActions.cancelInvoice(
        activity.case_id,
        activity.content.invoice_id
      )
    );
    setAreYouSurePopupAction("");
    setAreYouSurePopupText("");
    setOpenAreYouSurePopup(false);
  };

  const getNumAttachements = () => {
    if (
      !activity ||
      !activity.content ||
      !activity.content.mail_content ||
      !activity.content.mail_content.data ||
      !activity.content.mail_content.data.attachments
    )
      return 0;
    return activity.content.mail_content.data.attachments.length;
  };

  const renderAttachements = () => {
    if (
      !activity ||
      !activity.content ||
      !activity.content.mail_content ||
      !activity.content.mail_content.data ||
      !activity.content.mail_content.data.attachments
    )
      return <>This mail has no attachements</>;

    var retval = [];

    for (const item of activity.content.mail_content.data.attachments) {
      retval.push(
        <>
          <ListItem key={item.checksum}>
            <div className={styles.attachement_row}>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        {item.filename
                          ? item.filename
                          : createNameFromMimeAndChecksum(
                              item.checksum,
                              item.contentType
                            )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <Button
                className={styles.btn_cancel}
                onClick={() => downloadAttachement(item)}
              >
                Download
              </Button>
            </div>
          </ListItem>
        </>
      );
    }

    return retval;
  };

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div
        style={
          props.dottedborder !== undefined
            ? { border: "1px dashed lightgray" }
            : {}
        }
        className={hightClass ? styles.rootAnimation : styles.root}
      >
        <div className={styles.rootFlex}>
          <div className={styles.left_container}>
            <div className={styles.left_icon}>{renderIcon()}</div>
            <div className={styles.left_timestamp}>
              {activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED
                ? formatDatetime(activity.content.scheduled_at, "medium")
                : formatDatetime(activity.created, "medium")}
            </div>
          </div>
          <div className={styles.middle_container}>
            <div className={styles.middle_type}>{renderTypeText()}</div>
            <div className={styles.middle_user}> {renderTypeSubText()}</div>
          </div>
          <div
            className={
              needOpener() ||
              activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT ||
              activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_IN ||
              activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED
                ? styles.right_container_opener
                : styles.right_container
            }
            onClick={() => toggleDetails()}
          >
            {renderContent()}
          </div>
          <div className={styles.right_opener}>
            {activity.type ===
              caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED && (
              <DeleteIcon
                className={styles.delete_shopping_icon}
                fontSize="small"
                onClick={handleDeleteNote}
              />
            )}
            {activity.type ===
              caseConstants.CASEACTIVITY_TYPE_INVOICE_GENERATED && (
              <>
                <MoreHoriz
                  className={styles.edit_icon}
                  onClick={handleOpenInvoiceMenu}
                  ref={anchorRef}
                  aria-controls={openInvoiceMenu ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                />
                <Popper
                  open={openInvoiceMenu}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 1000 }}
                  className={styles.edit_popup}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={() => setOpenInvoiceMenu(false)}
                    >
                      <MenuList
                        autoFocusItem={openInvoiceMenu}
                        id="menu-list-grow"
                      >
                        <MenuItem onClick={() => handleDownloadInvoice("view")}>
                          View
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleDownloadInvoice("download")}
                        >
                          Download
                        </MenuItem>
                        {activity.content.type ===
                          invoiceTemplateConstants.INVOICE_TYPE_ACTIVE && (
                          <MenuItem onClick={handleCancelPopup}>
                            Cancel
                          </MenuItem>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Popper>
              </>
            )}
            {renderOpener()}
          </div>
        </div>
        <div>
          {detailsModalOpen &&
            (activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT ||
              activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_IN ||
              activity.type ===
                caseConstants.CASEACTIVITY_TYPE_EMAIL_SCHEDULED) && (
              <div className={styles.mailContainer}>{renderEmail()}</div>
            )}
        </div>
      </div>
      {/* <AreYouSurePopup
        open={deletionPopupOpen}
        close={() => setDeletionPopupOpen(false)}
        onClick={deleteActivity}
      >
        Are you sure you want to delete this?
      </AreYouSurePopup> */}

      <AreYouSurePopup
        open={openAreyouSurePopup}
        close={() => setOpenAreYouSurePopup(false)}
        onClick={areYouSurePopupAction}
      >
        {areYouSurePopupText}
      </AreYouSurePopup>

      {(activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_IN ||
        activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT) && (
        <>
          <RenderPopup
            title={
              "Reply to " +
              getEmailStringFromEmailObject(
                activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT
                  ? activity.content.to
                  : activity.content.from
              )
            }
            open={isReplyPopupOpen}
            email={activity.content.mail_content}
            close={() => setIsReplyPopupOpen(false)}
            data={emailData}
            caseId={activity.case_id}
            emailSetup={emailSetup}
            resumeAutomation={resumeAutomation}
            isReplyMode={true}
            isSelfReply={
              activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT
            }
            replyToAll={
              replyToAll.current &&
              !(activity.type === caseConstants.CASEACTIVITY_TYPE_EMAIL_OUT)
            }
            JwtData={jwtData}
          />

          <DialogPopup
            open={showAttachements}
            onClose={() => setShowAttachements(false)}
            title={
              'Attachments of: "' +
              shortnString(activity.content.subject, 50) +
              '"'
            }
          >
            <div className={styles.dialogContent}>{renderAttachements()}</div>
            <DialogActions style={{ padding: "8px 16px 8px 8px" }}>
              <Button
                onClick={() => setShowAttachements(false)}
                className={styles.btn_cancel}
              >
                Close
              </Button>
            </DialogActions>
          </DialogPopup>
        </>
      )}
    </div>
  );
}

export default CaseActivityCard;

function renderNoteShortened(activity) {
  return unEscapeHTML(activity.content.text)
    .split("\n")
    .map((str, index) => {
      return (
        <>
          {" "}
          {index === 0 ? (
            <>
              <p
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "400px",
                  whiteSpace: "nowrap",
                }}
              >
                {str}
              </p>
            </>
          ) : (
            <>
              {index === 1 ? (
                <>
                  <p>...</p>
                </>
              ) : (
                <></>
              )}
            </>
          )}{" "}
        </>
      );
    });
}
