import React, { useState, useEffect } from "react";
import styles from "./Account.module.css";
import { useSelector, useDispatch } from "react-redux";
import { addressActions } from "@_actions/address.actions";
import { isComponentDisabled } from "@_utils/helpers";

import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import BusinessIcon from "@material-ui/icons/Business";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import moment from "moment-timezone";
import { MenuItem } from "@material-ui/core";

function Account() {
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [timezonesList, setTimezonesList] = useState([]);

  const address = useSelector((state) => state.addressReducer);

  const inputDisabled = isComponentDisabled();

  const [businessDetails, setBusinessDetails] = useState({
    name: address.customer_address.name,
    address_line_1: address.customer_address.address_line_1 || "",
    postal_code: address.customer_address.postal_code || "",
    city: address.customer_address.city || "",
    country: address.customer_address.country,
    address_line_2: address.customer_address.address_line_2 || "",
    vat_id: address.customer_address.vat_id || "",
    timezone: address.customer_address.timezone || "",
  });

  useEffect(() => {
    setBusinessDetails({
      name: address.customer_address.name,
      address_line_1: address.customer_address.address_line_1 || "",
      postal_code: address.customer_address.postal_code || "",
      city: address.customer_address.city || "",
      country: address.customer_address.country,
      address_line_2: address.customer_address.address_line_2 || "",
      vat_id: address.customer_address.vat_id || "",
      timezone: address.customer_address.timezone || "",
    });
  }, [address.customer_address]);

  useEffect(() => {
    setTimezonesList(moment.tz.names());
  }, []);

  useEffect(() => {
    dispatch(addressActions.getAddress());
  }, [dispatch]);

  useEffect(() => {
    if (address.message) {
      setOpenErrorSnackbar(true);
    } else if (address.success) {
      setOpenSnackbar(true);
    } else {
      return;
    }
  }, [address]);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setBusinessDetails((businessDetails) => ({
      ...businessDetails,
      [name]: value,
    }));
  }

  function handleSubmitBusinessDetails(data) {
    let addressData = {
      address_line_1: data.address_line_1,
      city: data.city,
      postal_code: data.postal_code,
      address_line_2: data.address_line_2,
      vat_id: data.vat_id,
      timezone: data.timezone,
    };
    dispatch(addressActions.updateAddress(addressData));
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
  };

  return (
    <div>
      {address.loading && <LinearProgress />}
      <div className={styles.root}>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="success">
            Change saved!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={7000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {address.message}
          </Alert>
        </Snackbar>
        <div className={styles.account_title}>
          <BusinessIcon className={styles.business_icon} />
          <h2>Account </h2>
          <ArrowForwardIosIcon fontSize="small" className={styles.arrow_icon} />
          <h2>Business Details</h2>
        </div>
        <div className={styles.account_content}>
          <div className={styles.business_container}>
            <div className={styles.business_col}>
              <TextfieldInput
                InputLabelProps={{
                  shrink: true,
                }}
                label="* Company Name"
                id={"name"}
                name={"name"}
                value={businessDetails.name}
                onChange={handleInputChange}
                className={styles.input}
                disabled={inputDisabled}
              />
              <TextfieldInput
                label="Address Line 1"
                id={"address_line_1"}
                name={"address_line_1"}
                value={businessDetails.address_line_1}
                onChange={handleInputChange}
                className={styles.input}
                required
              />
              <TextfieldInput
                label="City"
                id={"city"}
                name={"city"}
                value={businessDetails.city}
                onChange={handleInputChange}
                className={styles.input}
                required
              />

              <TextfieldInput
                label="timezone"
                id={"timezone"}
                name={"timezone"}
                select
                onChange={handleInputChange}
                className={styles.input}
                value={businessDetails.timezone}
              >
                {timezonesList.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextfieldInput>
            </div>
            <div className={styles.business_col}>
              <TextfieldInput
                label="VAT ID"
                id={"vat_id"}
                name={"vat_id"}
                value={businessDetails.vat_id}
                onChange={handleInputChange}
                className={styles.input}
                disabled={address.customer_address.vat_id && inputDisabled}
              />
              <TextfieldInput
                label="Address Line 2"
                id={"address_line_2"}
                name={"address_line_2"}
                value={businessDetails.address_line_2}
                onChange={handleInputChange}
                className={styles.input}
              />

              <TextfieldInput
                label="Postal Code"
                id={"postal_code"}
                name={"postal_code"}
                value={businessDetails.postal_code}
                onChange={handleInputChange}
                className={styles.input}
                required
              />
              <TextfieldInput
                InputLabelProps={{
                  shrink: true,
                }}
                label="* Country"
                id={"country"}
                name={"country"}
                value={businessDetails.country}
                onChange={handleInputChange}
                className={styles.input}
                disabled={inputDisabled}
              />
            </div>
          </div>
          <div className={styles.confirm_btn_container}>
            <Button
              className={styles.confirm_btn}
              onClick={() => handleSubmitBusinessDetails(businessDetails)}
            >
              Save
            </Button>
          </div>
          <p className={styles.info_text}>
            * Should you need to change your company name, country or VAT ID,
            please get in touch with us at{" "}
            <a href="mailto:support@fairlicensing.com">
              support@fairlicensing.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default Account;
