import { templateConstants } from "../@_constants/template.constants";

const initialState = {
  templates: [],
  loading: false,
  message: "",
  success: false,
  flow_templates: null,
  add_template: false,
  selected_template: {},
  placeholders: [],
  affectedCases: [],
  status: {
    TEMPLATE_GET: "",
    TEMPLATE_POST: "",
    TEMPLATE_PUT: "",
    TEMPLATE_DELETE: "",
    ADD_TEMPLATE: "",
    SELECT_TEMPLATE: "",
    PLACEHOLDER_GET: "",
  },
};

export function templateReducer(state = initialState, action) {
  switch (action.type) {
    // TEMPLATE_CLEAR_STATUS_MESSAGE
    case templateConstants.TEMPLATE_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, [action.stateName]: "" },
      };
      break;

    // TEMPLATE GET REQUEST
    case templateConstants.TEMPLATE_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        success: false,
        status: { ...state.status, TEMPLATE_GET: "request" },
      };
      break;
    case templateConstants.TEMPLATE_GET_SUCCESS:
      let templates = [];
      for (const item of action.data) {
        templates.push({ ...item, emailFlowId: action.flow_id });
      }
      state = {
        ...state,
        templates: action.data,
        flow_templates: {
          ...state.flow_templates,
          [action.flow_id]: templates,
        },
        loading: false,
        message: "",
        success: false,
        status: { ...state.status, TEMPLATE_GET: "success" },
      };
      break;
    case templateConstants.TEMPLATE_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, TEMPLATE_GET: action.error },
      };
      break;

    // TEMPLATE POST REQUEST
    case templateConstants.TEMPLATE_POST_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        success: false,
        status: { ...state.status, TEMPLATE_POST: "request" },
      };
      break;
    case templateConstants.TEMPLATE_POST_SUCCESS:
      state = {
        ...state,
        loading: false,
        templates: "",
        success: true,
        message: "",
        status: { ...state.status, TEMPLATE_POST: "success" },
      };

      break;
    case templateConstants.TEMPLATE_POST_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, TEMPLATE_POST: action.error },
      };
      break;

    // TEMPLATE PUT REQUEST
    case templateConstants.TEMPLATE_UPDATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, TEMPLATE_PUT: "request" },
      };
      break;
    case templateConstants.TEMPLATE_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        templates: "",
        success: true,
        affectedCases: action.data,
        message: "",
        status: { ...state.status, TEMPLATE_PUT: "success" },
      };

      break;
    case templateConstants.TEMPLATE_UPDATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, TEMPLATE_PUT: action.error },
      };
      break;

    // TEMPLATE DELETE REQUEST
    case templateConstants.TEMPLATE_DELETE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, TEMPLATE_DELETE: "request" },
      };
      break;
    case templateConstants.TEMPLATE_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        templates: "",
        flow_templates: {
          ...state.flow_templates,
          [action.flow_id]: state.flow_templates[action.flow_id].filter(
            (template) => template.id !== action.template_id
          ),
        },
        selected_template: null,
        success: true,
        message: "",
        status: { ...state.status, TEMPLATE_DELETE: "success" },
      };
      break;
    case templateConstants.TEMPLATE_DELETE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, TEMPLATE_DELETE: action.error },
      };
      break;

    // Add template state for showing the empty template
    case templateConstants.ADD_TEMPLATE:
      state = {
        ...state,
        add_template: action.addTemplate,
        id: action.id,
        selected_template: {
          type: action.templateType,
          trigger_event: action.trigger,
          emailFlowId: action.emailFlowId,
        },
        message: "",
        status: { ...state.status, ADD_TEMPLATE: "success" },
      };
      break;
    case templateConstants.SELECT_TEMPLATE:
      state = {
        ...state,
        add_template: false,
        selected_template: action.data,
        success: false,
        message: "",
        status: { ...state.status, SELECT_TEMPLATE: "success" },
      };
      break;

    // get placeholders
    case templateConstants.PLACEHOLDER_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        success: false,
        status: { ...state.status, PLACEHOLDER_GET: "request" },
      };
      break;
    case templateConstants.PLACEHOLDER_GET_SUCCESS:
      state = {
        ...state,
        placeholders: {
          placeholders: [...action.data.placeholders],
        },
        loading: false,
        message: "",
        success: false,
        status: { ...state.status, PLACEHOLDER_GET: "success" },
      };
      break;
    case templateConstants.PLACEHOLDER_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, PLACEHOLDER_GET: action.error },
      };
      break;
    default:
      break;
  }
  return state;
}
