import React from 'react';
import config from '../../../../config.json';
import BusinessInfo from '@_assets/images/business_info.png';
import Box from '@material-ui/core/Box';
import TextfieldInput from '@_components/TextfieldInput/TextfieldInput';
import MenuItem from '@material-ui/core/MenuItem';
import CountryList from '@_configs/CountryList.config.js';
import { makeStyles } from '@material-ui/core';
import { InputAdornment } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import styles from './BusinessInformation.module.css';

const BusinessInformation = ({
  EuCountryList,
  customer_type,
  customer_name,
  address_line_1,
  address_line_2,
  postal_code,
  city,
  country,
  vat_id,
  state,
  handleChange,
  formDataValidation,
  formRef,
  StateList,
  recaptchaRef,
  handleChangeRecaptcha,
  name,
}) => {
  const useStyles = makeStyles({
    helperText: { fontSize: '12px !important' },
  });
  const classes = useStyles();
  const sitekey = config.REACT_APP_RE_CAPTCHA;

  return (
    <form className={styles.businessInformationContainer} ref={formRef}>
      <p className={styles.businessInformationCopy}>
        Thank you, {name}. Now tell us about your business, please.
      </p>
      <div className={styles.businessInformationFormContainer}>
        <div className={styles.businessInformationLeftFormContainer}>
          <p className={styles.businessInformationCopy}>
            What’s your business?
          </p>
          <Box sx={{ maxWidth: 370 }}>
            <TextfieldInput
              select
              label="Please select"
              placeholder="Please select"
              onChange={handleChange}
              name="customer_type"
              value={customer_type}
              required
            >
              <MenuItem value="PHOTO_LIBRARY">Photo Library</MenuItem>
              <MenuItem value="PHOTOGRAPHER">Photographer</MenuItem>
              <MenuItem value="NEWS_AGENCY">News Agency</MenuItem>
              <MenuItem value="AGENT">Agent</MenuItem>
              <MenuItem value="OTHER">Other</MenuItem>
            </TextfieldInput>
          </Box>
          <img
            src={BusinessInfo}
            alt="Fair Licensing Logo"
            className={styles.businessImgage}
          />
        </div>

        <div className={styles.businessInformationRightFormContainer}>
          <div className={styles.formContainer}>
            <p className={styles.requiredTag}>
              <span className={styles.requiredRed}>*</span> required fields
            </p>
            <TextfieldInput
              placeholder="Company Name"
              label="Company Name"
              type="text"
              name="customer_name"
              value={customer_name}
              onChange={handleChange}
              className={styles.businessInformationInput}
              required
            />
            <TextfieldInput
              placeholder="Address Line 1"
              label="Address Line 1"
              type="text"
              name="address_line_1"
              value={address_line_1}
              onChange={handleChange}
              className={styles.businessInformationInput}
              required
            />
            <TextfieldInput
              placeholder="Address Line 2"
              label="Address Line 2"
              type="text"
              name="address_line_2"
              value={address_line_2}
              onChange={handleChange}
              className={styles.businessInformationInput}
            />

            <div className={styles.doubleInput}>
              <Box sx={{ maxWidth: 180 }}>
                <TextfieldInput
                  id="left"
                  placeholder="Zip Code"
                  label="Zip Code"
                  type="text"
                  name="postal_code"
                  value={postal_code}
                  onChange={handleChange}
                  required
                />
              </Box>
              <Box sx={{ maxWidth: 180 }}>
                <TextfieldInput
                  placeholder="City"
                  label="City"
                  type="text"
                  name="city"
                  value={city}
                  onChange={handleChange}
                  required
                />
              </Box>
            </div>
            <div className={styles.doubleInput}>
              <Box sx={{ width: 180 }}>
                <TextfieldInput
                  select
                  placeholder="Country"
                  label="Country"
                  name="country"
                  value={country}
                  onChange={handleChange}
                  required
                >
                  {CountryList &&
                    CountryList.map(({ name, code }) => (
                      <MenuItem key={code} value={code}>
                        {name}
                      </MenuItem>
                    ))}
                </TextfieldInput>
              </Box>
              {country !== '' &&
              EuCountryList.includes(country) &&
              country !== 'DE' ? (
                <Box sx={{ maxWidth: 180 }}>
                  <TextfieldInput
                    placeholder="123456789"
                    label="VAT ID"
                    type="text"
                    name="vat_id"
                    value={vat_id}
                    onChange={handleChange}
                    error={Boolean(formDataValidation.vat_id)}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {country}
                        </InputAdornment>
                      ),
                    }}
                    helperText={formDataValidation.vat_id && 'Invalid VAT'}
                    required
                  />
                </Box>
              ) : country !== '' && country === 'DE' ? (
                <Box sx={{ maxWidth: 180 }}>
                  <TextfieldInput
                    placeholder="123456789"
                    label="VAT ID"
                    type="text"
                    name="vat_id"
                    value={vat_id}
                    onChange={handleChange}
                    error={Boolean(formDataValidation.vat_id)}
                    FormHelperTextProps={{
                      className: classes.helperText,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {country}
                        </InputAdornment>
                      ),
                    }}
                    helperText={formDataValidation.vat_id && 'Invalid VAT'}
                  />
                </Box>
              ) : (
                country !== '' &&
                country === 'US' && (
                  <Box sx={{ width: 180 }}>
                    <TextfieldInput
                      select
                      placeholder="State"
                      label="State"
                      name="state"
                      type="text"
                      value={state}
                      onChange={handleChange}
                      required
                    >
                      {StateList &&
                        StateList.map((code) => (
                          <MenuItem key={code} value={code}>
                            {code}
                          </MenuItem>
                        ))}
                    </TextfieldInput>
                  </Box>
                )
              )}
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={sitekey}
              onChange={handleChangeRecaptcha}
              size="invisible"
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default BusinessInformation;
