import React from "react";
import {
  ButtonBase,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";

const ClickableTextField = (props) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      style={{ width: props.fullWidth ? "100%" : "" }}
    >
      <Tooltip title={props.tooltip ? props.tooltip : ""} placement="top" arrow>
        <TextField
          label={props.label}
          variant={props.variant}
          size={props.size}
          fullWidth={props.fullWidth}
          className={props.className}
          value={props.value}
          required={props.required}
          style={{ backgroundColor: props.value ? "transparent" : "" }}
          InputProps={{
            readOnly: true,
            endAdornment: props.endAdornment && (
              <InputAdornment position="end" style={{ paddingTop: "10px" }}>
                {props.endAdornment}
              </InputAdornment>
            ),
            style: {
              cursor: "pointer",
              pointerEvents: "none",
            },
          }}
        />
      </Tooltip>
    </ButtonBase>
  );
};

export default ClickableTextField;
