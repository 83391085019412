import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { caseTemplateActions } from "@_actions/caseTemplate.actions";
import RenameFlow from "@_components/RenameFlow/RenameFlow";

import styles from "./CaseTemplateRow.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { Button } from "@material-ui/core";
import classNames from "classnames";

function EmailRow(props) {
  const { data } = props;
  let template = data;
  const caseTemplateReducer = useSelector((state) => state.caseTemplateReducer);
  const ref = useRef(null);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [renameFlow, setRenameFlow] = useState(false);
  const [isDeletionPopupOpen, setIsDeletionPopupOpen] = useState(false);
  const [newFlowName, setNewFlowName] = useState({
    name: "",
  });
  const [inputChange, setInputChange] = useState(false);

  useEffect(() => {
    dispatch(caseTemplateActions.getCaseTemplates(template.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templateReducer = useSelector((state) => state.templateReducer);

  const handleOpenEditPopup = (e) => {
    setOpenEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setOpenEditPopup(false);
  };

  const handleDeleteCaseTemplate = (id) => {
    setIsDeletionPopupOpen(false);
    if (isSelected) dispatch(caseTemplateActions.selectCaseTemplate(null));
    setTimeout(() => {
      dispatch(caseTemplateActions.deleteDeleteCaseTemplate(template.id));
    }, 100);
  };

  const handleInputChange = (e) => {
    setInputChange(true);
    const { name, value } = e.target;
    setNewFlowName((newFlowName) => ({
      ...newFlowName,
      [name]: value,
    }));
  };

  const handleSubmitInput = (e) => {
    e.preventDefault();
    if (newFlowName.name) {
      dispatch(
        caseTemplateActions.addCaseTemplate({
          name: newFlowName.name,
        })
      );
    } else {
      setRenameFlow(false);
      setOpenEditPopup(false);
    }
  };

  useEffect(() => {
    if (templateReducer.status.CASETEMPLATE_UPDATE === "success") {
      setNewFlowName({ name: "" });
      setRenameFlow(false);
    }
  }, [dispatch, templateReducer.status.CASETEMPLATE_UPDATE]);

  useEffect(() => {
    if (templateReducer.success && templateReducer.templates === "") {
      dispatch(caseTemplateActions.getTemplates(template.id));
    }
  });

  const handleSelectTemplate = (data) => {
    setIsSelected(true);
    dispatch(caseTemplateActions.selectCaseTemplate(data));
  };

  useEffect(() => {
    caseTemplateReducer.selectedCaseTemplate === template.id
      ? setIsSelected(true)
      : setIsSelected(false);
  }, [caseTemplateReducer.selectedCaseTemplate, template.id]);

  const handleGetTemplates = () => {
    dispatch(caseTemplateActions.selectCaseTemplate(template.id));

    if (templateList.length === 0) {
      // dispatch(caseTemplateActions.addTemplateState(true));
    } else {
      dispatch(caseTemplateActions.selectCaseTemplate(templateList[0]));
    }
  };

  useEffect(() => {
    if (templateReducer.flow_templates) {
      let key = Object.keys(templateReducer.flow_templates);
      key.forEach((key) => {
        let values = templateReducer.flow_templates[key];
        if (key === template.id) {
          setTemplateList(values);
        } else return;
      });
    }
  }, [templateReducer.flow_templates, template.id]);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenEditPopup(false);
    }
  }

  useEffect(() => {
    setRenameFlow(renameFlow);
  }, [renameFlow]);

  return (
    <div>
      {renameFlow ? (
        <div
          className={classNames(
            styles.email_row_root,
            isSelected ? styles.root_active : styles.root_inactive
          )}
          key={template.id}
          onClick={handleGetTemplates}
          ref={ref}
        >
          {props.message}
          <ListItem className={styles.flow_list_item}>
            <RenameFlow
              styles={styles}
              name={newFlowName.name}
              handleSubmitInput={handleSubmitInput}
              handleInputChange={handleInputChange}
              prevValue={template.name}
              id={template.id}
              onClickOutside={() => {
                setRenameFlow(false);
                setOpenEditPopup(false);
              }}
              inputChange={inputChange}
            />
          </ListItem>
        </div>
      ) : (
        <div
          className={classNames(
            styles.email_row_root,
            isSelected ? styles.root_active : styles.root_inactive
          )}
          key={template.id}
          onClick={() => handleSelectTemplate(template.id)}
        >
          <ListItem className={styles.flow_list_item}>
            <b className={styles.input_container}>{template.name}</b>
            <div className={styles.edit_icon_container}>
              <MoreHorizIcon
                className={styles.edit_icon}
                onClick={handleOpenEditPopup}
                ref={anchorRef}
                aria-controls={openEditPopup ? "menu-list-grow" : undefined}
                aria-haspopup="true"
              />
              <Popper
                open={openEditPopup}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                className={styles.edit_popup}
                style={{ zIndex: 1000 }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseEditPopup}>
                    <MenuList
                      autoFocusItem={openEditPopup}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setIsDeletionPopupOpen(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Popper>
            </div>
          </ListItem>
        </div>
      )}

      <DialogPopup
        title="Confirmation required"
        open={isDeletionPopupOpen}
        onClose={() => setIsDeletionPopupOpen(false)}
      >
        <div className={styles.deletionPopupText}>
          Do you really want to delete this Template?
        </div>

        <div className={styles.deletionPopupButtonContainer}>
          <Button
            className={styles.button_confirm}
            onClick={() => handleDeleteCaseTemplate(template.id)}
          >
            Yes
          </Button>
          <Button
            className={styles.button_cancel}
            onClick={() => setIsDeletionPopupOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </DialogPopup>
    </div>
  );
}

export default EmailRow;
