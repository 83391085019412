import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const customerService = {
  sendVerficationEmail,
  verifiedCustomer,
  createAccount,
  validateVat,
  getTermsOfService,
  getPaymentReport,
  getPaymentReportDetails,
  getInboundMails,
  mapMailToCase,
  deleteMail,
  downloadAttachement,
};

function sendVerficationEmail(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/user/resend_verification`,
    requestOptions
  ).then(handleResponse);
}

function verifiedCustomer(id) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    Config.API_ROOT + `/user/verification/${id}`,
    requestOptions
  ).then(handleResponse);
}

function createAccount(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + "/customer/account", requestOptions).then(
    handleResponse
  );
}

function validateVat(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + "/customer/vat_id/validation",
    requestOptions
  ).then(handleResponse);
}

function getTermsOfService() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + "/customer/terms_of_service", requestOptions)
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

function getPaymentReport(startDate, endDate) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/payment_report?startDate=${startDate}&end_date=${endDate}&format=excel`,
    requestOptions
  )
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

function getPaymentReportDetails(startDate, endDate) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/payment_report_details?startDate=${startDate}&end_date=${endDate}&format=excel`,
    requestOptions
  )
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

function getInboundMails(filter) {
  let requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  if (filter) requestOptions["body"] = JSON.stringify({ filter: filter });

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/emails/_search`,
    requestOptions
  )
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

function mapMailToCase(mailID, caseID) {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/email/${mailID}/map_to_case/${caseID}`,
    requestOptions
  )
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

function deleteMail(mailID) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/email/${mailID}`,
    requestOptions
  )
    .then(handleResponse)
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

function downloadAttachement(emailID, attachmentID) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader() },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/email/${emailID}/attachment/${attachmentID}`,
    requestOptions
  );
}
