import Config from "@_configs/api.config";
import { parseJwt, authHeader, getUserDataFromJwt } from "./helpers";

/**
 * Call to renew token endpoint
 *
 */
async function renew() {
  // get all infos for the call to renew endpoint
  const localStorageUser = JSON.parse(localStorage.getItem("user"));
  const jwtData = localStorageUser
    ? parseJwt(localStorageUser.accessToken)
    : { username: "", user_role: "", customer_id: "" };

  // call the endpoint and update token in local storage
  await callRenewAndUpdateToken(localStorageUser.email, jwtData.renew_token);
}

async function callRenewAndUpdateToken(email, renew_token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, renew_token }),
  };
  const response = await fetch(
    Config.API_ROOT + "/login/renew",
    requestOptions
  );
  const resptext = await response.text();
  const user = JSON.parse(resptext);
  user.email = email;
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("jwtData", JSON.stringify(getUserDataFromJwt()));
}

/**
 * Fetch helper wraps all requests of services
 * to handle a token timeout.
 *
 * If timout occurs, all reqeusts will be queued until
 * token refresh is finished. All Queued requests will be
 * resend with new token.
 *
 *
 */
class fetch_helper {
  constructor() {
    this.renew = renew;
  }

  /**
   * Fetch Wrapper which should used in every service call.
   *
   * @param {string} url Requets URL of the fetch
   * @param {object} options Request options
   * @returns {object} Response Ojbect
   */
  async fetch(url, options) {
    try {
      // first do the real fetch call
      var resp = null;
      resp = await fetch(url, options);

      // if response is not OK throw Error to go into catch.
      // (409 will not throw error by itself)
      if (!resp.ok) throw resp;

      // if there was a renew before we can not delete because
      // call was successfull
      localStorage.removeItem("isRenewing");

      // return response
      return resp;
    } catch (e) {
      // The catch part cant be interrupted by
      // so  we are singlethreaded here
      if (e.status === 401) {
        // check if we are already in Renewing procedure
        if (localStorage.getItem("isRenewing")) {
          // ok we are alreacy renewing
          // we have to wait for this requests until renew is done
          // we use timeout for this
          while (localStorage.getItem("isRenewing"))
            await new Promise((reslve) => setTimeout(reslve, 500));

          // since we the new token is now available we have to update
          // the auth header of the requets
          var newauth_for_waiters = authHeader();
          options = {
            ...options,
            headers: {
              ...options.headers,
              Authorization: newauth_for_waiters.Authorization,
            },
          };

          // now we try again with the new token
          return await fetch(url, options);
        }

        // we are not renewing (this is the first call that failed)
        // set globally that we are in renew procedure
        localStorage.setItem("isRenewing", "true");

        var responseJson = await e.json();

        if (responseJson.error_message === "Authentication Failure: Token expired") {
          //get a new thoken
          await renew(null);

          // ok renew is done
          // since this request failed before we have to update its
          // token
          var newauth = authHeader();
          options = {
            ...options,
            headers: {
              ...options.headers,
              Authorization: newauth.Authorization,
            },
          };

          // and now try call again
          var resp2 = await fetch(url, options);

          // we can stop the renew procedure to release all waiting
          // requests
          localStorage.removeItem("isRenewing");
          return resp2;
        }else{
          localStorage.removeItem("isRenewing");
          return resp;
        }
      } else return resp;
    }
  }
}

export default new fetch_helper();
