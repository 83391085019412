const CountryAutocompleteList = [
  { label: "Afghanistan", id: "AF" },
  { label: "Albania", id: "AL" },
  { label: "Algeria", id: "DZ" },
  { label: "Andorra", id: "AD" },
  { label: "Angola", id: "AO" },
  { label: "Antigua and Barbuda", id: "AG" },
  { label: "Argentina", id: "AR" },
  { label: "Armenia", id: "AM" },
  { label: "Australia", id: "AU" },
  { label: "Austria", id: "AT" },
  { label: "Azerbaijan", id: "AZ" },
  { label: "Bahamas", id: "BS" },
  { label: "Bahrain", id: "BH" },
  { label: "Bangladesh", id: "BD" },
  { label: "Barbados", id: "BB" },
  { label: "Belarus", id: "BY" },
  { label: "Belgium", id: "BE" },
  { label: "Belize", id: "BZ" },
  { label: "Benin", id: "BJ" },
  { label: "Bhutan", id: "BT" },
  { label: "Bolivia", id: "BO" },
  { label: "Bosnia and Herzegovina", id: "BA" },
  { label: "Botswana", id: "BW" },
  { label: "Brazil", id: "BR" },
  { label: "Brunei", id: "BN" },
  { label: "Bulgaria", id: "BG" },
  { label: "Burkina Faso", id: "BF" },
  { label: "Burundi", id: "BI" },
  { label: "Cabo Verde", id: "CV" },
  { label: "Cambodia", id: "KH" },
  { label: "Cameroon", id: "CM" },
  { label: "Canada", id: "CA" },
  { label: "Central African Republic", id: "CF" },
  { label: "Chad", id: "TD" },
  { label: "Chile", id: "CL" },
  { label: "China", id: "CN" },
  { label: "Colombia", id: "CO" },
  { label: "Comoros", id: "KM" },
  { label: "Congo (Congo-Brazzaville)", id: "CG" },
  { label: "Costa Rica", id: "CR" },
  { label: "Croatia", id: "HR" },
  { label: "Cuba", id: "CU" },
  { label: "Cyprus", id: "CY" },
  { label: "Czechia (Czech Republic)", id: "CZ" },
  { label: "Denmark", id: "DK" },
  { label: "Djibouti", id: "DJ" },
  { label: "Dominica", id: "DM" },
  { label: "Dominican Republic", id: "DO" },
  { label: "Ecuador", id: "EC" },
  { label: "Egypt", id: "EG" },
  { label: "El Salvador", id: "SV" },
  { label: "Equatorial Guinea", id: "GQ" },
  { label: "Eritrea", id: "ER" },
  { label: "Estonia", id: "EE" },
  { label: "Eswatini (fmr. 'Swaziland')", id: "SZ" },
  { label: "Ethiopia", id: "ET" },
  { label: "Fiji", id: "FJ" },
  { label: "Finland", id: "FI" },
  { label: "France", id: "FR" },
  { label: "Gabon", id: "GA" },
  { label: "Gambia", id: "GM" },
  { label: "Georgia", id: "GE" },
  { label: "Germany", id: "DE" },
  { label: "Ghana", id: "GH" },
  { label: "Greece", id: "GR" },
  { label: "Grenada", id: "GD" },
  { label: "Guatemala", id: "GT" },
  { label: "Guinea", id: "GN" },
  { label: "Guinea-Bissau", id: "GW" },
  { label: "Guyana", id: "GY" },
  { label: "Haiti", id: "HT" },
  { label: "Honduras", id: "HN" },
  { label: "Hungary", id: "HU" },
  { label: "Iceland", id: "IS" },
  { label: "India", id: "IN" },
  { label: "Indonesia", id: "ID" },
  { label: "Iran", id: "IR" },
  { label: "Iraq", id: "IQ" },
  { label: "Ireland", id: "IE" },
  { label: "Israel", id: "IL" },
  { label: "Italy", id: "IT" },
  { label: "Jamaica", id: "JM" },
  { label: "Japan", id: "JP" },
  { label: "Jordan", id: "JO" },
  { label: "Kazakhstan", id: "KZ" },
  { label: "Kenya", id: "KE" },
  { label: "Kiribati", id: "KI" },
  { label: "Korea, North", id: "KP" },
  { label: "Korea, South", id: "KR" },
  { label: "Kosovo", id: "XK" },
  { label: "Kuwait", id: "KW" },
  { label: "Kyrgyzstan", id: "KG" },
  { label: "Laos", id: "LA" },
  { label: "Latvia", id: "LV" },
  { label: "Lebanon", id: "LB" },
  { label: "Lesotho", id: "LS" },
  { label: "Liberia", id: "LR" },
  { label: "Libya", id: "LY" },
  { label: "Liechtenstein", id: "LI" },
  { label: "Lithuania", id: "LT" },
  { label: "Luxembourg", id: "LU" },
  { label: "Madagascar", id: "MG" },
  { label: "Malawi", id: "MW" },
  { label: "Malaysia", id: "MY" },
  { label: "Maldives", id: "MV" },
  { label: "Mali", id: "ML" },
  { label: "Malta", id: "MT" },
  { label: "Marshall Islands", id: "MH" },
  { label: "Mauritania", id: "MR" },
  { label: "Mauritius", id: "MU" },
  { label: "Mexico", id: "MX" },
  { label: "Micronesia", id: "FM" },
  { label: "Moldova", id: "MD" },
  { label: "Monaco", id: "MC" },
  { label: "Mongolia", id: "MN" },
  { label: "Montenegro", id: "ME" },
  { label: "Morocco", id: "MA" },
  { label: "Mozambique", id: "MZ" },
  { label: "Myanmar (formerly Burma)", id: "MM" },
  { label: "Namibia", id: "NA" },
  { label: "Nauru", id: "NR" },
  { label: "Nepal", id: "NP" },
  { label: "Netherlands", id: "NL" },
  { label: "New Zealand", id: "NZ" },
  { label: "Nicaragua", id: "NI" },
  { label: "Niger", id: "NE" },
  { label: "Nigeria", id: "NG" },
  { label: "North Macedonia (formerly Macedonia)", id: "MK" },
  { label: "Norway", id: "NO" },
  { label: "Oman", id: "OM" },
  { label: "Pakistan", id: "PK" },
  { label: "Palau", id: "PW" },
  { label: "Palestine State", id: "PS" },
  { label: "Panama", id: "PA" },
  { label: "Papua New Guinea", id: "PG" },
  { label: "Paraguay", id: "PY" },
  { label: "Peru", id: "PE" },
  { label: "Philippines", id: "PH" },
  { label: "Poland", id: "PL" },
  { label: "Portugal", id: "PT" },
  { label: "Qatar", id: "QA" },
  { label: "Romania", id: "RO" },
  { label: "Russia", id: "RU" },
  { label: "Rwanda", id: "RW" },
  { label: "Saint Kitts and Nevis", id: "KN" },
  { label: "Saint Lucia", id: "LC" },
  { label: "Saint Vincent and the Grenadines", id: "VC" },
  { label: "Samoa", id: "WS" },
  { label: "San Marino", id: "SM" },
  { label: "Sao Tome and Principe", id: "ST" },
  { label: "Saudi Arabia", id: "SA" },
  { label: "Senegal", id: "SN" },
  { label: "Serbia", id: "RS" },
  { label: "Seychelles", id: "SC" },
  { label: "Sierra Leone", id: "SL" },
  { label: "Singapore", id: "SG" },
  { label: "Slovakia", id: "SK" },
  { label: "Slovenia", id: "SI" },
  { label: "Solomon Islands", id: "SB" },
  { label: "Somalia", id: "SO" },
  { label: "South Africa", id: "ZA" },
  { label: "South Sudan", id: "SS" },
  { label: "Spain", id: "ES" },
  { label: "Sri Lanka", id: "LK" },
  { label: "Sudan", id: "SD" },
  { label: "Suriname", id: "SR" },
  { label: "Sweden", id: "SE" },
  { label: "Switzerland", id: "CH" },
  { label: "Syria", id: "SY" },
  { label: "Taiwan", id: "TW" },
  { label: "Tajikistan", id: "TJ" },
  { label: "Tanzania", id: "TZ" },
  { label: "Thailand", id: "TH" },
  { label: "Timor-Leste", id: "TL" },
  { label: "Togo", id: "TG" },
  { label: "Tonga", id: "TO" },
  { label: "Trinidad and Tobago", id: "TT" },
  { label: "Tunisia", id: "TN" },
  { label: "Turkey", id: "TR" },
  { label: "Turkmenistan", id: "TM" },
  { label: "Tuvalu", id: "TV" },
  { label: "Uganda", id: "UG" },
  { label: "Ukraine", id: "UA" },
  { label: "United Arab Emirates", id: "AE" },
  { label: "United Kingdom", id: "GB" },
  { label: "United States of America", id: "US" },
  { label: "Uruguay", id: "UY" },
  { label: "Uzbekistan", id: "UZ" },
  { label: "Vanuatu", id: "VU" },
  { label: "Vatican City (Holy See)", id: "VA" },
  { label: "Venezuela", id: "VE" },
  { label: "Vietnam", id: "VN" },
  { label: "Yemen", id: "YE" },
  { label: "Zambia", id: "ZM" },
  { label: "Zimbabwe", id: "ZW" },
];

export default CountryAutocompleteList;
