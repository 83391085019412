import React from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import styles from "./AreYouSurePopup.module.css";

function AreYouSurePopup(props) {
  return (
    <DialogPopup
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          props.onClick();
        }
      }}
      title={props.title ? props.title : ""}
      open={props.open}
      onClose={() => props.close()}
    >
      <div className={styles.content_container}>{props.children}</div>
      <div className="action_container">
        <Button className="btn_cancel" onClick={() => props.close()}>
          {props.cancelBtn ? props.cancelBtn : "No"}
        </Button>
        <Button className="btn_confirm" onClick={props.onClick}>
          {props.confirmBtn ? props.confirmBtn : "Yes"}
        </Button>
      </div>
    </DialogPopup>
  );
}

export default AreYouSurePopup;
