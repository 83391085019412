import React from 'react';
import { useDispatch } from 'react-redux';
import DialogPopup from '@_components/DialogPopup/DialogPopup';
import styles from './UpgradePlanpopup.module.css';
import { sidebarActions } from '@_actions/sidebar.actions';
import CloseIcon from '@material-ui/icons/Close';
import { Button, makeStyles } from '@material-ui/core';

let closeImg = {
  cursor: 'pointer',
  float: 'right',
  marginTop: '5px',
  width: '20px',
};

function UpgradePlanpopup({
  close,
  title,
  subtitle,
  imageUrl,
  copy_1,
  copy_2,
  open,
  upgradeButton,
  subscriptionImgStyle,
}) {
  const useStyles = makeStyles({
    helperText: { fontSize: '12px !important' },

    confirmButton: {
      fontWeight: 'bold !important',
      color: 'var(--color-theme-blue) !important',
      border: '1px solid var(--color-theme-blue) !important',
      width: 'auto',
      '&:hover': {
        color: 'var(--color-theme-white) !important',
        backgroundColor: 'var(--color-theme-blue) !important',
        boxShadow: '2px 6px 6px #0000002',
      },
    },
  });
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleUpgrade = () => {
    dispatch(sidebarActions.updateStatus('SUBSCRIPTION'));
  };

  return (
    <DialogPopup
      title={
        <span>
          <CloseIcon style={closeImg} onClick={close} />
        </span>
      }
      open={open}
      onClose={close}
    >
      <div className={styles.content_container}>
        <p className={styles.regular_text}>{title}</p>
        <p className={styles.bold_text}>{subtitle}</p>
        <img
          src={imageUrl}
          alt="Fair Licensing upgrade"
          className={
            subscriptionImgStyle
              ? styles.subscription_image
              : styles.upgrade_image
          }
        />
        <p className={styles.bold_text}>{copy_1}</p>
        {copy_2 && <p className={styles.regular_text}>{copy_2}</p>}
        {upgradeButton && (
          <Button
            className={classes.confirmButton}
            onClick={() => handleUpgrade()}
          >
            {upgradeButton}
          </Button>
        )}
      </div>
    </DialogPopup>
  );
}

export default UpgradePlanpopup;
