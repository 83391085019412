import { caseConstants } from "@_constants/case.constants";
import { LicenseConstants } from "@_constants/license.constants";
import { caseService } from "@_services/case.service";

export const caseActions = {
  getCase,
  updateCaseOpponentDetails,
  updateCaseLog,
  updateCaseImprint,
  addCaseLog,
  updateCaseFindingImageData,
  updateCaseFindingPrice,
  deleteCaseCallbackRequest,
  updateCaseClaimDetails,
  getCaseChargeDetails,
  updateCaseStateActivate,
  updateCaseStateCancel,
  updateCaseStateReset,
  getCaseWithChargeDetails,
  updateCaseCountry,
  addCaseAdditionalFees,
  updateCaseLicenseState,
  deleteCaseFinding,
  clearStateMessage,
  addCaseFinding,
  UploadLicense,
  addCaseFlow,
  addCaseTranslation,
  addCaseSendEmail,
  addEmailToLog,
  updateReminder,
  applyCaseTemplate,
  toggleAutomation,
  decreaseFailed,
};

function clearStateMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return { type: caseConstants.CASE_CLEAR_STATE_MESSGAE, stateName };
  }
}

function getCase(id, overview) {
  return (dispatch) => {
    dispatch(request());
    caseService.getCase(id).then(
      (caseDetails) => {
        caseDetails.overview = overview;
        dispatch(success(caseDetails));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_GET_REQUEST };
  }
  function success(caseDetails) {
    return { type: caseConstants.CASE_GET_SUCCESS, caseDetails };
  }
  function failure(error) {
    return { type: caseConstants.CASE_GET_FAILURE, error };
  }
}

function getCaseWithChargeDetails(id, overview) {
  return (dispatch) => {
    dispatch(request());
    caseService.getCase(id).then(
      (caseDetails) => {
        caseDetails.overview = overview;
        if (caseDetails.state === "VALID_LICENSE") {
          caseDetails.charge_details = null;
          dispatch(success(caseDetails));
        } else {
          caseService.getCaseChargeDetails(id).then(
            (charge_details) => {
              caseDetails.charge_details = charge_details;
              dispatch(success(caseDetails));
            },
            (error) => {
              console.log(error);
              dispatch(failure(error.toString()));
            }
          );
        }
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_GET_REQUEST };
  }
  function success(caseDetails) {
    return { type: caseConstants.CASE_GET_SUCCESS, caseDetails };
  }
  function failure(error) {
    return { type: caseConstants.CASE_GET_FAILURE, error };
  }
}

function updateCaseOpponentDetails(id, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseOpponentDetails(id, data).then(
      (results) => {
        dispatch(success(id, data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_OPPONENT_DETAILS_REQUEST };
  }
  function success(id, data) {
    return {
      type: caseConstants.CASE_UPDATE_OPPONENT_DETAILS_SUCCESS,
      id,
      data,
    };
  }
  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_OPPONENT_DETAILS_FAILURE, error };
  }
}

function updateCaseLog(id, logId, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseLog(id, logId, data).then(
      (results) => {
        dispatch(success(id, logId, data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_LOG_REQUEST };
  }
  function success(id, logId, data) {
    return { type: caseConstants.CASE_UPDATE_LOG_SUCCESS, id, logId, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_LOG_FAILURE, error };
  }
}

function addCaseLog(id, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.addCaseLog(id, data).then(
      (results) => {
        dispatch(success(id, results));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_ADD_LOG_REQUEST };
  }
  function success(id, data) {
    return { type: caseConstants.CASE_ADD_LOG_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_ADD_LOG_FAILURE, error };
  }
}

function addCaseSendEmail(id, data, attachments, caseActivityId) {
  if (attachments && attachments.length > 0) {
    data.attachments = attachments;
  }
  return (dispatch) => {
    dispatch(request());
    caseService.addCaseSendEmail(id, data, caseActivityId).then(
      (results) => {
        console.log(results);
        dispatch(success(id, results));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_SEND_EMAIL_REQUEST };
  }
  function success(id, data) {
    return { type: caseConstants.CASE_SEND_EMAIL_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_SEND_EMAIL_FAILURE, error };
  }
}

function deleteCaseCallbackRequest(id) {
  return (dispatch) => {
    dispatch(request());
    caseService.deleteCaseCallbackRequest(id).then(
      (results) => {
        dispatch(success(id));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_DELETE_CALLBACK_REQUEST_REQUEST };
  }
  function success(id) {
    return { type: caseConstants.CASE_DELETE_CALLBACK_REQUEST_SUCCESS, id };
  }
  function failure(error) {
    return { type: caseConstants.CASE_DELETE_CALLBACK_REQUEST_FAILURE, error };
  }
}

function updateCaseImprint(id, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseImprint(id, data).then(
      (results) => {
        dispatch(success(id, data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_IMPRINT_REQUEST };
  }
  function success(id, data) {
    return { type: caseConstants.CASE_UPDATE_IMPRINT_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_IMPRINT_FAILURE, error };
  }
}

function updateCaseFindingImageData(id, index, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseFindingImageData(id, index, data).then(
      (results) => {
        dispatch(success(id, index, data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_FINDING_IMAGE_DATA_REQUEST };
  }
  function success(id, index, data) {
    return {
      type: caseConstants.CASE_UPDATE_FINDING_IMAGE_DATA_SUCCESS,
      id,
      index,
      data,
    };
  }
  function failure(error) {
    return {
      type: caseConstants.CASE_UPDATE_FINDING_IMAGE_DATA_FAILURE,
      error,
    };
  }
}

function getCaseChargeDetails(id) {
  return (dispatch) => {
    dispatch(request());
    caseService.getCaseChargeDetails(id).then(
      (charge_details) => {
        dispatch(success(id, charge_details));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_GET_CHARGE_DETAILS_REQUEST };
  }
  function success(id, charge_details) {
    return {
      type: caseConstants.CASE_GET_CHARGE_DETAILS_SUCCESS,
      id,
      charge_details,
    };
  }
  function failure(error) {
    return { type: caseConstants.CASE_GET_CHARGE_DETAILS_FAILURE, error };
  }
}

function updateCaseFindingPrice(id, index, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseFindingPrice(id, index, data).then(
      (results) => {
        dispatch(success(id, index, data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_FINDING_PRICE_REQUEST };
  }
  function success(id, index, data) {
    return {
      type: caseConstants.CASE_UPDATE_FINDING_PRICE_SUCCESS,
      id,
      index,
      data,
    };
  }
  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_FINDING_PRICE_FAILURE, error };
  }
}

function updateCaseClaimDetails(id, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseClaimDetails(id, data).then(
      (results) => {
        dispatch(success(id, data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_CLAIM_DETAILS_REQUEST };
  }
  function success(id, data) {
    return { type: caseConstants.CASE_UPDATE_CLAIM_DETAILS_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_CLAIM_DETAILS_FAILURE, error };
  }
}

function updateCaseStateActivate(id) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseStateActivate(id).then(
      (results) => {
        dispatch(success(id, results));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_STATE_ACTIVATE_REQUEST };
  }
  function success(id, results) {
    return {
      type: caseConstants.CASE_UPDATE_STATE_ACTIVATE_SUCCESS,
      id,
      results,
    };
  }
  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_STATE_ACTIVATE_FAILURE, error };
  }
}

function updateCaseStateCancel(id, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseStateCancel(id, data).then(
      (results) => {
        dispatch(success(id, data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_STATE_CANCEL_REQUEST };
  }
  function success(id, data) {
    return { type: caseConstants.CASE_UPDATE_STATE_CANCEL_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_STATE_CANCEL_FAILURE, error };
  }
}

function updateCaseStateReset(id) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseStateReset(id).then(
      (results) => {
        dispatch(success(id));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_STATE_RESET_REQUEST };
  }
  function success(id, data) {
    return { type: caseConstants.CASE_UPDATE_STATE_RESET_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_STATE_RESET_FAILURE, error };
  }
}

function updateCaseCountry(id, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseCountry(id, data).then(
      (results) => {
        dispatch(success(id, data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_COUNTRY_REQUEST };
  }
  function success(id, data) {
    return { type: caseConstants.CASE_UPDATE_COUNTRY_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_COUNTRY_FAILURE, error };
  }
}

function addCaseAdditionalFees(id, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.addCaseAdditionalFees(id, data).then(
      (results) => {
        dispatch(success(id, data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_ADD_ADDITIONAL_FEES_REQUEST };
  }
  function success(id, data) {
    return { type: caseConstants.CASE_ADD_ADDITIONAL_FEES_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_ADD_ADDITIONAL_FEES_FAILURE, error };
  }
}

function UploadLicense(formData, findingId, caseId) {
  return (dispatch) => {
    dispatch(request());
    caseService.uploadLicense(formData, findingId, caseId).then(
      (results) => {
        dispatch(success(findingId, caseId, results));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: LicenseConstants.UPLOAD_LICENSE_REQUEST };
  }
  function success(findingId, caseId, results) {
    return {
      type: LicenseConstants.UPLOAD_LICENSE_SUCCESS,
      caseId,
      findingId,
      results,
    };
  }
  function failure(error) {
    return { type: LicenseConstants.UPLOAD_LICENSE_FAILURE, error };
  }
}

function updateCaseLicenseState(id, index, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateCaseLicenseState(id, index, data).then(
      (results) => {
        dispatch(success(id, index, data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_LICENSE_STATE_REQUEST };
  }
  function success(id, index, data) {
    return {
      type: caseConstants.CASE_UPDATE_LICENSE_STATE_SUCCESS,
      id,
      index,
      data,
    };
  }
  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_LICENSE_STATE_FAILURE, error };
  }
}

function deleteCaseFinding(id, findingId) {
  return (dispatch) => {
    dispatch(request());
    caseService.deleteCaseFinding(id, findingId).then(
      (results) => {
        dispatch(success(id, findingId));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_DELETE_FINDING_REQUEST };
  }
  function success(id, findingId) {
    return { type: caseConstants.CASE_DELETE_FINDING_SUCCESS, id, findingId };
  }
  function failure(error) {
    return { type: caseConstants.CASE_DELETE_FINDING_FAILURE, error };
  }
}

function addCaseFinding(id, data) {
  return (dispatch) => {
    dispatch(request());
    caseService.addCaseFinding(id, data).then(
      (results) => {
        dispatch(success(id, results));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_ADD_FINDING_REQUEST };
  }
  function success(id, data) {
    return { type: caseConstants.CASE_ADD_FINDING_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_ADD_FINDING_FAILURE, error };
  }
}

function addCaseFlow(id, flow) {
  return (dispatch) => {
    dispatch(request());
    caseService.addCaseFlow(id, flow).then(
      (results) => {
        dispatch(success(id, results));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_ADD_FLOW_REQUEST };
  }
  function success(id, flow) {
    return { type: caseConstants.CASE_ADD_FLOW_SUCCESS, id, flow };
  }
  function failure(error) {
    return { type: caseConstants.CASE_ADD_FLOW_FAILURE, error };
  }
}

function addCaseTranslation(id, translation) {
  return (dispatch) => {
    dispatch(request());
    caseService.addCaseTranslation(id, translation).then(
      (results) => {
        dispatch(success(id, results));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_ADD_TRANSLATION_REQUEST };
  }
  function success(id, translation) {
    return {
      type: caseConstants.CASE_ADD_TRANSLATION_SUCCESS,
      id,
      translation,
    };
  }
  function failure(error) {
    return { type: caseConstants.CASE_ADD_TRANSLATION_FAILURE, error };
  }
}

function addEmailToLog(id, data, caseActivityId) {
  return (dispatch) => {
    dispatch(request());
    caseService.addEmailToLog(id, data, caseActivityId).then(
      (results) => {
        dispatch(success(id, results));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_SAVE_EMAIL_TO_LOG_REQUEST };
  }
  function success(id, data) {
    return { type: caseConstants.CASE_SAVE_EMAIL_TO_LOG_SUCCESS, id, data };
  }
  function failure(error) {
    return { type: caseConstants.CASE_SAVE_EMAIL_TO_LOG_FAILURE, error };
  }
}

function updateReminder(id, deadline) {
  return (dispatch) => {
    dispatch(request());
    caseService.updateReminder(id, deadline).then(
      (results) => {
        dispatch(success(id, deadline, results));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_UPDATE_DEADLINE_REQUEST };
  }

  function success(id, deadline, results) {
    return {
      type: caseConstants.CASE_UPDATE_DEADLINE_SUCCESS,
      id,
      deadline,
      results,
    };
  }

  function failure(error) {
    return { type: caseConstants.CASE_UPDATE_DEADLINE_FAILURE, error };
  }
}

function applyCaseTemplate(id, template) {
  return (dispatch) => {
    dispatch(request());
    caseService.applyCaseTemplate(id, template.id).then(
      (resp) => {
        dispatch(success());
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_APPLY_TEMPLATE_REQUEST };
  }
  function success(resp) {
    return { type: caseConstants.CASE_APPLY_TEMPLATE_SUCCESS, resp };
  }
  function failure(error) {
    return { type: caseConstants.CASE_APPLY_TEMPLATE_FAILURE, error };
  }
}

function toggleAutomation(caseId, state) {
  return (dispatch) => {
    dispatch(request());
    caseService.toggleAutomation(caseId, state).then(
      (results) => {
        dispatch(success(caseId, state));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASE_TOGGLE_AUTOMATION_REQUEST };
  }
  function success(id, state) {
    return { type: caseConstants.CASE_TOGGLE_AUTOMATION_SUCCESS, id, state };
  }
  function failure(error) {
    return { type: caseConstants.CASE_TOGGLE_AUTOMATION_FAILURE, error };
  }
}

function decreaseFailed(caseId, typeFailedEmails) {
  return (dispatch) => {
    dispatch(toggleFail(caseId, typeFailedEmails));
  };
  function toggleFail(caseId, typeFailedEmails) {
    return {
      type: caseConstants.CASE_DECREASE_FAILED,
      caseId,
      typeFailedEmails,
    };
  }
}
