import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./CopyPasteEmailSetup.module.css";
import { emailSetupActions } from "@_actions/email_setup.actions";

import { Button } from "@material-ui/core";

function CopyPasteEmailSetup() {
  const dispatch = useDispatch();

  const emailSetupReducer = useSelector((state) => state.emailSetupReducer);

  const handleActivateCopyPaste = () => {
    dispatch(emailSetupActions.activateOutboundEmailSetup({}));
  };

  useEffect(() => {
    if (emailSetupReducer.status.EMAIL_SETUP_ACTIVATION_PUT === "success") {
      dispatch(emailSetupActions.getEmailSetup());
    }
  }, [emailSetupReducer.status.EMAIL_SETUP_ACTIVATION_PUT, dispatch]);

  return (
    <div className={styles.root}>
      <h2>Copy Paste</h2>
      <p>
        Copy & Paste requires no setup. Sending an email from Fair Licensing
        will require you to use the “Copy to Clipboard” button and paste the
        email content to your email program for every single email.<br></br>
        <br></br>
        Pro: Immediately available without any additional setup work.
        <br></br>
        <br></br>
        Con: A few additional clicks for every email you want to send, no
        logging of outbound emails in Fair Licensing.
      </p>
      {emailSetupReducer.email_setup &&
        emailSetupReducer.email_setup.active_email_setup && (
          <div className={styles.btn_container}>
            <Button
              className={styles.save_activate_btn}
              onClick={() => handleActivateCopyPaste()}
            >
              Activate
            </Button>
          </div>
        )}
    </div>
  );
}

export default CopyPasteEmailSetup;
