import React, { useEffect, useState } from "react";
import classnames from "classnames";
import caseStates from "@_configs/caseStates.config";
import { formatDatetime, getUserDataFromJwt } from "@_utils/helpers";
import { useDispatch } from "react-redux";
import { caseActions } from "@_actions/case.actions";
import { sidebarActions } from "@_actions/sidebar.actions";

import {
  EmailOutlined,
  EventBusy,
  InsertDriveFileOutlined,
  TimerOffTwoTone,
  Warning,
} from "@material-ui/icons";
import { caseActivityActions } from "@_actions/caseActivity.actions";
import { LinearProgress, Tooltip } from "@material-ui/core";
import styles from "./CaseRow.module.css";
import { userConstants } from "@_constants/user.constants";
import { caseConstants } from "@_constants/case.constants";

function CaseItem(props) {
  const dispatch = useDispatch();
  const { data, selected } = props;
  const [progressOfScheduled, setProgressOfScheduled] = useState(0);
  const [manualCase, setManualCase] = useState(false);
  let userData = getUserDataFromJwt();

  useEffect(() => {
    if (data.num_scheduled_emails_sent === 0 && data.initial_email_sent === 0) {
      //this is a "manueal case"
      setManualCase(true);
      return;
    } else {
      setManualCase(false);
    }
    setProgressOfScheduled(
      ((data.num_scheduled_emails_sent + (data.initial_email_sent ? 1 : 0)) /
        (data.total_scheduled_emails + (data.initial_email_sent ? 1 : 0))) *
        100
    );
  }, [
    data.total_scheduled_emails,
    data.num_scheduled_emails_sent,
    data.initial_email_sent,
  ]);

  function itemClicked() {
    dispatch(caseActions.getCaseWithChargeDetails(data.id, data));
    dispatch(caseActivityActions.resetCaseActivies());
    dispatch(sidebarActions.updateSelections(data.id));
  }

  return (
    <div className={styles.container}>
      <div
        className={classnames(
          userData.subscription !== userConstants.USER_SUBSCRIPTION_LITE &&
            data.state === caseConstants.CASE_STATE_ACTIVE
            ? styles.root
            : styles.root_plus_7,
          selected ? styles.root_active : styles.root_inactive
        )}
        onClick={itemClicked}
      >
        <div className={styles.left_container}>
          <div className={styles.status_icon}>
            {caseStates[data.state].icon}
          </div>
          <Tooltip title={"last Action on"} placement="top" arrow>
            <div className={styles.status_date}>
              {formatDatetime(data.last_case_activity_timestamp, "short")}
            </div>
          </Tooltip>
        </div>
        <div className={styles.middle_container}>
          <div className={styles.host_value}>
            <div className={styles.host_url}>{data.host}</div>
          </div>
          <div className={styles.price_value_container}>
            <Tooltip
              title={
                data.num_scheduled_emails_failed > 0 ||
                data.num_triggered_emails_failed > 0
                  ? "failed to send automated email"
                  : data.num_failed_payments > 0 &&
                    data.state === caseConstants.CASE_STATE_ACTIVE
                  ? "failed payment"
                  : ""
              }
              placement="top"
              arrow
            >
              <Warning
                className={(styles.icon, styles.warning_icon)}
                style={
                  data.num_scheduled_emails_failed > 0 ||
                  data.num_triggered_emails_failed > 0 ||
                  (data.num_failed_payments > 0 &&
                    data.state === caseConstants.CASE_STATE_ACTIVE)
                    ? {}
                    : { display: "none" }
                }
              />
            </Tooltip>
            <Tooltip
              title={
                data.total_unchecked_licenses > 0 ? `License uploaded` : ""
              }
              placement="top"
              arrow
            >
              <InsertDriveFileOutlined
                style={
                  data.total_unchecked_licenses > 0 ? {} : { opacity: 0.15 }
                }
                className={styles.icon}
              />
            </Tooltip>
            <Tooltip
              title={
                data.num_scheduled_emails_paused > 0 ||
                data.num_scheduled_emails_failed > 0
                  ? "Automation paused"
                  : ""
              }
              placement="top"
              arrow
            >
              <TimerOffTwoTone
                className={styles.icon}
                style={
                  data.num_scheduled_emails_paused > 0 ||
                  data.num_scheduled_emails_failed > 0 ||
                  data.num_triggered_emails_failed > 0
                    ? {}
                    : { opacity: 0.15 }
                }
              />
            </Tooltip>
            <div className={styles.total_value}>
              <div style={{ display: "flex" }}>
                <Tooltip
                  title={
                    data.reminder_date &&
                    new Date(data.reminder_date) < new Date()
                      ? `Reminder overdue`
                      : ""
                  }
                  placement="top"
                  arrow
                >
                  <EventBusy
                    style={
                      data.reminder_date &&
                      new Date(data.reminder_date) < new Date()
                        ? {}
                        : { opacity: 0.15 }
                    }
                    className={styles.icon}
                  />
                </Tooltip>
                <>
                  {" "}
                  <Tooltip
                    title={
                      data.total_unread_emails > 0 ? `Unread email(s)` : ""
                    }
                    placement="top"
                    arrow
                  >
                    <EmailOutlined
                      style={
                        data.total_unread_emails > 0 ? {} : { opacity: 0.15 }
                      }
                      className={(styles.email_marker_alone, styles.icon)}
                    ></EmailOutlined>
                  </Tooltip>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
      {userData.subscription !== userConstants.USER_SUBSCRIPTION_LITE &&
        data.state === caseConstants.CASE_STATE_ACTIVE && (
          <Tooltip
            title={
              manualCase
                ? "No automated emails"
                : `${
                    data.num_scheduled_emails_sent +
                    (data.initial_email_sent ? 1 : 0)
                  } out of ${
                    data.total_scheduled_emails +
                    (data.initial_email_sent ? 1 : 0)
                  } email(s) sent`
            }
            placement="top"
            arrow
          >
            <LinearProgress
              classes={{
                root: styles.progress_bar_root,
                bar: manualCase
                  ? styles.progress_bar_bar_gray
                  : styles.progress_bar_bar,
              }}
              variant="determinate"
              value={manualCase ? 100 : progressOfScheduled}
            />
          </Tooltip>
        )}
    </div>
  );
}

export default CaseItem;
