import { emailsConstants } from "@_constants/emails.constants";

const initialState = {
  emails: {},
};

export function emailsReducer(state = initialState, action) {
  switch (action.type) {
    case emailsConstants.LOAD_EMAILS:
      state = {
        ...state,
        emails: action.emails,
      };
      break;
    case emailsConstants.LOAD_EMAILS_FAILURE:
      state = {
        ...state,
        status: { ...state.status, LOAD_EMAILS: action.error },
      };
      break;
    case emailsConstants.LOAD_EMAILS_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, LOAD_EMAILS: "success" },
      };
      break;
    case emailsConstants.LOAD_EMAILS_REQUEST:
      state = {
        ...state,
        status: { ...state.status, LOAD_EMAILS: "request" },
      };
      break;
    case emailsConstants.UPDATE_EMAIL:
      state = {
        ...state,
        loading: false,
        emails: state.emails.map((email) =>
          email.id === action.id
            ? { ...email, [action.title]: action.value }
            : email
        ),
      };
      break;

    case emailsConstants.DELETE_EMAIL:
      state = {
        ...state,
        emails: state.emails.filter((email) => email.id !== action.emailId),
      };
      break;

    default:
      break;
  }
  return state;
}
