import React, { useEffect, useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import styles from "./DashboardSettingsPopup.module.css";
import { Button, CircularProgress, MenuItem } from "@material-ui/core";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { CloseOutlined } from "@material-ui/icons";
import { dateRange, dateGrouping } from "@_configs/dashboard.config";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "@_actions/dashboard.actions";
import { catchError, getUserDataFromJwt } from "@_utils/helpers";
import { userConstants } from "@_constants/user.constants";
import KpisSettingsPopup from "../KpiSettingsPopup/KpisSettingsPopup";
import Scrollbar from "react-perfect-scrollbar-z";

function DashboardSettingsPopup(props) {
  const dispatch = useDispatch();
  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const userReducer = useSelector((state) => state.userReducer);
  const jwtData = getUserDataFromJwt();
  const currentDashboardSettings = dashboardReducer.settings;
  const [newDashboardSettings, setNewDashboardSettings] = useState({
    filter: currentDashboardSettings.filter || {},
    kpis: currentDashboardSettings?.selected_kpis || [],
    charts:
      currentDashboardSettings?.selected_charts.length > 0
        ? currentDashboardSettings?.selected_charts
        : [{ class: "" }],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [openKpiPopup, setOpenKpiPopup] = useState(false);
  const [selectedKpiIndex, setSelectedKpiIndex] = useState(null);
  const close = props.close;

  useEffect(() => {
    addCurrentSettings();
    // eslint-disable-next-line
  }, [currentDashboardSettings]);

  const addCurrentSettings = () => {
    setNewDashboardSettings({
      filter: currentDashboardSettings.filter || {},
      kpis: currentDashboardSettings?.selected_kpis || [],
      charts:
        currentDashboardSettings?.selected_charts.length > 0
          ? currentDashboardSettings?.selected_charts
          : [{ class: "" }],
    });
  };

  const handleCloseKpiPopup = () => {
    setSelectedKpiIndex(false);
    setOpenKpiPopup(false);
  };
  const handleOpenKpiPopup = (index, selectedKpi) => {
    setSelectedKpiIndex(index);
    setOpenKpiPopup(true);
  };

  const editKpi = (index, KpiName, threshold) => {
    let copyKpiArray = [...newDashboardSettings.kpis];
    const editedKpi = {
      ...copyKpiArray[index],
      name: KpiName,
    };
    if (threshold) {
      const formatedThreshold = Number(threshold);
      editedKpi.threshold = formatedThreshold;
    }
    copyKpiArray[index] = editedKpi;
    setNewDashboardSettings((prev) => {
      return {
        ...prev,
        kpis: copyKpiArray,
      };
    });
  };

  const removeSelectedKpi = (index) => {
    let copyKpiArray = [...newDashboardSettings.kpis];
    copyKpiArray.splice(index, 1, {});
    setNewDashboardSettings((prev) => {
      return {
        ...prev,
        kpis: copyKpiArray,
      };
    });
  };

  const editChart = (event, index) => {
    let copyChartArray = [...newDashboardSettings.charts];
    const editedChart = {
      ...copyChartArray[index],
      [event.target.name]: event.target.value,
    };
    if (event.target.name === "class") {
      editedChart.subgroup = "";
      editedChart.date_group_by = "";
      editedChart.currency = "";
      if (
        editedChart.class === "Payments" &&
        !editedChart.currency &&
        currentDashboardSettings?.currencies
      ) {
        editedChart.currency = currentDashboardSettings?.currencies[0];
      }
    }
    if (
      copyChartArray[index].class === "Emails" &&
      (event.target.value === "received" || event.target.value === "sent")
    ) {
      editedChart.subgroup = "";
    }
    copyChartArray[index] = editedChart;
    setNewDashboardSettings((prev) => {
      return {
        ...prev,
        charts: copyChartArray,
      };
    });
  };

  const addChartRow = () => {
    setNewDashboardSettings((prev) => {
      return {
        ...prev,
        charts: [...prev.charts, { class: "" }],
      };
    });
  };

  const removeChart = (index) => {
    let copyChartArray = [...newDashboardSettings.charts];
    copyChartArray.splice(index, 1);
    setNewDashboardSettings((prev) => {
      return {
        ...prev,
        charts: copyChartArray,
      };
    });
  };

  const addFilter = (event) => {
    setNewDashboardSettings((prev) => {
      return {
        ...prev,
        filter: { ...prev.filter, [event.target.name]: event.target.value },
      };
    });
  };

  const saveDashboardSettings = () => {
    if (
      newDashboardSettings.charts?.some((chart) => chart.class.length === 0)
    ) {
      alert(
        "Can not save settings. There is a chart without a class. Please add a class to every chart."
      );
    } else {
      dispatch(dashboardActions.setUserSettings(newDashboardSettings));
    }
  };

  useEffect(() => {
    if (dashboardReducer.status.SETTINGS_SET === "request") {
      setIsLoading(true);
    } else if (dashboardReducer.status.SETTINGS_SET === "success") {
      dispatch(dashboardActions.clearStatusMessage("SETTINGS_SET"));
      dispatch(dashboardActions.getCustomerKpis());
      setIsLoading(false);
      close();
    } else if (catchError(dashboardReducer.status.SETTINGS_SET)) {
      dispatch(dashboardActions.clearStatusMessage("SETTINGS_SET"));
      //TODO Proper error and success messaging
      setIsLoading(false);
      close();
    }
  }, [dispatch, dashboardReducer.status.SETTINGS_SET, close]);

  const handleClosePopup = () => {
    addCurrentSettings();
    props.close();
  };

  const renderGroupByOptions = (chart) => {
    return currentDashboardSettings.available_charts
      ?.find((availableChart) => availableChart.class === chart.class)
      ?.option1?.map((option) => (
        <MenuItem value={option.value}>{option.label}</MenuItem>
      ));
  };

  const renderChartSubgroupLabel = (chart) => {
    return `${
      chart?.class !== "Emails" ||
      (chart?.class === "Emails" && chart?.date_group_by === "sent")
        ? "Subgroup"
        : "Filter"
    }`;
  };

  const renderChartSubgroupOptions = (chart) => {
    return chart?.class !== "Emails" ||
      (chart?.class === "Emails" && chart?.date_group_by === "sent")
      ? currentDashboardSettings.available_charts
          ?.find((availableChart) => availableChart.class === chart.class)
          ?.option2?.map((option) => (
            <MenuItem value={option.value}>{option.label}</MenuItem>
          ))
      : currentDashboardSettings.available_charts
          ?.find((availableChart) => availableChart.class === chart.class)
          ?.option3?.map((option) => (
            <MenuItem value={option.value}>{option.label}</MenuItem>
          ));
  };

  return (
    <DialogPopup
      title="Settings"
      open={props.open}
      onClose={handleClosePopup}
      className={styles.root}
    >
      <Scrollbar maxHeight="78vh" options={{suppressScrollX:true}}>
        <div>
          <KpisSettingsPopup
            open={openKpiPopup}
            close={handleCloseKpiPopup}
            newDashboardSettings={newDashboardSettings}
            index={selectedKpiIndex}
            editKpi={editKpi}
            removeSelectedKpi={removeSelectedKpi}
          />
          <h4>Default filtering and grouping</h4>{" "}
          <div className={styles.filter_group}>
            <div className={styles.filter_group_item}>
              <TextfieldInput
                label="Date range"
                select
                name="date_range"
                size="small"
                className={styles.select}
                value={newDashboardSettings.filter.date_range}
                onChange={addFilter}
              >
                {dateRange.map((range) => (
                  <MenuItem
                    style={{
                      display: `${range.value === "custom" ? "none" : ""}`,
                    }}
                    key={range.value}
                    value={range.value}
                  >
                    {`${range.label} `}
                  </MenuItem>
                ))}
              </TextfieldInput>
            </div>
            <div className={styles.filter_group_item}>
              <TextfieldInput
                label="Date grouping"
                select
                name="date_group_by"
                size="small"
                className={styles.select}
                value={newDashboardSettings.filter.date_group_by}
                onChange={addFilter}
              >
                {dateGrouping.map((group) => (
                  <MenuItem key={group.value} value={group.value}>
                    {`${group.label} `}
                  </MenuItem>
                ))}
              </TextfieldInput>
            </div>
            <div className={styles.filter_group_item}>
              <TextfieldInput
                label="Users"
                select
                name="user"
                size="small"
                className={styles.select}
                value={newDashboardSettings?.filter?.user || " "}
                onChange={addFilter}
              >
                {jwtData.user_role !== userConstants.USER_ROLE_AGENT ? (
                  userReducer.users.list &&
                  userReducer.users.list
                    .filter((user) => user.state !== "DEACTIVATED")
                    .map((filteredUser) => (
                      <MenuItem
                        key={filteredUser.user_id}
                        value={filteredUser.user_id}
                      >
                        {filteredUser.name}
                      </MenuItem>
                    ))
                ) : (
                  <MenuItem value={jwtData.user_id}>
                    {jwtData.username}
                  </MenuItem>
                )}
                <MenuItem value={" "}>All</MenuItem>
              </TextfieldInput>
            </div>
          </div>
        </div>
        <div>
          <h4>KPI</h4>
          <div className={styles.kpi_settings_container}>
            {currentDashboardSettings?.available_kpis?.map((e, index) => (
              <div
                key={index}
                className={styles.kpi_item}
                onClick={() =>
                  handleOpenKpiPopup(index, newDashboardSettings.kpis[index])
                }
              >
                {newDashboardSettings.kpis[index]?.name ? (
                  <h4 style={{ margin: "auto" }} className={styles.kpi_value}>
                    {" "}
                    {
                      currentDashboardSettings?.available_kpis?.find(
                        (kpi) =>
                          kpi.name === newDashboardSettings.kpis[index]?.name
                      ).displayed_name
                    }{" "}
                  </h4>
                ) : (
                  <h4 className={styles.add_kpi_text}>Add KPI</h4>
                )}

                <div></div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <h4>Charts</h4>
          {newDashboardSettings.charts.map((chart, index) => (
            <div className={styles.chart_settings_row}>
              <div
                className={styles.chart_setting}
                style={
                  chart?.class === "Payments" &&
                  currentDashboardSettings?.currencies?.length > 1
                    ? { width: "25%" }
                    : {}
                }
              >
                <TextfieldInput
                  label="Select chart class"
                  select
                  name="class"
                  size="small"
                  value={chart?.class || ""}
                  className={styles.select}
                  onChange={(event) => editChart(event, index)}
                >
                  {currentDashboardSettings.available_charts?.map((chart) => (
                    <MenuItem value={chart.class}>{chart.class}</MenuItem>
                  ))}
                </TextfieldInput>
              </div>

              <div
                className={styles.chart_setting}
                style={
                  chart?.class === "Payments" &&
                  currentDashboardSettings?.currencies?.length > 1
                    ? { width: "25%" }
                    : {}
                }
              >
                <TextfieldInput
                  label="Group by date"
                  select
                  name="date_group_by"
                  size="small"
                  className={styles.select}
                  value={chart?.date_group_by || ""}
                  onChange={(event) => editChart(event, index)}
                >
                  {renderGroupByOptions(chart)}
                </TextfieldInput>
              </div>

              {chart?.class === "Payments" &&
                currentDashboardSettings?.currencies?.length > 1 && (
                  <div className={styles.chart_currency_filter}>
                    <TextfieldInput
                      label="Currency"
                      select
                      name="currency"
                      size="small"
                      className={styles.select}
                      value={
                        chart?.currency ||
                        currentDashboardSettings.currencies[0]
                      }
                      onChange={(event) => editChart(event, index)}
                    >
                      {currentDashboardSettings?.currencies.map((currency) => (
                        <MenuItem value={currency}>{currency}</MenuItem>
                      ))}
                    </TextfieldInput>
                  </div>
                )}

              <div
                className={styles.chart_setting}
                style={
                  chart?.class === "Payments" &&
                  currentDashboardSettings?.currencies?.length > 1
                    ? { width: "25%" }
                    : {}
                }
              >
                <TextfieldInput
                  label={renderChartSubgroupLabel(chart)}
                  select
                  name="subgroup"
                  size="small"
                  className={styles.select}
                  value={chart?.subgroup || " "}
                  onChange={(event) => editChart(event, index)}
                >
                  {renderChartSubgroupOptions(chart)}
                </TextfieldInput>
              </div>
              <CloseOutlined
                onClick={() => removeChart(index)}
                className={styles.chart_clear_icon}
              />
            </div>
          ))}
          <Button onClick={addChartRow} className={styles.add_chart_button}>
            Add Chart
          </Button>
        </div>
        <div className="action_container">
          <>
            <Button className="btn_cancel" onClick={handleClosePopup}>
              Cancel
            </Button>
            {isLoading ? (
              <CircularProgress className={styles.loading} color="primary" />
            ) : (
              <Button
                className={`btn_confirm ${
                  newDashboardSettings.charts?.some(
                    (chart) =>
                      chart.class.length === 0 ||
                      !chart.date_group_by ||
                      !chart.subgroup
                  )
                    ? "btn_disabled"
                    : ""
                }`}
                onClick={saveDashboardSettings}
                disabled={newDashboardSettings.charts?.some(
                  (chart) =>
                    chart.class.length === 0 ||
                    !chart.date_group_by ||
                    !chart.subgroup
                )}
              >
                Confirm
              </Button>
            )}
          </>
        </div>
      </Scrollbar>
    </DialogPopup>
  );
}

export default DashboardSettingsPopup;
