export const languageConstants = {
  // get supported languages
  LANGUAGE_GET_REQUEST: "LANGUAGE_GET_REQUEST",
  LANGUAGE_GET_SUCCESS: "LANGUAGE_GET_SUCCESS",
  LANGUAGE_GET_FAILURE: "LANGUAGE_GET_FAILURE",

  // get customers added translations
  TRANSLATION_GET_REQUEST: "TRANSLATION_GET_REQUEST",
  TRANSLATION_GET_SUCCESS: "TRANSLATION_GET_SUCCESS",
  TRANSLATION_GET_FAILURE: "TRANSLATION_GET_FAILURE",

  // add translation to a supported language
  TRANSLATION_POST_REQUEST: "TRANSLATION_POST_REQUEST",
  TRANSLATION_POST_SUCCESS: "TRANSLATION_POST_SUCCESS",
  TRANSLATION_POST_FAILURE: "TRANSLATION_POST_FAILURE",

  // delete translation
  TRANSLATION_DELETE_REQUEST: "TRANSLATION_DELETE_REQUEST",
  TRANSLATION_DELETE_SUCCESS: "TRANSLATION_DELETE_SUCCESS",
  TRANSLATION_DELETE_FAILURE: "TRANSLATION_DELETE_FAILURE",

  // update login text
  LOGIN_TEXT_UPDATE_REQUEST: "LOGIN_TEXT_UPDATE_REQUEST",
  LOGIN_TEXT_UPDATE_SUCCESS: "LOGIN_TEXT_UPDATE_SUCCESS",
  LOGIN_TEXT_UPDATE_FAILURE: "LOGIN_TEXT_UPDATE_FAILURE",

  // update about us text
  ABOUT_US_TEXT_UPDATE_REQUEST: "ABOUT_US_TEXT_UPDATE_REQUEST",
  ABOUT_US_TEXT_UPDATE_SUCCESS: "ABOUT_US_TEXT_UPDATE_SUCCESS",
  ABOUT_US_TEXT_UPDATE_FAILURE: "ABOUT_US_TEXT_UPDATE_FAILURE",

  // update case overview text
  CASE_OVERVIEW_TEXT_UPDATE_REQUEST: "CASE_OVERVIEW_TEXT_UPDATE_REQUEST",
  CASE_OVERVIEW_TEXT_UPDATE_SUCCESS: "CASE_OVERVIEW_TEXT_UPDATE_SUCCESS",
  CASE_OVERVIEW_TEXT_UPDATE_FAILURE: "CASE_OVERVIEW_TEXT_UPDATE_FAILURE",

  // update faq's
  FAQ_UPDATE_REQUEST: "FAQ_UPDATE_REQUEST",
  FAQ_UPDATE_SUCCESS: "FAQ_UPDATE_SUCCESS",
  FAQ_UPDATE_FAILURE: "FAQ_UPDATE_FAILURE",

  // update default translation
  DEFAULT_TRANSLATION_UPDATE_REQUEST: "DEFAULT_TRANSLATION_UPDATE_REQUEST",
  DEFAULT_TRANSLATION_UPDATE_SUCCESS: "DEFAULT_TRANSLATION_UPDATE_SUCCESS",
  DEFAULT_TRANSLATION_UPDATE_FAILURE: "DEFAULT_TRANSLATION_UPDATE_FAILURE",

  // activate translation
  ACTIVATE_TRANSLATION_UPDATE_REQUEST: "ACTIVATE_TRANSLATION_UPDATE_REQUEST",
  ACTIVATE_TRANSLATION_UPDATE_SUCCESS: "ACTIVATE_TRANSLATION_UPDATE_SUCCESS",
  ACTIVATE_TRANSLATION_UPDATE_FAILURE: "ACTIVATE_TRANSLATION_UPDATE_FAILURE",

  // deactivate translation
  DEACTIVATE_TRANSLATION_UPDATE_REQUEST:
    "DEACTIVATE_TRANSLATION_UPDATE_REQUEST",
  DEACTIVATE_TRANSLATION_UPDATE_SUCCESS:
    "DEACTIVATE_TRANSLATION_UPDATE_SUCCESS",
  DEACTIVATE_TRANSLATION_UPDATE_FAILURE:
    "DEACTIVATE_TRANSLATION_UPDATE_FAILURE",

  // select translation
  SELECT_TRANSLATION: "SELECT_TRANSLATION",

  // clear status
  LANGUAGE_CLEAR_STATUS_MESSAGE: "LANGUAGE_CLEAR_STATUS_MESSAGE",
};
