import React from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { history } from '@_utils/helpers';

import Layout from '@_pages/Layout/Layout';
import CasesPage from '@_pages/Cases/Cases';
import LoginPage from '@_pages/Login/Login';
import SignupPage from '@_pages/Signup/Signup';
import AccountCreation from '@_pages/AccountCreation/AccountCreation';
import PrivacyPolicy from '@_pages/PrivacyPolicy/PrivacyPolicy';

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout {...props}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

function Routes() {
  return (
    <div>
      <Router history={history}>
        <Switch>
        <RouteWrapper
            path="/privacy-policy"
            component={PrivacyPolicy}
            layout={Layout}
          />
          <RouteWrapper
            path={['/login', '/user/verification']}
            component={LoginPage}
            layout={Layout}
          />
          <RouteWrapper path="/signup" component={SignupPage} layout={Layout} />
          <RouteWrapper
            path="/account-creation"
            component={AccountCreation}
            layout={Layout}
          />
          <Layout>
            <Switch>
              <PrivateRoute exact path="/cases" component={CasesPage} />
              <Redirect from="*" to="/cases" />
            </Switch>
          </Layout>
        </Switch>
      </Router>
    </div>
  );
}

export default Routes;
