import { casesConstants } from "@_constants/cases.constants";
import { casesService } from "@_services/cases.service";

export const casesActions = {
  getCases,
  addCases,
  getCasesCount,
  clearStateMessage,
};

function clearStateMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return { type: casesConstants.CASES_CLEAR_STATE_MESSGAE, stateName };
  }
}

function getCases(searchObject, resetCases) {
  var isUpdate = !searchObject && !resetCases;
  return (dispatch, getState) => {
    dispatch(request());
   
    if (!searchObject && !resetCases) {
      // this is an update call we get the old search object and set the
      // paging to load all cases which are loaded currently
      searchObject = getState().casesReducer.searchObject;
      searchObject.paging.page_size = getState().casesReducer.cases.length;
      searchObject.paging.page = 1;
    }
    casesService.getCases(searchObject).then(
      (cases) => {
        dispatch(success(cases));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: casesConstants.CASES_GET_REQUEST };
  }
  function success(cases) {
    return {
      type: casesConstants.CASES_GET_SUCCESS,
      data: {
        cases: cases,
        resetCases: resetCases,
        searchObject: searchObject,
        // if both is not set we set update to true
        isUpdate: isUpdate,
      },
    };
  }
  function failure(error) {
    return { type: casesConstants.CASES_GET_FAILURE, error };
  }
}

function getCasesCount() {
  return (dispatch) => {
    dispatch(request());
    casesService.getCasesCount().then(
      (cases) => {
        dispatch(success(cases));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: casesConstants.CASES_COUNT_GET_REQUEST };
  }
  function success(counts) {
    return { type: casesConstants.CASES_COUNT_GET_SUCCESS, counts };
  }
  function failure(error) {
    return { type: casesConstants.CASES_COUNT_GET_FAILURE, error };
  }
}

function addCases(data) {
  return (dispatch) => {
    dispatch(request());
    casesService.addCases(data).then(
      (response) => {
        dispatch(success(response.case_uuid));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: casesConstants.CASES_ADD_REQUEST };
  }
  function success(case_uuid) {
    return { type: casesConstants.CASES_ADD_SUCCESS, case_uuid };
  }
  function failure(error) {
    return { type: casesConstants.CASES_ADD_FAILURE, error };
  }
}
