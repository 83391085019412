import { fileConstants } from "../@_constants/files_settlement_portal.constants";

const initialState = {
  image_url: "",
  loading: false,
  message: "",
  success: false,
  status: {
    FILE_PUT: "",

    FILE_DELETE: "",
  },
};

export function fileReducer(state = initialState, action) {
  switch (action.type) {
    // FILE_CLEAR_STATUS_MESSAGE
    case fileConstants.FILE_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, [action.stateName]: "" },
      };
      break;
    // FILE PUT REQUEST
    case fileConstants.FILES_PUT_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, FILE_PUT: "request" },
      };
      break;
    case fileConstants.FILES_PUT_SUCCESS:
      state = {
        ...state,
        loading: false,
        image_url: action.data,
        success: true,
        status: { ...state.status, FILE_PUT: "success" },
      };

      break;
    case fileConstants.FILES_PUT_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, FILE_PUT: action.error },
      };
      break;

    // FILE DELETE REQUEST
    case fileConstants.FILES_DELETE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, FILE_DELETE: "request" },
      };
      break;
    case fileConstants.FILES_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        image_url: null,
        status: { ...state.status, FILE_DELETE: "success" },
      };

      break;
    case fileConstants.FILES_DELETE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, FILE_DELETE: action.error },
      };
      break;

    default:
      break;
  }
  return state;
}
