import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import styles from "./Subscription.module.css";
import ArrowForwardIosSharpIcon from "@material-ui/icons/ArrowForwardIosSharp";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LiteImage from "@_assets/images/lite-graphic.png";
import ProImage from "@_assets/images/pro-graphic.png";
import EnterpriseImage from "@_assets/images/enterprise-graphic.png";
import PlusCircleBlack from "@_assets/images/Circle-Plus_Black.svg";
import RocketLaunchIcon from "@_assets/images/rocket_black.svg";
import RocketLaunchIconBlue from "@_assets/images/rocket_blue.svg";
import PlusCircleBlue from "@_assets/images/Circle-Plus_Blue.svg";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import StarOutlineOutlinedIcon from "@material-ui/icons/StarOutlineOutlined";
import UpgradePlanpopup from "@_components/popups/UpgradePlanPopup/UpgradePlanPopup.js";
import subscriptionCopy from "@_configs/subscription.config.js";
import CheckIcon from "@material-ui/icons/Check";
import { getUserDataFromJwt } from "@_utils/helpers";
import UnderConstructionImage from "@_assets/images/under_construction.png";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid var(--color-theme-bright-gray)`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem" }}
        className={styles.arrow_icon}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "var(--color-theme-white)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function Subscription() {
  const { title, subtitle, copy_1, copy_2 } = subscriptionCopy.subscription;
  const jwtData = getUserDataFromJwt();
  const [expanded, setExpanded] = React.useState("");
  const [displayUpgradePopup, setDisplayUpgradePopup] = useState(false);
  const [nextUpgrade, setNextUpgrade] = useState("");
  const subscriptionImgStyle = true;

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    if (jwtData.subscription === "LITE") {
      setNextUpgrade("PRO");
    } else if (jwtData.subscription === "PRO") {
      setNextUpgrade("ENTERPRISE");
    } else return;
  }, [jwtData.subscription]);

  useEffect(() => {
    if (nextUpgrade === "PRO") {
      setExpanded("panel2");
    } else if (nextUpgrade === "ENTERPRISE") {
      setExpanded("panel3");
    } else return;
  }, [nextUpgrade]);

  return (
    <div className={styles.root}>
      <UpgradePlanpopup
        title={title}
        subtitle={subtitle}
        copy_1={copy_1}
        copy_2={copy_2}
        imageUrl={UnderConstructionImage}
        open={displayUpgradePopup}
        close={() => setDisplayUpgradePopup(false)}
        subscriptionImgStyle={subscriptionImgStyle}
      />
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <div className={styles.subscription_header}>
            <div className={styles.right_row}>
              <div>
                <CameraAltOutlinedIcon className={styles.camera_icon} />
              </div>
              <div className={styles.plan}>LITE</div>
              <div>€ 0/free plan</div>
              <div>Share charged: 50%</div>
            </div>
            {jwtData.subscription === "LITE" && (
              <div className={styles.left_row}>
                <div className={styles.current_plan}>Your current plan</div>
                <CheckIcon className={styles.check_icon} />
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.content_container}>
            {" "}
            <div className={styles.left_container}>
              <div className={styles.features_header}>FEATURES:</div>
              <div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Single seat</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Case management portal</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Email templating engine</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Run settlement portal on fairlicensing.com</p>
                </div>
              </div>
            </div>
            <div className={styles.right_container_lite}>
              <div className={styles.image_container}>
                <img
                  src={LiteImage}
                  alt="woman holding camera"
                  className={styles.lite_illustration}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <div
            className={
              nextUpgrade === "PRO"
                ? styles.subscription_header_blue
                : styles.subscription_header
            }
          >
            <div className={styles.right_row}>
              <div>
                <StarOutlineOutlinedIcon
                  className={
                    nextUpgrade === "PRO"
                      ? styles.star_icon_blue
                      : styles.star_icon
                  }
                />
              </div>
              <div className={styles.plan}>PRO</div>
              <div>€ 99/month</div>
              <div>Share charged: 33%</div>
            </div>
            <div className={styles.left_row}>
              {jwtData.subscription === "LITE" && (
                <Button
                  className={
                    nextUpgrade === "PRO"
                      ? styles.btn_upgrade_blue
                      : styles.btn_upgrade
                  }
                  onClick={(e) => setDisplayUpgradePopup(true)}
                >
                  upgrade to pro
                </Button>
              )}
              {jwtData.subscription === "PRO" && (
                <>
                  {" "}
                  <div className={styles.current_plan}>Your current plan</div>
                  <CheckIcon className={styles.check_icon} />
                </>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className={
              nextUpgrade === "PRO"
                ? styles.content_container_blue
                : styles.content_container
            }
          >
            {" "}
            <div className={styles.left_container}>
              <div className={styles.features_header}>FEATURES:</div>
              <div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Case management portal</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Email templating engine</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Run settlement portal on fairlicensing.com</p>
                </div>
              </div>
            </div>
            <img
              src={nextUpgrade === "PRO" ? PlusCircleBlue : PlusCircleBlack}
              className={styles.plus_circle}
              alt="plus circle black"
            />
            <div className={styles.right_container}>
              <div className={styles.left_feature_list}>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Run settlement portal in your own domain</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Customize settlement portal with your own logo</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Send emails from Fair Licensing using your own domain</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>
                    Possibility to book additional seats for only €29/month per
                    seat
                  </p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Define up to three different email flows</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>
                    Possibility to operate settlement portal in up to two
                    different languages (German and English)
                  </p>
                </div>
              </div>
              <div className={styles.image_container}>
                <img
                  src={ProImage}
                  alt="woman reaching for star"
                  className={styles.pro_illustration}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          {" "}
          <div
            className={
              nextUpgrade === "ENTERPRISE"
                ? styles.subscription_header_blue
                : styles.subscription_header
            }
          >
            <div className={styles.right_row}>
              <div>
                <img
                  src={
                    nextUpgrade === "ENTERPRISE"
                      ? RocketLaunchIconBlue
                      : RocketLaunchIcon
                  }
                  alt="rocket launch icon"
                  className={styles.rocket_icon}
                />
              </div>
              <div className={styles.plan}>ENTERPRISE</div>
              <div>€ 949/month</div>
              <div>Share charged: 25%</div>
            </div>
            <div className={styles.left_row}>
              {jwtData.subscription === "ENTERPRISE" ? (
                <>
                  {" "}
                  <div className={styles.current_plan}>Your current plan</div>
                  <CheckIcon className={styles.check_icon} />
                </>
              ) : (
                <div>
                  <Button
                    className={
                      nextUpgrade === "ENTERPRISE"
                        ? styles.btn_upgrade_blue
                        : styles.btn_upgrade
                    }
                    onClick={(e) => setDisplayUpgradePopup(true)}
                  >
                    upgrade to enterprise
                  </Button>
                </div>
              )}
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className={
              nextUpgrade === "ENTERPRISE"
                ? styles.content_container_blue
                : styles.content_container
            }
          >
            {" "}
            <div className={styles.left_container}>
              <div className={styles.features_header}>FEATURES:</div>
              <div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Case management portal</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Email templating engine</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Run settlement portal on fairlicensing.com</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Run settlement portal in your own domain</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Customize settlement portal with your own logo</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Send emails from Fair Licensing using your own domain</p>
                </div>
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>
                    Possibility to book additional seats for only €29/month per
                    seat
                  </p>
                </div>
              </div>
            </div>
            <img
              src={
                nextUpgrade === "ENTERPRISE" ? PlusCircleBlue : PlusCircleBlack
              }
              className={styles.plus_circle}
              alt="plus circle black"
            />
            <div className={styles.right_container}>
              <div className={styles.left_feature_list}>
                {" "}
                <div className={styles.list_item}>
                  <CheckCircleIcon className={styles.check_circle_icon} />
                  <p>Unlimited email flows</p>
                </div>
              </div>

              <div className={styles.image_container}>
                <img
                  src={EnterpriseImage}
                  alt="woman with rocket"
                  className={styles.enterprise_illustration}
                />
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className={styles.info_text}>
        <p>
          {" "}
          All prices mentioned are net prices, the legally applicable VAT will
          be added.
        </p>
      </div>
    </div>
  );
}
