import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { caseActions } from "@_actions/case.actions";
import { stateError, isComponentDisabled } from "@_utils/helpers";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./SelectEmailFlowPopup.module.css";

const componetTag = "select_email_flow_popup";
function SelectEmailFlowPopup(props) {
  const dispatch = useDispatch();
  const { caseId, data } = props;
  const caseReducer = useSelector((state) => {
    return state.caseReducer;
  });
  const emailFlowReducer = useSelector((state) => {
    return state.emailFlowReducer;
  });
  const inputDisabled = isComponentDisabled(componetTag, data.state);

  const [flow, setFlow] = useState(data.email_flow && data.email_flow.id);

  function actionClicked() {
    dispatch(caseActions.addCaseFlow(caseId, flow));
  }

  useEffect(() => {
    const currState = caseReducer.status.CASE_ADD_FLOW;
    if (currState === "success") {
      dispatch(caseActions.getCaseWithChargeDetails(caseId, data.overview));
      dispatch(caseActions.clearStateMessage("CASE_ADD_FLOW"));
      props.close();
    }
    // eslint-disable-next-line
  }, [caseReducer.status.CASE_ADD_FLOW, dispatch]);

  function handleInputChange(e) {
    const value = e.target;
    setFlow(value.value);
  }

  const closePopup = () => {
    dispatch(caseActions.clearStateMessage("CASE_ADD_FLOW"));
    props.close();
  };

  useEffect(() => {
    if (
      !data.email_flow &&
      emailFlowReducer.emailFlows &&
      emailFlowReducer.emailFlows.length === 1
    ) {
      setFlow(emailFlowReducer.emailFlows[0].id);
    }
  }, [data.email_flow, emailFlowReducer.emailFlows]);

  return (
    <DialogPopup
      title="Assign email flow"
      open={props.open}
      onClose={closePopup}
    >
      <div className={styles.content_container}>
        <TextfieldInput
          select
          label="Email Flow"
          id="flow"
          name="flow"
          defaultValue={flow}
          onChange={handleInputChange}
          disabled={inputDisabled}
        >
          {emailFlowReducer &&
            emailFlowReducer.emailFlows.map((email, index) => (
              <MenuItem key={email.id} value={email.id}>
                {email.name}
              </MenuItem>
            ))}
        </TextfieldInput>
      </div>
      {stateError(caseReducer.status.CASE_ADD_FLOW) && (
        <div className={styles.error_container}>
          <div className={styles.error_text}>
            {stateError(caseReducer.status.CASE_ADD_FLOW)}
          </div>
        </div>
      )}
      <div className="action_container">
        {!inputDisabled ? (
          <>
            <Button className="btn_cancel" onClick={() => props.close()}>
              Cancel
            </Button>
            <Button
              className="btn_confirm"
              onClick={actionClicked}
              disabled={inputDisabled}
            >
              Confirm
            </Button>
          </>
        ) : (
          <Button className="btn_cancel" onClick={() => props.close()}>
            Close
          </Button>
        )}
      </div>
    </DialogPopup>
  );
}

export default SelectEmailFlowPopup;
