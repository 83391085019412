import { invoiceTemplateConstants } from "@_constants/invoiceTemplate.constants";

const initialState = {
  templates: [],
  invoiceList: [],
  loading: false,
  message: "",
  success: false,
  selected_template: null,
  add_template: false,
  placeholders: [],
  status: {
    INVOICE_TEMPLATES_GET: "",
    INVOICES_LIST_GET: "",
    INVOICE_TEMPLATE_CREATE: "",
    INVOICE_TEMPLATE_UPDATE: "",
    INVOICE_TEMPLATE_DELETE: "",
    INVOICE_TEMPLATE_PREVIEW: "",
    INVOICE_DOWNLOAD: "",
    INVOICES_LIST_DOWNLOAD: "",
    ADD_INVOICE_TEMPLATE: "",
    SELECT_INVOICE_TEMPLATE: "",
  },
};

export function invoiceTemplateReducer(state = initialState, action) {
  switch (action.type) {
    // TEMPLATE_CLEAR_STATUS_MESSAGE
    case invoiceTemplateConstants.INVOICE_TEMPLATE_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        loading: false,
        success: true,
        message: "",
        status: { ...state.status, [action.stateName]: "" },
      };
      break;

    // TEMPLATE GET REQUEST
    case invoiceTemplateConstants.INVOICE_TEMPLATES_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        success: false,
        status: { ...state.status, INVOICE_TEMPLATES_GET: "request" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_TEMPLATES_GET_SUCCESS:
      state = {
        ...state,
        templates: action.data,
        loading: false,
        message: "",
        success: true,
        status: { ...state.status, INVOICE_TEMPLATES_GET: "success" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_TEMPLATES_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, INVOICE_TEMPLATES_GET: action.error },
      };
      break;

    // TEMPLATE INVOICE GET REQUEST
    case invoiceTemplateConstants.INVOICES_LIST_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        success: false,
        status: { ...state.status, INVOICES_LIST_GET: "request" },
      };
      break;
    case invoiceTemplateConstants.INVOICES_LIST_GET_SUCCESS:
      state = {
        ...state,
        invoiceList: action.data,
        loading: false,
        message: "",
        success: true,
        status: { ...state.status, INVOICES_LIST_GET: "success" },
      };
      break;
    case invoiceTemplateConstants.INVOICES_LIST_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, INVOICES_LIST_GET: action.error },
      };
      break;

    //Template Create Request
    case invoiceTemplateConstants.INVOICE_TEMPLATE_CREATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, INVOICE_TEMPLATE_CREATE: "request" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_TEMPLATE_CREATE_SUCCESS:
      state = {
        ...state,
        selected_template: action.id || null,
        loading: false,
        message: "",
        status: { ...state.status, INVOICE_TEMPLATE_CREATE: "success" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_TEMPLATE_CREATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, INVOICE_TEMPLATE_CREATE: action.error },
      };
      break;

    // Case Template update REQUEST
    case invoiceTemplateConstants.INVOICE_TEMPLATE_UPDATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, INVOICE_TEMPLATE_UPDATE: "request" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_TEMPLATE_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, INVOICE_TEMPLATE_UPDATE: "success" },
      };

      break;
    case invoiceTemplateConstants.INVOICE_TEMPLATE_UPDATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, INVOICE_TEMPLATE_UPDATE: action.error },
      };
      break;

    case invoiceTemplateConstants.INVOICE_TEMPLATE_DELETE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, INVOICE_TEMPLATES_DELETE: "request" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_TEMPLATE_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        emails: "",
        message: "",
        templates: state.templates.filter((x) => x.id !== action.id),
        status: { ...state.status, INVOICE_TEMPLATES_DELETE: "success" },
      };

      if (action.id === state.selected_template) {
        state = { ...state, selected_template: null };
      }
      break;
    case invoiceTemplateConstants.INVOICE_TEMPLATE_DELETE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, INVOICE_TEMPLATES_DELETE: action.error },
      };
      break;

    case invoiceTemplateConstants.INVOICE_TEMPLATE_PREVIEW_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        success: false,
        status: { ...state.status, INVOICE_TEMPLATE_PREVIEW: "request" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_TEMPLATE_PREVIEW_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        success: true,
        status: { ...state.status, INVOICE_TEMPLATE_PREVIEW: "success" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_TEMPLATE_PREVIEW_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, INVOICE_TEMPLATE_PREVIEW: action.error },
      };
      break;

    // INVOICE DWONLOAD REQUEST
    case invoiceTemplateConstants.INVOICES_LIST_DOWNLOAD_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        success: false,
        status: { ...state.status, INVOICES_LIST_DOWNLOAD: "request" },
      };
      break;
    case invoiceTemplateConstants.INVOICES_LIST_DOWNLOAD_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        success: true,
        status: { ...state.status, INVOICES_LIST_DOWNLOAD: "success" },
      };
      break;
    case invoiceTemplateConstants.INVOICES_LIST_DOWNLOAD_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, INVOICES_LIST_DOWNLOAD: action.error },
      };
      break;

    // INVOICE LIST DWONLOAD REQUEST
    case invoiceTemplateConstants.INVOICE_DOWNLOAD_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        success: false,
        status: { ...state.status, INVOICE_DOWNLOAD: "request" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_DOWNLOAD_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        success: true,
        status: { ...state.status, INVOICE_DOWNLOAD: "success" },
      };
      break;
    case invoiceTemplateConstants.INVOICE_DOWNLOAD_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, INVOICE_DOWNLOAD: action.error },
      };
      break;

    case invoiceTemplateConstants.ADD_INVOICE_TEMPLATE:
      state = {
        ...state,
        add_template: action.addTemplate,
        message: "",
      };
      break;

    case invoiceTemplateConstants.SELECT_INVOICE_TEMPLATE:
      state = {
        ...state,
        selected_template: action.id || null,
        message: "",
      };
      break;
    default:
      break;
  }
  return state;
}
