export const kpiNames = [
  { label: "Unread emails", value: "unread_emails" },
  { label: "Standby cases", value: "standby_cases" },
  { label: "Licenses to check", value: "licenses_to_check" },
  { label: "Unmapped emails", value: "unmapped_emails" },
  { label: "Failed payments", value: "failed_payments" },
  { label: "Failed emails", value: "failed_emails" },
  { label: "Overdue reminder", value: "overdue_reminders" },
  { label: "Paused automation", value: "paused_automation" },
];

export const dateRange = [
  { label: "Last 7 days", value: "7_days" },
  { label: "Last 2 weeks", value: "2_weeks" },
  { label: "Last 4 weeks", value: "4_weeks" },
  { label: "Last 3 months", value: "3_months" },
  { label: "Last 12 months", value: "12_months" },
  { label: "All time", value: "all" },
  { label: "Custom", value: "custom" },
];

export const dateGrouping = [
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Yearly", value: "yearly" },
  { label: "All time", value: "all_time" },
];

export const paymentGrouping = [
  { label: "By activation date", value: "activation" },
  { label: "By payment date", value: "payment" },
];

export const emailFilter = [
  { label: "Sent", value: "SENT" },
  { label: "Recieved", value: "RECIEVED" },
  { label: "Unread", value: "UNREAD" },
];

export const barChartColors = [
  "#4E79A7",
  "#F28E2B",
  "#E15759",
  "#76B7B2",
  "#59A14F",
  "#EDC948",
  "#B07AA1",
  "#FF9DA7",
  "#9C755F",
  "#BAB0AC",
];

export const emailGrouping = [];
