import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { caseActions } from "@_actions/case.actions";
import { casesActions } from "@_actions/cases.actions";
import { stateError } from "@_utils/helpers";
import styles from "./AddFindingPopup.module.css";
import { validateLink, getBase64, isComponentDisabled } from "@_utils/helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { InputAdornment } from "@material-ui/core";

const defaultInputsState = {
  page_link: "",
  price: 0,

  page_screenshot_file: "",
  page_screenshot_url: "",
  page_screenshot_content64: "",
  page_screenshot_filename: "",
};

const defaultOriginalImageState = {
  content64: "",
  file: "",
  filename: "",
  url: "",
};

const componetTag = "add_finding_popup";

function AddFindingPopup(props) {
  const { caseId, allData } = props;
  const inputDisabled = isComponentDisabled(componetTag, allData.state);

  const dispatch = useDispatch();
  const caseReducer = useSelector((state) => {
    return state.caseReducer;
  });

  const [inputs, setInputs] = useState(defaultInputsState);
  const [isClicked, setIsClicked] = useState(false);

  const [originalImageInputs, setOriginalImageInputs] = useState(
    defaultOriginalImageState
  );
  const [disabledBtn, setDisabledBtn] = useState(false);

  useEffect(() => {
    setInputs(defaultInputsState);
  }, []);

  function actionClicked(inputs, originalImageInputs) {
    setIsClicked(true);

    if (inputs.page_screenshot_content64 === "") {
      alert("Please select page screenshot.");
      return;
    }
    if (originalImageInputs.content64 === "") {
      alert("Please select original images.");
      return;
    }
    if (!validateLink(inputs.page_link)) {
      alert("Please enter a valid page link");
      return;
    }
    if (inputs.price <= 0) {
      alert("Please Enter a License fee");
      return;
    }

    setDisabledBtn(true);

    const input_data = { ...inputs, original_image: originalImageInputs };
    dispatch(caseActions.addCaseFinding(caseId, input_data));
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  function handleOriginalInputChange(e) {
    const { name, value } = e.target;
    setOriginalImageInputs((originalImageInputs) => ({
      ...originalImageInputs,
      [name]: value,
    }));
  }

  function handleFindingPriceInputChange(e) {
    const { value } = e.target;
    setInputs((inputs) => ({ ...inputs, price: parseFloat(value) }));
  }

  const closePopup = () => {
    dispatch(caseActions.clearStateMessage("CASE_ADD_FINDING"));
    setTimeout(() => {
      setInputs(defaultInputsState);
      setOriginalImageInputs(defaultOriginalImageState);
      setDisabledBtn(false);
      dispatch(casesActions.getCases());
      dispatch(caseActions.getCaseWithChargeDetails(caseId, allData.overview));
      props.close();
    }, 200);
  };

  const handleFileInputChange = (e, type) => {
    if (type === "page_screenshot") {
      let page_screenshot_file = e.target.files[0];
      let page_screenshot_url = URL.createObjectURL(e.target.files[0]);

      getBase64(page_screenshot_file)
        .then((result) => {
          page_screenshot_file["base64"] = result;
          setInputs({
            ...inputs,
            page_screenshot_filename: page_screenshot_file.name,
            page_screenshot_content64: result.replace(
              /^data:image\/[a-z]+;base64,/,
              ""
            ),
            page_screenshot_url,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (type === "original_image") {
      let file = e.target.files[0];
      let url = URL.createObjectURL(e.target.files[0]);

      getBase64(file)
        .then((result) => {
          file["base64"] = result;
          setOriginalImageInputs({
            ...originalImageInputs,
            filename: file.name,
            content64: result.replace(/^data:image\/[a-z]+;base64,/, ""),
            url,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  if (caseReducer.status.CASE_ADD_FINDING === "success") {
    closePopup();
  }
  if (stateError(caseReducer.status.CASE_ADD_FINDING)) {
    setDisabledBtn(false);
  }

  return (
    <DialogPopup title="Add image use" open={props.open} onClose={closePopup}>
      <div className={styles.content_container}>
        <div className={styles.finding_col}>
          <b>
            Page Screenshot <span className={styles.requiredRed}>*</span>
          </b>
          <div className={styles.upload_container}>
            <div className={styles.image_box}>
              {inputs.page_screenshot_url &&
                inputs.page_screenshot_url !== "" && (
                  <img
                    key={"page_screenshot"}
                    id={"page_screenshot"}
                    className={styles.image}
                    alt="uploaded finding"
                    src={inputs.page_screenshot_url}
                  />
                )}
            </div>
            <div className={styles.upload_actions_container}>
              <label className={styles.upload_label}>
                <input
                  className={styles.upload_input}
                  id="contained-button-file-page"
                  type="file"
                  accept=".jpg, .png .gif"
                  onChange={(e) => handleFileInputChange(e, "page_screenshot")}
                  disabled={inputDisabled}
                />
                Select File
              </label>
            </div>
          </div>
        </div>
        <TextfieldInput
          label="Page Link"
          id={"page_link"}
          name={"page_link"}
          value={inputs.page_link}
          onChange={handleInputChange}
          error={isClicked && (!inputs.page_link || inputs.page_link === "")}
          disabled={inputDisabled}
          required
        />

        <div className={styles.finding_col}>
          <b>Original Image</b> <span className={styles.requiredRed}>*</span>
          <div className={styles.upload_container}>
            <div className={styles.image_box}>
              {originalImageInputs.url && originalImageInputs.url !== "" && (
                <img
                  key={"original_screenshot"}
                  id={"original_screenshot"}
                  className={styles.image}
                  alt="uploaded finding"
                  src={originalImageInputs.url}
                />
              )}
            </div>
            <div className={styles.upload_actions_container}>
              <label className={styles.upload_label}>
                <input
                  className={styles.upload_input}
                  id="contained-button-file-original"
                  type="file"
                  accept=".jpg, .png .gif"
                  onChange={(e) => handleFileInputChange(e, "original_image")}
                  disabled={inputDisabled}
                />
                Select File
              </label>
            </div>
          </div>
          <TextfieldInput
            label="Original Image Name"
            id={"filename"}
            name={"filename"}
            value={originalImageInputs.filename}
            onChange={handleOriginalInputChange}
            error={
              isClicked &&
              (!originalImageInputs.filename ||
                originalImageInputs.filename === "")
            }
            disabled={inputDisabled}
            required
          />
        </div>
        <TextfieldInput
          label="License Fee"
          type="number"
          name="price"
          value={inputs.price}
          onChange={handleFindingPriceInputChange}
          InputProps={{
            inputProps: { min: 0 },
            endAdornment: (
              <InputAdornment position="start">
                {allData.claim_details.currency}
              </InputAdornment>
            ),
          }}
          required
          error={isClicked && (inputs.price <= 0 || !inputs.price)}
        />
      </div>
      {stateError(caseReducer.status.CASE_ADD_FINDING) && (
        <div className={styles.error_container}>
          <div className={styles.error_text}>
            {stateError(caseReducer.status.CASE_ADD_FINDING)}
          </div>
        </div>
      )}
      <div className="action_container">
        {!inputDisabled ? (
          <>
            {caseReducer.status.CASE_ADD_FINDING === "request" ? (
              <CircularProgress />
            ) : (
              <>
                <Button className="btn_cancel" onClick={closePopup}>
                  Cancel
                </Button>
                <Button
                  className="btn_confirm"
                  onClick={() => actionClicked(inputs, originalImageInputs)}
                  disabled={inputDisabled ? true : disabledBtn}
                >
                  Confirm
                </Button>
              </>
            )}
          </>
        ) : (
          <Button className="btn_cancel" onClick={() => props.close()}>
            Close
          </Button>
        )}
      </div>
    </DialogPopup>
  );
}

export default AddFindingPopup;
