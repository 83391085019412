import { caseConstants } from "@_constants/case.constants";
import { caseService } from "@_services/case.service";

export const caseActivityActions = {
  getCaseActivities,
  saveNote,
  clearState,
  deleteNote,
  deleteScheduledMails,
  getEmailContent,
  markUnread,
  restEmailLoaded,
  resetCaseActivies,
  getEmailTemplateContentById,
  getEmailTemplateContentByTemplate,
  getEmailTemplateContentRequest,
  getEmailTemplateContentSuccess,
  getEmailTemplateContentFailure,
};

function clearState(stateName) {
  return (dispatch) =>
    dispatch({
      type: caseConstants.CASEACTIVITY_CLEAR_STATE,
      state: stateName,
    });
}

function restEmailLoaded() {
  return (dispatch) =>
    dispatch({
      type: caseConstants.CASEACTIVITY_EMAILMARKUNREAD_SUCCESS,
      data: { content: null, case_id: null, email_id: null },
    });
}

function getCaseActivities(case_id) {
  return (dispatch) => {
    dispatch(request());
    caseService.getCaseActivities(case_id).then(
      (activities) => {
        dispatch(success(activities));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASEACTIVITY_GET_REQUEST };
  }
  function success(activities) {
    return { type: caseConstants.CASEACTIVITY_GET_SUCCESS, activities };
  }
  function failure(error) {
    return { type: caseConstants.CASEACTIVITY_GET_FAILURE, error };
  }
}

function saveNote(case_id, text) {
  return (dispatch) => {
    dispatch(request());
    caseService.addNote(case_id, text).then(
      (activities) => {
        dispatch(success(activities));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASEACTIVITY_ADD_REQUEST };
  }
  function success(activity) {
    return { type: caseConstants.CASEACTIVITY_ADD_SUCCESS, activity };
  }
  function failure(error) {
    return { type: caseConstants.CASEACTIVITY_ADD_FAILURE, error };
  }
}

function deleteNote(case_id, activity_id) {
  return (dispatch) => {
    dispatch(request());
    caseService.deleteNote(case_id, activity_id).then(
      () => {
        dispatch(success(activity_id));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASEACTIVITY_DELETENOTE_REQUEST };
  }
  function success(activity_id) {
    return { type: caseConstants.CASEACTIVITY_DELETENOTE_SUCCESS, activity_id };
  }
  function failure(error) {
    return { type: caseConstants.CASEACTIVITY_DELETENOTE_FAILURE, error };
  }
}

function deleteScheduledMails(case_id, activity_id) {
  return (dispatch) => {
    dispatch(request());
    caseService.deleteSecheduledMail(case_id, activity_id).then(
      () => {
        dispatch(success(activity_id));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASEACTIVITY_DELETE_SCHEDULED_MAILS_REQUEST };
  }
  function success(activity_id) {
    return {
      type: caseConstants.CASEACTIVITY_DELETE_SCHEDULED_MAILS_SUCCESS,
      activity_id,
    };
  }
  function failure(error) {
    return {
      type: caseConstants.CASEACTIVITY_DELETE_SCHEDULED_MAILS_FAILURE,
      error,
    };
  }
}

function getEmailContent(email_id, case_id, isUnread) {
  return (dispatch) => {
    dispatch(request());
    caseService.getEmailContent(email_id).then(
      (content) => {
        dispatch(success(content));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASEACTIVITY_EMAILCONTENT_REQUEST };
  }
  function success(content) {
    return {
      type: caseConstants.CASEACTIVITY_EMAILCONTENT_SUCCESS,
      data: { content, email_id, case_id, isUnread },
    };
  }
  function failure(error) {
    return { type: caseConstants.CASEACTIVITY_EMAILCONTENT_FAILURE, error };
  }
}

function getEmailTemplateContentById(case_id, email_template_id) {
  return (dispatch) => {
    dispatch(getEmailTemplateContentRequest());
    caseService.getEmailTemplateContentById(case_id, email_template_id).then(
      (content) => {
        dispatch(getEmailTemplateContentSuccess(content, email_template_id));
      },
      (error) => {
        console.log(error);
        dispatch(getEmailTemplateContentFailure(error.toString()));
      }
    );
  };
}

function getEmailTemplateContentByTemplate(
  case_id,
  email_template_id,
  template
) {
  return (dispatch) => {
    dispatch(getEmailTemplateContentRequest());
    caseService.getEmailTemplateContentByTemplate(case_id, template).then(
      (content) => {
        dispatch(getEmailTemplateContentSuccess(content, email_template_id));
      },
      (error) => {
        console.log(error);
        dispatch(getEmailTemplateContentFailure(error.toString()));
      }
    );
  };
}

function getEmailTemplateContentRequest() {
  return { type: caseConstants.CASEACTIVITY_EMAILCONTENT_REQUEST };
}
function getEmailTemplateContentSuccess(content, email_template_id) {
  return {
    type: caseConstants.CASEACTIVITY_EMAIL_TEMPLATE_CONTENT_SUCCESS,
    data: { content, email_template_id },
  };
}
function getEmailTemplateContentFailure(error) {
  return { type: caseConstants.CASEACTIVITY_EMAILCONTENT_FAILURE, error };
}

function markUnread(email_id, case_id) {
  return (dispatch) => {
    dispatch(request());
    caseService.markEmailUnread(email_id).then(
      (content) => {
        dispatch(success(content));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: caseConstants.CASEACTIVITY_EMAILMARKUNREAD_REQUEST };
  }
  function success(content) {
    return {
      type: caseConstants.CASEACTIVITY_EMAILMARKUNREAD_SUCCESS,
      data: { content, case_id, email_id },
    };
  }
  function failure(error) {
    return { type: caseConstants.CASEACTIVITY_EMAILMARKUNREAD_FAILURE, error };
  }
}

function resetCaseActivies() {
  return (dispatch) => {
    return dispatch({ type: caseConstants.CASEACTIVITY_RESET });
  };
}
