import { emailSetupConstants } from "../@_constants/email_setup.constants";

const initialState = {
  email_setup: {},
  status: {
    EMAIL_SETUP_GET: "",
    EMAIL_SETUP_PUT: "",
    EMAIL_SETUP_ACTIVATION_PUT: "",
    EMAIL_SETUP_VALIDATION_PUT: "",
    INBOUND_EMAIL_ACTIVATION: "",
  },
};

export function emailSetupReducer(state = initialState, action) {
  switch (action.type) {
    // EMAIL_SETUP_CLEAR_STATUS_MESSAGE
    case emailSetupConstants.EMAIL_SETUP_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        status: { ...state.status, [action.stateName]: "" },
      };
      break;

    // EMAIL SETUP GET REQUEST
    case emailSetupConstants.EMAIL_SETUP_GET_REQUEST:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_GET: "request" },
      };
      break;
    case emailSetupConstants.EMAIL_SETUP_GET_SUCCESS:
      state = {
        ...state,
        email_setup: action.data,
        status: { ...state.status, EMAIL_SETUP_GET: "success" },
      };
      break;
    case emailSetupConstants.EMAIL_SETUP_GET_FAILURE:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_GET: action.error },
      };
      break;

    case emailSetupConstants.OUTBOUND_EMAIL_UPDATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_PUT: "request" },
      };
      break;
    case emailSetupConstants.OUTBOUND_EMAIL_UPDATE_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_PUT: "success" },
      };
      break;
    case emailSetupConstants.OUTBOUND_EMAIL_UPDATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_PUT: action.error },
      };
      break;

    case emailSetupConstants.OUTBOUND_EMAIL_ACTIVATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_ACTIVATION_PUT: "request" },
      };
      break;
    case emailSetupConstants.OUTBOUND_EMAIL_ACTIVATE_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_ACTIVATION_PUT: "success" },
      };
      break;
    case emailSetupConstants.OUTBOUND_EMAIL_ACTIVATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_ACTIVATION_PUT: action.error },
      };
      break;

    case emailSetupConstants.OUTBOUND_EMAIL_VALIDATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_VALIDATION_PUT: "request" },
      };
      break;
    case emailSetupConstants.OUTBOUND_EMAIL_VALIDATE_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_VALIDATION_PUT: "success" },
      };
      break;
    case emailSetupConstants.OUTBOUND_EMAIL_VALIDATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, EMAIL_SETUP_VALIDATION_PUT: action.error },
      };
      break;

    case emailSetupConstants.INBOUND_EMAIL_ACTIVATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, INBOUND_EMAIL_ACTIVATION: "request" },
      };
      break;
    case emailSetupConstants.INBOUND_EMAIL_ACTIVATE_SUCCESS:
      state = {
        ...state,
        email_setup: {
          ...state.email_setup,
          inbound_emails_active: action.data.passed ? 1 : 0,
        },
        status: {
          ...state.status,
          INBOUND_EMAIL_ACTIVATION: action.data.passed,
        },
      };
      break;
    case emailSetupConstants.INBOUND_EMAIL_ACTIVATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, INBOUND_EMAIL_ACTIVATION: action.error },
      };
      break;

    default:
      break;
  }
  return state;
}
