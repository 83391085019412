import React from "react";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className={styles.footer}>
      <div className={styles.footer_text}>
        <li>
          <a
            className={styles.footer_links}
            href="https://pixray.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            © PIXRAY GmbH {new Date().getFullYear()}{" "}
          </a>
        </li>
      </div>
      <div className={styles.footer_links_container}>
        <li>
          <Link
            to="privacy-policy"
            className={styles.footer_links}
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
        </li>
        <li>
          <a
            className={styles.footer_links}
            href="https://pixray.com/imprint/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Imprint
          </a>
        </li>
        <li>
          <a
            className={styles.footer_links}
            href="https://fairlicensing.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fair Licensing
          </a>
        </li>
      </div>
    </div>
  );
}

export default Footer;
