import React, { useState, useEffect } from "react";
import styles from "./SettlementPortalTexts.module.css";
import { useDispatch, useSelector } from "react-redux";
import { languageActions } from "@_actions/language.actions";

import { withStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LanguageList from "@_configs/LanguageList.config";

const CustomTabs = withStyles({
  root: {
    fontSize: 24,
    height: 60,
    backgroundColor: "var(--color-theme-white)",
  },
  indicator: {
    backgroundColor: "var(--color-theme-blue)",
  },
})(Tabs);

const CustomTab = withStyles((theme) => ({
  root: {
    height: 60,
    fontSize: 18,
    color: "var(--color-theme-10)",
    "&:hover": {
      backgroundColor: "var(--color-theme-lightblue)",
      opacity: 1,
    },
    "&$selected": {
      color: "var(--color-theme-blue)",
    },
    "&:focus": {
      color: "var(--color-theme-blue)",
    },
  },
  selected: {},
}))(Tab);

function SettlementPortalTexts() {
  const dispatch = useDispatch();

  const inputProps = {
    color: "white",
  };

  const languageReducer = useSelector((state) => state.languageReducer);
  const [selectedTab, setSelectedTab] = useState(0);
  const [loginRadioBtn, setLoginRadioBtn] = useState("");
  const [settlementDetails, setSettlementDetails] = useState({
    about_us_text: languageReducer.selected_translation.about_us_text || "",
    login_text: languageReducer.selected_translation.login_text || "",
    case_overview_text:
      languageReducer.selected_translation.case_overview_text || "",
  });
  const [faqList, setFaqList] = useState([]);
  const [isActive, setIsActive] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  useEffect(() => {
    setSettlementDetails({
      about_us_text: languageReducer.selected_translation.about_us_text || "",
      login_text: languageReducer.selected_translation.login_text || "",
      case_overview_text:
        languageReducer.selected_translation.case_overview_text || "",
    });
  }, [languageReducer.selected_translation]);

  useEffect(() => {
    languageReducer.selected_translation.login_text ||
    languageReducer.selected_translation.login_text === ""
      ? setLoginRadioBtn("Custom Text")
      : setLoginRadioBtn("Automatic Text");
  }, [setLoginRadioBtn, languageReducer.selected_translation.login_text]);

  useEffect(() => {
    languageReducer.selected_translation.faq &&
      languageReducer.selected_translation.faq.map((faq) => {
        return setFaqList([...languageReducer.selected_translation.faq]);
      });
  }, [languageReducer.selected_translation]);

  useEffect(() => {
    if (!languageReducer.selected_translation.faq) {
      setFaqList([]);
    }
  }, [languageReducer.selected_translation]);

  useEffect(() => {
    languageReducer.translations &&
      languageReducer.translations.translations &&
      languageReducer.translations.translations.map((translation) => {
        if (
          languageReducer.selected_translation &&
          languageReducer.selected_translation.id === translation.id &&
          languageReducer.selected_translation !== translation
        ) {
          dispatch(languageActions.selectTranslation(translation));
        }
        return <></>;
      });
  });

  useEffect(() => {
    if (languageReducer.status.FAQ_PUT === "success") {
      dispatch(languageActions.getTranslations());
    }
  }, [languageReducer.status.FAQ_PUT, dispatch]);

  useEffect(() => {
    if (languageReducer.status.ACTIVATE_TRANSLATION_PUT === "success") {
      setIsActive(true);
      dispatch(languageActions.getTranslations());
    } else return;
  }, [
    languageReducer.status.ACTIVATE_TRANSLATION_PUT,
    isActive,
    dispatch,
    languageReducer.selected_translation.is_active,
  ]);

  useEffect(() => {
    if (languageReducer.status.DEACTIVATE_TRANSLATION_PUT === "success") {
      setIsActive(false);
      dispatch(languageActions.getTranslations());
    } else return;
  }, [
    languageReducer.status.DEACTIVATE_TRANSLATION_PUT,
    dispatch,
    isActive,
    languageReducer.selected_translation.is_active,
  ]);

  useEffect(() => {
    if (
      languageReducer.selected_translation.is_active === 0 ||
      languageReducer.selected_translation.is_active === null
    ) {
      setIsActive(false);
    }
  }, [languageReducer.selected_translation.is_active]);

  useEffect(() => {
    if (languageReducer.selected_translation.is_active === 1) {
      setIsActive(true);
    }
  }, [languageReducer.selected_translation.is_active]);

  useEffect(() => {
    if (
      languageReducer.translations.default_translation_id ===
      languageReducer.selected_translation.id
    ) {
      setIsDefault(true);
    }
  }, [
    isDefault,
    languageReducer.translations.default_translation_id,
    languageReducer.selected_translation.id,
  ]);

  useEffect(() => {
    if (
      languageReducer.translations.default_translation_id !==
      languageReducer.selected_translation.id
    ) {
      setIsDefault(false);
    }
  }, [
    isDefault,
    languageReducer.translations.default_translation_id,
    languageReducer.selected_translation.id,
  ]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  function handleLoginRadioChange(e) {
    setLoginRadioBtn(e.target.value);
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setSettlementDetails((settlementDetails) => ({
      ...settlementDetails,
      [name]: value,
    }));
  }

  const handleFaqInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...faqList];
    list[index][name] = value;
    setFaqList(list);
  };

  const handleFaqRemoveClick = (index) => {
    const list = [...faqList];
    list.splice(index, 1);
    setFaqList(list);
  };

  const handleFaqAddClick = () => {
    setFaqList([...faqList, { question: "", answer: "" }]);
  };

  const handleSubmitLoginText = () => {
    if (loginRadioBtn === "Automatic Text") {
      delete settlementDetails.login_text;
    }
    let data = {
      language: languageReducer.selected_translation.language,
      content: settlementDetails.login_text,
    };
    dispatch(languageActions.updateLoginText(data));
  };

  if (languageReducer.status.LOGIN_TEXT_PUT === "success") {
  }

  const handleSubmitCaseOverviewText = () => {
    let data = {
      language: languageReducer.selected_translation.language,
      content: settlementDetails.case_overview_text,
    };
    dispatch(languageActions.updateCaseText(data));
  };

  const handleSubmitAboutUsText = () => {
    let data = {
      language: languageReducer.selected_translation.language,
      content: settlementDetails.about_us_text,
    };
    dispatch(languageActions.updateAboutUsText(data));
  };

  const handleSubmitFaqs = () => {
    let data = {
      language: languageReducer.selected_translation.language,
      content: faqList,
    };
    if (faqList.length === 0) {
      delete data.content;
    }
    dispatch(languageActions.updateFaqs(data));
  };

  const handleActivateTranslation = () => {
    let data = {
      language: languageReducer.selected_translation.language,
    };
    dispatch(languageActions.updateActiveTranslation(data));
  };

  const handleDeactivateTranslation = () => {
    let data = {
      language: languageReducer.selected_translation.language,
    };
    dispatch(languageActions.updateDeactivateTranslation(data));
  };

  const handleTranslationDefault = () => {
    let data = {
      language: languageReducer.selected_translation.language,
    };
    dispatch(languageActions.updateDefaultTranslation(data));
  };

  useEffect(() => {
    if (languageReducer.status.DEFAULT_TRANSLATION_PUT === "success") {
      dispatch(languageActions.getTranslations());
    }
  }, [dispatch, languageReducer.status.DEFAULT_TRANSLATION_PUT]);

  return (
    <div className={styles.root}>
      <div className={styles.language_header}>
        <div className={styles.language_name_container}>
          <h2 className={styles.language_title}>
            {" "}
            <b>{languageReducer.selected_translation.language.toUpperCase()}</b>
          </h2>
          <h2 className={styles.language_title}>
            {" "}
            <b>
              {LanguageList.find(
                (lang) =>
                  lang.code === languageReducer.selected_translation.language
              )?.language || "UNKNOWN"}
            </b>
          </h2>
        </div>
        <div>
          {isActive && !isDefault ? (
            <Button
              className={styles.btn_default}
              onClick={() => handleTranslationDefault()}
            >
              set as default
            </Button>
          ) : null}
          {isActive ? (
            <>
              <Button
                className={styles.btn_deactivate}
                onClick={() => handleDeactivateTranslation()}
              >
                Deactivate
              </Button>
            </>
          ) : (
            <Button
              className={styles.btn_activate}
              onClick={() => handleActivateTranslation()}
            >
              activate
            </Button>
          )}
        </div>
      </div>
      <div className={styles.tabs_and_content_container}>
        {" "}
        <div>
          <CustomTabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            variant="fullWidth"
          >
            <CustomTab label="Login Page " />
            <CustomTab label="Case Page *" />
            <CustomTab label="About Us Page *" />
            <CustomTab label="FAQ Page" />
          </CustomTabs>
        </div>
        <div className={styles.tabs_content_container}>
          <div hidden={selectedTab !== 0}>
            <RadioGroup value={loginRadioBtn} onChange={handleLoginRadioChange}>
              <div className={styles.login_content}>
                <div className={styles.login_col}>
                  <p className={styles.label}>Login Page Text </p>{" "}
                  <p className={styles.text_description}>
                    Text shown on the login page of your settlement portal. You
                    may set a custom text or choose to display an automatic
                    standard text.
                  </p>
                  <div className={styles.automatic_login_container}>
                    <FormControlLabel
                      value="Automatic Text"
                      control={<Radio color="primary" />}
                      className={styles.login_radio_btn}
                      label="Automatic Text"
                      color="primary"
                    />
                  </div>
                </div>
                <div className={styles.login_col}>
                  <div className={styles.login_custom_text}>
                    <FormControlLabel
                      value="Custom Text"
                      control={<Radio color="primary" />}
                      label="Custom Text"
                    />
                    {loginRadioBtn === "Custom Text" && (
                      <TextfieldInput
                        label="Enter custom text"
                        multiline
                        rows={10}
                        variant="outlined"
                        id={"login_text"}
                        name={"login_text"}
                        value={settlementDetails.login_text}
                        onChange={handleInputChange}
                        className={styles.textfield}
                        inputProps={inputProps}
                      />
                    )}
                  </div>
                </div>
              </div>
            </RadioGroup>
            <div className={styles.confirm_btn_container}>
              <Button
                className={styles.confirm_btn}
                onClick={() => handleSubmitLoginText()}
              >
                Save
              </Button>
            </div>
          </div>
          <div hidden={selectedTab !== 1}>
            <p className={styles.label}>
              Case Page Text <b>(required)</b>{" "}
            </p>{" "}
            <p className={styles.text_description}>
              Text shown on the main case overview page the user sees after
              logging into the settlement portal.
            </p>
            <TextfieldInput
              label="Enter custom text"
              multiline
              rows={10}
              id={"case_overview_text"}
              name={"case_overview_text"}
              value={settlementDetails.case_overview_text}
              variant="outlined"
              onChange={handleInputChange}
              required
            />
            <div className={styles.confirm_btn_container}>
              <Button
                className={styles.confirm_btn}
                onClick={() => handleSubmitCaseOverviewText()}
              >
                Save
              </Button>
            </div>
          </div>
          <div hidden={selectedTab !== 2}>
            <p className={styles.label}>
              About Us Page Text <b>(required)</b>{" "}
            </p>{" "}
            <p className={styles.text_description}>
              Text shown when the users clicks on the “About Us” menu point in
              the settlement portal.
            </p>
            <TextfieldInput
              label="Enter custom text"
              multiline
              rows={10}
              variant="outlined"
              id={"about_us_text"}
              name={"about_us_text"}
              value={settlementDetails.about_us_text}
              onChange={handleInputChange}
              required
            />
            <div className={styles.confirm_btn_container}>
              <Button
                className={styles.confirm_btn}
                onClick={() => handleSubmitAboutUsText()}
              >
                Save
              </Button>
            </div>
          </div>
          <div hidden={selectedTab !== 3}>
            <p className={styles.label_faq}>FAQ Page Text</p>
            <div>
              {faqList.length !== 0 ? (
                <div>
                  {faqList.map((faq, i) => (
                    <div key={i}>
                      <div className={styles.faq_container}>
                        <div className={styles.faq_input_container}>
                          <TextfieldInput
                            label="Question"
                            multiline
                            variant="outlined"
                            name="question"
                            value={faq.question}
                            onChange={(e) => handleFaqInputChange(e, i)}
                          />
                          <br></br>
                          <TextfieldInput
                            label="Answer"
                            multiline
                            rows={3}
                            variant="outlined"
                            name="answer"
                            value={faq.answer}
                            onChange={(e) => handleFaqInputChange(e, i)}
                          />
                        </div>
                        <DeleteIcon
                          onClick={() => handleFaqRemoveClick(i)}
                          className={styles.delete_icon}
                        >
                          Remove
                        </DeleteIcon>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className={styles.text_description}>
                  You do not have any FAQs defined at present and the FAQ
                  section will not be shown in your settlement portal. Add your
                  first question and answer pair to create an FAQ section in the
                  settlement portal.
                </p>
              )}

              <div className={styles.add_faq_btn_container}>
                <Button
                  className={styles.add_faq_btn}
                  onClick={handleFaqAddClick}
                >
                  <AddIcon />
                  Add new question & answer
                </Button>
              </div>
            </div>
            <div className={styles.confirm_btn_container}>
              <Button
                className={styles.confirm_btn}
                onClick={() => handleSubmitFaqs()}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettlementPortalTexts;
