import { subscriptionConstants } from '@_constants/subscription.constants';
import { subscriptionService } from '@_services/subscription.service';

export const subscriptionActions = {
  getSubscriptionConfig,
};

function getSubscriptionConfig() {
  return (dispatch) => {
    dispatch(request());
    subscriptionService.getSubscriptionConfig().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: subscriptionConstants.SUBSCRIPTION_GET_REQUEST };
  }
  function success(data) {
    return { type: subscriptionConstants.SUBSCRIPTION_GET_SUCCESS, data };
  }
  function failure(error) {
    return { type: subscriptionConstants.SUBSCRIPTION_GET_FAILURE, error };
  }
}
