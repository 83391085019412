import React, { useState, useEffect } from "react";
import styles from "./InboundEmailSetup.module.css";
import { Button } from "@material-ui/core";
import InboundEmailTestPopup from "@_components/popups/InboundEmailTestPopup/InboundEmailTestPopup";
import { emailSetupActions } from "@_actions/email_setup.actions";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataFromJwt } from "@_utils/helpers";

const InboundEmailSetup = () => {
  const jwtData = getUserDataFromJwt();
  const dispatch = useDispatch();

  const emailSetupReducer = useSelector((state) => state.emailSetupReducer);
  const [inboundEmailTestPopupOpen, setInboundEmailTestPopup] = useState(false);
  const [inboundEmailActivationStatus, setInboundEmailActivationStatus] =
    useState("");

  useEffect(() => {
    setInboundEmailActivationStatus(
      emailSetupReducer.status.INBOUND_EMAIL_ACTIVATION
    );
  }, [
    emailSetupReducer.status.INBOUND_EMAIL_ACTIVATION,
    emailSetupReducer.email_setup.inbound_emails_active,
  ]);

  const sendTestInboundEmail = () => {
    setInboundEmailTestPopup(true);
    dispatch(emailSetupActions.activateInboundEmailSetup());
  };

  const handleClosePopup = () => {
    setInboundEmailTestPopup(false);
  };
  return (
    <div>
      <div className={styles.inbound_text_container}>
        <div className={styles.header_row}>
          <h2 className={styles.header}>Inbound emails</h2>
          <p>
            Status:{" "}
            {emailSetupReducer.email_setup.inbound_emails_active ? (
              <span style={{ color: "green" }}>Active</span>
            ) : (
              <span style={{ color: "red" }}>Inactive</span>
            )}
          </p>
        </div>
        <div className={styles.inbound_text}>
          <p>
            In order to see incoming emails in the Fair Licensing portal, you
            have to set up an email redirection in your email account to the
            following address: {jwtData.customer_id}@mailfwd.fairlicensing.com
          </p>
          <p>
            <a
              href="https://knowledgebase.fairlicensing.com/how-do-i-setup-inbound-emailing-with-gmail"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to set up email redirection in gmail?
            </a>
          </p>
          <p>
            <a
              href="https://knowledgebase.fairlicensing.com/how-do-i-set-up-inbound-emailing-with-office365"
              target="_blank"
              rel="noopener noreferrer"
            >
              How to set up email redirection in Office365?{" "}
            </a>
          </p>
        </div>
      </div>
      <div className={styles.btn_container}>
        <Button className={styles.activate_btn} onClick={sendTestInboundEmail}>
          {emailSetupReducer.email_setup.inbound_emails_active
            ? "SEND TEST EMAIL"
            : "ACTIVATE"}
        </Button>
      </div>
      <InboundEmailTestPopup
        open={inboundEmailTestPopupOpen}
        onClose={handleClosePopup}
        status={inboundEmailActivationStatus}
      />
    </div>
  );
};

export default InboundEmailSetup;
