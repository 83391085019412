import React from 'react';
import CasesIcon from '@material-ui/icons/ViewList';
import ValidIcon from '@material-ui/icons/Copyright';
import PaidIcon from '@material-ui/icons/Euro';
import ActiveIcon from '@material-ui/icons/Send';
import CanceledIcon from '@material-ui/icons/Close';
import StandbyIcon from '@material-ui/icons/Snooze';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const Config = {
  CASES: {
    key: 'CASES',
    name: 'Cases',
    icon: <CasesIcon />,
  },
  STANDBY: {
    key: 'STANDBY',
    name: 'Standby',
    icon: <StandbyIcon />,
  },
  ACTIVE: {
    key: 'ACTIVE',
    name: 'Active',
    icon: <ActiveIcon />,
  },
  PAYMENT_PROCESSING: {
    key: 'PAYMENT_PROCESSING',
    name: 'Payment Processing',
    icon: <AccessTimeIcon />,
  },
  PAID: {
    key: 'PAID',
    name: 'Paid',
    icon: <PaidIcon />,
  },
  VALID_LICENSE: {
    key: 'VALID_LICENSE',
    name: 'Valid License',
    icon: <ValidIcon />,
  },
  CANCELLED: {
    key: 'CANCELLED',
    name: 'Cancelled',
    icon: <CanceledIcon />,
  },
};

export default Config;
