import { emailsConstants } from "@_constants/emails.constants";
import { customerService } from "@_services/customer.service";

export const emailsActions = {
  loadEmails,
  updateEmailProperty,
  deleteEmail,
};

function loadEmails() {
  return (dispatch) => {
    dispatch(request());
    customerService
      .getInboundMails({
        outbound: false,
        inbound: false,
        unread: false,
        unmapped: true,
      })
      .then((emails) => {
        dispatch(load(emails));
        dispatch(success());
      })
      .catch((error) => {
        console.error(error);
        dispatch(failure(error.toString()));
      });
  };
}

function load(emails) {
  return { type: emailsConstants.LOAD_EMAILS, emails };
}
function request() {
  return { type: emailsConstants.LOAD_EMAILS_REQUEST };
}
function success(data) {
  return { type: emailsConstants.LOAD_EMAILS_SUCCESS };
}
function failure(error) {
  return { type: emailsConstants.LOAD_EMAILS_FAILURE, error };
}

function updateEmailProperty(id, property_name, value) {
  return { type: emailsConstants.UPDATE_EMAIL, id, property_name, value };
}

function deleteEmail(emailId) {
  return { type: emailsConstants.DELETE_EMAIL, emailId };
}
