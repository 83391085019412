import React from 'react';
import styles from './PrivacyPolicy.module.css';

function PrivacyPolicy() {
  return (
    <div className={styles.privacy_policy}>
      <div className={styles.privacy_policy_header}>Privacy Policy</div>
      <h1>
        Information on the Processing of Personal Data by the Operator of the
        Case Management Portal
      </h1>
      <p>
        For information on how any data that you enter in the Case Management
        Portal is processed, please refer to the operator who provides you with
        the access.
      </p>
      <br />
      <h1>
        Privacy Policy for the Processing of Personal Data by PIXRAY on the Case
        Management Portal
      </h1>
      <p>
        PIXRAY GmbH, Eisenhartstraße 4, 14469 Potsdam (“PIXRAY”, “we”) hosts
        this website and software as a service (the “Site”) for its operator.
        When you provide personal information over the Case Management Portal,
        the sole Controller of your personal information is the operator and
        PIXRAY will only act as a processor on behalf of the operator.
      </p>
      <p>
        However, for some purposes that are connected with the hosting, PIXRAY
        acts as a controller of its own. With this privacy policy, PIXRAY
        informs you about the personal data it collects and processes in this
        regard. Thereby, PIXRAY also fulfils its obligation to inform you
        pursuant to Article 13 General Data Protection Regulation (GDPR).
      </p>
      <br />
      <h1>
        <span>I. </span>
        <span>Identity of the Controller </span>
      </h1>
      <p>
        PIXRAY GmbH
        <br />
        Eisenhartstraße 4<br />
        14469 Potsdam
      </p>
      <p>
        Telephone: +49 331 288 380 00
        <br />
        Telefax: +49 331 288 380 99
        <br />
        Email: <a href="mailto:gdpr@pixray.com">gdpr@pixray.com</a>
      </p>
      <br />
      <h1>
        <span>II. </span>
        <span> Data Protection Officer</span>
      </h1>
      <p>
        We have appointed a data protection officer for our company.
        <br />
        Herr Benjamin Jankovic
        <br />
        Eisenhartstraße 4<br />
        14469 Potsdam
        <br />
        Telephone: +49 331 288 380 00
        <br />
        Email:{' '}
        <a href="mailto:benjamin.jankovic@pixray.com">
          benjamin.jankovic@pixray.com
        </a>
        <br />
      </p>
      <br />
      <h1>
        III. Purposes of Processing, its Legal Basis and the Storage Period
      </h1>
      <p>
        Generally, we do not store personal data while you use the Site with the
        exception that our webserver registers all connections to the Site
        automatically and collects the following technical information about
        your visit:
      </p>
      <p>
        <span>
          -
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>IP address</span>
      </p>
      <p>
        <span>
          -
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>Name of the files accessed</span>
      </p>
      <p>
        <span>
          -
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>Information about the transmission</span>
      </p>
      <p>
        <span>
          -
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>Date and time of the connection</span>
      </p>
      <p>
        <span>
          -
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>Amount of data transmitted</span>
      </p>
      <p>
        <span>
          -
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span>Operating system and</span>
      </p>
      <p>
        <span>
          -
          <span>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        <span> Web browser/ user agent</span>
      </p>
      <p>
        We process this data to establish a connection to your device over the
        Internet. We store the aforementioned data in log files in order to
        ensure the security and integrity of our IT systems. The respective
        purposes of the processing also constitute our legitimate interests we
        pursue with it (Art. 6 par. 1 lit. f) GDPR). We retain our log files for
        14 days and delete them thereafter.
      </p>
      <br />
      <h1>
        <span>IV. </span>
        <span>Recipients and transfers to third countries</span>
      </h1>
      <p>
        If we are not able to provide services ourselves, we use external
        service providers. These service providers are primarily providers of IT
        services, such as our hosting provider, e-mail provider or
        telecommunications provider. If not specifically mentioned elsewhere in
        this privacy policy, we do not transfer your data to third countries.
      </p>
      <br />
      <h1>
        <span>V. </span>
        <span>Rights of the data subject</span>
      </h1>
      <p>
        If the respective requirements are met, the GDPR grants you certain
        rights as a data subject.
      </p>
      <p>
        <b>Art. 15 GDPR – Right of access:</b> You shall have the right to
        obtain from us confirmation as to whether or not personal data
        concerning you are being processed, and, where that is the case, access
        to the personal data and certain information.
      </p>
      <br />
      <p>
        <b>Art. 16 GDPR – Right to rectification:</b> You shall have the right
        to obtain from us without undue delay the rectification of inaccurate
        personal data concerning you. Taking into account the purposes of the
        processing, you shall have the right to have incomplete personal data
        completed, including by means of providing a supplementary statement.
      </p>
      <br />
      <p>
        <b>Art. 17 GDPR – Right to erasure:</b> You shall have the right to
        obtain from us the erasure of personal data concerning you without undue
        delay.
      </p>
      <p>
        <br />
        <b>Art. 18 GDPR – Right to restriction of processing:</b> You shall have
        the right to obtain from us the restriction of processing.
      </p>
      <br />
      <p>
        <b>Art. 20 GDPR – Right to data portability: </b> You shall have the
        right to receive the personal data concerning you, which you have
        provided to us, in a structured, commonly used and machine-readable
        format and you shall have the right to transmit those data to another
        controller without hindrance from us. You shall also have the right to
        have the personal data transmitted directly from us to another
        controller, where technically feasible.
      </p>
      <br />
      <p>
        <b>
          Art. 77 GDPR – Right to lodge a complaint with a supervisory
          authority:{' '}
        </b>
        Without prejudice to any other administrative or judicial remedy, you
        shall have the right to lodge a complaint with a supervisory authority,
        in particular in the Member State of your habitual residence, place of
        work or place of the alleged infringement if you consider that the
        processing of personal data relating to you infringes the GDPR.
      </p>
      <br />
      <h1>
        <span>VI. </span>
        <span>Especially right to object and withdrawal of consent</span>
      </h1>
      <p>
        <b>Art. 21 GDPR – Right to Object:</b> You shall have the right to
        object, on grounds relating to your particular situation, at any time to
        processing of personal data concerning you, which is based on legitimate
        interests or for the performance of a task carried out in the public
        interest or in the exercise of official authority vested in the
        controller In such case, we shall no longer process the personal data
        unless we demonstrate compelling legitimate grounds for the processing,
        which override your interests, rights and freedoms or where the
        processing is necessary for the establishment, exercise or defence of
        legal claims.
      </p>
      <br />
      <p>
        Where personal data are processed for direct marketing purposes, you
        shall have the right to object at any time for such marketing, which
        includes profiling to the extent that it is related to such direct
        marketing.
      </p>
      <br />
      <p>
        If you wish to object to any processing of data, you may send us an
        email to one of our aforementioned email addresses.
      </p>
      <br />
      <p>
        <b>Art. 7 par. 3 GDPR – Withdrawal of Consent:</b> If you have given us
        your consent, you have the right to withdraw your consent at any time.
        In case of withdrawal, all processing based on your consent before your
        withdrawal will remain lawful.
      </p>
      <br />
      <h1>
        <span>VII. </span>
        <span>Obligation to provide us with personal data</span>
      </h1>
      <p>
        You have no statutory or contractual obligation to provide us with any
        personal data. However, we may not be able to provide you with our
        services if you decide not to do so.
      </p>
      <br />
      <h1>
        <span>VIII. </span>
        <span>
          Existence of automated individual decision-making, including profiling
        </span>
      </h1>
      <p>
        We do not use automated individual decision-making, including profiling
        pursuant to Art. 22 GDPR, which produces legal effects concerning you or
        similarly significantly affects you.
      </p>
      <br />
      <br />
      <p>Potsdam, March 2022</p>
    </div>
  );
}

export default PrivacyPolicy;
