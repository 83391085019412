import React from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";

function InfoLicensePopup(props) {
  function actionClicked() {
    console.log("confirm clicked");
  }

  return (
    <DialogPopup
      title="License Info"
      open={props.open}
      onClose={() => props.close()}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          actionClicked();
        }
      }}
    >
      <div>License Info Popup Content</div>
      <div className="action_container">
        <Button className="btn_cancel" onClick={() => props.close()}>
          Cancel
        </Button>
        <Button className="btn_confirm" onClick={actionClicked}>
          Confirm
        </Button>
      </div>
    </DialogPopup>
  );
}

export default InfoLicensePopup;
