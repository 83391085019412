import { sidebarConstants } from "@_constants/sidebar.constants";
import caseStates from "@_configs/caseStates.config";

const initialState = {
  status: "DASHBOARD",
  optionalFilter: null,
  numbers: {},
  selections: {},
  hideCaseList: false,
};
Object.keys(caseStates).map((state, i) => {
  initialState.numbers[state] = "--";
  initialState.selections[state] = null;
  return initialState;
});

export function sidebarReducer(state = initialState, action) {
  switch (action.type) {
    case sidebarConstants.UPDATE_STATUS:
      state = {
        ...state,
        status: action.status,
        optionalFilter: action.optionalFilter,
      };
      break;

    case sidebarConstants.INIT_NUMBERS:
      const numbers = Object.assign(state.numbers, action.numbers);
      state = { ...state, numbers: numbers };
      break;

    case sidebarConstants.UPDATE_SELECTIONS:
      state = {
        ...state,
        selections: { ...state.selections, [state.status]: action.id },
      };
      break;
    case sidebarConstants.RESET_FILTER:
      state = {
        ...state,
        optionalFilter: null,
      };
      break;
    default:
      break;
  }

  return state;
}
