import Config from "@_configs/api.config";
import { authHeader, handleResponse } from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const userService = {
  getUsers,
  addUser,
  deleteUser,
  activateUser,
  deactivateUser,
  getSignup,
  updateSignup,
  resetPassword,
  resetName,
  updateUser,
  sendPasswordLink,
  getPasswordLink,
  getSignature,
  newSignature,
  newPassword,
  getOnboardingStatus,
};

function getUsers() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/list`, requestOptions).then(
    handleResponse
  );
}

function addUser(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user`, requestOptions).then(handleResponse);
}

function deleteUser(id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/${id}`, requestOptions).then(
    handleResponse
  );
}

function activateUser(id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/${id}/activate`, requestOptions).then(
    handleResponse
  );
}

function deactivateUser(id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/${id}/deactivate`, requestOptions).then(
    handleResponse
  );
}

function getSignup(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/signup/${id}`, requestOptions).then(
    handleResponse
  );
}

function updateSignup(id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/signup/${id}`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // user.email = email;
      localStorage.setItem("user", JSON.stringify(user));
      return user;
    });
}

function resetPassword(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/password`, requestOptions).then(
    handleResponse
  );
}

function resetName(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/name`, requestOptions).then(
    handleResponse
  );
}

function updateUser(id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/${id}`, requestOptions).then(
    handleResponse
  );
}

// forgot password?
function sendPasswordLink(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/password_reset`, requestOptions).then(
    handleResponse
  );
}

function getPasswordLink(password_reset_id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/user/password_reset/${password_reset_id}`,
    requestOptions
  ).then(handleResponse);
}

function getSignature() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(Config.API_ROOT + `/user/email_signature`, requestOptions).then(
    handleResponse
  );
}

function newSignature(signature) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(signature),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + `/user/email_signature`, requestOptions).then(
    handleResponse
  );
}

function newPassword(password_reset_id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/user/password_reset/${password_reset_id}`,
    requestOptions
  ).then(handleResponse);
}

function getOnboardingStatus() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/user/onboarding/status`,
    requestOptions
  ).then(handleResponse);
}
