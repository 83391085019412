import { dashboardConstants } from "@_constants/dashboard.constants";

const initialState = {
  settings: {
    filter: {
      user: " ",
    },
    available_kpis: [],
    selected_kpis: [],
    available_charts: [],
    selected_charts: [],
  },
  kpis: [],
  chartData: [],
  loading: false,
  message: "",
  status: {
    SETTINGS_GET: "",
    SETTINGS_SET: "",
    KPIS_GET: "",
    TIME_CHART_GET: "",
  },
};

export function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    // DASHBOARD_CLEAR_STATUS_MESSAGE
    case dashboardConstants.DASHBOARD_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, [action.stateName]: "" },
      };
      break;

    // Settings GET REQUEST
    case dashboardConstants.DASHBOARD_SETTINGS_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",

        status: { ...state.status, SETTINGS_GET: "request" },
      };
      break;
    case dashboardConstants.DASHBOARD_SETTINGS_GET_SUCCESS:
      state = {
        ...state,
        settings: action.data,
        loading: false,
        message: "",
        status: { ...state.status, SETTINGS_GET: "success" },
      };

      break;
    case dashboardConstants.DASHBOARD_SETTINGS_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,

        status: { ...state.status, SETTINGS_GET: action.error },
      };
      break;

    // Settings SET REQUEST
    case dashboardConstants.DASHBOARD_SETTINGS_SET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",

        status: { ...state.status, SETTINGS_SET: "request" },
      };
      break;
    case dashboardConstants.DASHBOARD_SETTINGS_SET_SUCCESS:
      state = {
        ...state,
        settings: {
          ...state.settings,
          selected_kpis: action.settings.kpis,
          selected_charts: action.settings.charts,
          filter: action.settings.filter,
        },
        loading: false,
        message: "",
        status: { ...state.status, SETTINGS_SET: "success" },
      };
      break;
    case dashboardConstants.DASHBOARD_SETTINGS_SET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,

        status: { ...state.status, SETTINGS_SET: action.error },
      };
      break;

    // KPIS GET REQUEST
    case dashboardConstants.DASHBOARD_KPIS_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",

        status: { ...state.status, KPIS_GET: "request" },
      };
      break;
    case dashboardConstants.DASHBOARD_KPIS_GET_SUCCESS:
      state = {
        ...state,
        kpis: action.data,
        loading: false,
        message: "",
        status: { ...state.status, KPIS_GET: "success" },
      };
      break;
    case dashboardConstants.DASHBOARD_KPIS_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,

        status: { ...state.status, KPIS_GET: action.error },
      };
      break;

    // TIME DASHBOARD GET REQUES

    case dashboardConstants.DASHBOARD_TIME_CHART_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",

        status: { ...state.status, TIME_CHART_GET: "request" },
      };
      break;
    case dashboardConstants.DASHBOARD_TIME_CHART_GET_SUCCESS:
      const chartIndex =
        action.index ||
        state.settings.selected_charts.findIndex(
          (c) => c.class === action.chartClass
        );
      let copyChartData = [...state.chartData];
      if (chartIndex > -1) copyChartData.splice(chartIndex, 1, action.data);
      else copyChartData.push(action.data);
      state = {
        ...state,
        chartData: copyChartData,
        loading: false,
        message: "",
        status: { ...state.status, TIME_CHART_GET: "success" },
      };
      break;
    case dashboardConstants.DASHBOARD_TIME_CHART_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,

        status: { ...state.status, TIME_CHART_GET: action.error },
      };
      break;

    default:
      break;
  }
  return state;
}
