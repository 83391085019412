import React from "react";
import PersonIcon from "@material-ui/icons/Person";
import CanceledIcon from "@material-ui/icons/Close";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

const Config = {
  ACTIVE: {
    key: "ACTIVE",
    name: "Active",
    icon: <PersonIcon />,
  },
  DEACTIVATED: {
    key: "DEACTIVATED",
    name: "Deactivated",
    icon: <CanceledIcon />,
  },
  INVITATION_PENDING: {
    key: "INVITATION_PENDING",
    name: "Invitation Pending",
    icon: <AccessTimeIcon />,
  },
};

export default Config;
