import React from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Cancel from "@material-ui/icons/Cancel";
import styles from "./InfoPopup.module.css";

let closeImg = {
  cursor: "pointer",
  float: "right",
  marginTop: "5px",
  width: "20px",
};
const InfoPopup = (props) => {
  const { title } = props;
  return (
    <DialogPopup
      title={
        <span>
          {title}
          <Cancel style={closeImg} onClick={() => props.close()} />
        </span>
      }
      open={props.open}
      onClose={() => props.close()}
    >
      <div className={styles.container}>{props.children}</div>
    </DialogPopup>
  );
};

export default InfoPopup;
