import React, { useState } from "react";
import { formatDatetime } from "@_utils/helpers";
import ChatIcon from "@material-ui/icons/Chat";
import LicensePopup from "@_components/popups/LicensePopup/LicensePopup";
import LicenseIcon from "@material-ui/icons/Policy";
import styles from "./LicenseLogItem.module.css";

function LicenseLogItem(props) {
  const [openLicensePopup, setOpenLicensePopup] = useState(false);

  const { data, caseId, summary, resumeAutomation } = props;

  return (
    <div>
      <div className={styles.root} onClick={() => setOpenLicensePopup(true)}>
        <div className={styles.icon_container}>
          <div className={styles.title_icon}>
            <LicenseIcon />
          </div>
        </div>
        <div className={styles.left_container}>
          <div className={styles.title_container}>
            <div className={styles.title_text}>License Uploaded</div>
          </div>
          <div className={styles.date}>
            {formatDatetime(summary.date_uploaded, "medium")}
          </div>
        </div>
        <div className={styles.center_container}>
          <div className={styles.center_secondary}>Total</div>
          <div className={styles.center_primary}>{summary.total} License</div>
        </div>
        <div className={styles.center_container}>
          <div className={styles.center_secondary}>Status</div>
          <div className={styles.center_primary}>Pending Review</div>
        </div>
        <div
          className={
            !summary.note
              ? styles.right_container_disabled
              : styles.right_container
          }
        >
          <ChatIcon className={styles.chat_icon} />
        </div>
      </div>
      <LicensePopup
        open={openLicensePopup}
        close={() => setOpenLicensePopup(false)}
        resumeAutomation={resumeAutomation}
        data={data}
        caseId={caseId}
      />
    </div>
  );
}

export default LicenseLogItem;
