import React, { useEffect, useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { caseActions } from "@_actions/case.actions";
import styles from "./TemplatePopup.module.css";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { sidebarActions } from "@_actions/sidebar.actions";
import { MenuItem } from "@material-ui/core";

function TemplatePopup(props) {
  const { caseId } = props;
  const dispatch = useDispatch();
  const [caseTemplateSelection, setCaseTemplateSelection] = useState(null);
  const caseTemplateReducer = useSelector((state) => state.caseTemplateReducer);

  const handleNavigateToTemplates = () => {
    dispatch(sidebarActions.updateStatus("CASETEMPLATES"));
  };

  function actionClicked() {
    dispatch(
      caseActions.applyCaseTemplate(
        caseId,
        caseTemplateReducer.caseTemplates.find(
          (x) => x.id === caseTemplateSelection
        )
      )
    );
  }

  useEffect(() => {
    if (
      caseTemplateReducer.caseTemplates &&
      caseTemplateReducer.caseTemplates.length === 1
    )
      setCaseTemplateSelection(caseTemplateReducer.caseTemplates[0].id);
  }, [caseTemplateReducer.caseTemplates]);

  return (
    <DialogPopup
      title={
        caseTemplateReducer.caseTemplates &&
        caseTemplateReducer.caseTemplates.length > 0
          ? "Choose Template"
          : "Create Template?"
      }
      open={props.open}
      onClose={() => props.close()}
    >
      <div className={styles.content_container}>
        <div className={styles.case_template_title}>
          {caseTemplateReducer.caseTemplates &&
          caseTemplateReducer.caseTemplates.length > 0 ? (
            <>
              <div className={styles.content_container}>
                <TextfieldInput
                  select
                  label="Case Template"
                  id="template"
                  name="template"
                  defaultValue={caseTemplateSelection}
                  onChange={(event) =>
                    setCaseTemplateSelection(event.target.value)
                  }
                >
                  {caseTemplateReducer &&
                    caseTemplateReducer.caseTemplates.map((template, index) => (
                      <MenuItem key={template.id} value={template.id}>
                        {template.name}
                      </MenuItem>
                    ))}
                </TextfieldInput>
              </div>
            </>
          ) : (
            <>
              You didn't create a case template yet. Case templates can be used
              to auto-fill cases with default values. Would you like to create
              one?
            </>
          )}
        </div>
      </div>
      <div className="action_container">
        {caseTemplateReducer.caseTemplates &&
        caseTemplateReducer.caseTemplates.length > 0 ? (
          <>
            {" "}
            <Button className="btn_cancel" onClick={() => props.close()}>
              Close
            </Button>
            <Button
              className={`btn_confirm ${
                !caseTemplateSelection ? "btn_disabled" : ""
              }`}
              disabled={!caseTemplateSelection}
              onClick={actionClicked}
            >
              Apply
            </Button>
          </>
        ) : (
          <>
            {" "}
            <Button className="btn_cancel" onClick={() => props.close()}>
              Not now
            </Button>
            <Button
              className="btn_confirm"
              style={{ width: 162 }}
              onClick={handleNavigateToTemplates}
            >
              Create a Template
            </Button>
          </>
        )}
      </div>
    </DialogPopup>
  );
}

export default TemplatePopup;
