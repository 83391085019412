import { languageConstants } from '../@_constants/language.constants';

const initialState = {
  languages: [],
  translations: [],
  status: {
    LANGUAGE_GET: '',

    TRANSLATION_GET: '',

    TRANSLATION_POST: '',

    TRANSLATION_DELETE: '',

    LOGIN_TEXT_PUT: '',

    CASE_OVERVIEW_TEXT_PUT: '',

    ABOUT_US_TEXT_PUT: '',

    FAQ_PUT: '',

    DEFAULT_TRANSLATION_PUT: '',

    ACTIVATE_TRANSLATION_PUT: '',

    DEACTIVATE_TRANSLATION_PUT: '',
  },
};

export function languageReducer(state = initialState, action) {
  switch (action.type) {
    // LANGUAGE_CLEAR_STATUS_MESSAGE
    case languageConstants.LANGUAGE_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        status: { ...state.status, [action.stateName]: '' },
      };
      break;

    // LANGUAGE GET REQUEST
    case languageConstants.LANGUAGE_GET_REQUEST:
      state = {
        ...state,
        status: { ...state.status, LANGUAGE_GET: 'request' },
      };
      break;
    case languageConstants.LANGUAGE_GET_SUCCESS:
      state = {
        ...state,
        languages: action.data,
        status: { ...state.status, LANGUAGE_GET: 'success' },
      };
      break;
    case languageConstants.LANGUAGE_GET_FAILURE:
      state = {
        ...state,
        status: { ...state.status, LANGUAGE_GET: action.error },
      };
      break;

    // TRANSLATIONS GET REQUEST
    case languageConstants.TRANSLATION_GET_REQUEST:
      state = {
        ...state,
        status: { ...state.status, TRANSLATION_GET: 'request' },
      };
      break;
    case languageConstants.TRANSLATION_GET_SUCCESS:
      state = {
        ...state,
        translations: action.data,
        status: { ...state.status, TRANSLATION_GET: 'success' },
      };
      break;
    case languageConstants.TRANSLATION_GET_FAILURE:
      state = {
        ...state,
        status: { ...state.status, TRANSLATION_GET: action.error },
      };
      break;

    // TRANSLATION POST REQUEST
    case languageConstants.TRANSLATION_POST_REQUEST:
      state = {
        ...state,
        status: { ...state.status, TRANSLATION_POST: 'request' },
      };
      break;
    case languageConstants.TRANSLATION_POST_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, TRANSLATION_POST: 'success' },
      };

      break;
    case languageConstants.TRANSLATION_POST_FAILURE:
      state = {
        ...state,
        status: { ...state.status, TRANSLATION_POST: action.error },
      };
      break;

    // TRANSLATION DELETE REQUEST
    case languageConstants.TRANSLATION_DELETE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, TRANSLATION_DELETE: 'request' },
      };
      break;
    case languageConstants.TRANSLATION_DELETE_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, TRANSLATION_DELETE: 'success' },
      };

      break;
    case languageConstants.TRANSLATION_DELETE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, TRANSLATION_DELETE: action.error },
      };
      break;

    // LOGIN TEXT PUT REQUEST
    case languageConstants.LOGIN_TEXT_UPDATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, LOGIN_TEXT_PUT: 'request' },
      };
      break;
    case languageConstants.LOGIN_TEXT_UPDATE_SUCCESS:
      state = {
        ...state,
        login_text: action.data,
        status: { ...state.status, LOGIN_TEXT_PUT: 'success' },
      };

      break;
    case languageConstants.LOGIN_TEXT_UPDATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, LOGIN_TEXT_PUT: action.error },
      };
      break;

    // CASE OVERVIEW TEXT PUT REQUEST
    case languageConstants.CASE_OVERVIEW_TEXT_UPDATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, CASE_OVERVIEW_TEXT_PUT: 'request' },
      };
      break;
    case languageConstants.CASE_OVERVIEW_TEXT_UPDATE_SUCCESS:
      state = {
        ...state,
        case_overview_text: action.data,
        status: { ...state.status, CASE_OVERVIEW_TEXT_PUT: 'success' },
      };

      break;
    case languageConstants.CASE_OVERVIEW_TEXT_UPDATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, CASE_OVERVIEW_TEXT_PUT: action.error },
      };
      break;

    // ABOUT US TEXT PUT REQUEST
    case languageConstants.ABOUT_US_TEXT_UPDATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, ABOUT_US_TEXT_PUT: 'request' },
      };
      break;
    case languageConstants.ABOUT_US_TEXT_UPDATE_SUCCESS:
      state = {
        ...state,
        about_us_text: action.data,
        status: { ...state.status, ABOUT_US_TEXT_PUT: 'success' },
      };

      break;
    case languageConstants.ABOUT_US_TEXT_UPDATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, ABOUT_US_TEXT_PUT: action.error },
      };
      break;

    // FAQ's TEXT PUT REQUEST
    case languageConstants.FAQ_UPDATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, FAQ_PUT: 'request' },
      };
      break;
    case languageConstants.FAQ_UPDATE_SUCCESS:
      state = {
        ...state,
        faqs: action.data,
        status: { ...state.status, FAQ_PUT: 'success' },
      };

      break;
    case languageConstants.FAQ_UPDATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, FAQ_PUT: action.error },
      };
      break;

    // DEFAULT TRANSLATION PUT REQUEST
    case languageConstants.DEFAULT_TRANSLATION_UPDATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, DEFAULT_TRANSLATION_PUT: 'request' },
      };
      break;
    case languageConstants.DEFAULT_TRANSLATION_UPDATE_SUCCESS:
      state = {
        ...state,
        default_translation: action.data,
        status: { ...state.status, DEFAULT_TRANSLATION_PUT: 'success' },
      };

      break;
    case languageConstants.DEFAULT_TRANSLATION_UPDATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, DEFAULT_TRANSLATION_PUT: action.error },
      };
      break;

    // ACTIVATE TRANSLATION PUT REQUEST
    case languageConstants.ACTIVATE_TRANSLATION_UPDATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, ACTIVATE_TRANSLATION_PUT: 'request' },
      };
      break;
    case languageConstants.ACTIVATE_TRANSLATION_UPDATE_SUCCESS:
      state = {
        ...state,
        active_translations: action.data,
        status: { ...state.status, ACTIVATE_TRANSLATION_PUT: 'success' },
      };

      break;
    case languageConstants.ACTIVATE_TRANSLATION_UPDATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, ACTIVATE_TRANSLATION_PUT: action.error },
      };
      break;

    // DEACTIVATE TRANSLATION PUT REQUEST
    case languageConstants.DEACTIVATE_TRANSLATION_UPDATE_REQUEST:
      state = {
        ...state,
        status: { ...state.status, DEACTIVATE_TRANSLATION_PUT: 'request' },
      };
      break;
    case languageConstants.DEACTIVATE_TRANSLATION_UPDATE_SUCCESS:
      state = {
        ...state,
        active_translations: action.data,
        status: { ...state.status, DEACTIVATE_TRANSLATION_PUT: 'success' },
      };

      break;
    case languageConstants.DEACTIVATE_TRANSLATION_UPDATE_FAILURE:
      state = {
        ...state,
        status: { ...state.status, DEACTIVATE_TRANSLATION_PUT: action.error },
      };
      break;

    // SELECT TRANSLATION
    case languageConstants.SELECT_TRANSLATION:
      state = {
        ...state,
        selected_translation: action.data,
      };
      break;

    default:
      break;
  }
  return state;
}
