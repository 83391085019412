import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";

export const authService = {
  login,
  getStripe,
  getTos,
  updateTos,
  sendRenewalToken,
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(Config.API_ROOT + "/login", requestOptions)
    .then(handleResponse)
    .then((user) => {
      user.email = email;
      localStorage.clear();
      localStorage.setItem("user", JSON.stringify(user));
      if (localStorage.getItem("invalid")) localStorage.removeItem("invalid");
      return user;
    });
}

async function sendRenewalToken(email, renew_token) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, renew_token }),
  };
  const response = await fetch(
    Config.API_ROOT + "/login/renew",
    requestOptions
  );
  const user = await handleResponse(response);
  user.email = email;
  localStorage.setItem("user", JSON.stringify(user));
  return user;
}

function getStripe() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      "/customer/" +
      getUserDataFromJwt().customer_id +
      "/stripe_onboarding_status?redirect_url=" +
      window.location.href,
    requestOptions
  )
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function getTos() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  // const endpoint = "./data/tos_get.json"
  const endpoint =
    Config.API_ROOT +
    "/customer/" +
    getUserDataFromJwt().customer_id +
    "/terms_of_service";
  return fetch(endpoint, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

function updateTos(version) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  // const endpoint = "./data/tos_get.json"
  const endpoint =
    Config.API_ROOT +
    "/customer/" +
    getUserDataFromJwt().customer_id +
    "/terms_of_service/accept?tos_version=" +
    version;
  return fetch(endpoint, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}
