import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { combineReducers } from "redux";
import { authReducer } from "@_reducers/auth.reducer";
import { alertReducer } from "@_reducers/alert.reducer";
import { casesReducer } from "@_reducers/cases.reducer";
import { caseReducer } from "@_reducers/case.reducer";
import { sidebarReducer } from "@_reducers/sidebar.reducer";
import { addressReducer } from "@_reducers/address.reducer";
import { settlementReducer } from "@_reducers/settlement_portal.reducer";
import { fileReducer } from "@_reducers/files_settlement_portal.reducer";
import { emailFlowReducer } from "@_reducers/email_flows.reducer";
import { templateReducer } from "@_reducers/template.reducer";
import { languageReducer } from "@_reducers/language.reducer";
import { emailSetupReducer } from "@_reducers/email_setup.reducer";
import { caseTemplateReducer } from "@_reducers/case_templates.reducer";
import { userReducer } from "@_reducers/user.reducer";
import { customerReducer } from "@_reducers/customer.reducer";
import { subscriptionReducer } from "@_reducers/subscription.reducer";
import { caseActivityReducer } from "@_reducers/caseActivity.reducer";
import { emailsReducer } from "@_reducers/emails.reducer";
import config from "../config.json";
import { invoiceTemplateReducer } from "@_reducers/invoiceTemplate.reducer";
import { dashboardReducer } from "@_reducers/dashboard.reducer";

const appReducer = combineReducers({
  subscriptionReducer,
  authReducer,
  alertReducer,
  casesReducer,
  caseReducer,
  sidebarReducer,
  addressReducer,
  settlementReducer,
  fileReducer,
  emailFlowReducer,
  templateReducer,
  languageReducer,
  emailSetupReducer,
  caseTemplateReducer,
  userReducer,
  customerReducer,
  caseActivityReducer,
  emailsReducer,
  invoiceTemplateReducer,
  dashboardReducer,
});

// to set to initial state on logout
const rootReducer = (state, action) => {
  if (action.type === "USERS_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const Store = createStore(
  rootReducer,
  config.debug_mode
    ? compose(applyMiddleware(thunkMiddleware), composeWithDevTools())
    : compose(applyMiddleware(thunkMiddleware))
);
