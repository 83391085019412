import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const addressService = {
  getAddress,
  updateAddress,
};

function getAddress() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/customer/${getUserDataFromJwt().customer_id}/address`,
    requestOptions
  ).then(handleResponse);
}

function updateAddress(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/customer/${getUserDataFromJwt().customer_id}/address`,
    requestOptions
  ).then(handleResponse);
}
