import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const settlementService = {
  getSettlement,
  // updateSettlement,
  updatePortalAddress,
  updateLegalContact,
};

function getSettlement() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/config/settlement_portal`,
    requestOptions
  ).then(handleResponse);
}

function updatePortalAddress(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/portal_address`,
    requestOptions
  ).then(handleResponse);
}

function updateLegalContact(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/legal_and_contact`,
    requestOptions
  ).then(handleResponse);
}
