import { caseTemplatesConstants } from "../@_constants/caseTemplates.constants";

const initialState = {
  caseTemplates: [],
  loading: false,
  message: "",
  selectedCaseTemplate: null,
  status: {
    CASETEMPLATE_ADD: "",
    CASETEMPLATE_DELETE: "",
    CASETEMPLATE_UPDATE: "",
    CASETEMPLATE_GET: "",
    CASETEMPLATE_CLEAR: "",
  },
};

export function caseTemplateReducer(state = initialState, action) {
  switch (action.type) {
    // EMAIL_CLEAR_STATUS_MESSAGE
    case caseTemplatesConstants.CASETEMPLATE_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, [action.stateName]: "" },
      };
      break;

    // TEMPLATES GET REQUEST
    case caseTemplatesConstants.CASETEMPLATE_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASETEMPLATE_GET: "request" },
      };
      break;
    case caseTemplatesConstants.CASETEMPLATE_GET_SUCCESS:
      state = {
        ...state,
        caseTemplates: action.data,
        loading: false,
        message: "",
        status: { ...state.status, CASETEMPLATE_GET: "success" },
      };
      break;
    case caseTemplatesConstants.CASETEMPLATE_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,

        status: { ...state.status, CASETEMPLATE_GET: action.error },
      };
      break;

    // Case Template add REQUEST
    case caseTemplatesConstants.CASETEMPLATE_ADD_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASETEMPLATE_ADD: "request" },
      };
      break;
    case caseTemplatesConstants.CASETEMPLATE_ADD_SUCCESS:
      state = {
        ...state,
        loading: false,
        emails: action.data,
        selectedCaseTemplate: action.data.case_template_id,
        message: "",
        status: { ...state.status, CASETEMPLATE_ADD: "success" },
      };
      break;
    case caseTemplatesConstants.CASETEMPLATE_ADD_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASETEMPLATE_ADD: action.error },
      };
      break;

    // Case Template update REQUEST
    case caseTemplatesConstants.CASETEMPLATE_UPDATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASETEMPLATE_UPDATE: "request" },
      };
      break;
    case caseTemplatesConstants.CASETEMPLATE_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        emails: action.data,
        message: "",
        status: { ...state.status, CASETEMPLATE_UPDATE: "success" },
      };

      break;
    case caseTemplatesConstants.CASETEMPLATE_UPDATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASETEMPLATE_UPDATE: action.error },
      };
      break;

    // Case Template DELETE REQUEST
    case caseTemplatesConstants.CASETEMPLATE_DELETE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASETEMPLATE_DELETE: "request" },
      };
      break;
    case caseTemplatesConstants.CASETEMPLATE_DELETE_SUCCESS:
      state = {
        ...state,
        loading: false,
        emails: "",
        message: "",
        caseTemplates: [
          state.caseTemplates.filter((x) => x.id !== action.data.id),
        ],
        status: { ...state.status, CASETEMPLATE_DELETE: "success" },
      };

      if (action.data.id === state.selectedCaseTemplate) {
        state = { ...state, selectedCaseTemplate: null };
      }

      break;
    case caseTemplatesConstants.CASETEMPLATE_DELETE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASETEMPLATE_DELETE: action.error },
      };
      break;

    // selected email
    case caseTemplatesConstants.SELECT_CASETEMPLATE:
      state = {
        ...state,
        selectedCaseTemplate: action.id || null,
        message: "",
      };
      break;

    default:
      break;
  }
  return state;
}
