import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateRangeIcon from "@material-ui/icons/DateRange";
import styles from "./DateInput.module.css";

const CustomDateField = withStyles({
  root: {
    "& .MuiButtonBase-root": {
      width: 0,
    },
    "& .MuiOutlinedInput-adornedStart":{
      height:35
    },
    "& label.Mui-focused": {
      color: "var(--color-theme-10)",
    },
    "& label.Mui-disabled": {
      color: "#888",
    },
    "& .Mui-disabled:hover": {},
    "& label": {
      color: "var(--color-theme-10)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "var(--color-theme-10)",
    },
    "& .MuiOutlinedInput-multiline": {
      backgroundColor: "var(--color-theme-gray)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "var(--color-theme-10)",
      },
      "&:hover fieldset": {
        borderColor: "var(--color-theme-10)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "var(--color-theme-10)",
      },
      "& .MuiInputBase-input": {
        color: "var(--color-theme-10)",
      },
      "& .MuiInputBase-input::placeholder": {
        color: "var(--color-theme-10)",
      },
      "& .MuiSelect-icon": {
        color: "var(--color-theme-10)",
      },
    },
  },
})(KeyboardDatePicker);

function DateInputSmall(props) {
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <CustomDateField
        {...props}
        autoOk
        fullWidth={true}
        variant="inline"
        inputVariant="outlined"
        margin="normal"
        format="DD/MM/yyyy"
        mask="__/__/____"
        placeholder="__/__/____"
        InputAdornmentProps={{ position: "start" }}
        InputLabelProps={{ shrink: true }}
        helperText={""}
        error={null}
        autocomplete={false}
        keyboardIcon={<DateRangeIcon className={styles.calendar_icon} />}
      >
        {props.children}
      </CustomDateField>
    </MuiPickersUtilsProvider>
  );
}

export default DateInputSmall;
