import Config from '@_configs/api.config';
import { authHeader, handleResponse } from '@_utils/helpers';
import fetch_helper from "@_utils/fetch_helper"
const fetch = fetch_helper.fetch;

export const subscriptionService = {
  getSubscriptionConfig,
};

function getSubscriptionConfig() {
  const requestOptions = {
    method: 'GET',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
  };

  return fetch(
    Config.API_ROOT + `/customer/subscription_config`,
    requestOptions
  ).then(handleResponse);
}
