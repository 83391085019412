export const caseTemplatesConstants = {
  CASETEMPLATE_GET_REQUEST: 'CASETEMPLATE_GET_REQUEST',
  CASETEMPLATE_GET_SUCCESS: 'CASETEMPLATE_GET_SUCCESS',
  CASETEMPLATE_GET_FAILURE: 'CASETEMPLATE_GET_FAILURE',

  CASETEMPLATE_ADD_REQUEST: 'CASETEMPLATE_ADD_REQUEST',
  CASETEMPLATE_ADD_SUCCESS: 'CASETEMPLATE_ADD_SUCCESS',
  CASETEMPLATE_ADD_FAILURE: 'CASETEMPLATE_ADD_FAILURE',

  CASETEMPLATE_UPDATE_REQUEST: 'CASETEMPLATE_UPDATE_REQUEST',
  CASETEMPLATE_UPDATE_SUCCESS: 'CASETEMPLATE_UPDATE_SUCCESS',
  CASETEMPLATE_UPDATE_FAILURE: 'CASETEMPLATE_UPDATE_FAILURE',

  CASETEMPLATE_DELETE_REQUEST: 'CASETEMPLATE_DELETE_REQUEST',
  CASETEMPLATE_DELETE_SUCCESS: 'CASETEMPLATE_DELETE_SUCCESS',
  CASETEMPLATE_DELETE_FAILURE: 'CASETEMPLATE_DELETE_FAILURE',

  SELECT_CASETEMPLATE: 'SELECT_CASETEMPLATE',

  // clear status
  CASETEMPLATE_CLEAR_STATUS_MESSAGE: 'CASETEMPLATE_CLEAR_STATUS_MESSAGE',
};
