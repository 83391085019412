import React, { useState } from "react";
import _ from "lodash";
import styles from "./InboundEmails.module.css";
import InboundEmailsDetail from "@_components/InboundEmailsDetail/InboundEmailsDetail";
import { useSelector } from "react-redux";
import Scrollbar from "react-perfect-scrollbar-z";
import "scrollbar.css";

function InboundEmails() {
  const [selectedMail, setSelectedMail] = useState(null);
  const emails = useSelector((state) => state.emailsReducer.emails);

  /**
   * Unselects mail and reloads list
   */
  const onCloseDetails = () => {
    setSelectedMail(null);
  };

  const renderMailList = () => {
    var list = [];
    _.forOwn(emails, (email) => {
      list.push(
        <div
          key={email.id}
          onClick={() => {
            setSelectedMail(email);
          }}
          className={
            selectedMail?.id === email?.id
              ? styles.list_item_container_selected
              : styles.list_item_container
          }
        >
          <div className={styles.upper_row}>
            {email.case_id === null ? <></> : <>{email.case_id}</>}
          </div>
          {email.unread === 1 && <div className={styles.unread_marker}></div>}
          <div
            className={email.subject ? styles.lower_row : styles.lower_row_gray}
          >
            {email.subject ? email.subject : "No Subject set"}
          </div>
        </div>
      );
    });

    return list;
  };

  return (
    <div className={styles.root}>
      <div className={styles.list_container}>
        <Scrollbar maxHeight="calc(100vh - 148px)">{renderMailList()}</Scrollbar>
      </div>
      <div className={styles.details_container}>
        <InboundEmailsDetail
          email={selectedMail}
          onCloseDetails={onCloseDetails}
        ></InboundEmailsDetail>
      </div>
    </div>
  );
}
export default InboundEmails;
