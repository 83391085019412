import React, { useEffect, useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { Button, MenuItem } from "@material-ui/core";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import styles from "./KpisSettingsPopup.module.css";
import { useSelector } from "react-redux";

const KpisSettingsPopup = (props) => {
  const dashboardReducer = useSelector((state) => state.dashboardReducer);
  const currentDashboardSettings = dashboardReducer.settings;
  const { newDashboardSettings, index } = props;
  const currentKpi = newDashboardSettings.kpis[index];

  const [kpiName, setkpiName] = useState(null);
  const [kpiThreshold, setKpiThreshold] = useState(null);

  useEffect(() => {
    setkpiName(currentKpi?.name || null);
    if (currentKpi?.threshold) setKpiThreshold(currentKpi?.threshold || null);
  }, [currentKpi]);

  const setKpiValues = (event) => {
    setKpiThreshold(null);
    setkpiName(event.target.value);
  };

  const editKpi = () => {
    props.editKpi(index, kpiName, kpiThreshold);
    closePopup();
  };

  const removeKpi = () => {
    props.removeSelectedKpi(index);
    closePopup();
  };

  const closePopup = () => {
    setkpiName(null);
    setKpiThreshold(null);
    props.close();
  };
  return (
    <DialogPopup title="Select KPI" open={props.open} onClose={closePopup}>
      {" "}
      <div>
        <div className={styles.select_kpi}>
          <div className={styles.text_field}>
            <TextfieldInput
              label="KPI"
              select
              name="name"
              size="small"
              className={styles.select}
              value={kpiName || ""}
              onChange={(event) => setKpiValues(event)}
            >
              {currentDashboardSettings.available_kpis.map((kpi, i) => (
                <MenuItem
                  key={i}
                  style={{
                    display: `${
                      newDashboardSettings.kpis.some(
                        (selectedKpi) =>
                          selectedKpi?.name === kpi?.name &&
                          selectedKpi?.name !== currentKpi?.name
                      )
                        ? "none"
                        : ""
                    }`,
                  }}
                  value={kpi?.name || ""}
                >
                  {kpi?.displayed_name}
                </MenuItem>
              ))}
            </TextfieldInput>
          </div>
          <div>
            <Button className="btn_cancel" onClick={removeKpi}>
              Remove KPI
            </Button>
          </div>
        </div>
        <h4>Highlight, when value greater than: </h4>
        <div className={styles.text_field}>
          <TextfieldInput
            type="number"
            onChange={(event) => {
              event.persist();
              setKpiThreshold(event.target.value);
            }}
            name="threshold"
            size="small"
            value={Number(kpiThreshold) || ""}
            disabled={!kpiName}
          />
        </div>
        <div className="action_container">
          <>
            <Button className="btn_cancel" onClick={closePopup}>
              Cancel
            </Button>
            <Button className="btn_confirm" onClick={editKpi}>
              Confirm
            </Button>
          </>
        </div>
      </div>
    </DialogPopup>
  );
};

export default KpisSettingsPopup;
