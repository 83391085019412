import React, { useState } from "react";

import {
  formatDatetime,
  getCurrencySign,
  toAmountNotation,
} from "@_utils/helpers";
import SvgIcon from "@material-ui/core/SvgIcon";
import styles from "./PaymentInfoSection.module.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

function PaymentInfoSection(props) {
  const { data } = props;

  const [infoPopupOpen, setInfoPopupOpen] = useState(false);

  return (
    <div className={styles.root}>
      <div
        onClick={() => setInfoPopupOpen(true)}
        className={styles.payment_info_container}
      >
        <div className={styles.status_column}>
          <div className={styles.icon}>
            <SvgIcon
              children={
                <path d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-2 0H3V6h14v8zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm13 0v11c0 1.1-.9 2-2 2H4v-2h17V7h2z"></path>
              }
            ></SvgIcon>
          </div>
          <div className={styles.status_info}>
            <table>
              <tbody>
                <tr>
                  <td className={styles.big_n_bold}>Payment received</td>
                </tr>
                <tr>
                  <td>
                    {formatDatetime(data.payment.date_received, "medium")}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.amount_column}>
          <table>
            <tbody>
              <tr>
                <td>Total</td>
              </tr>
              <tr>
                <td className={styles.big_n_bold}>
                  {getCurrencySign(data.claim_details.currency) +
                    " " +
                    toAmountNotation(data.payment.amount_received)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={styles.type_column}>
          <table>
            <tbody>
              <tr>
                <td className={styles.bold}>Payment method</td>
              </tr>
              <tr>
                <td
                  style={{ textTransform: "capitalize" }}
                  className={styles.big_n_bold}
                >
                  {data.payment.payment_method_type}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <Dialog open={infoPopupOpen} onClose={() => setInfoPopupOpen(false)}>
        <div className={styles.dialog_title}>BILLING ADDRESS</div>
        {data.billing_address && (
          <DialogContent>
            <table>
              <tr>
                <td>
                  <div className={styles.small_title}>Company</div>
                  <div className={styles.big_value}>
                    {data.billing_address.company}
                  </div>
                </td>
                <td>
                  <div className={styles.small_title}>VAT ID</div>
                  <div className={styles.big_value}>
                    {data.billing_address.vat_id}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className={styles.small_title}>Name</div>
                  <div className={styles.big_value}>
                    {data.billing_address.name}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className={styles.small_title}>Street Address</div>
                  <div className={styles.big_value}>
                    {data.billing_address.street_address}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <table style={{ marginLeft: -3 }}>
                    <tr>
                      <td>
                        <div className={styles.small_title}>Postal Code</div>
                        <div className={styles.big_value}>
                          {data.billing_address.postal_code + " "}
                        </div>
                      </td>
                      <td>
                        <div className={styles.small_title}>City</div>
                        <div className={styles.big_value}>
                          {data.billing_address.city}
                        </div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className={styles.small_title}>Country</div>
                  <div className={styles.big_value}>
                    {data.billing_address.country}
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <div className={styles.small_title}>Email</div>
                  <div className={styles.big_value}>
                    {data.billing_address.email}
                  </div>
                </td>
              </tr>
            </table>
          </DialogContent>
        )}
        <DialogActions className={styles.dialog_button}>
          <Button
            onClick={() => setInfoPopupOpen(false)}
            variant="outlined"
            title="OK"
            color="primary"
            className={styles.popup_ok_button}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PaymentInfoSection;
