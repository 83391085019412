import React from "react";
import CallbackIcon from "@material-ui/icons/PhoneCallback";
import CallIcon from "@material-ui/icons/Call";
import NoteIcon from "@material-ui/icons/Chat";
import { EmailOutlined } from "@material-ui/icons";

const Config = {
  "CALLBACK":{
    title: "Callback Requested",
    icon: <CallbackIcon/>
  },
  "CALL":{
    title: "Call",
    icon: <CallIcon/>
  },
  "EMAIL":{
    title: "Email",
    icon: <EmailOutlined/>
  },
  "NOTE":{
    title: "Note",
    icon: <NoteIcon/>
  }
}

export default Config;