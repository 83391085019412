import React, { useEffect, useContext, useState } from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector, ReactReduxContext } from "react-redux";
import { caseActions } from "@_actions/case.actions";
import { casesActions } from "@_actions/cases.actions";
import { formatDatetime, getCurrencySign, stateError } from "@_utils/helpers";
import styles from "./ActivateCasePopup.module.css";
import NoImage from "@_assets/images/noimage.png";

function ActivateCasePopup(props) {
  const { store } = useContext(ReactReduxContext);
  const { data, caseId } = props;
  const caseReducer = useSelector((state) => {
    return state.caseReducer;
  });
  const dispatch = useDispatch();
  const [selectedTax, setSelectedTax] = useState("");

  const actionClicked = () => {
    dispatch(caseActions.updateCaseStateActivate(caseId));
    setTimeout(() => {
      const currState =
        store.getState().caseReducer.status.CASE_UPDATE_STATE_ACTIVATE;

      if (currState === "success") {
        dispatch(casesActions.getCases());
        dispatch(casesActions.getCasesCount());
        dispatch(caseActions.getCaseWithChargeDetails(caseId, data.overview));
        props.close();
      }
    }, 200);
  };

  const closePopup = () => {
    dispatch(caseActions.clearStateMessage("CASE_UPDATE_STATE_ACTIVATE"));
    props.close();
  };

  useEffect(() => {
    if (caseReducer.status.CASE_UPDATE_STATE_ACTIVATE === "success") {
      props.close();
      dispatch(casesActions.getCases());
      dispatch(casesActions.getCasesCount());
      dispatch(caseActions.getCaseWithChargeDetails(caseId, data.overview));
      dispatch(caseActions.clearStateMessage("CASE_UPDATE_STATE_ACTIVATE"));
    }
  });

  useEffect(() => {
    if (
      data.charge_details &&
      data.charge_details.indirect_tax_type === "SALES_TAX"
    ) {
      setSelectedTax("Sales Tax");
    } else if (
      data.charge_details &&
      data.charge_details.indirect_tax_type === "VAT"
    ) {
      setSelectedTax("VAT");
    } else if (
      data.charge_details &&
      data.charge_details.indirect_tax_type === "GST"
    ) {
      setSelectedTax("GST");
    } else setSelectedTax("");
  }, [data.charge_details]);

  return (
    <DialogPopup
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          actionClicked();
        }
      }}
      title="Activate Case"
      open={props.open}
      onClose={() => props.close()}
    >
      <div className={styles.content_container}>
        <div className={styles.disclaimer}>
          By activating the case you confirm that the licensing offer below is
          correct. After activation you will not be able to change it without
          sending it back to the Standby state.
        </div>

        <div className={styles.findings_container}>
          {data.findings &&
            data.findings.map((finding, index) => (
              <div key={index}>
                {finding.license && finding.license.state === "VALID" ? (
                  <></>
                ) : (
                  <div className={styles.finding_container} key={index}>
                    <div className={styles.finging_image_container}>
                      <img
                        className={styles.finging_image}
                        src={
                          finding.original_image.original_image_url || NoImage
                        }
                        alt={"Finding result"}
                      />
                    </div>
                    <div className={styles.finging_details_container}>
                      <p className={styles.finding_id}>
                        {finding.original_image.filename}
                      </p>
                    </div>
                    <div className={styles.finging_price_container}>
                      <div className={styles.price_label}>License Fee</div>
                      <div className={styles.price_value}>
                        {getCurrencySign(data.claim_details.currency)}{" "}
                        {finding.price}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className={styles.total_container}>
          <div className={styles.total_label}>Net License fee</div>
          <div className={styles.total_value}>
            {getCurrencySign(data.claim_details.currency)}{" "}
            {data.charge_details.total_net_amount_findings}
          </div>
        </div>

        {data.charge_details.additional_fees_no_settlement &&
          data.charge_details.additional_fees_no_settlement.map(
            (fee, index) => (
              <div className={styles.price_row} key={index}>
                <div key={1} className={styles.price_container}>
                  <div className={styles.price_label}>Name</div>
                  <div className={styles.price_value}>{fee.name}</div>
                </div>
                <div key={2} className={styles.price_container}>
                  <div className={styles.price_label}>Percentage</div>
                  <div className={styles.price_value}>{fee.percentage} %</div>
                </div>
                <div key={3} className={styles.price_container}>
                  <div className={styles.price_label}>Additional Fee</div>
                  <div className={styles.price_value}>
                    {getCurrencySign(data.claim_details.currency)} {fee.amount}
                  </div>
                </div>
                <br />
              </div>
            )
          )}
        <hr className={styles.activate_hr} />
        <div className={styles.price_row}>
          <div key={4} className={styles.price_container}>
            {data.charge_details.indirect_tax_type === "NO_INDIRECT_TAX" ? (
              <div className={styles.price_label}>No Indirect Tax</div>
            ) : (
              <>
                <div className={styles.price_label}>{selectedTax}</div>
                <div className={styles.price_value}>
                  {(data.charge_details.indirect_tax_settlement &&
                    data.charge_details.indirect_tax_settlement.percentage) ||
                    (data.charge_details.indirect_tax_no_settlement &&
                      data.charge_details.indirect_tax_no_settlement
                        .percentage)}{" "}
                  %
                </div>
              </>
            )}
          </div>
          <div key={5} className={styles.price_container}>
            <div className={styles.price_label}>Claim Opponent Country</div>
            <div className={styles.price_value}>{data.country}</div>
          </div>
          <div key={6} className={styles.price_container}>
            {data.charge_details.indirect_tax_type !== "NO_INDIRECT_TAX" && (
              <>
                {" "}
                <div className={styles.price_label}>{selectedTax} Amount</div>
                <div className={styles.price_value}>
                  {getCurrencySign(data.claim_details.currency)}{" "}
                  {(data.charge_details.indirect_tax_settlement &&
                    data.charge_details.indirect_tax_settlement.amount) ||
                    (data.charge_details.indirect_tax_no_settlement &&
                      data.charge_details.indirect_tax_no_settlement.amount)}
                </div>
              </>
            )}
          </div>
        </div>
        <hr className={styles.activate_hr} />
        <div className={styles.price_row}>
          <div key={7} className={styles.price_container}>
            <div className={styles.price_label}>Discount</div>
            <div className={styles.price_value}>
              {(data.charge_details.settlement_offer &&
                data.charge_details.settlement_offer.settlement_percentage) ||
                "--"}{" "}
              %
            </div>
          </div>
          <div key={8} className={styles.price_container}>
            <div className={styles.price_label}>Discount Deadline</div>
            <div className={styles.price_value}>
              {data.charge_details.settlement_offer &&
                formatDatetime(
                  data.charge_details.settlement_offer.settlement_date,
                  "short"
                )}{" "}
            </div>
          </div>
        </div>

        <div className={styles.total_container}>
          <div className={styles.total_label}>
            Gross incl.
            {data.charge_details.indirect_tax_type !== "NO_INDIRECT_TAX" &&
              selectedTax + " & "}
            Fees
          </div>
          <div className={styles.total_value}>
            {getCurrencySign(data.claim_details.currency)}{" "}
            {data.charge_details.final_amount_gross_no_settlement}
          </div>
          <br />
          {data.charge_details.final_amount_gross_settlement && (
            <>
              <div className={styles.total_label}>
                Discounted gross incl.
                {data.charge_details.indirect_tax_type !== "NO_INDIRECT_TAX" &&
                  selectedTax + " & "}
                Fees
              </div>

              <div className={styles.total_value}>
                {getCurrencySign(data.claim_details.currency)}{" "}
                {data.charge_details.final_amount_gross_settlement || "--"}
              </div>
            </>
          )}
        </div>
      </div>

      {stateError(caseReducer.status.CASE_UPDATE_STATE_ACTIVATE) && (
        <div className={styles.error_container}>
          <div className={styles.error_text}>
            {stateError(caseReducer.status.CASE_UPDATE_STATE_ACTIVATE)}
          </div>
        </div>
      )}

      <div className="action_container">
        <Button className="btn_cancel" onClick={closePopup}>
          Cancel
        </Button>
        <Button className="btn_confirm" onClick={actionClicked}>
          Activate Case
        </Button>
      </div>
    </DialogPopup>
  );
}

export default ActivateCasePopup;
