import React from 'react';
import styles from './PasswordChanged.module.css';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

function PasswordChanged(props) {
  return (
    <div className={styles.loginForm}>
      <div className={styles.loginFormHeader}>CHANGE PASSWORD</div>
      <div className={styles.link_sent_content}>
        <CheckCircleIcon className={styles.success_icon} />
        <p>You have successfully changed your password. </p>
        <div>
          <button
            className={styles.go_to_login_button}
            onClick={() => props.handleCancel()}
          >
            GO TO LOGIN PAGE
          </button>
        </div>
      </div>
    </div>
  );
}

export default PasswordChanged;
