import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const caseTemplatesService = {
  getCaseTemplates,
  addCaseTemplate,
  updateCaseTemplate,
  deleteCaseTemplate,
};

function getCaseTemplates() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/case_templates`,
    requestOptions
  ).then(handleResponse);
}

function addCaseTemplate(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/case_template`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseTemplate(case_template_id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/case_template/${case_template_id}`,
    requestOptions
  ).then(handleResponse);
}

function deleteCaseTemplate(case_template_id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/case_template/${case_template_id}`,
    requestOptions
  ).then(handleResponse);
}
