import { fileConstants } from "@_constants/files_settlement_portal.constants";
import { fileService } from "@_services/files_settlement_portal.service";

export const fileActions = {
  updateFiles,
  deleteFiles,
  clearStatusMessage,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return { type: fileConstants.FILE_CLEAR_STATUS_MESSAGE, stateName };
  }
}
function updateFiles(type, data) {
  return (dispatch) => {
    dispatch(request());
    fileService.updateFiles(type, data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: fileConstants.FILES_PUT_REQUEST };
  }
  function success(data) {
    return { type: fileConstants.FILES_PUT_SUCCESS, data };
  }
  function failure(error) {
    return { type: fileConstants.FILES_PUT_FAILURE, error };
  }
}

function deleteFiles(type, data) {
  return (dispatch) => {
    dispatch(request());
    fileService.deleteFiles(type, { image_name: data }).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: fileConstants.FILES_DELETE_REQUEST };
  }
  function success(data) {
    return { type: fileConstants.FILES_DELETE_SUCCESS, data };
  }
  function failure(error) {
    return { type: fileConstants.FILES_DELETE_FAILURE, error };
  }
}
