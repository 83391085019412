import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { casesActions } from "@_actions/cases.actions";
import { caseActions } from "@_actions/case.actions";
import { stateError, validateDomain } from "@_utils/helpers";
import styles from "./AddCasePopup.module.css";
import { sidebarActions } from "@_actions/sidebar.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { caseConstants } from "@_constants/case.constants";
import config from "@_configs/api.config"

const defaultState = {
  host: "",
  source_case_id: "",
};

function AddCasePopup(props) {
  const casesReducer = useSelector((state) => {
    return state.casesReducer;
  });
  const [inputs, setInputs] = useState(defaultState);
  const [isClicked, setIsClicked] = useState(false);
  const [additonInProgress, setAdditionInProgress] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const dispatch = useDispatch();

  const closePopup = useCallback(() => {
    props.close();
  }, [props]);

  function actionClicked() {
    setIsClicked(true);
    setConfirmClicked(true);
    if (inputs.host.trim() === "" || !validateDomain(inputs.host.trim())) {
      alert("Please use a valid domain name e.g. domain.com");
      return;
    }
    setAdditionInProgress(true);
    dispatch(casesActions.addCases(inputs));
  }

  useEffect(() => {
    const currState = casesReducer.status.CASES_ADD;
    if (currState === "success") {
      dispatch(sidebarActions.updateStatus(caseConstants.CASE_STATE_STANDBY));
      var searchObject = {
        sort: {
          key: "date_state_updated",
          ascending: false,
        },
        filter: {
          unread_emails: false,
          unchecked_licenses: false,
          overdue_reminders: false,
          failed_emails: false,
          paused_scheduled_emails: false,
          action_required: false,
          idle: false,
          state: "STANDBY",
        },
        paging: {
          page: 1,
          page_size: config.PAGE_SIZE,
        },
      };
      dispatch(casesActions.getCases(searchObject, true));
      dispatch(casesActions.getCasesCount());
      dispatch(casesActions.clearStateMessage("CASES_ADD"));
    }
    // eslint-disable-next-line
  }, [casesReducer.status.CASES_ADD]);

  // check for getCases to be succeeded
  useEffect(() => {
    if (additonInProgress && casesReducer.status.CASES_GET === "success") {
      setAdditionInProgress(false);
      const newCaseId = localStorage.getItem("case_uuid");
      dispatch(casesActions.clearStateMessage("CASES_GET"));

      dispatch(
        caseActions.getCaseWithChargeDetails(
          newCaseId,
          casesReducer.cases[newCaseId]
        )
      );
      dispatch(sidebarActions.updateSelections(newCaseId));

      setInputs(defaultState);
      closePopup();
    }
  }, [
    additonInProgress,
    casesReducer.cases,
    casesReducer.status.CASES_GET,
    closePopup,
    dispatch,
  ]);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  useEffect(() => {
    if (confirmClicked) {
      setTimeout(() => {
        setConfirmClicked(false);
      }, [1000]);
    }
  }, [confirmClicked]);

  return (
    <DialogPopup title="Add New Case" open={props.open} onClose={closePopup}>
      <div className={styles.content_container}>
        <TextfieldInput
          label="Domain"
          id={"host"}
          name={"host"}
          placeholder={"e.g. domain.com"}
          value={inputs.host}
          onChange={handleInputChange}
          error={isClicked && (!inputs.host || inputs.host === "")}
          required
        />
        <TextfieldInput
          label="Case Reference Number"
          id={"source_case_id"}
          name={"source_case_id"}
          value={inputs.source_case_id}
          onChange={handleInputChange}
        />
      </div>
      {stateError(casesReducer.status.CASES_ADD) && (
        <div className={styles.error_container}>
          <div className={styles.error_text}>
            {stateError(casesReducer.status.CASES_ADD)}
          </div>
        </div>
      )}
      <div className="action_container">
        {casesReducer.status.CASES_ADD === "request" && <CircularProgress />}
        {casesReducer.status.CASES_ADD !== "request" && (
          <>
            <Button className="btn_cancel" onClick={closePopup}>
              Cancel
            </Button>
            <Button
              className="btn_confirm"
              onClick={!confirmClicked && actionClicked}
            >
              Confirm
            </Button>
          </>
        )}
      </div>
    </DialogPopup>
  );
}

export default AddCasePopup;
