import React from 'react';
import styles from './ExpiredSignup.module.css';
import WarningIcon from '@material-ui/icons/Warning';

function ExpiredSignup() {
  return (
    <div className={styles.container}>
      <div className={styles.signup_header}>SIGNUP</div>
      <div className={styles.warning_container}>
        <WarningIcon className={styles.warning_icon} />
        <div className={styles.copy}>Your signup link has expired.</div>
        <div className={styles.copy}>
          Please contact your admin to request a new signup link.
        </div>
      </div>
    </div>
  );
}

export default ExpiredSignup;
