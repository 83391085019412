import React from 'react';
import styles from './Header.module.css';
import Logo from '@_assets/images/logo-fair-licensing-black_20201019_001.png';
import Profile from '@_components/Profile/Profile.js';

function Header({ loggedIn }) {
  return (
    <div className={styles.root}>
      <div className={styles.left_container}>
        <div className={styles.logo}>
          <img src={Logo} alt="Fair Licensing Logo" className={styles.logo} />
        </div>
      </div>

      {loggedIn && <Profile />}
    </div>
  );
}

export default Header;
