import React from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import styles from "./StripeInfoPopup.module.css";
import { getUserDataFromJwt } from "@_utils/helpers";

import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";
import { Button } from "@material-ui/core";

let closeImg = {
  cursor: "pointer",
  float: "right",
  marginTop: "5px",
  width: "20px",
};

function StripeInfoPopup({ close, open, stripeUrl }) {
  const jwtData = getUserDataFromJwt();

  return (
    <DialogPopup
      title={
        <span>
          <CloseIcon style={closeImg} onClick={close} />
        </span>
      }
      open={open}
      onClose={close}
    >
      <div className={styles.content_container}>
        <div className={styles.info_container}>
          <InfoIcon className={styles.info_icon} />
          <p className={styles.info_icon_text}> Action Required!</p>
        </div>
        <div className={styles.text_container}>
          <p>
            <b>Please connect your Stripe account.</b> <br></br>In order to
            activate a case, your Stripe account must be connected to Fair
            Licensing.
          </p>
          {jwtData.user_role === "AGENT" && (
            <p>
              <b>
                {" "}
                If you don't have access to the payment setup please contact an
                admin.
              </b>
            </p>
          )}
        </div>
        {jwtData.user_role === "ADMIN" && (
          <Button className={styles.confirm_btn}>
            <a href={stripeUrl} target="_blank" rel="noopener noreferrer">
              Connect to stripe
            </a>
          </Button>
        )}
      </div>
    </DialogPopup>
  );
}

export default StripeInfoPopup;
