import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const languageService = {
  getLanguages,
  getTranslations,
  addTranslation,
  deleteTranslation,
  updateLoginText,
  updateCaseText,
  updateAboutUsText,
  updateFaqs,
  updateDefaultTranslation,
  updateActiveTranslation,
  updateDeactivateTranslation,
};

function getLanguages() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/customer/config/settlement_portal/languages`,
    requestOptions
  ).then(handleResponse);
}

function getTranslations() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/translations`,
    requestOptions
  ).then(handleResponse);
}

function addTranslation(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/translations`,
    requestOptions
  ).then(handleResponse);
}

function deleteTranslation(data) {
  const requestOptions = {
    method: "DELETE",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/translations`,
    requestOptions
  ).then(handleResponse);
}

function updateLoginText(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/texts/login`,
    requestOptions
  ).then(handleResponse);
}

function updateCaseText(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/texts/case_overview`,
    requestOptions
  ).then(handleResponse);
}

function updateAboutUsText(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/texts/about_us`,
    requestOptions
  ).then(handleResponse);
}
function updateFaqs(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/texts/faq`,
    requestOptions
  ).then(handleResponse);
}

function updateDefaultTranslation(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/translation/default`,
    requestOptions
  ).then(handleResponse);
}

function updateActiveTranslation(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/translation/activate`,
    requestOptions
  ).then(handleResponse);
}
function updateDeactivateTranslation(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/settlement_portal/translation/deactivate`,
    requestOptions
  ).then(handleResponse);
}
