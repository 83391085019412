import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const emailSetupService = {
  getEmailSetup,
  updateOutboundEmailSetup,
  activateOutboundEmailSetup,
  validateOutboundEmailSetup,
  activateInboundEmailSetup,
};

function getEmailSetup() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/outbound_email`,
    requestOptions
  ).then(handleResponse);
}

function updateOutboundEmailSetup(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/outbound_email/smtp`,
    requestOptions
  ).then(handleResponse);
}

function activateOutboundEmailSetup(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/outbound_email/activate`,
    requestOptions
  ).then(handleResponse);
}

function validateOutboundEmailSetup(data, type) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/outbound_email/${type}/validation`,
    requestOptions
  ).then(handleResponse);
}

function activateInboundEmailSetup() {
  const requestOptions = {
    method: "PUT",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/inbound_email/test`,
    requestOptions
  ).then(handleResponse);
}
