import React from "react";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import styles from "./ChangeEmailTemplatePopup.module.css";

function ChangeEmailTemplatePopup(props) {
  return (
    <DialogPopup open={props.open} onClose={() => props.close()}>
      <div className={styles.content_container}>{props.children}</div>
      <div className="action_container">
        <Button className="btn_cancel" onClick={() => props.close()}>
          Cancel{" "}
        </Button>
        <Button className={styles.btn_no} onClick={props.onNoClick}>
          No{" "}
        </Button>
        <Button className="btn_confirm" onClick={props.onYesClick}>
          Yes{" "}
        </Button>
      </div>
    </DialogPopup>
  );
}

export default ChangeEmailTemplatePopup;
