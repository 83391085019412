import React, { useEffect, useState } from "react";

import styles from "./AddressComponent.module.css";
import { validateEmail } from "@_utils/helpers";
import { Button, Chip, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

/**
 * @readonly
 * @enum {number}
 */
const ELEMENT_CODE = {
  TO: 1,
  CC: 2,
  BCC: 3,
};

/**
 * Callback when subject was changed.
 *
 * @callback handleSubjectChangedCallback
 * @param {string} newSubject - New Subject as string
 */

/**
 * Callback when email address field was changed
 *
 * @callback handleEmailAddressFieldChangedCallback
 * @param {string} newEmailTo - New Email(s) as string seperated by ';'
 */

/**
 *
 *
 * @param {object} props - Props object
 * @param {boolean} props.isReplyMode - Indicates that the popup was open for a reply. If true, the adresses are editable.
 * @param {boolean} props.sendEmailTag - Indecates if user has an active smtp setting to send mails.
 * @param {string} props.emailTo - String containing all emails for to field seperated by ';'
 * @param {string} props.emailCc - String contaiting all cc emails for cc field seperated by ';'
 * @param {string} props.subject - Subject of the email
 * @param {handleSubjectChangedCallback} props.handleSubjectChanged - Callback when input changed.
 * @param {handleEmailAddressFieldChangedCallback} props.handleEmailToChanged - Callback when to input has changed
 * @param {handleEmailAddressFieldChangedCallback} props.handleEmailCcChanged - Callback when cc input has changed
 * @param {handleEmailAddressFieldChangedCallback} props.handleEmailBccChanged - Callback when bcc input has changed
 * @returns
 */
function AddressComponent(props) {
  // Destructor must reset to not show, else it would keep it open
  // even if only emailCC is set
  useEffect(() => {
    return () => {
      setShowCC(false);
    };
  }, []);

  /**
   * Updates email chips because, its initialized with empty mail.
   */
  useEffect(() => {
    setEmailTo(props.emailTo);
    setEmailCc(props.emailCc);

    if (props.emailCc !== "" && props.emailCc !== undefined) {
      setShowCC(true);
    }

  }, [props.emailCc, props.emailTo]);

  /**
   * If true CC and BCC inputs are shown. This can be overwritten
   * by the fact that cc is set from email.
   */
  const [showCC, setShowCC] = useState(false);
  /**
   * key for to coomponent to empty its content
   */
  const [newToKey, setNewToKey] = useState(8);
  /**
   * key for to coomponent to empty its content
   */
  const [newCcKey, setNewCcKey] = useState(8);
  /**
   * key for to coomponent to empty its content
   */
  const [newBccKey, setNewBccKey] = useState(8);
  /**
   * String with all to email addresses
   */
  const [emailTo, setEmailTo] = useState(props.emailTo);
  /**
   * String with all to email addresses
   */
  const [emailCc, setEmailCc] = useState(props.emailCc || "");
  /**
   * String with all to email addresses
   */
  const [emailBcc, setEmailBcc] = useState(props.emailBcc || "");

  /**
   * Saving the saved input of the to field (values of the chips)
   */
  const [emailToPlaceholderInput, setEmailToPlaceholderInput] = useState("ab");

  /**
   * Saving the current input of the to field
   */
  const [emailToInputInner, setEmailToInputInner] = useState("");

  /**
   * Saving the saved input of the to field (values of the chips)
   */
  const [emailCCPlaceholderInput, setEmailCCPlaceholderInput] = useState("ab");

  /**
   * Saving the current input of the to field
   */
  const [emailCCInputInner, setEmailCCInputInner] = useState("");

  /**
   * Saving the saved input of the to field (values of the chips)
   */
  const [emailBCCPlaceholderInput, setEmailBCCPlaceholderInput] =
    useState("ab");

  /**
   * Saving the current input of the to field
   */
  const [emailBCCInputInner, setEmailBCCInputInner] = useState("");

  /**
   * Deletes a mail address from to filed. Called when the x on a chip was clicked
   * @param {*} mail
   */
  const deleteMailAddressFromField = (elementCode, mail) => {
    switch (elementCode) {
      case ELEMENT_CODE.TO:
        if (emailTo && emailTo.trim() !== "") {
          let mails = emailTo.split(";");
          setEmailAndCallback(
            elementCode,
            mails.filter((x) => x !== mail).join(";")
          );
        }
        break;
      case ELEMENT_CODE.CC:
        if (emailCc && emailCc.trim() !== "") {
          let mails = emailCc.split(";");
          setEmailAndCallback(
            elementCode,
            mails.filter((x) => x !== mail).join(";")
          );
        }
        break;
      case ELEMENT_CODE.BCC:
        if (emailBcc && emailBcc.trim() !== "") {
          let mails = emailBcc.split(";");
          setEmailAndCallback(
            elementCode,
            mails.filter((x) => x !== mail).join(";")
          );
        }
        break;
      default:
        throw new Error("invalid element code");
    }
  };

  /**
   * Renders the chips in the to autocomplete field
   *
   * @param {number} elementCode - which input element to render chips in
   *
   * @returns {JSX.Element[]} Array of jsx chips
   */
  const renderChips = (elementCode) => {
    var retval = [];

    switch (elementCode) {
      case ELEMENT_CODE.TO:
        if (emailTo && emailTo.trim() !== "") {
          let mails = emailTo.split(";");
          for (const item of mails) {
            retval.push(
              <Chip
                className={styles.mail_chip}
                onDelete={() =>
                  deleteMailAddressFromField(ELEMENT_CODE.TO, item)
                }
                label={item}
                key={item}
              />
            );
          }
        }
        break;
      case ELEMENT_CODE.CC:
        if (emailCc && emailCc.trim() !== "") {
          let mails = emailCc.split(";");
          for (const item of mails) {
            retval.push(
              <Chip
                className={styles.mail_chip}
                onDelete={() =>
                  deleteMailAddressFromField(ELEMENT_CODE.CC, item)
                }
                label={item}
                key={item}
              />
            );
          }
        }
        break;
      case ELEMENT_CODE.BCC:
        if (emailBcc && emailBcc.trim() !== "") {
          let mails = emailBcc.split(";");
          for (const item of mails) {
            retval.push(
              <Chip
                className={styles.mail_chip}
                onDelete={() =>
                  deleteMailAddressFromField(ELEMENT_CODE.BCC, item)
                }
                label={item}
                key={item}
              />
            );
          }
        }
        break;
      default:
        throw new Error("invalid element code");
    }

    return retval;
  };

  /**
   * Called on keyup on email to autocomplete field.
   * We wanner know if user hits enter to update internal state (inputs)
   * @param {number} elementCode - which element fired the event
   * @param {Event} ev
   */
  const onEmailToKeyUp = (elementCode, ev) => {
    if (ev.key === "Enter" || ev.keyCode === 13) {
      checkAndSetEmailTo(elementCode);
    }
  };

  /**
   * Called on foucsOut of the email to autocomplete.
   * We wanna check if user typed a complete mail and update internel state (inputs)
   * @param {number} elementCode - which element fired the event
   * @param {Event} ev
   */
  const onEmailToBlur = (elementCode, ev) => {
    checkAndSetEmailTo(elementCode);
  };

  /**
   * Called when to autocomplete content has changed and will
   * update the state (inputs) when a valid mail was given.
   * @returns
   */

  const checkAndSetEmailTo = (elementCode) => {
    let input = "";
    switch (elementCode) {
      case ELEMENT_CODE.TO:
        input = emailToInputInner;
        break;
      case ELEMENT_CODE.CC:
        input = emailCCInputInner;
        break;
      case ELEMENT_CODE.BCC:
        input = emailBCCInputInner;
        break;
      default:
        throw new Error("invalid element code");
    }

    if (input) input = input.trim();
    if (validateEmail(input)) {
      switch (elementCode) {
        case ELEMENT_CODE.TO:
          if (emailTo.indexOf(input.toLowerCase()) >= 0) return;
          let mails = emailTo.split(";");
          if (mails.length === 1 && mails[0] === "") mails = [];
          mails.push(input);
          setEmailAndCallback(elementCode, mails.join(";"));
          setEmailToPlaceholderInput("ab");
          setEmailToInputInner("");
          // when a mail was added by click outside of autocomplete
          // we must set a new key to clear the textbox content
          window.setTimeout(() => {
            setNewToKey(Math.random().toString());
          }, 10);
          break;
        case ELEMENT_CODE.CC:
          if (emailCc.indexOf(input.toLowerCase()) >= 0) return;
          let mailscc = emailCc.split(";");
          if (mailscc.length === 1 && mailscc[0] === "") mailscc = [];
          mailscc.push(input);
          setEmailAndCallback(elementCode, mailscc.join(";"));
          setEmailCCPlaceholderInput("ab");
          setEmailCCInputInner("");
          // when a mail was added by click outside of autocomplete
          // we must set a new key to clear the textbox content
          window.setTimeout(() => {
            setNewCcKey(Math.random().toString());
          }, 10);
          break;
        case ELEMENT_CODE.BCC:
          if (emailBcc.indexOf(input.toLowerCase()) >= 0) return;
          let mailsbcc = emailBcc.split(";");
          if (mailsbcc.length === 1 && mailsbcc[0] === "") mailsbcc = [];
          mailsbcc.push(input);
          setEmailAndCallback(elementCode, mailsbcc.join(";"));
          setEmailBCCPlaceholderInput("ab");
          setEmailBCCInputInner("");
          // when a mail was added by click outside of autocomplete
          // we must set a new key to clear the textbox content
          window.setTimeout(() => {
            setNewBccKey(Math.random().toString());
          }, 10);
          break;
        default:
          throw new Error("invalid element code");
      }
    }
  };

  /**
   * Sets the emailTo State and calls the callback for parent component
   * @param {string} to - String of all to email addresses seperated by ';'
   */
  const setEmailAndCallback = (elementCode, mail) => {
    switch (elementCode) {
      case ELEMENT_CODE.TO:
        setEmailTo(mail);
        props.handleEmailToChanged(mail);
        break;
      case ELEMENT_CODE.CC:
        setEmailCc(mail);
        props.handleEmailCcChanged(mail);
        break;
      case ELEMENT_CODE.BCC:
        setEmailBcc(mail);
        props.handleEmailBccChanged(mail);
        break;
      default:
        throw new Error("invalid element code");
    }
  };

  /**
   * Helper to decide if CC should be shown
   * @returns true if eather CC button was activated or CC/BCC is set in the email
   */
  const mustCCBeVisible = () => {
    return showCC || (props.cc && props.cc.length > 0);
  };

  return (
    <>
      <div className={styles.send_email_form}>
        <div className={styles.row_container}>
          <span className={styles.label}>To</span>
          {!props.isReplyMode ? (
            <input
              className={
                props.sendEmailTag ? styles.input_send : styles.input_copy_paste
              }
              value={emailTo}
              type="email"
              name="to"
              readOnly
              disabled
              required
            />
          ) : (
            <div className={styles.to_and_cc_button_container}>
              <Autocomplete
                multiple
                disableClearable
                id="amil-to"
                key={newToKey}
                options={[]}
                freeSolo
                className={
                  props.sendEmailTag
                    ? styles.email_to_autocomplete
                    : styles.email_to_autocomplete_cp
                }
                renderTags={() => renderChips(ELEMENT_CODE.TO)}
                value={emailToPlaceholderInput}
                onChange={(ev) => setEmailToPlaceholderInput(ev.target.value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    value={emailToInputInner}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    onChange={(ev) => setEmailToInputInner(ev.target.value)}
                    onKeyUp={(ev) => onEmailToKeyUp(ELEMENT_CODE.TO, ev)}
                    onBlur={() => onEmailToBlur(ELEMENT_CODE.TO)}
                    id="email-to-input"
                    className={
                      props.sendEmailTag
                        ? styles.input_send
                        : styles.input_copy_paste
                    }
                  />
                )}
              />
              {!mustCCBeVisible() ? (
                <div style={{ width: "170px" }}>
                  {" "}
                  <Button
                    onClick={() => {
                      setShowCC(true);
                    }}
                    className={styles.btn_default}
                  >
                    Show CC/BCC
                  </Button>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        {mustCCBeVisible() ? (
          <>
            <div className={styles.row_container}>
              <span className={styles.label}>Cc</span>
              {!props.isReplyMode ? (
                <input
                  className={
                    props.sendEmailTag
                      ? styles.input_send
                      : styles.input_copy_paste
                  }
                  value={emailCc}
                  type="email"
                  name="cc"
                  readOnly
                  disabled
                  required
                />
              ) : (
                <div className={styles.to_and_cc_button_container}>
                  <Autocomplete
                    multiple
                    disableClearable
                    id="amil-cc"
                    key={newCcKey}
                    options={[]}
                    freeSolo
                    className={
                      props.sendEmailTag
                        ? styles.email_to_autocomplete
                        : styles.email_to_autocomplete_cp
                    }
                    renderTags={() => renderChips(ELEMENT_CODE.CC)}
                    value={emailCCPlaceholderInput}
                    onChange={(ev) =>
                      setEmailCCPlaceholderInput(ev.target.value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={emailCCInputInner}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        onChange={(ev) => setEmailCCInputInner(ev.target.value)}
                        onKeyUp={(ev) => onEmailToKeyUp(ELEMENT_CODE.CC, ev)}
                        onBlur={() => onEmailToBlur(ELEMENT_CODE.CC)}
                        id="email-to-input"
                        className={
                          props.sendEmailTag
                            ? styles.input_send
                            : styles.input_copy_paste
                        }
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className={styles.row_container}>
              <span className={styles.label}>Bcc</span>
              {!props.isReplyMode ? (
                <input
                  className={
                    props.sendEmailTag
                      ? styles.input_send
                      : styles.input_copy_paste
                  }
                  value={null}
                  type="email"
                  name="bcc"
                  readOnly
                  disabled
                  required
                />
              ) : (
                <div className={styles.to_and_cc_button_container}>
                  <Autocomplete
                    multiple
                    disableClearable
                    id="amil-bcc"
                    key={newBccKey}
                    options={[]}
                    freeSolo
                    className={
                      props.sendEmailTag
                        ? styles.email_to_autocomplete
                        : styles.email_to_autocomplete_cp
                    }
                    renderTags={() => renderChips(ELEMENT_CODE.BCC)}
                    value={emailBCCPlaceholderInput}
                    onChange={(ev) =>
                      setEmailBCCPlaceholderInput(ev.target.value)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={emailBCCInputInner}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                        onChange={(ev) =>
                          setEmailBCCInputInner(ev.target.value)
                        }
                        onKeyUp={(ev) => onEmailToKeyUp(ELEMENT_CODE.BCC, ev)}
                        onBlur={() => onEmailToBlur(ELEMENT_CODE.BCC)}
                        id="email-to-input"
                        className={
                          props.sendEmailTag
                            ? styles.input_send
                            : styles.input_copy_paste
                        }
                      />
                    )}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <></>
        )}
        <div className={styles.row_container}>
          <span className={styles.label}>Subject</span>
          <input
            className={
              props.sendEmailTag ? styles.input_send : styles.input_copy_paste
            }
            autoComplete="off"
            type="text"
            name="subject"
            onChange={(e) => props.handleSubjectChanged(e.target.value)}
            value={props.subject}
            required
          />
        </div>
      </div>
    </>
  );
}

export default AddressComponent;
