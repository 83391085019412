export const fileConstants = {
  FILES_PUT_REQUEST: "FILES_PUT_REQUEST",
  FILES_PUT_SUCCESS: "FILES_PUT_SUCCESS",
  FILES_PUT_FAILURE: "FILES_PUT_FAILURE",

  FILES_DELETE_REQUEST: "FILES_DELETE_REQUEST",
  FILES_DELETE_SUCCESS: "FILES_DELETE_SUCCESS",
  FILES_DELETE_FAILURE: "FILES_DELETE_FAILURE",

  // clear status
  FILE_CLEAR_STATUS_MESSAGE: "FILE_CLEAR_STATUS_MESSAGE",
};
