import React from 'react';
import styles from './Stepper.module.css';

const Stepper = ({ stepList, currentStep }) => {
  return (
    <div className={styles.stepperContainer}>
      {stepList &&
        stepList.map((el, index) => (
          <div
            className={
              currentStep === index
                ? styles.stepContainerSelected
                : styles.stepContainer
            }
          >
            <span
              className={
                currentStep === index ? styles.dotSelected : styles.dot
              }
            >
              {index + 1}
            </span>
            <div className={currentStep === index ? styles.fontSelected : null}>
              {el}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Stepper;
