import { customerConstants } from "../@_constants/customer.constants";

const initialState = {
  verification_data: {},
  status: {
    VERIFICATION_EMAIL: "",
    CUSTOMER_VERIFICATION: "",
    PAYMENT_REPORT: "",
    PAYMENT_REPORT_DETAILS: "",
    EMAIL_CONTENT: "",
  },
};

export function customerReducer(state = initialState, action) {
  switch (action.type) {
    case customerConstants.USER_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        status: { ...state.status, [action.stateName]: "" },
      };
      break;

    // CUSTOMER VERIFICATION EMAIL POST REQUEST
    case customerConstants.CUSTOMER_VERIFICATION_EMAIL_REQUEST:
      state = {
        ...state,
        status: { ...state.status, VERIFICATION_EMAIL: "request" },
      };
      break;
    case customerConstants.CUSTOMER_VERIFICATION_EMAIL_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, VERIFICATION_EMAIL: "success" },
      };

      break;
    case customerConstants.CUSTOMER_VERIFICATION_EMAIL_FAILURE:
      state = {
        ...state,
        status: { ...state.status, VERIFICATION_EMAIL: action.error },
      };
      break;
    // CUSTOMER VERIFICATION PUT REQUEST
    case customerConstants.CUSTOMER_VERIFICATION_PUT_REQUEST:
      state = {
        ...state,
        status: { ...state.status, CUSTOMER_VERIFICATION: "request" },
      };
      break;
    case customerConstants.CUSTOMER_VERIFICATION_PUT_SUCCESS:
      state = {
        ...state,
        verification_data: action.data,
        status: { ...state.status, CUSTOMER_VERIFICATION: "success" },
      };

      break;
    case customerConstants.CUSTOMER_VERIFICATION_PUT_FAILURE:
      state = {
        ...state,
        status: { ...state.status, CUSTOMER_VERIFICATION: action.error },
      };
      break;

    // CUSTOMER PAYMENT REPORT GET REQUEST
    case customerConstants.CUSTOMER_GET_PAYMENT_REPORT_REQUEST:
      state = {
        ...state,
        status: { ...state.status, PAYMENT_REPORT: "request" },
      };
      break;
    case customerConstants.CUSTOMER_GET_PAYMENT_REPORT_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, PAYMENT_REPORT: "success" },
      };

      break;
    case customerConstants.CUSTOMER_GET_PAYMENT_REPORT_FAILURE:
      state = {
        ...state,
        status: { ...state.status, PAYMENT_REPORT: action.error },
      };
      break;

    // CUSTOMER PAYMENT REPORT DETAILS GET REQUEST
    case customerConstants.CUSTOMER_GET_PAYMENT_REPORT_DETAILS_REQUEST:
      state = {
        ...state,
        status: { ...state.status, PAYMENT_REPORT_DETAILS: "request" },
      };
      break;
    case customerConstants.CUSTOMER_GET_PAYMENT_REPORT_DETAILS_SUCCESS:
      state = {
        ...state,
        status: { ...state.status, PAYMENT_REPORT_DETAILS: "success" },
      };

      break;
    case customerConstants.CUSTOMER_GET_PAYMENT_REPORT_DETAILS_FAILURE:
      state = {
        ...state,
        status: { ...state.status, PAYMENT_REPORT_DETAILS: action.error },
      };
      break;
    default:
      break;
  }
  return state;
}
