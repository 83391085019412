import { createBrowserHistory } from "history";
import dateFormat from "dateformat";
import isURL from "validator/lib/isURL";
import { errors } from "@_constants/errors.constants";
import { caseConstants } from "@_constants/case.constants";

export const history = createBrowserHistory();

export const catchError = (data) => {
  if (data && data !== "success" && data !== "request" && data !== "") {
    return true;
  } else return false;
};

export const highlightPlaceholder = (string) => {
  return string
    .replace("base64, ", "base64,")
    .replace(
      /({{[^{}]{1,}}})/g,
      '<span style="background-color: rgb(230, 184, 175);">$1</span>'
    );
};

export const removeClassesFromHtml = (htmlString) => {
  let htmlParser = new DOMParser();
  let parsedHtml = htmlParser.parseFromString(htmlString, "text/html");
  var stack = [],
    node,
    ii;
  stack.push(parsedHtml.childNodes[0]);

  while (stack.length > 0) {
    node = stack.pop();
    node.removeAttribute("class");
    if (node.children && node.children.length) {
      for (ii = 0; ii < node.children.length; ii += 1) {
        stack.push(node.children[ii]);
      }
    }
  }

  htmlString = parsedHtml.children[0].children[1].innerHTML;
  return htmlString;
};

export const checkPlaceholders = (string) => {
  if (!string) return false;
  const match = string.match(/({{[^{}]{1,}}})/g);
  return Boolean(match);
};

/* Checkk if user is using the gmail smtp server */
export const checkGoogleServer = (string) => {
  const isGoogle =
    string.indexOf("gmail") > -1 || string.indexOf("googlemail") > -1;
  return isGoogle;
};

/* eslint-disable no-useless-escape */

export function authHeader() {
  // return authorization header with jwt token
  let user = JSON.parse(localStorage.getItem("user"));

  if (user && user.accessToken) {
    return { Authorization: "Bearer " + user.accessToken };
  } else {
    return {};
  }
}

export function clearToken() {
  localStorage.removeItem("user");
  // TODO we shoule remove below once the BE returns a token on changeing name
  localStorage.removeItem("jwtData");
}

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (
        data.error_message &&
        data.error_message === errors.AUTH_FAILURE_INVALID_TOKEN &&
        !localStorage.getItem("invalid")
      ) {
        localStorage.setItem("invalid", "true");
        alert(
          "You have been logged out. Someone else might have logged in with the same account"
        );
        clearToken();
        history.push("/login");
      } else if (response.status === 401) {
        // auto logout if 401 response returned from api
        // for debugging purposes we let this here for some weeks
        // if everything is fine, this should be deleted with the
        // next release (in december)
        console.info("token expired. Wait for renew");
      }
      const error = (data && data.error_message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export function formatDatetime(rawDatetime, length) {
  if (!rawDatetime || rawDatetime === "0000-00-00T00:00:00Z") return "--/--/20";

  const parsedDatetime = Date.parse(rawDatetime);

  if (length === "short") {
    return dateFormat(parsedDatetime, "dd/mm/yy");
  } else if (length === "medium") {
    return dateFormat(parsedDatetime, "dd/mm/yyyy, HH:MM");
  } else if (length === "picker") {
    // 2000-01-01T00:00:00
    return dateFormat(parsedDatetime, "yyyy-mm-dd");
  } else {
    return dateFormat(parsedDatetime, "dddd, mmmm dS, yyyy, H:MM:ss");
  }
}

export function toAmountNotation(amount) {
  // check if string or number
  if (typeof amount === "number") {
    return amount.toFixed(2);
  } else if (typeof amount === "string") {
    return Number.parseFloat(amount).toFixed(2);
  } else {
    return "";
  }
}

export function getCurrencySign(currency) {
  if (currency == null) {
    return "";
  } else {
    return currency;
  }
}

export function getPathId(pathname) {
  const lastPart = pathname.split("/").splice(-1).pop();
  return lastPart === "cases" || lastPart === "" || lastPart == null
    ? null
    : lastPart;
}

export function isNull(value) {
  return value == null || value === "";
}

export function validateEmail(email) {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.toLowerCase()
  ); // eslint-disable-line
}

export function validatePasswordRules(pass) {
  return /^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@\[\]^_`{|}~])(?=.*[a-z]).{8,}$/.test(
    pass
  );
}

export function stateError(state) {
  return state === "success" || state === "request" || state === ""
    ? null
    : state;
}

export function isComponentDisabled(componentName, state) {
  /*
  - finding_popup
  - case_log_popup
  - claim_popup
  - contact_popup
  - contact_info_popup
  - contact_log_popup
  - license_popup
  - add_finding_popup

  STANDBY
  ACTIVE
  PAID
  VALID_LICENSE
  CANCELLED
  */

  var disabled = true;

  if (componentName === "finding_popup") {
    disabled =
      state !== caseConstants.CASE_STATE_STANDBY &&
      state !== caseConstants.CASE_STATE_ACTIVE
        ? true
        : false;
  } else if (componentName === "case_log_popup") {
    disabled = state !== "STANDBY" ? true : false;
  } else if (componentName === "claim_popup") {
    disabled =
      state !== caseConstants.CASE_STATE_STANDBY &&
      state !== caseConstants.CASE_STATE_ACTIVE
        ? true
        : false;
  } else if (componentName === "select_email_flow_popup") {
    disabled =
      state !== caseConstants.CASE_STATE_STANDBY &&
      state !== caseConstants.CASE_STATE_CANCELLED
        ? true
        : false;
  } else if (componentName === "contact_popup") {
    disabled = state !== "STANDBY" ? true : false;
  } else if (componentName === "contact_info_popup") {
    disabled =
      state !== caseConstants.CASE_STATE_STANDBY &&
      state !== caseConstants.CASE_STATE_ACTIVE
        ? true
        : false;
  } else if (componentName === "contact_log_popup") {
    disabled = state !== "STANDBY" ? true : false;
  } else if (componentName === "add_finding_popup") {
    disabled =
      state !== caseConstants.CASE_STATE_STANDBY &&
      state !== caseConstants.CASE_STATE_ACTIVE
        ? true
        : false;
  } else if (componentName === "license_popup") {
    disabled = state === "PAID" || state === "VALID_LICENSE" ? true : false;
  } else {
    // nothing just disabled
  }

  return disabled;
}

export function atou(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export function parseJwt(token) {
  try {
    return JSON.parse(atou(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}

export function getUserDataFromJwt() {
  return localStorage.getItem("user")
    ? parseJwt(JSON.parse(localStorage.getItem("user")).accessToken)
    : { username: "", user_role: "", customer_id: "", subscription: "" };
}

export function getEmail() {
  return JSON.parse(localStorage.getItem("user")).email;
}

export function validateDomain(domain) {
  return /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])\.[a-z]{2,}\/?[a-zA-Z0-9_-]*$/gm.test(
    domain.toLowerCase()
  ); // eslint-disable-line
}

export function validateLink(link) {
  var url = link.trim().toLowerCase();
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return isURL(url); // eslint-disable-line
  } else {
    return false;
  }
}

export function getBase64(file) {
  return new Promise((resolve) => {
    let baseURL = "";
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
}

/**
 * Shortens a string to a max amount of characters.
 *
 * @param {string} string The string which should be shortened
 * @param {number} length Max number of characters before shortening begins
 * @param {string} placeholder string which placed at the end of the string when shortening is done
 */
export function shortnString(string, length, placeholder) {
  if (!placeholder) placeholder = "...";
  if (!length) length = 20;
  if (!string) return;

  if (string.length < length) return string;
  else return string.slice(0, length).concat(placeholder);
}

/**
 * The returned Object does not contain fields which are null.
 * This is helpful when sending JSON data.
 * @param {Object} o Object to minipulate
 */
export function removeNullsFromModel(o) {
  let retval = Object.assign({}, o);
  for (const key in retval) {
    if (Object.hasOwnProperty.call(retval, key)) {
      const field = retval[key];
      if (!field) if (field !== 0) if (field !== false) delete retval[key];
      if (Array.isArray(field) === true && field.length === 0)
        delete retval[key];
    }
  }
  return retval;
}

export function escapeHTML(str) {
  return str.replace(
    /[&<>'"]/g,
    (tag) =>
      ({
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        "'": "&#39;",
        '"': "&quot;",
      }[tag])
  );
}

export function unEscapeHTML(str) {
  return str
    .replace("&amp;", "&")
    .replace("&quot;", '"')
    .replace("&#39;", "'")
    .replace("&gt;", ">")
    .replace("&lt;", "<");
}

/**
 * This snipped is based on https://web.archive.org/web/20120507054320/http://codeaid.net/javascript/convert-size-in-bytes-to-human-readable-format-(javascript)
 * @param {*} bytes
 * @param {*} decimals
 * @returns
 */
export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

/**
 * Extracts the email address from the name-address format (full name <email@address.com>)
 * @param {string} textInput The text part of the Address Ojbect of node mailer {text:'',html:''}
 */
export function extractEmailFromAddressText(textInput) {
  // the text Input should be like 'full name <email@address.com>'

  // check if we have input at all
  if (!textInput || textInput.trim() === "") return textInput;

  // check if we already have only mail address
  if (validateEmail(textInput)) return textInput;

  // We now assume that the input has the name-mail format.
  var retval = textInput;

  // first we repclase < and > to special character, then we split by that character.
  let replaced = textInput.replace("<", "||").replace(">", "||");

  let split = replaced.split("||");

  // now we search for emails in the split
  for (const item of split) {
    if (validateEmail(item)) retval = item;
  }

  return retval;
}

/**
 *
 * @param {string} htmlString
 * @returns {string}
 */
export function removeHtmlComments(htmlString) {
  while (htmlString.indexOf("<!--") !== -1) {
    const start = htmlString.indexOf("<!--");
    const end = htmlString.indexOf("-->") + 3;

    if (end !== -1) {
      htmlString = htmlString.substring(0, start) + htmlString.substring(end);
    } else {
      break;
    }
  }

  while (htmlString.indexOf("<body") !== -1) {
    const start = htmlString.indexOf("<body");
    const end = htmlString.indexOf("/body>") + 7;

    if (end !== -1) {
      htmlString = htmlString.substring(
        htmlString.indexOf(">", start + 1) + 1,
        end - 1
      );
    } else {
      break;
    }
  }

  return htmlString;
}

export function newLinesToBr(input) {
  if (!input) return input;
  if (typeof input === "string") {
    return input.replace(/(?:\r\n|\r|\n)/g, "<br>");
  } else return input;
}

export function newLinesToPTag(input) {
  if (!input) return input;
  if (typeof input !== "string") return input;

  let sentences = input.split(/(?:\r\n|\r|\n)/g);
  input = "<p>";
  let is_first_sentence_in_p = true;
  for (const sentence of sentences) {
    if (!sentence || sentence.length === 0) {
      input += "</p><p>";
      is_first_sentence_in_p = true;
    } else {
      if (is_first_sentence_in_p) {
        is_first_sentence_in_p = false;
      } else {
        input += "<br>";
      }
      input += sentence;
    }
  }
  input += "</p>";

  return input;
}

export function createNameFromMimeAndChecksum(checksum, contentType) {
  if (!checksum) return "no name available";
  if (!contentType) return checksum;

  switch (contentType) {
    // email attachements
    case "message/rfc822":
      return checksum + ".eml";
    // office
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return checksum + ".docx";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return checksum + ".xlsx";
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      return checksum + ".pptx";
    // signatures
    case "application/pkcs7-mime":
      return checksum + ".p7m";
    case "application/pgp-signature":
      return checksum + ".asc";
    case "application/pgp-encrypted":
      return checksum + ".pgp";
    // image
    case "image/jpeg":
      return checksum + ".jpg";
    case "image/png":
      return checksum + ".png";
    case "image/webp":
      return checksum + ".webp";
    case "image/gif":
      return checksum + ".gif";
    default:
      return checksum;
  }
}

export const randomColorGenerator = () => {
  return "#" + Math.floor(Math.random() * 16777215).toString(16);
};

export function translateDateRange(range) {
  let startDate = new Date();
  let endDate = new Date();
  switch (range) {
    case "7_days":
      startDate.setDate(startDate.getDate() - 7);

      break;
    case "4_weeks":
      startDate.setDate(startDate.getDate() - 28);

      break;
    case "2_weeks":
      startDate.setDate(startDate.getDate() - 14);

      break;
    case "12_months":
      startDate.setMonth(startDate.getMonth() - 12);

      break;
    case "3_months":
      startDate.setMonth(startDate.getMonth() - 3);

      break;
    case "all":
      startDate = "";
      endDate = "";
      break;

    default:
      break;
  }

  return {
    startDate: startDate,
    endDate: endDate,
  };
}
