export const casesConstants = {
  CASES_CLEAR_STATE_MESSGAE: "CASES_CLEAR_STATE_MESSGAE",

  CASES_GET_REQUEST: "CASES_GET_REQUEST",
  CASES_GET_SUCCESS: "CASES_GET_SUCCESS",
  CASES_GET_FAILURE: "CASES_GET_FAILURE",

  CASES_ADD_REQUEST: "CASES_ADD_REQUEST",
  CASES_ADD_SUCCESS: "CASES_ADD_SUCCESS",
  CASES_ADD_FAILURE: "CASES_ADD_FAILURE",

  CASES_COUNT_GET_REQUEST: "CASES_COUNT_REQUEST",
  CASES_COUNT_GET_SUCCESS: "CASES_COUNT_SUCCESS",
  CASES_COUNT_GET_FAILURE: "CASES_COUNT_FAILURE"
};
