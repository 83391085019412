import { settlementConstants } from "../@_constants/settlement_portal.constants";

const initialState = {
  settlement_details: {},
  loading: false,
  message: "",
  success: false,
  portal_address: {},
  legal_and_contact: {},
  status: {
    SETTLEMENT_GET: "",
    SETTLEMENT_PUT: "",
    // PORTAL_ADDRESS_PUT: "",
    // LEGAL_CONTACT_PUT: "",
  },
};

export function settlementReducer(state = initialState, action) {
  switch (action.type) {
    // SETTLEMENT_CLEAR_STATE_MESSGAE
    case settlementConstants.CASE_CLEAR_STATE_MESSGAE:
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, [action.stateName]: "" },
      };
      break;
    case settlementConstants.SETTLEMENT_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        status: { ...state.status, SETTLEMENT_GET: "request" },
      };
      break;
    case settlementConstants.SETTLEMENT_GET_SUCCESS:
      state = {
        ...state,
        settlement_details: action.data,
        loading: false,
        status: { ...state.status, SETTLEMENT_GET: "success" },
      };
      break;
    case settlementConstants.SETTLEMENT_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, SETTLEMENT_GET: action.error },
      };
      break;

    // SETTLEMENT PORTAL ADDRESS PUT REQUEST
    case settlementConstants.SETTLEMENT_PORTAL_ADDRESS_UPDATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, SETTLEMENT_PUT: "request" },
      };
      break;
    case settlementConstants.SETTLEMENT_PORTAL_ADDRESS_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        portal_address: action.data,
        success: true,
        status: { ...state.status, SETTLEMENT_PUT: "success" },
      };

      break;
    case settlementConstants.SETTLEMENT_PORTAL_ADDRESS_UPDATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, SETTLEMENT_PUT: action.error },
      };
      break;

    // SETTLEMENT LEGAL & CONTACT PUT REQUEST
    case settlementConstants.SETTLEMENT_LEGAL_CONTACT_UPDATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, SETTLEMENT_PUT: "request" },
      };
      break;
    case settlementConstants.SETTLEMENT_LEGAL_CONTACT_UPDATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        legal_and_contact: action.data,
        success: true,
        status: { ...state.status, SETTLEMENT_PUT: "success" },
      };

      break;
    case settlementConstants.SETTLEMENT_LEGAL_CONTACT_UPDATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, SETTLEMENT_PUT: action.error },
      };
      break;
    default:
      break;
  }
  return state;
}
