export const settlementConstants = {
  SETTLEMENT_GET_REQUEST: "SETTLEMENT_GET_REQUEST",
  SETTLEMENT_GET_SUCCESS: "SETTLEMENT_GET_SUCCESS",
  SETTLEMENT_GET_FAILURE: "SETTLEMENT_GET_FAILURE",

  SETTLEMENT_UPDATE_REQUEST: "SETTLEMENT_UPDATE_REQUEST",
  SETTLEMENT_UPDATE_SUCCESS: "SETTLEMENT_UPDATE_SUCCESS",
  SETTLEMENT_UPDATE_FAILURE: "SETTLEMENT_UPDATE_FAILURE",

  SETTLEMENT_PORTAL_ADDRESS_UPDATE_REQUEST:
    "SETTLEMENT_PORTAL_ADDRESS_UPDATE_REQUEST",
  SETTLEMENT_PORTAL_ADDRESS_UPDATE_SUCCESS:
    "SETTLEMENT_PORTAL_ADDRESS_UPDATE_SUCCESS",
  SETTLEMENT_PORTAL_ADDRESS_UPDATE_FAILURE:
    "SETTLEMENT_PORTAL_ADDRESS_UPDATE_FAILURE",

  SETTLEMENT_LEGAL_CONTACT_UPDATE_REQUEST:
    "SETTLEMENT_LEGAL_CONTACT_UPDATE_REQUEST",
  SETTLEMENT_LEGAL_CONTACT_UPDATE_SUCCESS:
    "SETTLEMENT_LEGAL_CONTACT_UPDATE_SUCCESS",
  SETTLEMENT_LEGAL_CONTACT_UPDATE_FAILURE:
    "SETTLEMENT_LEGAL_CONTACT_UPDATE_FAILURE",

  // clear status
  SETTLEMENT_CLEAR_STATUS_MESSAGE: "SETTLEMENT_CLEAR_STATUS_MESSAGE",
};
