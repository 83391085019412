import DialogPopup from "@_components/DialogPopup/DialogPopup";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { Button, DialogActions } from "@material-ui/core";
import React, { useState } from "react";

/**
 * @typedef SingleTextInputPopupProps
 * @type {object}
 * @property {string} title - Title of the popup
 * @property {boolean} open - Show/hide the popup
 * @property {function} onClose - called when the popup closed by cancel or backdrop click
 * @property {function(event)} handleTextChange - text input onChange handler
 * @property {function(event)} onSubmit - called when submit button is used (keep in mind that it will not give you the value of the textfield, use handleTextChange to save the input)
 * @property {boolean} required - if true there must be a text in the input field, else it will not submit. If set to false, empty/null value is allowed
 * @property {string} placeholder - Text of the placeholder in the input field
 * @property {string | undefined} cancelButtonText - Optional cancel button text, default: Cancel
 * @property {string | undefined} submitButtonText - Optional submit button text, default: Submit
 * @property {string} - Label of the text field
 *
 */

/**
 * A Popup with a textfield and cancel/submit button. You can set text input to required.
 *
 * @param {SingleTextInputPopupProps} param0  Configuration of the popup
 * @returns
 */
function SingleTextInputPopup({
  title,
  open,
  onClose,
  handleTextChange,
  required,
  helperText,
  onSubmit,
  placeholder,
  cancelButtonText,
  submitButtonText,
  textInputLabel,
}) {
  const [currentInput, setCurrentInput] = useState(null);
  const [isError, setIsError] = useState(false);

  /**
   * Check if text is required and text is set.
   * If yes it submits, else it will show error
   */
  const onTrySubmit = (e) => {
    if (required) {
      if (!currentInput) {
        setIsError(true);
      } else onSubmit(e);
    } else onSubmit(e);
  };
  return (
    <DialogPopup title={title} open={open} onClose={onClose}>
      <TextfieldInput
        onChange={(e) => {
          setIsError(false);
          setCurrentInput(e.target.value);
          handleTextChange(e);
        }}
        label={textInputLabel}
        placeholder={placeholder}
        required={required}
        error={required && isError}
        helperText={isError ? helperText : undefined}
      ></TextfieldInput>
      <DialogActions>
        <Button onClick={onClose} className="btn_cancel">
          {cancelButtonText ? cancelButtonText : "Cancel"}
        </Button>
        <Button onClick={(e) => onTrySubmit(e)} className="btn_confirm">
          {submitButtonText ? submitButtonText : "Submit"}
        </Button>
      </DialogActions>
    </DialogPopup>
  );
}

export default SingleTextInputPopup;
