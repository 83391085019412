export const templateConstants = {
  TEMPLATE_GET_REQUEST: "TEMPLATE_GET_REQUEST",
  TEMPLATE_GET_SUCCESS: "TEMPLATE_GET_SUCCESS",
  TEMPLATE_GET_FAILURE: "TEMPLATE_GET_FAILURE",

  TEMPLATE_POST_REQUEST: "TEMPLATE_POST_REQUEST",
  TEMPLATE_POST_SUCCESS: "TEMPLATE_POST_SUCCESS",
  TEMPLATE_POST_FAILURE: "TEMPLATE_POST_FAILURE",

  TEMPLATE_UPDATE_REQUEST: "TEMPLATE_UPDATE_REQUEST",
  TEMPLATE_UPDATE_SUCCESS: "TEMPLATE_UPDATE_SUCCESS",
  TEMPLATE_UPDATE_FAILURE: "TEMPLATE_UPDATE_FAILURE",

  TEMPLATE_DELETE_REQUEST: "TEMPLATE_DELETE_REQUEST",
  TEMPLATE_DELETE_SUCCESS: "TEMPLATE_DELETE_SUCCESS",
  TEMPLATE_DELETE_FAILURE: "TEMPLATE_DELETE_FAILURE",

  ADD_TEMPLATE: "ADD_TEMPLATE",

  SELECT_TEMPLATE: "SELECT_TEMPLATE",

  // template placeholders
  PLACEHOLDER_GET_REQUEST: "PLACEHOLDER_GET_REQUEST",
  PLACEHOLDER_GET_SUCCESS: "PLACEHOLDER_GET_SUCCESS",
  PLACEHOLDER_GET_FAILURE: "PLACEHOLDER_GET_FAILURE",

  // clear status
  TEMPLATE_CLEAR_STATUS_MESSAGE: "TEMPLATE_CLEAR_STATUS_MESSAGE",
};
