import { addressConstants } from "../@_constants/address.constants";

const initialState = {
  customer_address: {},
  loading: false,
  message: "",
  success: false,
};

export function addressReducer(state = initialState, action) {
  switch (action.type) {

    // ADDRESS GET REQUEST
    case addressConstants.ADDRESS_GET_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case addressConstants.ADDRESS_GET_SUCCESS:
      state = {
        ...state,
        customer_address: action.data,
        loading: false,
      };
      break;
    case addressConstants.ADDRESS_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
      };
      break;

      // ADDRESS PUT REQUEST
      case addressConstants.ADDRESS_UPDATE_REQUEST:
        state = {...state, loading: true, message: ""}
        break;
      case addressConstants.ADDRESS_UPDATE_SUCCESS:
        state = {...state, 
          loading: false,
          customer_address: action.data,
          success: true,
        };

        break;
      case addressConstants.ADDRESS_UPDATE_FAILURE:
        state = {...state, loading: false, message: action.error}
        break;  


    default:
      break;
  }
  return state;
}
