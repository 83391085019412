import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { caseActions } from "@_actions/case.actions";
import { stateError } from "@_utils/helpers";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./SelectTranslationPopup.module.css";

function SelectTranslationPopup(props) {
  const dispatch = useDispatch();
  const { caseId, data } = props;
  const caseReducer = useSelector((state) => {
    return state.caseReducer;
  });
  const languageReducer = useSelector((state) => {
    return state.languageReducer;
  });

  const getTranslationById = (id) => {
    return languageReducer.translations.translations.find((x) => x.id === id);
  };

  const [translation, setTranslation] = useState(
    (data.translation && data.translation.id) || "DEFAULT"
  );

  const actionClicked = (e) => {
    const translationDetails = getTranslationById(translation);
    dispatch(
      caseActions.addCaseTranslation(caseId, {
        translation_id: translationDetails?.id || "",
      })
    );
  };

  const handleInputChange = (e) => {
    const value = e.target;
    setTranslation(value.value || "");
  };

  const closePopup = () => {
    dispatch(caseActions.clearStateMessage("CASE_ADD_TRANSLATION"));
    dispatch(caseActions.getCaseWithChargeDetails(caseId, data.overview));
    props.close();
  };

  if (caseReducer.status.CASE_ADD_TRANSLATION === "success") {
    closePopup();
  }

  return (
    <DialogPopup title="Select Language" open={props.open} onClose={closePopup}>
      <div>
        <TextfieldInput
          select
          label="Translation"
          id="translation"
          name="translation"
          defaultValue={translation || "DEFAULT"}
          onChange={handleInputChange}
        >
          <MenuItem value={"DEFAULT"}>DEFAULT</MenuItem>
          {languageReducer &&
            languageReducer.translations &&
            languageReducer.translations.translations &&
            languageReducer.translations.translations.map(
              (translation, index) =>
                translation.is_active && (
                  <MenuItem key={index} value={translation.id}>
                    {translation.language.toUpperCase()}
                  </MenuItem>
                )
            )}
        </TextfieldInput>
      </div>
      {stateError(caseReducer.status.CASE_ADD_TRANSLATION) && (
        <div className={styles.error_container}>
          <div className={styles.error_text}>
            {stateError(caseReducer.status.CASE_ADD_TRANSLATION)}
          </div>
        </div>
      )}
      <div className="action_container">
        <Button className="btn_cancel" onClick={closePopup}>
          Cancel
        </Button>
        <Button className="btn_confirm" onClick={actionClicked}>
          Confirm
        </Button>
      </div>
    </DialogPopup>
  );
}

export default SelectTranslationPopup;
