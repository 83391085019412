export const dashboardConstants = {
  // SETTINGS GET
  DASHBOARD_SETTINGS_GET_REQUEST: "DASHBOARD_SETTINGS_GET_REQUEST",
  DASHBOARD_SETTINGS_GET_SUCCESS: "DASHBOARD_SETTINGS_GET_SUCCESS",
  DASHBOARD_SETTINGS_GET_FAILURE: "DASHBOARD_SETTINGS_GET_FAILURE",

  // SETTINGS SET
  DASHBOARD_SETTINGS_SET_REQUEST: "DASHBOARD_SETTINGS_SET_REQUEST",
  DASHBOARD_SETTINGS_SET_SUCCESS: "DASHBOARD_SETTINGS_SET_SUCCESS",
  DASHBOARD_SETTINGS_SET_FAILURE: "DASHBOARD_SETTINGS_SET_FAILURE",

  //KPIS GET
  DASHBOARD_KPIS_GET_REQUEST: "DASHBOARD_KPIS_GET_REQUEST",
  DASHBOARD_KPIS_GET_SUCCESS: "DASHBOARD_KPIS_GET_SUCCESS",
  DASHBOARD_KPIS_GET_FAILURE: "DASHBOARD_KPIS_GET_FAILURE",

  //TIME CHART GET
  DASHBOARD_TIME_CHART_GET_REQUEST: "DASHBOARD_TIME_CHART_GET_REQUEST",
  DASHBOARD_TIME_CHART_GET_SUCCESS: "DASHBOARD_TIME_CHART_GET_SUCCESS",
  DASHBOARD_TIME_CHART_GET_FAILURE: "DASHBOARD_TIME_CHART_GET_FAILURE",

  // clear status
  DASHBOARD_CLEAR_STATUS_MESSAGE: "DASHBOARD_STATUS_MESSAGE",
};
