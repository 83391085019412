import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const invoiceTemplateService = {
  getInvoiceTemplates,
  getInvoicesList,
  getInvoiceTemplate,
  createInvoiceTemplate,
  deleteInvoiceTemplate,
  updateInvoiceTemplate,
  previewInvoiceTemplate,
  downloadInvoice,
  downloadInvoicesList,
  cancelInvoice,
};

function getInvoiceTemplates() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/invoice_templates`,
    requestOptions
  ).then(handleResponse);
}

function getInvoicesList(filter) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify({ filter: filter }),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/customer/${getUserDataFromJwt().customer_id}/invoices`,
    requestOptions
  ).then(handleResponse);
}

function getInvoiceTemplate(invoice_template_id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/invoice_template/${invoice_template_id}`,
    requestOptions
  ).then(handleResponse);
}

function createInvoiceTemplate(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/invoice_template`,
    requestOptions
  ).then(handleResponse);
}

function updateInvoiceTemplate(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/invoice_template/${
        data.id
      }`,
    requestOptions
  ).then(handleResponse);
}

function deleteInvoiceTemplate(invoice_template_id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/invoice_template/${invoice_template_id}`,
    requestOptions
  ).then(handleResponse);
}

function previewInvoiceTemplate(data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/sample_invoice`,
    requestOptions
  );
}

function downloadInvoice(caseId, invoiceId, output_filename, action) {
  let url = Config.API_ROOT + `/cases/${caseId}/invoice/${invoiceId}`;

  return fetch(url, {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  });
}

function downloadInvoicesList(filter) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify({ filter: filter }),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/invoices/download`,
    requestOptions
  );
}

function cancelInvoice(caseId, cancelledInvoiceId) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ cancelled_invoice_id: cancelledInvoiceId }),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/cases/${caseId}/cancellation_invoice`,
    requestOptions
  ).then(handleResponse);
}
