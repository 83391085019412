import React, { useState } from "react";
import styles from "./PaymentReports.module.css";
import moment from "moment";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Button from "@material-ui/core/Button";
import DateInputSmall from "@_components/DateInput/DateInputSmall";
import SvgIcon from "@material-ui/core/SvgIcon";
import { PaymentService } from "@_services/payment.service";

function PaymentReports() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isClicked, setIsClicked] = useState(false);

  function handleStartDateChange(date) {
    setStartDate(date);
  }

  function handleEndDateChange(date) {
    setEndDate(date);
  }

  function handleSubmit(details) {
    setIsClicked(true);
    const start_date = startDate ? startDate.format("yyyy-MM-DD") : "";
    const end_date = endDate ? endDate.format("yyyy-MM-DD") : "";

    let outfile = "payment_report" + (details ? "_details" : "") + ".xlsx";
    console.log(outfile);
    PaymentService.downloadPaymentReport(
      start_date,
      end_date,
      outfile,
      "excel",
      details
    );
  }

  return (
    <div className={styles.root}>
      {" "}
      <div className={styles.title}>
        <SvgIcon
          children={
            <path d="M19 14V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zm-2 0H3V6h14v8zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zm13 0v11c0 1.1-.9 2-2 2H4v-2h17V7h2z"></path>
          }
          className={styles.icon}
        />
        <h2>Accounting</h2>
        <ArrowForwardIosIcon fontSize="small" className={styles.arrow_icon} />
        <h2>Payments</h2>
      </div>
      <p className={styles.description}>
        Download a payment overview report and a payment breakdown report for
        cases paid in the specified time range.
        <br></br>
        <br></br>
        The time range is from 00:00:00 (UTC) on the selected start date till
        23:59:59 (UTC) on the selected end date. If you do not pick any date the
        report will include all cases paid so far.
      </p>
      <div className={styles.content_container}>
        <div className={styles.date_input_container}>
          <DateInputSmall
            label="Start Date"
            id={"start_date"}
            name={"start_date"}
            value={startDate}
            className={styles.date_input}
            error={
              isClicked &&
              startDate &&
              moment(startDate).format("DD/MM/yyyy") === "Invalid date"
            }
            onChange={(date) => handleStartDateChange(date)}
            disableFuture
          />
        </div>
        <div className={styles.date_input_container}>
          <DateInputSmall
            label="End Date"
            id={"end_date"}
            name={"end_date"}
            className={styles.date_input}
            value={endDate}
            error={
              isClicked &&
              endDate &&
              moment(endDate).format("DD/MM/yyyy") === "Invalid date"
            }
            onChange={(date) => handleEndDateChange(date)}
            disableFuture
          />
        </div>

        <div>
          <Button className={styles.confirm_btn} onClick={() => handleSubmit()}>
            payment overview
          </Button>
          <Button
            className={styles.confirm_btn}
            onClick={(details) => handleSubmit(details)}
          >
            payment breakdown
          </Button>
        </div>
      </div>
      <p className={styles.description}>
        Disclaimer: The reports feature is in beta state, please use the reports
        with caution.
      </p>
    </div>
  );
}

export default PaymentReports;
