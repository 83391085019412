import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { catchError } from "@_utils/helpers";

import { Button, Paper, makeStyles } from "@material-ui/core";
import { userActions } from "@_actions/user.actions";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import styles from "./ChangeSignature.module.css";
import RichTextEditor from "@_components/RichTextEditor/RichTextEditor";

function ChangeSignature({
  userName,
  userSignature,
  setProfileName,
  setDisplayChangeSignature,
}) {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userReducer);
  const useStyles = makeStyles({
    helperText: { fontSize: "12px !important" },

    confirmButton: {
      fontWeight: "bold !important",
      color: "var(--color-theme-blue) !important",
      border: "1px solid var(--color-theme-blue) !important",

      "&:hover": {
        color: "var(--color-theme-white) !important",
        backgroundColor: "var(--color-theme-blue) !important",
        boxShadow: "2px 6px 6px  #0000002",
      },
    },
    cancelButton: {
      fontWeight: "bold !important",
      color: "var(--color-theme-silver) !important",
      border: "1px solid var(--color-theme-silver) !important",
      "&:hover": {
        color: "var(--color-theme-white) !important",
        backgroundColor: "var(--color-theme-silver) !important",
        boxShadow: "2px 6px 6px  #0000002",
      },
    },
  });
  const classes = useStyles();

  const [error, setError] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  var editorInstance = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editorInstance.current) {
      dispatch(
        userActions.newSignature({
          signature: editorInstance.current.getHtmlUnleaned(),
        })
      );
    } else {
      setError(true);
      setErrorMessage("Failed to load editor");
    }
  };

  useEffect(() => {
    setProfileName(userName);
  }, [userName, setProfileName]);

  useEffect(() => {
    if (userState.status.USER_PUT_NEW_SIGNATURE === "success") {
      dispatch(userActions.getSignature());
      setSuccessMessage(true);
      dispatch(userActions.clearStatusMessage("USER_PUT_NEW_SIGNATURE"));
      setTimeout(() => {
        setDisplayChangeSignature(false);
      }, 1000);
    } else if (catchError(userState.status.USER_PUT_NEW_SIGNATURE)) {
      setErrorMessage(userState.status.USER_PUT_NEW_SIGNATURE);
      setError(true);
    }
  }, [userState.status, setDisplayChangeSignature, dispatch]);

  const handleClickAway = () => {
    setDisplayChangeSignature(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Paper className={styles.container}>
        <h1 className={styles.changeNameHeader}>Email Signature</h1>
        <div className={styles.signatre_body}>
          <p className={styles.describtion}>
            By saving your email signature, it will be appended to all manually
            sent emails (new emails, emails from custom templates, replies to
            emails) as well as automated (scheduled or triggered) emails for
            cases that were activated by you.
          </p>
          <div style={{ textAlign: "left" }}>
            <RichTextEditor
              reference={editorInstance}
              defaultValue={userSignature}
              showAttachmentButton={false}
              useReducedButtonList={true}
              height={350}
            />
          </div>
          <div className={styles.button_container}>
            <Button
              className={classes.cancelButton}
              onClick={() => {
                setDisplayChangeSignature(false);
              }}
            >
              Cancel
            </Button>
            <Button className={classes.confirmButton} onClick={handleSubmit}>
              Save
            </Button>
          </div>
          {successMessage && (
            <div className={styles.succsse_message}>
              <CheckCircleIcon />
              You have successfully changed your Signature
            </div>
          )}
          {error && (
            <div className={styles.error_message}>
              <CheckCircleIcon />
              {errorMessage}
            </div>
          )}
        </div>
      </Paper>
    </ClickAwayListener>
  );
}
export default ChangeSignature;
