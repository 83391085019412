import {
  Button,
  FormControlLabel,
  InputLabel,
  Select,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";

import React, { useState } from "react";
import styles from "./NewEmailFlowPopup.module.css";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";

const NewEmailFlowPopup = (props) => {
  const [missingName, setMissingName] = useState(false);
  const [missingCheckbox, setMissingCheckbox] = useState(false);
  const [missingPreset, setMissingPreset] = useState(false);
  const [showPresetText, setShowPresetText] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [presetSelected, setPresetSelected] = useState(false);

  const reset = () => {
    setMissingCheckbox(false);
    setMissingName(false);
    setMissingPreset(false);
    setCheckboxValue(false);
    setPresetSelected(false);
    setShowPresetText(false);
  };

  const handleClose = () => {
    reset();
    props.onClose();
  };

  const handleSelectPreset = (event) => {
    props.onSelect(event);
    setPresetSelected(true);
  };

  const resetSelect = () => {
    setShowPresetText(false);
    setCheckboxValue(false);
    setPresetSelected(true);
  };

  const handleConfirm = () => {
    if (!props.name) {
      setMissingName(true);
      return;
    }
    if (showPresetText && !checkboxValue) {
      setMissingCheckbox(true);
      return;
    }
    if (!presetSelected) {
      setMissingPreset(true);
      return;
    }
    reset();
    props.onConfirm();
  };
  return (
    <DialogPopup
      className={styles.dialog}
      open={props.open}
      onClose={props.close}
      title="New Email Flow"
    >
      <TextfieldInput
        margin="dense"
        id="name"
        label="Name"
        type="text"
        variant="outlined"
        fullWidth
        onChange={props.onEmailFlowNameChange}
        required
        error={missingName}
      />

      <InputLabel htmlFor="select-multiple-native">
        <p>Sample Template Library</p>
      </InputLabel>
      <Select
        variant="outlined"
        fullWidth
        multiple
        native
        error={missingPreset}
        inputProps={{
          id: "select-multiple-native",
        }}
        onChange={handleSelectPreset}
      >
        <option onClick={resetSelect} value={""}>
          Empty Flow
        </option>
        {Array.isArray(props.presetNames) &&
          props.presetNames.map((name) => (
            <option
              onClick={() => setShowPresetText(true)}
              key={name}
              value={name}
            >
              {name}
            </option>
          ))}
      </Select>

      <p>
        <FormControlLabel
          required={showPresetText}
          control={
            <Checkbox
              checked={checkboxValue}
              disabled={!showPresetText}
              required={showPresetText}
              onChange={() => setCheckboxValue(!checkboxValue)}
            />
          }
          label={
            <span
              className={`${styles.checkbox_text} ${
                missingCheckbox ? styles.checkbox_missing : ""
              }`}
            >
              I am aware that the supplied Templates are for demonstration
              purposes only and I will make any necessary adaptions to ensure
              applicability to my specific licensing offer and compliance with
              any legal requirements that may apply
            </span>
          }
        />
      </p>
      <div className="action_container">
        <Button className="btn_cancel" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="btn_confirm" onClick={handleConfirm}>
          Confirm
        </Button>
      </div>
    </DialogPopup>
  );
};

export default NewEmailFlowPopup;
