import React from 'react';
import styles from './ErrorAccountCreation.module.css';
import error_dino from '@_assets/images/error_dino.png';

const ErrorAccountCreation = ({ email }) => {
  return (
    <div className={styles.errorAccountCreationContainer}>
      <img
        className={styles.errorAccountCreationImage}
        src={error_dino}
        alt="Fair Licensing Logo"
      />
      <div className={styles.errorAccountCreationTextContainer}>
        <p className={styles.errorAccountCreationTitle}>
          An Error has occurred.
        </p>
        <p className={styles.errorAccountCreationCopy}>
          Unfortunately we could not create your Fair Licensing account.
        </p>
        <p className={styles.errorAccountCreationSubtitle}>
          Please try again later or contact us at:
          <br />
          <a
            className={styles.errorAccountCreationEmail}
            href="mailto:support@fairlicensing.com"
          >
            support@fairlicensing.com
          </a>
        </p>
      </div>
    </div>
  );
};

export default ErrorAccountCreation;
