import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  checkPlaceholders,
  extractEmailFromAddressText,
  formatDatetime,
  highlightPlaceholder,
  newLinesToPTag,
  removeClassesFromHtml,
} from "@_utils/helpers";
import { caseActions } from "@_actions/case.actions";
import { casesActions } from "@_actions/cases.actions";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import ErrorMessagePopup from "../ErrorMessagePopup/ErrorMessagePopup";
import Button from "@material-ui/core/Button";
import ErrorIcon from "@material-ui/icons/Error";
import SendIcon from "@material-ui/icons/Send";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import styles from "./RenderPopup.module.css";
import { catchError } from "@_utils/helpers";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Checkbox, Menu, MenuItem, SvgIcon } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DateInputSmall from "@_components/DateInput/DateInputSmall";
import TextfieldInputSmall from "@_components/TextfieldInput/TextfieldInputSmall";
import { caseActivityActions } from "@_actions/caseActivity.actions";
import AreYouSurePopup from "@_components/popups/AreYouSurePopup/AreYouSurePopup";

import RichTextEditor from "@_components/RichTextEditor/RichTextEditor";
import { caseConstants } from "@_constants/case.constants";
import { emailConstants } from "@_constants/email_flows.constants";
import AddressComponent from "./AddressComponent/AddressComponent";

const defaultState = {
  to: "",
  subject: "",
  content: "",
  // bcc: '',
};

function RenderPopup(props) {
  const {
    data,
    caseId,
    emailSetup,
    activity,
    resumeAutomation,
    email,
    replyToAll,
    JwtData,
    isReplyMode,
    title,
  } = props;
  const activityType = activity?.content?.type;
  var editorInstance = useRef(null);
  const closePopup = props.close;
  const [attachments, setAttachments] = useState([]);

  const caseReducer = useSelector((state) => state.caseReducer);
  const userReducer = useSelector((state) => state.userReducer);
  const autoHideDuration = 3000;
  const contentRef = useRef(null);
  const dispatch = useDispatch();
  // Check for the missing placeholder on the first data loading
  const [missingPlaceholder, setMissingPlaceholder] = React.useState(false);
  // Update the content of text Editor change
  const [editedText, setEditedText] = React.useState();
  // Display send component or copy to clip base on the user server setup
  const [sendEmailTag, setSendEmailTag] = React.useState(true);
  const [inputs, setInputs] = useState(defaultState);

  // Snackbar and error message popup
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // ReminderMenu
  const [reminderMenuOpen, setReminderMenuOpen] = useState(null);
  const [reminderSetting, setReminderSetting] = useState("in 7 days");
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [reminderDate, setReminderDate] = useState(null);
  const [doSaveDeadline, setDoSaveDeadline] = useState(
    isReplyMode ? false : true
  );
  const [reminderName, setReminderName] = useState("Follow Up: ");
  const [showSaveReminderPopup, setShowSaveReminderPopup] = useState(false);
  const [showAreYouSurePopup, setShowAreYouSurePopup] = useState(false);
  const [deleteReminder, setDeleteReminder] = useState(false);
  /**
   * Key of autocomplete --> needed to rerender the component after accepting a email
   */
  useEffect(() => {
    if (isReplyMode || data.name === emailConstants.NEW_MAIL)
      setReminderName("Follow up");
    else setReminderName("Follow Up: " + data.name);
  }, [data.name, isReplyMode]);
  // save copy paste email to log dialog
  const [open, setOpen] = useState(false);

  const handleOpenPopup = () => {
    setOpen(true);
    copyTextToClipboard(contentRef.current);
  };

  const handleClosePopup = () => {
    setOpen(false);
  };

  const handleOpenReminderMenu = (event) => {
    setReminderMenuOpen(event.currentTarget);
  };

  const handleCloseReminderMenu = () => {
    setReminderMenuOpen(null);
  };

  const handleChangeReminderOption = (numberOrCustom) => {
    if (typeof numberOrCustom === "number") {
      setReminderSetting("in " + numberOrCustom + " days");
      handleCloseReminderMenu();
      setShowDatePicker(false);
    } else {
      // activate datepicker
      setReminderSetting("on");
      handleCloseReminderMenu();
      setShowDatePicker(true);
    }
  };

  const setTextToContent = (content, goToFirstLine) => {
    content && setMissingPlaceholder(checkPlaceholders(content));
    const newContent = newLinesToPTag(content && highlightPlaceholder(content));
    if (editorInstance.current && editorInstance.current.setContents) {
      editorInstance.current.setContents("");
      editorInstance.current.insertHtmlNoNewLine(newContent, goToFirstLine);
    }
    setEditedText(newContent);
  };

  const Alert = (props) => (
    <MuiAlert elevation={6} variant="filled" {...props} />
  );
  /**
   * Initial load of data --> editor content is set as well as subject and to field.
   * Only called once at the beginning.
   */
  useEffect(() => {
    if (isReplyMode && email) {
      if (loading === true) return;
      // This is necessary because of the current popup implementation
      setErrorSnackbar(false);
      setSuccessSnackbar(false);

      if (!emailSetup) {
        setSendEmailTag(false);
      } else {
        setSendEmailTag(true);
      }

      // get email signature
      var emailSignature = "";
      if (
        userReducer.user_signature &&
        typeof userReducer.user_signature === "string" &&
        userReducer.user_signature.length > 0
      )
        emailSignature = userReducer.user_signature;

      /**
       * We extend the mail with the reply header
       */
      var html = `<span><br></span><span><br></span>
    <div> ${emailSignature} </div> 
    <span><br></span>
    <hr style="display:inline-block;width:98%;color:black" tabindex="-1"></hr>
    <div id="divRplyFwdMsg" dir="ltr">
      <font face="Calibri, sans-serif" style="font-size:11pt" color="#000000">
      <b>From:</b> ${
        email.from && email.from.length > 0 ? email.from[0].address : ""
      }<br>
      <b>Sent:</b>  ${
        props.isSelfReply
          ? formatDatetime(email.created)
          : formatDatetime(email.data.date)
      }<br>
      <b>To:</b> ${
        email.to && email.to.length > 0 ? email.to[0].address : ""
      }<br>
      <b>Subject:</b> ${email.data.subject}</font>
      <div>&nbsp;</div></div>
      ${
        typeof email.data.html === "boolean"
          ? email.data.textAsHtml
          : "<div>" + newLinesToPTag(email.data.html) + "</div>"
      }
    `;

      // update the editor
      setContent(html);

      let emails = "";
      let ccMail = "";

      // find target email source
      let source = "from";
      if (props.isSelfReply === true) source = "to";

      // is reply all active?
      if (replyToAll === true && source === "from") {
        emails = extractEmailFromAddressText(
          email.from && email.from.length
            ? email.from[0].address !== JwtData.smtp_email
              ? email.from[0].address
              : ""
            : ""
        );
        // clean with refactoring add cc and to as one list
        if (email.to && email.to.length) {
          email.to.forEach((email) => {
            if (email.address !== JwtData.smtp_email) {
              emails =
                emails.length > 0
                  ? emails + ";" + extractEmailFromAddressText(email.address)
                  : extractEmailFromAddressText(email.address);
            }
          });
        }
        if (email.data.cc && email.data.cc.value.length) {
          email.data.cc.value.forEach((email) => {
            if (email.address !== JwtData.smtp_email) {
              ccMail =
                ccMail.length > 0
                  ? ccMail + ";" + extractEmailFromAddressText(email.address)
                  : extractEmailFromAddressText(email.address);
            }
          });
        }
      }

      // update inner state accordingly
      setInputs({
        to: extractEmailFromAddressText(
          email[source] && email[source].length
            ? // email could be email itself or in an array
              Array.isArray(email[source])
              ? email[source][0].address
              : email[source]
            : ""
        ),
        subject: "Re: " + email.data.subject,
        cc: replyToAll && ccMail ? ccMail : "",
        content: html, // TODO: format answer
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReplyMode, props.open]);
  // this will update the editor when its loaded to initial value
  useEffect(() => {
    if (!isReplyMode) {
      if (editorInstance.current && editorInstance.current.insertHTML) {
        var emailSignature = "";
        if (
          userReducer.user_signature &&
          typeof userReducer.user_signature === "string" &&
          userReducer.user_signature.length > 0
        ) {
          emailSignature = userReducer.user_signature;
          setTextToContent(
            "<div>" +
              (data.content !== null &&
              data.content !== "" &&
              data.content.length > 0
                ? data.content
                : "<p>&nbsp;</ br></p>") +
              "</div>" +
              "<div>" +
              emailSignature +
              "</div>",
            true
          );
        } else setTextToContent(data.content, true);
      }
    }
    // this effect must be executed when editor instance is set.
    // Or when the popup gets opened/closed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorInstance.current, data.content, props.open, isReplyMode]);

  useEffect(() => {
    if (!isReplyMode) {
      // This is necessary because of the current popup implementation
      setErrorSnackbar(false);
      setSuccessSnackbar(false);

      if (!emailSetup) {
        setSendEmailTag(false);
      } else {
        setSendEmailTag(true);
      }

      setTextToContent(data.content, true);
      setInputs({
        to: data.opponentEmail,
        subject: data.subject,
        content: data.content,
      });
    }
  }, [data.content, data.opponentEmail, data.subject, emailSetup, isReplyMode]);

  const setContent = (data) => {
    setMissingPlaceholder(checkPlaceholders(data));
    setTextToContent(data);
  };

  function copyTextToClipboard(element) {
    function listener(e) {
      e.clipboardData.setData(
        "text/html",
        removeClassesFromHtml(editorInstance.current.getHtmlUnleaned())
      );
      e.preventDefault();
    }

    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);

    var sel, range;
    if (window.getSelection && document.createRange) {
      sel = window.getSelection();
      if (sel.toString() === "") {
        window.setTimeout(function () {
          range = document.createRange();
          range.selectNodeContents(element);
          sel.removeAllRanges();
          sel.addRange(range);
        }, 1);
      }
    } else if (document.selection) {
      sel = document.selection.createRange();
      if (sel.text === "") {
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      }
    }
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: value });
  }
  const setReminderChecked = (event) => {
    setDoSaveDeadline(Boolean(event.target.checked));
    if (caseReducer.case[caseId].reminder_date) {
      setShowAreYouSurePopup(!Boolean(event.target.checked));
    }
  };

  const handleDeleteReminder = () => {
    setDeleteReminder(true);
    setShowAreYouSurePopup(false);
  };

  const handleAreYouSurePopupClose = () => {
    setShowAreYouSurePopup(false);
  };

  function saveDeadline(doOpenEmailLogPopup) {
    // fetch infos
    let dateInput = new Date();
    if (doSaveDeadline) {
      if (reminderSetting === "custom") {
        dateInput = reminderDate;
      } else {
        switch (reminderSetting) {
          case "in 3 days":
            dateInput.setDate(dateInput.getDate() + 3);
            break;
          case "in 5 days":
            dateInput.setDate(dateInput.getDate() + 5);
            break;
          case "in 7 days":
            dateInput.setDate(dateInput.getDate() + 7);
            break;
          case "in 14 days":
            dateInput.setDate(dateInput.getDate() + 14);
            break;
          case "in 30 days":
            dateInput.setDate(dateInput.getDate() + 30);
            break;
          default:
            break;
        }
      }
      dispatch(
        caseActions.updateReminder(caseId, {
          name: reminderName,
          date: dateInput,
        })
      );
    } else if (deleteReminder) {
      dispatch(caseActions.updateReminder(caseId));
      setDeleteReminder(false);
    }
    setShowSaveReminderPopup(false);
    setTimeout(() => dispatch(casesActions.getCases(), 500));
    if (doOpenEmailLogPopup) handleOpenPopup();
    return true;
  }

  const sendEmail = (e) => {
    e.preventDefault();
    if (!missingPlaceholder && inputs.to && inputs.subject && inputs.content) {
      if (!resumeAutomation && saveDeadline()) {
        setTextToContent(
          removeClassesFromHtml(editorInstance.current.getHtmlUnleaned())
        );
      } else console.log("todo snackbar");
      if (resumeAutomation) {
        resumeAutomation();
      }
      if (activity) {
        dispatch(
          caseActions.addCaseSendEmail(caseId, inputs, attachments, activity.id)
        );
      } else
        dispatch(caseActions.addCaseSendEmail(caseId, inputs, attachments));
    }
  };

  const handleCopyEmail = () => {
    if (doSaveDeadline === true) {
      setShowSaveReminderPopup(true);
    } else if (deleteReminder) {
      dispatch(caseActions.updateReminder(caseId));
      setDeleteReminder(false);
      handleOpenPopup();
    } else {
      setOpen(true);
    }
    setTimeout(() => dispatch(casesActions.getCases(), 500));
  };

  useEffect(() => {
    setInputs((inputs) => ({
      ...inputs,
      content: editedText,
    }));
  }, [editedText]);

  // display template POST success & error
  useEffect(() => {
    const responseStatus = caseReducer.status.CASE_SEND_EMAIL;
    if (responseStatus && responseStatus === "success") {
      setSuccessMessage("Email was sent!");
      setSuccessSnackbar(true);
      setLoading(false);

      //TODO check if timeout is needed here

      //TODO check if timeout is needed here
      setTextToContent(data.content);
      dispatch(caseActivityActions.getCaseActivities(caseId));
      dispatch(caseActivityActions.restEmailLoaded());
      dispatch(caseActions.clearStateMessage("CASE_SEND_EMAIL"));
      closePopup();
    }
    if (catchError(responseStatus)) {
      dispatch(caseActions.clearStateMessage("CASE_SEND_EMAIL"));
      setErrorMessage(caseReducer.message);
      setErrorSnackbar(true);
      setLoading(false);
    } else return;
  }, [
    dispatch,
    caseReducer.status.CASE_SEND_EMAIL,
    caseReducer.message,
    data.content,
    closePopup,
    caseId,
    resumeAutomation,
    activityType,
  ]);

  // display email log  POST success & error
  useEffect(() => {
    const responseStatus = caseReducer.status.CASE_SAVE_EMAIL_TO_LOG;
    if (responseStatus && responseStatus === "success") {
      setSuccessMessage("Email was saved!");
      setSuccessSnackbar(true);
      setLoading(false);

      setTimeout(() => {
        closePopup();
        dispatch(caseActions.clearStateMessage("CASE_SAVE_EMAIL_TO_LOG"));
        setTimeout(() => {
          setTextToContent(data.content);
          dispatch(caseActivityActions.getCaseActivities(caseId));
          dispatch(caseActivityActions.restEmailLoaded());
        }, 300);
      }, 400);
    }
    if (catchError(responseStatus)) {
      dispatch(caseActions.clearStateMessage("CASE_SAVE_EMAIL_TO_LOG"));
      setErrorMessage(caseReducer.message);
      setErrorSnackbar(true);
      setLoading(false);
    } else return;
  }, [
    dispatch,
    caseReducer.status.CASE_SAVE_EMAIL_TO_LOG,
    caseReducer.message,
    data.content,
    caseId,
    closePopup,
  ]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSuccessSnackbar(false);
    setErrorSnackbar(false);
  };

  useEffect(() => {
    if (caseReducer.status.CASE_SEND_EMAIL === "request") {
      setLoading(true);
    }
  }, [caseReducer.status.CASE_SEND_EMAIL]);

  const handleSaveEmailToLog = () => {
    if (!missingPlaceholder && inputs.to && inputs.subject && inputs.content) {
      dispatch(caseActions.addEmailToLog(caseId, inputs, activity?.id));
      if (resumeAutomation) {
        resumeAutomation();
      }
    } else {
      setErrorMessage(
        "Please fill missing information (content, subject,recipient email and placeholders (if needed)"
      );
      setErrorSnackbar(true);
      setOpen(false);
    }
  };

  useEffect(() => {
    if (caseReducer.status.CASE_SAVE_EMAIL_TO_LOG === "success") {
      setOpen(false);
    }
  }, [caseReducer.status.CASE_SAVE_EMAIL_TO_LOG]);

  const addAttachment = () => {
    // Create input Element
    const link = document.createElement("input");
    link.type = "file";
    link.onchange = (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(file);

      /* 
          lastModified:1539873569000
          lastModifiedDate:Thu Oct 18 2018 16:39:29 GMT+0200 (Mitteleuropäische Sommerzeit) {}
          name:"ip_command.pdf"
          size:89608
          type: "application/pdf"
          webkitRelativePath:""
          */
      reader.onloadend = () => {
        setAttachments((oldVal) => {
          return [
            ...oldVal,
            ...[
              {
                filename: file.name,
                content: Buffer(reader.result).toString("base64"),
                contentType: file.type,
                encoding: "base64",
              },
            ],
          ];
        });
      };
    };

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  return (
    <DialogPopup
      title={
        <span className={styles.title}>
          <EmailRoundedIcon className={styles.title_icon} />
          {title ||
            `Email preview: ${
              data.name === emailConstants.NEW_MAIL ? "New email" : data.name
            }`}
        </span>
      }
      open={props.open}
      onClose={(e, reason) => {
        if (reason === "backdropClick") return;
        setAttachments([]);
        props.close();
      }}
      size={"large"}
      disableEscapeKeyDown={true}
    >
      <Snackbar
        open={successSnackbar}
        autoHideDuration={autoHideDuration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>

      {errorSnackbar && (
        <ErrorMessagePopup
          popupTitle="Sending email failed"
          open={errorSnackbar}
          close={() => setErrorSnackbar(false)}
          errorMessage={errorMessage}
        />
      )}
      <div className={styles.send_email_title}>
        <div className={styles.send_btn_container}>
          {loading && (
            <div className={styles.loading}>
              <CircularProgress />
            </div>
          )}
          {!loading &&
            sendEmailTag &&
            caseReducer.case[caseId].state !==
              caseConstants.CASE_STATE_STANDBY && (
              <button
                className={
                  missingPlaceholder || !inputs.to || !inputs.subject
                    ? styles.send_email_btn_disabled
                    : styles.send_email_btn
                }
                onClick={sendEmail}
                disabled={missingPlaceholder || !inputs.to || !inputs.subject}
              >
                <SendIcon
                  className={
                    missingPlaceholder
                      ? styles.send_icon_disabled
                      : styles.send_icon
                  }
                />
                SEND
              </button>
            )}
        </div>
        <AddressComponent
          emailTo={inputs.to}
          emailCc={inputs.cc}
          handleEmailToChanged={(s) =>
            handleInputChange({ target: { name: "to", value: s } })
          }
          handleSubjectChanged={(s) =>
            handleInputChange({ target: { name: "subject", value: s } })
          }
          handleEmailBccChanged={(s) =>
            handleInputChange({ target: { name: "bcc", value: s } })
          }
          handleEmailCcChanged={(s) =>
            handleInputChange({ target: { name: "cc", value: s } })
          }
          isReplyMode={isReplyMode}
          sendEmailTag={sendEmailTag}
          subject={inputs.subject}
        />
      </div>

      <div
        className={
          missingPlaceholder
            ? styles.placeholder_missing
            : styles.placeholder_missing_none
        }
      >
        <ErrorIcon className={styles.placeholder_message_icon} />
        <p className={styles.placeholder_message}>
          The marked elements do not have a value. Please add the missing
          information.
        </p>
      </div>
      <div
        style={{ maxHeight: "61vh", overflowY: "auto", overflowX: "hidden" }}
        ref={contentRef}
      >
        <RichTextEditor
          addAttachmentCallback={addAttachment}
          setAttachmentsCallback={(newAttachment) =>
            setAttachments((oldVal) => [...oldVal, newAttachment])
          }
          setMissingPlaceholderCallback={setMissingPlaceholder}
          setContentCallback={setContent}
          reference={editorInstance}
        />
      </div>
      {attachments && attachments.length > 0 && (
        <div className={styles.attachments_row}>
          <div className={styles.attachment_prefix}>Attachments: </div>
          {attachments.map((x) => {
            return (
              <div className={styles.attachment_item}>
                {x.filename}
                {
                  <SvgIcon
                    className={styles.attachment_delete_icon}
                    onClick={(e) =>
                      setAttachments(attachments.filter((y) => y !== x))
                    }
                    children={
                      <>
                        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                      </>
                    }
                  ></SvgIcon>
                }
              </div>
            );
          })}
        </div>
      )}
      <div className="action_container">
        {caseReducer.case[caseId].state !== caseConstants.CASE_STATE_STANDBY &&
          !resumeAutomation && (
            <div className={styles.reminder_container}>
              <Checkbox
                className={styles.reminder_container_content}
                checked={doSaveDeadline}
                onChange={setReminderChecked} // todo seems wrong
              ></Checkbox>
              <div
                style={{ marginTop: 12 }}
                className={styles.reminder_container_content}
              >
                set reminder
              </div>

              <Button
                style={{ marginTop: 5, marginLeft: 12 }}
                variant="outlined"
                color="default"
                className={styles.reminder_container_content}
                onClick={handleOpenReminderMenu}
                endIcon={<ArrowDropDownIcon />}
              >
                {
                  <div className={styles.menu_item}>
                    {" "}
                    <p className={styles.menu_item_span}>{reminderSetting}</p>
                  </div>
                }
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={reminderMenuOpen}
                keepMounted={false}
                open={Boolean(reminderMenuOpen)}
                onClose={handleCloseReminderMenu}
              >
                <MenuItem
                  className={styles.email_setup_dropdown}
                  onClick={() => handleChangeReminderOption(3)}
                >
                  <div className={styles.menu_item}>
                    <p>in 3 days</p>{" "}
                  </div>
                </MenuItem>
                <MenuItem
                  className={styles.email_setup_dropdown}
                  onClick={() => handleChangeReminderOption(5)}
                >
                  <div className={styles.menu_item}>
                    <p>in 5 days</p>{" "}
                  </div>
                </MenuItem>
                <MenuItem
                  className={styles.email_setup_dropdown}
                  onClick={() => handleChangeReminderOption(7)}
                >
                  <div className={styles.menu_item}>
                    <p>in 7 days</p>{" "}
                  </div>
                </MenuItem>
                <MenuItem
                  className={styles.email_setup_dropdown}
                  onClick={() => handleChangeReminderOption(14)}
                >
                  <div className={styles.menu_item}>
                    <p>in 14 days</p>{" "}
                  </div>
                </MenuItem>
                <MenuItem
                  className={styles.email_setup_dropdown}
                  onClick={() => handleChangeReminderOption(30)}
                >
                  <div className={styles.menu_item}>
                    <p>in 30 days</p>{" "}
                  </div>
                </MenuItem>
                <MenuItem
                  className={styles.email_setup_dropdown}
                  onClick={() => handleChangeReminderOption("custom")}
                >
                  <div className={styles.menu_item}>
                    <p>custom</p>{" "}
                  </div>
                </MenuItem>
              </Menu>
              {showDatePicker && (
                <DateInputSmall
                  style={{
                    width: 150,
                    display: "inline-block",
                    float: "left",
                    marginLeft: 12,
                    marginTop: 5,
                  }}
                  label="Date"
                  id="reminder_date"
                  name="reminder_date"
                  value={reminderDate}
                  onChange={(date) => setReminderDate(date)}
                  minDate={new Date().setDate(new Date().getDate() + 1)}
                />
              )}
              <div
                style={{ marginTop: 12, marginLeft: 12, marginRight: 12 }}
                className={styles.reminder_container_content}
              ></div>
              <TextfieldInputSmall
                style={{
                  width: 180,
                  display: "inline-block",
                  float: "left",
                  marginTop: "5px",
                }}
                label="Name"
                id="reminder_title"
                name="reminder_title"
                type="string"
                value={reminderName}
                onChange={(event) => setReminderName(event.target.value)}
              />
            </div>
          )}
        <Button
          className="btn_cancel"
          onClick={() => {
            setAttachments([]);
            setErrorSnackbar(false);
            dispatch(caseActions.clearStateMessage("CASE_SEND_EMAIL"));
            props.close();
          }}
        >
          Close
        </Button>
        {!sendEmailTag && (
          <Button
            className={
              missingPlaceholder
                ? `${styles.btn_disabled}`
                : ` btn_confirm ${styles.btn_confirm}`
            }
            onClick={handleCopyEmail}
            disabled={missingPlaceholder}
          >
            Copy to Clipboard
          </Button>
        )}
      </div>

      <AreYouSurePopup
        open={open}
        title="Content Copied!"
        close={() => handleClosePopup()}
        onClick={() => handleSaveEmailToLog()}
        confirmBtn="Ok"
        cancelBtn="Cancel"
      >
        Copied email will be saved in the Case Activities
      </AreYouSurePopup>

      <AreYouSurePopup
        open={showSaveReminderPopup}
        title="Content Copied!"
        close={() => {
          setShowSaveReminderPopup(false);
          handleOpenPopup();
        }}
        onClick={() => saveDeadline(true)}
      >
        Would you like activate the Reminder now?
      </AreYouSurePopup>

      <AreYouSurePopup
        open={showAreYouSurePopup}
        close={handleAreYouSurePopupClose}
        onClick={handleDeleteReminder}
      >
        You currently have a reminder set: Would you like to delete it?
      </AreYouSurePopup>
    </DialogPopup>
  );
}
export default RenderPopup;
