import React from 'react';
import { useSelector } from 'react-redux';
import styles from './LinkSent.module.css';

import InfoIcon from '@material-ui/icons/Info';

function LinkSent(props) {
  const userReducer = useSelector((state) => state.userReducer);
  const email =
    userReducer.forgot_password_details.email &&
    userReducer.forgot_password_details.email;

  return (
    <div className={styles.loginForm}>
      <div className={styles.loginFormHeader}>FORGOT PASSWORD?</div>
      <div className={styles.link_sent_content}>
        <InfoIcon className={styles.info_icon} />
        <p>We have sent the password reset link to {email}</p>
        <p> Please check your email account.</p>
        <div>
          <button
            className={styles.go_to_login_button_gray}
            onClick={() => props.handleCancel()}
          >
            BACK TO LOGIN PAGE
          </button>
        </div>
      </div>
    </div>
  );
}

export default LinkSent;
