const Config = {
  // Account
  BUSINESS_DETAILS: {
    key: 'BUSINESS_DETAILS',
    name: 'Business Details',
  },
  USERS: {
    key: 'USERS',
    name: 'Users',
  },
  SUBSCRIPTION: {
    key: 'SUBSCRIPTION',
    name: 'Subscription',
  },
  EMAIL_SETUP: {
    key: 'EMAIL_SETUP',
    name: 'Email Setup',
  },
  PAYMENT_SETUP: {
    key: 'PAYMENT_SETUP',
    name: 'Payment Setup',
  },
};

export default Config;
