import Config from "@_configs/api.config";
import { authHeader, handleResponse } from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const casesService = {
  getCases,
  addCases,
  getCasesCount,
};

/**
 *
 * @param {{filter:undefined|
 * {search:string
 * ,unread_emails:boolean
 * ,unchecked_licenses: boolean
 * ,overdue_reminders: boolean
 * ,failed_emails: boolean
 * ,paused_scheduled_emails: boolean
 * ,action_required: boolean}
 * ,sort:undefined| {key:string,ascending:boolean}
 * ,paging:{page:number,page_size:number}}} searchObject
 * @returns
 */
function getCases(searchObject) {
  let requestOptions = {
    method: "POST",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  if (searchObject) {
    requestOptions.body = JSON.stringify(searchObject);
  }

  return fetch(Config.API_ROOT + "/cases/_search", requestOptions).then(
    handleResponse
  );
}

function addCases(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + "/cases/create", requestOptions).then(
    handleResponse
  );
}

function getCasesCount() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(Config.API_ROOT + "/cases/_count", requestOptions).then(
    handleResponse
  );
}
