import { dashboardConstants } from "@_constants/dashboard.constants";
import { dashboardService } from "@_services/dashboard.service";

export const dashboardActions = {
  clearStatusMessage,
  getUserSettings,
  setUserSettings,
  getCustomerKpis,
  getTimeBasedChart,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return {
      type: dashboardConstants.DASHBOARD_CLEAR_STATUS_MESSAGE,
      stateName,
    };
  }
}

function getUserSettings() {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getUserSettings().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: dashboardConstants.DASHBOARD_SETTINGS_GET_REQUEST };
  }
  function success(data) {
    return {
      type: dashboardConstants.DASHBOARD_SETTINGS_GET_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.DASHBOARD_SETTINGS_GET_FAILURE,
      error,
    };
  }
}

function setUserSettings(settings) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.setUserSettings(settings).then(
      (data) => {
        dispatch(success(settings));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: dashboardConstants.DASHBOARD_SETTINGS_SET_REQUEST };
  }
  function success(settings) {
    return {
      type: dashboardConstants.DASHBOARD_SETTINGS_SET_SUCCESS,
      settings,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.DASHBOARD_SETTINGS_SET_FAILURE,
      error,
    };
  }
}

function getCustomerKpis() {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getCustomerKpis().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: dashboardConstants.DASHBOARD_KPIS_GET_REQUEST };
  }
  function success(data) {
    return {
      type: dashboardConstants.DASHBOARD_KPIS_GET_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.DASHBOARD_KPIS_GET_FAILURE,
      error,
    };
  }
}

function getTimeBasedChart(chartClass, settings, index) {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getTimeBasedChart(chartClass, settings).then(
      (data) => {
        dispatch(success(chartClass, data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
  function request() {
    return { type: dashboardConstants.DASHBOARD_TIME_CHART_GET_REQUEST };
  }
  function success(chartClass, data) {
    return {
      type: dashboardConstants.DASHBOARD_TIME_CHART_GET_SUCCESS,
      chartClass,
      data,
      index,
    };
  }
  function failure(error) {
    return {
      type: dashboardConstants.DASHBOARD_TIME_CHART_GET_FAILURE,
      error,
    };
  }
}
