import { customerConstants } from "@_constants/customer.constants";
import { customerService } from "@_services/customer.service";

export const customerActions = {
  clearStatusMessage,
  sendVerficationEmail,
  verifiedCustomer,
  getPaymentReport,
  getPaymentReportDetails,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return { type: customerConstants.CUSTOMER_CLEAR_STATUS_MESSAGE, stateName };
  }
}

function sendVerficationEmail(data) {
  return (dispatch) => {
    dispatch(request(data));
    customerService.sendVerficationEmail(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_EMAIL_REQUEST,
      data,
    };
  }
  function success(data) {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_EMAIL_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_EMAIL_FAILURE,
      error,
    };
  }
}

function verifiedCustomer(id) {
  return (dispatch) => {
    dispatch(request(id));
    customerService.verifiedCustomer(id).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_PUT_REQUEST,
      id,
    };
  }
  function success(data) {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_PUT_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_PUT_FAILURE,
      error,
    };
  }
}

function getPaymentReport(startDate, endDate) {
  return (dispatch) => {
    dispatch(request(startDate, endDate));
    customerService.getPaymentReport(startDate, endDate).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_PUT_REQUEST,

      startDate,
      endDate,
    };
  }
  function success(data) {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_PUT_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_PUT_FAILURE,
      error,
    };
  }
}

function getPaymentReportDetails(startDate, endDate) {
  return (dispatch) => {
    dispatch(request(startDate, endDate));
    customerService.getPaymentReportDetails(startDate, endDate).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_PUT_REQUEST,
      startDate,
      endDate,
    };
  }
  function success(data) {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_PUT_SUCCESS,
      data,
    };
  }
  function failure(error) {
    return {
      type: customerConstants.CUSTOMER_VERIFICATION_PUT_FAILURE,
      error,
    };
  }
}
