import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";
const fetch = fetch_helper.fetch;

export const dashboardService = {
  getUserSettings,
  setUserSettings,
  getCustomerKpis,
  getTimeBasedChart,
};

function getUserSettings() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/user/dashboard_settings`,
    requestOptions
  ).then(handleResponse);
}

function setUserSettings(settings) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(settings),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/user/dashboard_settings`,
    requestOptions
  ).then(handleResponse);
}

function getCustomerKpis() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT + `/customer/${getUserDataFromJwt().customer_id}/kpi`,
    requestOptions
  ).then(handleResponse);
}

function getTimeBasedChart(chartClass, settings) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(settings),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/time_based_chart?chart_class=${chartClass}  `,
    requestOptions
  ).then(handleResponse);
}
