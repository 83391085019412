import { subscriptionConstants } from '@_constants/subscription.constants';
const initialState = {
  subscriptionConfig: {},
  loading: false,
  message: '',
  status: {
    SUBSCRIPTION_GET: '',
  },
};
export function subscriptionReducer(state = initialState, action) {
  switch (action.type) {
    case subscriptionConstants.SUBSCRIPTION_GET_REQUEST:
      state = {
        ...state,
        loading: false,
        message: '',
        status: { ...state.status, SUBSCRIPTION_GET: 'request' },
      };
      break;
    case subscriptionConstants.SUBSCRIPTION_GET_SUCCESS:
      state = {
        ...state,
        subscriptionConfig: action.data,
        loading: false,
        message: '',
        status: { ...state.status, SUBSCRIPTION_GET: 'success' },
      };
      break;
    case subscriptionConstants.SUBSCRIPTION_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, SUBSCRIPTION_GET: action.error },
      };
      break;

    default:
      break;
  }

  return state;
}
