import { casesConstants } from "@_constants/cases.constants";
import { caseConstants } from "@_constants/case.constants";

const initialState = {
  cases: [],
  count: 0,
  searchObject: {},
  cases_count: {
    all: "...",
    VALID_LICENSE: "...",
    PAYMENT_PROCESSING: "...",
    CANCELLED: "...",
    ACTIVE: "...",
    STANDBY: "...",
    PAID: "...",
  },
  loading: false,
  message: "",
  status: {
    CASES_GET: "",
    CASES_ADD: "",
    CASES_COUNT_GET: "",
  },
};

export function casesReducer(state = initialState, action) {
  switch (action.type) {
    // CASE_CLEAR_STATE_MESSGAE
    case casesConstants.CASES_CLEAR_STATE_MESSGAE:
     
      state = {
        ...state,
        loading: false,
        message: "",
        status: { ...state.status, [action.stateName]: "" },
      };
      break;

    case casesConstants.CASES_GET_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASES_GET: "request" },
      };
      break;
    case casesConstants.CASES_GET_SUCCESS:
      let newCases = [];
      if (state.cases && action.data.resetCases !== true && !action.data.isUpdate)
        newCases = state.cases;
      state = {
        ...state,
        loading: false,
        searchObject: action.data.isUpdate
          ? state.searchObject
          : action.data.searchObject,
        message: "",
        cases: [...newCases, ...action.data.cases.cases],
        count: action.data.cases.total_count,
        status: { ...state.status, CASES_GET: "success" },
      };
      break;
    case casesConstants.CASES_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASES_GET: action.error },
      };
      break;

    case casesConstants.CASES_ADD_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        status: { ...state.status, CASES_ADD: "request" },
      };
      break;
    case casesConstants.CASES_ADD_SUCCESS:
      localStorage.setItem("case_uuid", action.case_uuid);
      state = {
        ...state,
        loading: false,
        message: action.case_uuid,
        status: { ...state.status, CASES_ADD: "success" },
      };
      break;
    case casesConstants.CASES_ADD_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, CASES_ADD: action.error },
      };
      break;

    case caseConstants.CASEACTIVITY_EMAILMARKUNREAD_SUCCESS:
      if (action.data.case_id === null) return state;
      state = {
        ...state,
        loading: false,
        cases: state.cases.map((val, i) =>
          val.id === action.data.case_id
            ? { ...val, total_unread_emails: val.total_unread_emails + 1 }
            : val
        ),
      };
      break;

    case caseConstants.CASEACTIVITY_EMAILCONTENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        cases: state.cases.map((val, i) =>
          val.id === action.data.case_id
            ? {
                ...val,
                total_unread_emails: Math.max(
                  val.total_unread_emails - (action.data.isUnread ? 1 : 0),
                  0
                ),
              }
            : val
        ),
      };
      break;

    case caseConstants.CASE_TOGGLE_AUTOMATION_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        status: {
          ...state.status,
          CASE_TOGGLE_AUTOMATION: "success",
        },
        cases: state.cases.map((val, i) => {
          if (val.id === action.id) {
            return {
              ...val,
              num_scheduled_emails_paused:
                action.state === caseConstants.CASE_AUTOMATION_STATE_SCHEDULED
                  ? 0
                  : val.num_scheduled_emails_scheduled,

              num_scheduled_emails_scheduled:
                action.state === caseConstants.CASE_AUTOMATION_STATE_PAUSED
                  ? 0
                  : val.num_scheduled_emails_paused,
            };
          }
          return val;
        }),
      };
      break;

    case caseConstants.CASE_DECREASE_FAILED:
      state = {
        ...state,
        loading: false,
        message: "",
        status: {
          ...state.status,
          CASE_DECREASE_FAILED: "success",
        },
        cases: state.cases.map((val, i) => {
          if (val.id === action.caseId) {
            return {
              ...val,
              [action.typeFailedEmails]:
                val[action.typeFailedEmails] > 0
                  ? val[action.typeFailedEmails] - 1
                  : 0,
            };
          }
          return val;
        }),
      };
      break;

    case casesConstants.CASES_COUNT_GET_REQUEST:
      state = {
        ...state,
        cases_count: {
          all: "...",
          VALID_LICENSE: "...",
          PAYMENT_PROCESSING: "...",
          CANCELLED: "...",
          ACTIVE: "...",
          STANDBY: "...",
          PAID: "...",
        },
      };
      break;
    case casesConstants.CASES_COUNT_GET_SUCCESS:
      state = {
        ...state,
        cases_count: action.counts,
      };
      break;
    case casesConstants.CASES_COUNT_GET_FAILURE:
      state = {
        ...state,
        cases_count: {
          all: "N/A",
          VALID_LICENSE: "N/A",
          PAYMENT_PROCESSING: "N/A",
          CANCELLED: "N/A",
          ACTIVE: "N/A",
          STANDBY: "N/A",
          PAID: "N/A",
        },
      };
      break;
    default:
      break;
  }

  return state;
}
