import React, { useState, useEffect } from "react";
import styles from "./SpfSetup.module.css";
import { useSelector } from "react-redux";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import ValidateEmailSetupPopup from "@_components/popups/ValidateEmailSetupPopup/ValidateEmailSetupPopup";

import { Button } from "@material-ui/core";

function SpfSetup() {
  const emailSetupReducer = useSelector((state) => state.emailSetupReducer);

  const [senderEmail, setSenderEmail] = useState(
    emailSetupReducer.email_setup.spf_email || ""
  );

  useEffect(() => {
    setSenderEmail(emailSetupReducer.email_setup.spf_email || "");
  }, [emailSetupReducer.email_setup.spf_email]);

  const handleInputChange = (e) => {
    setSenderEmail(e.target.value);
  };

  return (
    <div className={styles.root}>
      <p>SPF Setup</p>
      <div className={styles.spf_info_container}>
        <ul>
          <li>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet.
          </li>
          <li>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua.
          </li>
          <li>
            At vero eos et accusam et justo duo dolores et ea rebum. Stet clita
            kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit
            amet.
          </li>
          <li>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet.
          </li>
        </ul>
      </div>
      <div>
        <p className={styles.label}>
          Sender Email <b>(required)</b>{" "}
        </p>
        <div className={styles.input_container}>
          {" "}
          <TextfieldInput
            id={"spf_email"}
            name={"spf_email"}
            value={senderEmail}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.btn_container_validate}>
        <Button className={styles.validate_btn}>validate my setup</Button>
      </div>
      {emailSetupReducer.email_setup &&
      emailSetupReducer.email_setup.active_email_setup !== "SPF" ? (
        <div className={styles.btn_container}>
          <Button className={styles.save_activate_btn}>Save & Activate</Button>
        </div>
      ) : (
        <div className={styles.btn_container}>
          <Button className={styles.save_activate_btn}>Save</Button>
        </div>
      )}

      <ValidateEmailSetupPopup
      // open={openValidatePopup}
      // close={() => setOpenValidatePopup(false)}
      // encryptionData={encryptionRadioBtn}
      // id={jwtData.customer_id}
      // smtpData={smtpDetails}
      />
    </div>
  );
}

export default SpfSetup;
