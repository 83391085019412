import React, { useState, useEffect } from "react";
import styles from "./TranslationRow.module.css";

import { useDispatch, useSelector } from "react-redux";
import { languageActions } from "@_actions/language.actions";

import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import FlagIcon from "@material-ui/icons/Flag";
import Tooltip from "@material-ui/core/Tooltip";
import CheckIcon from "@material-ui/icons/Check";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import LanguageList from "@_configs/LanguageList.config";
function TranslationRow(props) {
  let translation = props.translation;

  const dispatch = useDispatch();
  const anchorRef = React.useRef(null);
  const languageReducer = useSelector((state) => state.languageReducer);

  const [openEditPopup, setOpenEditPopup] = useState(false);

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenEditPopup(false);
    }
  };

  const handleOpenEditPopup = (e) => {
    setOpenEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setOpenEditPopup(false);
  };

  const handleDeleteTranslation = (translation) => {
    let data = {
      language: translation,
    };
    dispatch(languageActions.deleteTranslation(data));
  };

  const handleSelectTranslation = (data) => {
    dispatch(languageActions.selectTranslation(data));
  };

  useEffect(() => {
    if (languageReducer.status.TRANSLATION_DELETE === "success") {
      dispatch(languageActions.selectTranslation(null));
      dispatch(languageActions.getTranslations());
    }
  }, [dispatch, languageReducer.status.TRANSLATION_DELETE]);

  return (
    <div>
      <div
        key={translation.language}
        onClick={() => handleSelectTranslation(translation)}
        className={
          (languageReducer.selected_translation &&
            languageReducer.selected_translation.language) ===
          translation.language
            ? styles.translation_row_root_selected
            : styles.translation_row_root
        }
      >
        <div className={styles.short_language}>
          <p>
            <b> {translation.language.toUpperCase()}</b>
          </p>
        </div>
        <div className={styles.long_language}>
          <p>
            {LanguageList.find(
              (lang) => lang.code === translation.language
            )?.language.toUpperCase() || "UNKNOWN"}
          </p>
        </div>
        <div className={styles.default_translation}>
          {languageReducer.translations.default_translation_id ===
            translation.id && (
            <Tooltip title={<p>default</p>}>
              <FlagIcon fontSize="small" />
            </Tooltip>
          )}
        </div>
        <div className={styles.active_translation}>
          {translation.is_active === 1 && (
            <Tooltip title={<p>active</p>}>
              <CheckIcon fontSize="small" />
            </Tooltip>
          )}
        </div>

        <div className={styles.default_translation}>
          <MoreHorizIcon
            fontSize="small"
            className={styles.btn_add_language}
            onClick={(e) => handleOpenEditPopup()}
            ref={anchorRef}
            aria-controls={openEditPopup ? "menu-list-grow" : undefined}
            aria-haspopup="true"
          />
          <Popper
            open={openEditPopup}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            className={styles.edit_popup}
          >
            <div>
              <Paper>
                <ClickAwayListener onClickAway={handleCloseEditPopup}>
                  <MenuList
                    autoFocusItem={openEditPopup}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={(e) =>
                        handleDeleteTranslation(translation.language)
                      }
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </div>
          </Popper>
        </div>
      </div>
    </div>
  );
}

export default TranslationRow;
