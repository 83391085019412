export const emailSetupConstants = {
  EMAIL_SETUP_GET_REQUEST: "EMAIL_SETUP_GET_REQUEST",
  EMAIL_SETUP_GET_SUCCESS: "EMAIL_SETUP_GET_SUCCESS",
  EMAIL_SETUP_GET_FAILURE: "EMAIL_SETUP_GET_FAILURE",

  //   EMAIL_POST_REQUEST: "EMAIL_POST_REQUEST",
  //   EMAIL_POST_SUCCESS: "EMAIL_POST_SUCCESS",
  //   EMAIL_POST_FAILURE: "EMAIL_POST_FAILURE",

  OUTBOUND_EMAIL_UPDATE_REQUEST: "OUTBOUND_EMAIL_UPDATE_REQUEST",
  OUTBOUND_EMAIL_UPDATE_SUCCESS: "OUTBOUND_EMAIL_UPDATE_SUCCESS",
  OUTBOUND_EMAIL_UPDATE_FAILURE: "OUTBOUND_EMAIL_UPDATE_FAILURE",

  OUTBOUND_EMAIL_ACTIVATE_REQUEST: "OUTBOUND_EMAIL_ACTIVATE_REQUEST",
  OUTBOUND_EMAIL_ACTIVATE_SUCCESS: "OUTBOUND_EMAIL_ACTIVATE_SUCCESS",
  OUTBOUND_EMAIL_ACTIVATE_FAILURE: "OUTBOUND_EMAIL_ACTIVATE_FAILURE",

  OUTBOUND_EMAIL_VALIDATE_REQUEST: "OUTBOUND_EMAIL_VALIDATE_REQUEST",
  OUTBOUND_EMAIL_VALIDATE_SUCCESS: "OUTBOUND_EMAIL_VALIDATE_SUCCESS",
  OUTBOUND_EMAIL_VALIDATE_FAILURE: "OUTBOUND_EMAIL_VALIDATE_FAILURE",

  INBOUND_EMAIL_ACTIVATE_REQUEST: "INBOUND_EMAIL_ACTIVATE_REQUEST",
  INBOUND_EMAIL_ACTIVATE_SUCCESS: "INBOUND_EMAIL_ACTIVATE_SUCCESS",
  INBOUND_EMAIL_ACTIVATE_FAILURE: "INBOUND_EMAIL_ACTIVATE_FAILURE",

  //   EMAIL_DELETE_REQUEST: "EMAIL_DELETE_REQUEST",
  //   EMAIL_DELETE_SUCCESS: "EMAIL_DELETE_SUCCESS",
  //   EMAIL_DELETE_FAILURE: "EMAIL_DELETE_FAILURE",

  // clear status
  EMAIL_SETUP_CLEAR_STATUS_MESSAGE: "EMAIL_SETUP_CLEAR_STATUS_MESSAGE",
};
