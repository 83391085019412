import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import { emailActions } from "@_actions/email_flows.actions";

function RenameFlow({
  styles,
  handleSubmitInput,
  handleInputChange,
  name,
  prevValue,
  id,
  onClickOutside,
  inputChange,
}) {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const refName = useRef(null);
  useEffect(() => {
    refName.current.select();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (name) {
        dispatch(
          emailActions.updateEmail(id, {
            name: name,
          })
        );
      }
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside, dispatch, id, name]);

  return (
    <div ref={ref} className="info-box">
      <div className={styles.email_row_root} key={id}>
        <AccountTreeIcon className={styles.account_tree_icon} />

        <form onSubmit={(e) => handleSubmitInput(e)}>
          <b>
            <input
              ref={refName}
              className={styles.email_flow_input}
              value={inputChange ? name : prevValue}
              name="name"
              onChange={(e) => handleInputChange(e)}
              maxLength="30"
              defaultValue={prevValue}
              placeholder={!inputChange && prevValue}
            />
          </b>
        </form>
      </div>
    </div>
  );
}
export default RenameFlow;
