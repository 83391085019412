import Config from "@_configs/api.config";
import {
  authHeader,
  getUserDataFromJwt,
  handleResponse,
} from "@_utils/helpers";
import fetch_helper from "@_utils/fetch_helper";

const fetch = fetch_helper.fetch;

export const emailService = {
  getEmail,
  addEmail,
  updateEmail,
  deleteEmail,
  getEmailFlowPresets,
};

function getEmail(id) {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };
  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/config/email_flows`,
    requestOptions
  ).then(handleResponse);
}

function addEmail(data) {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${getUserDataFromJwt().customer_id}/config/email_flows`,
    requestOptions
  ).then(handleResponse);
}

function updateEmail(email_flow_id, data) {
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify(data),
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/email_flows/${email_flow_id}`,
    requestOptions
  ).then(handleResponse);
}

function deleteEmail(email_flow_id) {
  const requestOptions = {
    method: "DELETE",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/email_flows/${email_flow_id}`,
    requestOptions
  ).then(handleResponse);
}

function getEmailFlowPresets() {
  const requestOptions = {
    method: "GET",
    headers: { ...authHeader(), "Content-Type": "application/json" },
  };

  return fetch(
    Config.API_ROOT +
      `/customer/${
        getUserDataFromJwt().customer_id
      }/config/email_flows/presets`,
    requestOptions
  ).then(handleResponse);
}
