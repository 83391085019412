import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import styles from "./InvoiceTemplateRow.module.css";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import { Button } from "@material-ui/core";
import classNames from "classnames";
import { invoiceTemplateActions } from "@_actions/invoiceTemplate.actions";

function InvoiceTemplateRow(props) {
  const { data } = props;
  let template = data;
  const invoiceTemplateReducer = useSelector(
    (state) => state.invoiceTemplateReducer
  );
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();
  const [isSelected, setIsSelected] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [isDeletionPopupOpen, setIsDeletionPopupOpen] = useState(false);

  //   useEffect(() => {
  //     dispatch(caseTemplateActions.getCaseTemplates(template.id));
  //     eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [data]);

  useEffect(() => {
    invoiceTemplateReducer.selectedTemplate === template.id
      ? setIsSelected(true)
      : setIsSelected(false);
  }, [invoiceTemplateReducer.selectedTemplate, template.id]);

  const handleOpenEditPopup = (e) => {
    setOpenEditPopup(true);
  };

  const handleCloseEditPopup = () => {
    setOpenEditPopup(false);
  };

  const handleDeleteInvoiceTemplate = (id) => {
    if (isSelected) {
      dispatch(invoiceTemplateActions.selectInvoiceTemplate(null));
      setIsSelected(false);
    }
    dispatch(invoiceTemplateActions.deleteInvoiceTemplate(template.id));
  };

  useEffect(() => {
    if (invoiceTemplateReducer.status.INVOICE_TEMPLATE_DELETE === "success")
      setIsDeletionPopupOpen(false);
  }, [dispatch, invoiceTemplateReducer.status.INVOICE_TEMPLATE_DELETE]);

  const handleSelectTemplate = (templateId) => {
    dispatch(invoiceTemplateActions.addInvoiceTemplateState(false));
    setIsSelected(true);
    dispatch(invoiceTemplateActions.selectInvoiceTemplate(templateId));
  };

  useEffect(() => {
    invoiceTemplateReducer.selected_template === template.id
      ? setIsSelected(true)
      : setIsSelected(false);
  }, [invoiceTemplateReducer.selected_template, template.id]);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenEditPopup(false);
    }
  }

  const handleCopyInvoiceTemplate = (id) => {
    dispatch(invoiceTemplateActions.addInvoiceTemplateState(id));
    dispatch(invoiceTemplateActions.selectInvoiceTemplate(null));
  };

  return (
    <div>
      <div
        className={classNames(
          styles.invoice_template_row_root,
          isSelected ? styles.root_active : styles.root_inactive
        )}
        key={template.id}
        onClick={() => handleSelectTemplate(template.id)}
      >
        <ListItem className={styles.template_list_item}>
          <b className={styles.input_container}>{template.name}</b>
          <div className={styles.edit_icon_container}>
            <MoreHorizIcon
              className={styles.edit_icon}
              onClick={handleOpenEditPopup}
              ref={anchorRef}
              aria-controls={openEditPopup ? "menu-list-grow" : undefined}
              aria-haspopup="true"
            />
            <Popper
              open={openEditPopup}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              className={styles.drop_down}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleCloseEditPopup}>
                  <MenuList
                    autoFocusItem={openEditPopup}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          handleCopyInvoiceTemplate(template.id);
                          setOpenEditPopup(false);
                        }}
                      >
                        Duplicate
                      </MenuItem>
                    }
                    <MenuItem
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        setIsDeletionPopupOpen(true);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Popper>
          </div>
        </ListItem>
      </div>

      <DialogPopup
        title="Confirmation required"
        open={isDeletionPopupOpen}
        onClose={() => setIsDeletionPopupOpen(false)}
      >
        <div className={styles.deletionPopupText}>
          Do you really want to delete this Template?
        </div>

        <div className={styles.deletionPopupButtonContainer}>
          <Button
            className={styles.button_confirm}
            onClick={() => handleDeleteInvoiceTemplate(template.id)}
          >
            Yes
          </Button>
          <Button
            className={styles.button_cancel}
            onClick={() => setIsDeletionPopupOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </DialogPopup>
    </div>
  );
}

export default InvoiceTemplateRow;
