const CurrencyList = [
  { label: "Euro", id: "EUR" },
  { label: "British pound", id: "GBP" },
  { label: "Swiss franc", id: "CHF" },
  { label: "U.S. dollar", id: "USD" },
  { label: "Australian dollar", id: "AUD" },
  { label: "Canadian dollar", id: "CAD" },
  { label: "Norwegian krone", id: "NOK" },
  { label: "Polish zloty", id: "PLN" },
  { label: "Romanian leu", id: "RON" },
  { label: "Bulgarian lev", id: "BGN" },
  { label: "Croatian kuna", id: "HRK" },
  { label: "Russian ruble", id: "RUB" },
  { label: "South Korean won", id: "KRW" },
  { label: "Danish krone", id: "DKK" },
];

export default CurrencyList;
