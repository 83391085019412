import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import DialogPopup from "@_components/DialogPopup/DialogPopup";
import Button from "@material-ui/core/Button";
import TextfieldInput from "@_components/TextfieldInput/TextfieldInput";
import { caseActions } from "@_actions/case.actions";
import styles from "./ContactInfoPopup.module.css";
import { isComponentDisabled, isNull, validateEmail } from "@_utils/helpers";
import Genders from "@_configs/Genders.config";
import { MenuItem } from "@material-ui/core";

const componetTag = "contact_info_popup";
function ContactInfoPopup(props) {
  const { opponentData, data } = props;
  const inputDisabled = isComponentDisabled(componetTag, data.state);
  const dispatch = useDispatch();

  const [isClicked, setIsClicked] = useState(false);
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    setInputs({
      first_name: (opponentData && opponentData.first_name) || "",
      last_name: (opponentData && opponentData.last_name) || "",
      company: (opponentData && opponentData.company) || "",
      gender: (opponentData && opponentData.gender) || "",
      title: (opponentData && opponentData.title) || "",
      email: (opponentData && opponentData.email) || "",
      phone_number: (opponentData && opponentData.phone_number) || "",
      address_line_1: (opponentData && opponentData.address_line_1) || "",
      address_line_2: (opponentData && opponentData.address_line_2) || "",
      postal_code: (opponentData && opponentData.postal_code) || "",
      city: (opponentData && opponentData.city) || "",
      country: (opponentData && opponentData.country) || "",
    });
  }, [opponentData]);

  function handleInputChange(e) {
    const { id, value, name } = e.target;
    setInputs((inputs) => ({ ...inputs, [id || name]: value }));
  }

  function actionClicked() {
    setIsClicked(true);

    var inputCopy = { ...inputs };

    if (isNull(inputCopy.email)) return;
    else {
      inputCopy.email = inputs.email.trim();
      if (!validateEmail(inputCopy.email)) return;
    }
    if (!inputs.gender) {
      inputCopy.gender = "NOT_KNOWN";
    }
    dispatch(caseActions.updateCaseOpponentDetails(props.caseId, inputCopy));
    // TODO: snackbar and error handling before closing the popup
    props.close();
  }

  const handleCancel = () => {
    props.close();
    setInputs({
      first_name: (opponentData && opponentData.first_name) || "",
      last_name: (opponentData && opponentData.last_name) || "",
      company: (opponentData && opponentData.company) || "",
      gender: (opponentData && opponentData.gender) || "",
      title: (opponentData && opponentData.title) || "",
      email: (opponentData && opponentData.email) || "",
      phone_number: (opponentData && opponentData.phone_number) || "",
      address_line_1: (opponentData && opponentData.address_line_1) || "",
      address_line_2: (opponentData && opponentData.address_line_2) || "",
      postal_code: (opponentData && opponentData.postal_code) || "",
      city: (opponentData && opponentData.city) || "",
      country: (opponentData && opponentData.country) || "",
    });
  };

  return (
    <DialogPopup
      title="Website Owner Contact Info"
      open={props.open}
      onClose={() => handleCancel()}
    >
      <div className={styles.content_container}>
        <div className={styles.contact_container}>
          <TextfieldInput
            label="Email"
            id="email"
            value={inputs.email}
            onChange={handleInputChange}
            disabled={inputDisabled}
            error={
              isClicked &&
              (isNull(inputs.email) || !validateEmail(inputs.email))
            }
            required
          />

          <div className={styles.TwoInputs}>
            <TextfieldInput
              label="Gender"
              select
              id="gender"
              name="gender"
              className={styles.subInput}
              value={inputs.gender}
              onChange={handleInputChange}
              disabled={inputDisabled}
            >
              {Genders.map((gender) => (
                <MenuItem key={gender.id} value={gender.id}>
                  {`${gender.label} `}
                </MenuItem>
              ))}
            </TextfieldInput>
            <TextfieldInput
              label="Title"
              id="title"
              className={styles.subInput}
              value={inputs.title}
              onChange={handleInputChange}
              disabled={inputDisabled}
            />
          </div>

          <TextfieldInput
            label="First Name"
            id="first_name"
            value={inputs.first_name}
            onChange={handleInputChange}
            disabled={inputDisabled}
          />
          <TextfieldInput
            label="Last Name"
            id="last_name"
            value={inputs.last_name}
            onChange={handleInputChange}
            disabled={inputDisabled}
          />

          <TextfieldInput
            label="Phone"
            id="phone_number"
            value={inputs.phone_number}
            onChange={handleInputChange}
            disabled={inputDisabled}
          />
        </div>

        <div className={styles.contact_container}>
          <TextfieldInput
            label="Company Name"
            id="company"
            value={inputs.company}
            onChange={handleInputChange}
            disabled={inputDisabled}
          />
          <TextfieldInput
            label="Address Line 1"
            id="address_line_1"
            value={inputs.address_line_1}
            onChange={handleInputChange}
            disabled={inputDisabled}
          />
          <TextfieldInput
            label="Address Line 2"
            id="address_line_2"
            value={inputs.address_line_2}
            onChange={handleInputChange}
            disabled={inputDisabled}
          />
          <TextfieldInput
            label="City"
            id="city"
            value={inputs.city}
            onChange={handleInputChange}
            disabled={inputDisabled}
          />

          <div className={styles.TwoInputs}>
            <TextfieldInput
              label="Postal Code"
              id="postal_code"
              className={styles.subInput}
              value={inputs.postal_code}
              onChange={handleInputChange}
              disabled={inputDisabled}
            />

            <TextfieldInput
              label="Country"
              id="country"
              className={styles.subInput}
              value={inputs.country}
              onChange={handleInputChange}
              disabled={inputDisabled}
            />
          </div>
        </div>
      </div>
      <div className="action_container">
        {!inputDisabled ? (
          <>
            <Button className="btn_cancel" onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button
              className="btn_confirm"
              onClick={actionClicked}
              disabled={inputDisabled}
            >
              Confirm
            </Button>
          </>
        ) : (
          <Button className="btn_cancel" onClick={() => props.close()}>
            Close
          </Button>
        )}
      </div>
    </DialogPopup>
  );
}

export default ContactInfoPopup;
