import React, { useState, useEffect } from "react";
import styles from "./InvoiceTemplates.module.css";
import { Button, Snackbar } from "@material-ui/core";
import InvoiceTemplateRow from "@_components/InvoiceTemplateRow/InvoiceTemplateRow";
import InvoiceTemplateEditor from "@_components/InvoiceTemplateEditor/invoiceTemplateEditor";
import { invoiceTemplateActions } from "@_actions/invoiceTemplate.actions";
import { useDispatch, useSelector } from "react-redux";
import { catchError } from "@_utils/helpers";
import { Alert } from "@material-ui/lab";
import Scrollbar from "react-perfect-scrollbar-z";
import "scrollbar.css";

function InvoiceTemplates() {
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const invoiceTemplateReducer = useSelector(
    (state) => state.invoiceTemplateReducer
  );

  useEffect(() => {
    dispatch(invoiceTemplateActions.getInvoiceTemplates());
  }, [dispatch]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
    setOpenErrorSnackbar(false);
    setErrorMessage("");
    setSuccessMessage("");
  };

  const handleAddInvoiceTemplate = () => {
    dispatch(invoiceTemplateActions.addInvoiceTemplateState(true));
    dispatch(invoiceTemplateActions.selectInvoiceTemplate(null));
  };

  useEffect(() => {
    if (
      invoiceTemplateReducer.status.INVOICE_TEMPLATE_CREATE === "success" ||
      invoiceTemplateReducer.status.INVOICE_TEMPLATE_UPDATE === "success"
    ) {
      setSuccessMessage("Success!");
      setOpenSnackbar(true);
      dispatch(invoiceTemplateActions.getInvoiceTemplates());
      dispatch(invoiceTemplateActions.addInvoiceTemplateState(false));
      dispatch(
        invoiceTemplateActions.clearStatusMessage("INVOICE_TEMPLATE_CREATE")
      );
      dispatch(
        invoiceTemplateActions.clearStatusMessage("INVOICE_TEMPLATE_UPDATE")
      );
    } else if (
      catchError(invoiceTemplateReducer.status.INVOICE_TEMPLATE_CREATE)
    ) {
      setErrorMessage(invoiceTemplateReducer.status.INVOICE_TEMPLATE_CREATE);
      setOpenErrorSnackbar(true);
      dispatch(
        invoiceTemplateActions.clearStatusMessage("INVOICE_TEMPLATE_CREATE")
      );
    } else if (
      catchError(invoiceTemplateReducer.status.INVOICE_TEMPLATE_UPDATE)
    ) {
      setErrorMessage(invoiceTemplateReducer.status.INVOICE_TEMPLATE_UPDATE);
      setOpenErrorSnackbar(true);
      dispatch(
        invoiceTemplateActions.clearStatusMessage("INVOICE_TEMPLATE_UPDATE")
      );
    } else return;
  }, [
    dispatch,
    invoiceTemplateReducer.status.INVOICE_TEMPLATE_CREATE,
    invoiceTemplateReducer.status.INVOICE_TEMPLATE_UPDATE,
  ]);

  useEffect(() => {
    if (catchError(invoiceTemplateReducer.status.INVOICE_TEMPLATE_PREVIEW)) {
      setErrorMessage(invoiceTemplateReducer.status.INVOICE_TEMPLATE_PREVIEW);
      setOpenErrorSnackbar(true);
      dispatch(
        invoiceTemplateActions.clearStatusMessage("INVOICE_TEMPLATE_PREVIEW")
      );
    } else return;
  }, [dispatch, invoiceTemplateReducer.status.INVOICE_TEMPLATE_PREVIEW]);

  return (
    <div className={styles.root}>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success">
          {successMessage}
        </Alert>
      </Snackbar>
      {errorMessage && (
        <Snackbar
          open={openErrorSnackbar}
          autoHideDuration={4000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      <div className={styles.template_list_container}>
        <Scrollbar maxHeight="calc(100vh - 140px)">
          <div className={styles.add_template_container}>
            <Button
              style={{ backgroundColor: "white" }}
              onClick={handleAddInvoiceTemplate}
              variant="outlined"
            >
              Add invoice Template
            </Button>
          </div>
          {invoiceTemplateReducer.templates &&
            invoiceTemplateReducer.templates.map((template, index) => {
              return (
                <InvoiceTemplateRow
                  data={template}
                  key={template.id}
                  index={index}
                />
              );
            })}
        </Scrollbar>
      </div>
      <div className={styles.template_container}>
        {(invoiceTemplateReducer.selected_template &&
          Object.keys(invoiceTemplateReducer.selected_template).length !== 0) ||
        invoiceTemplateReducer.add_template ? (
          <InvoiceTemplateEditor
            data={
              invoiceTemplateReducer.add_template === true
                ? {}
                : typeof invoiceTemplateReducer.add_template === "string"
                ? {
                    ...invoiceTemplateReducer.templates.find(
                      (x) => x.id === invoiceTemplateReducer.add_template
                    ),
                    id: undefined,
                    name:
                      invoiceTemplateReducer.templates.find(
                        (x) => x.id === invoiceTemplateReducer.add_template
                      ).name + " - Copy",
                  }
                : invoiceTemplateReducer.templates.find(
                    (x) => x.id === invoiceTemplateReducer.selected_template
                  )
            }
            // we need to change the key when add_template is true (this is when new invoice template is clicked)
            // without random key it will not re-rendered when you copy first and then click new template, because key is null
            // in both cases
            key={
              invoiceTemplateReducer.add_template === true
                ? Math.random().toString
                : invoiceTemplateReducer.selected_template
            }
          />
        ) : null}
      </div>
    </div>
  );
}
export default InvoiceTemplates;
