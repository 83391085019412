const subscriptionCopy = {
  translation: {
    lite: {
      title: 'Fair Licensing Lite',
      subtitle: 'Includes only one translation.',
      copy_1: 'Go Pro to add a translation.',
      upgradeButton: 'Upgrade to Pro',
    },
    pro: {
      title: 'Fair Licensing Pro',
      subtitle: 'You have used up your two included translations.',
      copy_1: 'Go Enterprise to add a translation.',
      upgradeButton: 'Upgrade to Enterprise',
    },
    enterprise: {},
  },
  emailFlow: {
    lite: {
      title: 'Fair Licensing Lite',
      subtitle: 'Includes one email flow only.',
      copy_1: 'Go Pro to use the advantages of up to three email flows.',
      upgradeButton: 'Upgrade to Pro',
    },
    pro: {
      title: 'Fair Licensing Pro',
      subtitle: 'you have reached your limit of three email flows.',
      copy_1: 'Go Enterprise to use the advantages of unlimited email flows.',
      upgradeButton: 'Upgrade to Enterprise',
    },
    enterprise: {},
  },
  addUser: {
    lite: {
      title: 'Fair Licensing Lite',
      subtitle: 'You have reached the limit of included seat.',
      copy_1: 'Go Pro to build your team.',
      copy_2:
        'Fair Licensing Pro gives you the opportunity to add additional seats.',
      upgradeButton: 'Upgrade to Pro',
    },
    pro: {},
    enterprise: {},
  },
  subscription: {
    title: 'Upgrade Coming Soon!',
    subtitle: 'Thank you for your interest in upgrading to a paid plan.',
    copy_1:
      'We are in the middle of a few finishing touches to some features included in the paid plans. An upgrade will be availabe soon.',
    copy_2: 'Thanks for your patience.',
    upgradeButton: '',
  },
};
export default subscriptionCopy;
